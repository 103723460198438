import React, { useState } from "react"
import * as Images from "../../../utilities/images";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavItem } from "react-bootstrap";
import { useAuthSelector } from "../../../redux/selector/auth";
import { useLocation, useNavigate } from "react-router-dom";

const MainNav = () => {
    const navigate = useNavigate(); 
    const location = useLocation();
    const authSelector = useAuthSelector();

    const [activeTab, setActiveTab] = useState("");
    const step = authSelector?.userInfo?.user?.step <= 6 || authSelector?.userStep?.step == 6;
    const button = authSelector?.userInfo?.user?.step == 6;
    const complete_step = authSelector?.userStep?.step == 6;
    const token = localStorage.getItem("authToken")
    const path = location.pathname;
    
    return (
        <>
            <div className={`authNavBar navbarContain mainNavBar ${path === '/small-business' ? 'membershipAuthNav' : path === '/terms-condition' ? 'membershipAuthNav' : path === '/privacy-policy' ? 'membershipAuthNav' : path === '/frequent-question' ? 'membershipAuthNav' : path === '/contact-us' ? 'membershipAuthNav'  : path === '/membershipchooseperrole' ? 'membershipAuthNav' : path === '/enterprise-business' ? 'membershipAuthNav' : ''}`} >
                <Navbar  className={`${path == "/" ? "homePage" : ""}`} expand="lg">
                    <div className="container"  >
                        <Navbar.Brand href="/">
                            <img src={Images.logo} alt="logo" className="img-fluid" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0">
                            <i className="las la-bars barIcon"></i>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Item>
                                    <Nav.Link href="/about-us"
                                        className={activeTab == 'about' ? 'active' : ''}
                                        onClick={() => setActiveTab('about')}
                                    >About</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/#membership"
                                    >Membership</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/#buyers"
                                    >Buyers</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        href="/#suppliers"
                                    >Suppliers</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/#opportunities"
                                        className={activeTab == 'opportunities' ? 'active' : ''}
                                        onClick={() => setActiveTab('opportunities')}
                                    >Opportunities</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/#community"
                                        className={activeTab == 'community' ? 'active' : ''}
                                        onClick={() => setActiveTab('community')}
                                    >Community</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/resource"
                                        className={activeTab == 'resources' ? 'active' : ''}
                                        onClick={() => setActiveTab('resources')}
                                    >Resources</Nav.Link>
                                </Nav.Item>
                                {token && step && button ?
                                    <div className="loginDetails ms-lg-4 ms-0">
                                        <NavItem>
                                            <Nav.Link href="/dashboard" className="primaryBtn">Dashboard</Nav.Link>
                                        </NavItem>
                                    </div> : button ?
                                        <div className="loginDetails ms-lg-4 ms-0 d-block d-md-flex">
                                            <Nav.Item>
                                                <Nav.Link href="/login"><b>Login Account</b></Nav.Link>
                                            </Nav.Item>
                                            {/* <NavItem>
                                                <Nav.Link href="/chooserolesfor=signup" className="primaryBtn d-inline-block">Get Started</Nav.Link>
                                            </NavItem> */}
                                        </div> : complete_step ?
                                            <div className="loginDetails ms-lg-4 ms-0">
                                                <NavItem>
                                                    <Nav.Link href="/dashboard" className="primaryBtn">Dashboard</Nav.Link>
                                                </NavItem>
                                            </div> :
                                            <div className="loginDetails ms-lg-4 ms-0 d-block d-md-flex">
                                                <Nav.Item>
                                                    <Nav.Link href="/login"><b>Login Account</b></Nav.Link>
                                                </Nav.Item>
                                                {/* <NavItem>
                                                    <Nav.Link href="/chooserolesfor=signup" className="primaryBtn d-inline-block">Get Started</Nav.Link>
                                                </NavItem> */}
                                            </div>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </div>
        </>
    )

}

export default MainNav;