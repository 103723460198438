import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import CustomModal from '../../components/common/shared/CustomModal';
import AccountUserDetails from './accountUserDetails';
import EditCompanyInformation from './editCompanyInformation';
import EditCompanyInformationDetails from './editCompanyInformationDetails';
import EditExistingCertifications from './editExistingCertifications';
import { getCompanyProfileInfo } from '../../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import Changepassword from './changePassword';
import { useAuthSelector } from '../../../redux/selector/auth';


const Editprofile = () => {
    const dispatch = useDispatch();
    const authSelector = useAuthSelector()
    const [key, setKey] = useState(Math.random());
    const [hideEditBtn, setHideEditBtn] = useState(false);
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const [userDetail, setUserDetail] = useState("");
    const [userDetailProfile, setUserDetailProfile] = useState("");
    const [userData, setUserData] = useState("")

    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const EditAccountUserDetail = (flag) => {
        if (flag == "EditUser") {
            setModalDetail({ show: true, flag: "editaccountuserdetails", });
            setKey(Math.random());
        }
        else if (flag == "EditCompany") {
            setModalDetail({ show: true, flag: "editCompanyInformation", });
            setKey(Math.random());
        }
        else if (flag == "EditCompanyInformation") {
            setModalDetail({ show: true, flag: "editCompanyInformationDetails", });
            setKey(Math.random());
        }
        else if (flag == "ChnagePassword") {
            setModalDetail({ show: true, flag: "Chnagepassword", });
            setKey(Math.random());
        }
        else if (flag == "editExistingCertifications") {
            setModalDetail({ show: true, flag: "editExistingCertifications", });
            setKey(Math.random());
        }
    }

    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(getCompanyProfileInfo({
            cb(res) {
                // if (res.status) {
                //     setUserDetailProfile(res?.data?.payload?.userDetails?.user_profile)
                //     setUserDetail(res?.data?.payload?.userDetails)
                // }
                // else {
                // }
                if (res.status) {
                    if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                        setUserDetailProfile(res?.data?.payload?.userDetails?.user_profile)
                        setUserDetail(res?.data?.payload?.userDetails?.user_company_information)
                        setUserData(res?.data?.payload?.userDetails)
                        // setUserData(res?.data?.payload?.userDetails)
                    }
                    else {
                        // setUserDetail(res?.data?.payload?.userDetails)
                        setUserData(res?.data?.payload?.userDetails)
                        setUserDetailProfile(res?.data?.payload?.userDetails?.user_profile)
                        setUserDetail(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
                    }

                }
                else {

                }
            }
        }))
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        companyProfileInfo()
        document.title = "Edit-profile";
    }, []);

    // useEffect(() => {
    //     if (authSelector?.userInfo?.user?.user_company_information?.company_name !== null) {
    //         setHideEditBtn(false)
    //     }
    //     else {
    //         setHideEditBtn(true)
    //     }
    // }, [])


    useEffect(() => {
        if (authSelector?.userStep?.user?.user_company_information !== null) {
            setHideEditBtn(false)
        }
        else if (authSelector?.userStep?.user?.user_company_information === null) {
            setHideEditBtn(true)
        }
    }, [])

    return (
        <>
            <section className='Editprofile'>
                <div className='container'>
                    <div className='row'>
                        {/* <div className='col-lg-3'>
                            <div className='motionSec'>
                                <h3 className='mainHeading'>Setting</h3>
                            </div>
                        </div> */}

                        <div className='col-lg-12'>
                            <div className='motionSec px-3'>
                                <h3 className='headTxtBlueSmall mb-4'><b> Edit Profile</b></h3>
                                {authSelector.loading ?
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12 text-center pt-5 pb-5">
                                                <div className="spinner-grow text-success" role="status"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className='profileBox mt-2'>
                                        <div className='flexBox'>
                                            <h5 className='profileHeading'>Account User Details</h5>
                                            <div className='flexBox pe-3'>
                                                <h6 className='profilesubhead'>Edit</h6>
                                                <img onClick={() => EditAccountUserDetail("EditUser")} src={Images.EditIcon} alt="edit" className='img-fluid editIcon' />
                                            </div>
                                        </div>
                                        <div className='userInfo_'>
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <figure>
                                                        <img className="img-fluid editProfileuser" src={userDetailProfile?.profile_photo ? userDetailProfile?.profile_photo : Images.companyprofile} alt='editProfileuser' />
                                                    </figure>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <article className=''>
                                                        <h6 className='userprofileName'>{userDetailProfile?.first_name + " " + (userDetailProfile?.last_name === null ? " " : userDetailProfile?.last_name)}</h6>
                                                        <p className='userEmail'>{userData?.email}</p>
                                                    </article>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <article className='flexBox'>
                                                        <h6 className='userPassword'>Password ********</h6>
                                                        <img onClick={() => EditAccountUserDetail("ChnagePassword")} src={Images.Edit} alt="" className='img-fluid EditIcon' />
                                                    </article>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='profileBox mt-5'>
                                            <div className='flexBox'>
                                                <h5 className='profileHeading pb-3'>Company Information</h5>
                                                {
                                                    hideEditBtn === true ? "" :
                                                        <div className='flexBox pe-3'>
                                                            <h6 className='profilesubhead'>Edit</h6>
                                                            <img onClick={() => EditAccountUserDetail("EditCompany")} src={Images.EditIcon} alt="edit" className='img-fluid editIcon' />
                                                        </div>
                                                }

                                            </div>
                                            <div className='d-flex'>

                                                <div className='row'>
                                                    <div className='col-lg-2'>
                                                        <figure>
                                                            <img src={userDetail?.company_logo ? userDetail?.company_logo : Images.companyprofile} className='img-fluid editProfileuser' alt='editProfileuser' />
                                                        </figure>
                                                    </div>
                                                    <div className='col-lg-10'>
                                                        <div className='row'>
                                                            <div className='col-lg-4'>
                                                                <article className=''>
                                                                    <h6 className='companyprofileInfo'>WEBSITE</h6>
                                                                    <p className='companyDetails text-break'>{userDetail?.company_website}</p>
                                                                </article>
                                                            </div>
                                                            <div className='col-lg-4'>
                                                                <article className=''>
                                                                    <h6 className='companyprofileInfo'>KEYWORDS</h6>
                                                                    <p className='companyDetails'>{userDetail?.key_words} </p>
                                                                </article>
                                                            </div>
                                                            <div className='col-lg-4'>
                                                                <article className=''>
                                                                    <h6 className='companyprofileInfo'>DIVERSE STATUS</h6>
                                                                    <p className='companyDetails'>
                                                                        {userDetail?.company_diverse_supplier_description?.length > 0 ?
                                                                            <>
                                                                                {userDetail?.company_diverse_supplier_description?.map((data, index) => {

                                                                                    return (
                                                                                        <>
                                                                                            <p className='companyDetails'>{data?.diverse_supplier_description_details?.name}</p>
                                                                                        </>

                                                                                    )
                                                                                })
                                                                                }
                                                                            </> : "No Diverse Status found"
                                                                        }
                                                                    </p>


                                                                </article>
                                                            </div>
                                                            <div className='col-lg-12'>
                                                                {/* <article className=''>
                                                                    <h6 className='companyprofileInfo'>COMPANY DESCRIPTION</h6>
                                                                    <p className='companyDetails'>{userDetail?.user_company_information?.company_service_description}</p>
                                                                </article> */}
                                                                <article className=''>
                                                                    <h6 className='companyprofileInfo'> ABOUT COMPANY</h6>
                                                                    <p className='companyDetails'>{userDetail?.company_service_description}</p>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='profileBox mt-5'>
                                            <div className='flexBox pe-3'>
                                                <h5 className='profileHeading pb-3'>Company Details</h5>
                                                {
                                                    hideEditBtn === true ? "" :
                                                        <div className='flexBox'>
                                                            <h6 className='profilesubhead'>Edit</h6>
                                                            <img onClick={() => EditAccountUserDetail("EditCompanyInformation")} src={Images.EditIcon} alt="edit" className='img-fluid editIcon' />
                                                        </div>
                                                }

                                            </div>
                                            <div className='row'>
                                                {/* <div className='col-lg-12'>
                                                    <h6 className='companyprofileInfo'>Location</h6>
                                               
                                                </div> */}
                                                <div className='col-lg-3'>
                                                    <h6 className='companyprofileInfo'>COMPANY STATUS</h6>
                                                    <p className='companyDetails' >
                                                        {userDetail?.company_status == 1 ? "Private" : userDetail?.company_status == 2 ? "Pubic" : ""}
                                                    </p>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <h6 className='companyprofileInfo'>BUSINESS OWNERSHIP TYPE</h6>
                                                    <p className='companyDetails'>{userDetail?.business_ownership_types?.name}</p>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <h6 className='companyprofileInfo'>OPEN TO JV OPPORTUNITIES</h6>
                                                    <p className='companyDetails'>
                                                        {userDetail?.open_to_jv_supplier_opportunities == 1 ? "Yes" : userDetail?.open_to_jv_supplier_opportunities == 2 ? "No" : ""}

                                                    </p>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <h6 className='companyprofileInfo'># EMPLOYEES</h6>
                                                    <p className='companyDetails'>{userDetail?.number_of_employees}</p>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <h6 className='companyprofileInfo'>LOCAL SERVICE AREA</h6>
                                                    <p className='companyDetails'>{userDetail?.domestic_service_areas}</p>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <h6 className='companyprofileInfo'>INTERNATIONAL SERVICE AREA</h6>
                                                    <p className='companyDetails'>{userDetail?.global_service_areas}</p>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <h6 className='companyprofileInfo'>INDUSTRY GROUP</h6>
                                                    <p className='companyDetails'>{userDetail?.industries_group_details?.name}</p>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <h6 className='companyprofileInfo'>INDUSTRY SUB GROUP</h6>
                                                    <p className='companyDetails'>{userDetail?.industries_sub_group_details?.name}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='profileBox mt-5'>
                                            <div className='flexBox'>
                                                <h5 className='profileHeading pb-3'>Existing Certifications</h5>
                                                {
                                                    hideEditBtn === true ? "" :
                                                        <div className='flexBox pe-3'>
                                                            <h6 className='profilesubhead'>Edit</h6>
                                                            <img src={Images.EditIcon} onClick={() => EditAccountUserDetail("editExistingCertifications")} alt="edit" className='img-fluid editIcon' />
                                                        </div>
                                                }

                                            </div>
                                            <div className='row'>
                                                {userDetail?.company_existing_certificate_details?.length > 0 ?
                                                    <>
                                                        {userDetail?.company_existing_certificate_details?.map((data, index) => {
                                                            return (
                                                                <>
                                                                    <div className='col-lg-6'>
                                                                        <div className='userInfo'>
                                                                            <figure>
                                                                                <img src={Images.Certifications} className='img-fluid Certifications' alt='Certifications' />
                                                                            </figure>
                                                                            <article className=''>
                                                                                {/* <h6 className='companyprofileInfo'>{data?.existing_certificates_details?.name
                                                                                    .split(" ")
                                                                                    .map((word) => (word.charAt(0)))
                                                                                    .join("")}</h6> */}
                                                                                <p className='companyDetails m-2'>{data?.existing_certificates_details?.name}</p>
                                                                            </article>
                                                                        </div>
                                                                    </div>
                                                                </>

                                                            )
                                                        })
                                                        }
                                                    </> : <p>No Certificate found</p>
                                                }
                                                {/* <div className='col-lg-6'>
                                                    <div className='userInfo'>
                                                        <figure>
                                                            <img src={Images.Certifications} className='img-fluid Certifications' alt='Certifications' />
                                                        </figure>
                                                        <article className=''>
                                                            <h6 className='companyprofileInfo'>CCSP</h6>
                                                            <p className='companyDetails m-0'>Certified ethical hacker</p>
                                                        </article>
                                                    </div>
                                                </div> */}
                                                {/* <div className='col-lg-6'>
                                                    <div className='userInfo'>
                                                        <figure>
                                                            <img src={Images.Certifications} className='img-fluid Certifications' alt='Certifications' />
                                                        </figure>
                                                        <article className=''>
                                                            <h6 className='companyprofileInfo'>CEH</h6>
                                                            <p className='companyDetails m-0'>Certified ethical hacker</p>
                                                        </article>
                                                    </div>
                                                </div> */}
                                                <h5 className='profileHeading mt-3 '>Trade Memberships</h5>

                                                {userDetail?.company_trade_membership_details?.length > 0 ?
                                                    <>
                                                        {userDetail?.company_trade_membership_details?.map((data, index) => {
                                                            return (
                                                                <>
                                                                    <div className='col-lg-6'>
                                                                        <div className='userInfo'>
                                                                            <figure>
                                                                                <img src={Images.Certifications} className='img-fluid Certifications' alt='Certifications' />
                                                                            </figure>
                                                                            <article className=''>
                                                                                {/* <h6 className='companyprofileInfo'>{data?.trade_membership_details?.name
                                                                                    .split(" ")
                                                                                    .map((word) => (word.charAt(0)))
                                                                                    .join("")}</h6> */}
                                                                                <p className='companyDetails m-2'>{data?.trade_membership_details?.name}</p>
                                                                            </article>
                                                                        </div>
                                                                    </div>
                                                                </>

                                                            )
                                                        })
                                                        }
                                                    </> : <p>No Certificate found</p>
                                                }
                                                {/* <div className='col-lg-6'>
                                                    <div className='userInfo'>
                                                        <figure>
                                                            <img src={Images.Certifications} className='img-fluid Certifications' alt='Certifications' />
                                                        </figure>
                                                        <article className=''>
                                                            <h6 className='companyprofileInfo'>Security+</h6>
                                                            <p className='companyDetails m-0'>CompTIA</p>
                                                        </article>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='userInfo'>
                                                        <figure>
                                                            <img src={Images.Certifications} className='img-fluid Certifications' alt='Certifications' />
                                                        </figure>
                                                        <article className=''>
                                                            <h6 className='companyprofileInfo'>CIBC</h6>
                                                            <p className='companyDetails m-0'>Project Management Professional</p>
                                                        </article>
                                                    </div>
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "editaccountuserdetails" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "editaccountuserdetails" ? "editaccountuserdetails" :
                    modalDetail.flag === "editCompanyInformationDetails" ? "editCompanyInformationDetails" :
                        modalDetail.flag === "Chnagepassword" ? "chnagePassword" :
                            modalDetail.flag === "editCompanyInformation" ? "editCompanyInformation" :
                                modalDetail.flag === "editExistingCertifications" ? "editExistingCertifications" : ""
                }
                child={
                    modalDetail.flag === "editaccountuserdetails" ? (
                        <AccountUserDetails
                            close={() => handleOnCloseModal()}
                            refreshUserDetail={() => companyProfileInfo()}
                        />
                    ) : modalDetail.flag === "editCompanyInformation" ? (
                        <EditCompanyInformation
                            refreshCompanyDetail={() => companyProfileInfo()}
                            close={() => handleOnCloseModal()} />
                    ) :
                        modalDetail.flag === "editCompanyInformationDetails" ? (
                            <EditCompanyInformationDetails
                                refreshCompanyDetail={() => companyProfileInfo()}
                                close={() => handleOnCloseModal()} />
                        ) :
                            modalDetail.flag === "Chnagepassword" ? (
                                <Changepassword
                                    refreshUserDetail={() => companyProfileInfo()}
                                    close={() => handleOnCloseModal()} />
                            ) :
                                modalDetail.flag === "editExistingCertifications" ? (
                                    <EditExistingCertifications
                                        refreshCompanyDetail={() => companyProfileInfo()}
                                        close={() => handleOnCloseModal()} />
                                )


                                    :
                                    ""

                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "editaccountuserdetails" ?
                                <h2 className="headingSmall_">
                                    Edit User Detail
                                </h2>
                                : modalDetail.flag === "editCompanyInformation" ?
                                    <h2 className="headingSmall_">
                                        Edit Company Information
                                    </h2>

                                    : modalDetail.flag === "editCompanyInformationDetails" ?
                                        <h2 className="headingSmall_">
                                            Edit Company Details
                                        </h2>
                                        : modalDetail.flag === "Chnagepassword" ?
                                            <h2 className="headingSmall_">
                                                Change Password
                                            </h2>
                                            : modalDetail.flag === "editExistingCertifications" ?
                                                <h2 className="headingSmall_">
                                                    Edit Existing Certifications & Trade Memberships
                                                </h2>
                                                : ""
                            }
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}
export default Editprofile