import React, { useState, useEffect } from "react";

const SubmitEndrosment = () => {
    return (
        <>
            Endorsement Sent, Thank you.
        </>
    )
}

export default SubmitEndrosment