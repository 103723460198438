import React, { useEffect, useState } from 'react'
import CustomModal from "../../components/common/shared/CustomModal";
import * as Images from "../../../utilities/images";
import ReactPaginate from "react-paginate";
import swal from 'sweetalert';
import ManageUserModal from './manageUserModal';
import { useDispatch } from 'react-redux';
import { deleteManageUser, getCompanyUsers } from '../../../redux/slices/manageusers';
import { useManageUsersSelector } from '../../../redux/selector/manageUsers';
import moment from 'moment';
import { useAuthSelector } from '../../../redux/selector/auth';

const ManageUser = () => {
    const dispatch = useDispatch();
    const manageUsersSelector = useManageUsersSelector();
    const [key, setKey] = useState(Math.random());
    const [userList, setUserList] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [totalRecords, setTotalRecords] = useState()
    const authSelector = useAuthSelector()
    const users = authSelector?.userInfo?.user?.user_membership_data[0]?.membership_details?.total_users

    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    //on change page pagination
    const handlePageClick = (e) => {
        companyUsers(e.selected + 1);
    };

    //for COMPANY USERS INFORMATION.......
    const companyUsers = (page = 1, limit = 10) => {
        let params = {
            page: page,
            limit: limit,
        }
        dispatch(getCompanyUsers({
            ...params, cb(res) {
                if (res.status) {
                    setUserList(res?.data?.payload?.data)
                    setPageCount(res?.data?.payload?.per_page)
                    setTotalRecords(res?.data?.payload?.total_records)
                }
                else {

                }
            }
        }))
    }
     // delete user
     const DeleteUser = (id) => {
        swal({
            text: `Are you sure want to remove this user?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    const params = {
                        id: id
                    }
                    dispatch(deleteManageUser({
                        ...params, cb(res) {
                            if (res.status) {
                                setPageCount(1)
                                companyUsers();
                            }
                        }
                    }))
                }
            })

    }



    useEffect(() => {
        companyUsers();
    }, []);

    const user = users ? users :"0"
    const totalUsersInvited = totalRecords ? totalRecords : "0"

    return (
        <>
            <div className='manageUser'>
                <h3 class="headTxtBlueSmall">Manage User Accounts</h3>
                <div className='userAvailable'>
                    <p className='mb-0 me-3'><b>{totalUsersInvited} of {user}</b> Users Available</p> <button className='primaryBtn'
                        onClick={() => {
                            setModalDetail({ show: true, flag: "manageUser" });
                            setKey(Math.random());
                        }}
                    >Invite Co-worker</button>
                </div>
                {manageUsersSelector.loading ?
                    <>
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                    <div className='table-responsive'>
                            <table className='manageUserTable'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        <th>Position</th>
                                        <th>Action</th>
                                        <th>Member Since</th>
                                    </tr>
                                </thead>
                                {userList?.length ?
                                    <>

                                        {userList?.map((data, index) => {
                                            return (
                                                <>
                                                    <tbody>
                                                        <tr>
                                                            <td>  { data.user_details.user_profile.first_name}</td>
                                                            <td>{data?.user_details?.email}</td>
                                                            <td>{data?.user_details?.user_membership_data[0]?.membership_details?.name}</td>
                                                            <td>
                                                                <img 
                                                                onClick={() => DeleteUser(data?.user_id)}
                                                                src={Images.trashIcon} alt="icon" className='trashIcon me-2' />
                                                                <img src={Images.greenWarning} alt="icon" className='trashIcon me-2' />
                                                            </td>
                                                            <td>{moment(data?.user_details?.created_at).format("YYYY-MM-DD")}</td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                            )
                                        })
                                        }

                                    </>
                                   
                                    :<tr><td colSpan={5}> <b>No Users Found</b></td></tr>
                                }
                            </table>
                        </div>
                    </>
                }
            </div>
            <div className="col-12 ">
                <div className="customPagination mt-4">
                    <div className="pagination">
                        {userList?.length > 0 ? (
                            <ReactPaginate
                                breakLabel=""
                                // nextLabel=">"
                                nextLabel={<i className='fas fa-caret-right'></i>}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={pageCount}
                                marginPagesDisplayed={0}
                                pageCount={pageCount}
                                previousLabel={<i className='fas fa-caret-left'></i>}
                                // previousLabel="<"
                                renderOnZeroPageCount={null}
                            />
                        ) : ""}
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                flag={modalDetail.flag}
                // showCustomBackdrop={modalDetail.flag === "manageUser" ? true : false}
                show={modalDetail.show}
                backdrop={modalDetail.flag === "manageUser" ? null : "static"}
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "manageUser" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "manageUser" ? "hello" : ""}
                child={
                    modalDetail.flag === "manageUser" ? (
                        <ManageUserModal
                            id={modalDetail.id}
                            companyUsers={() => companyUsers()}
                            close={() => handleOnCloseModal()} />
                    )
                        : ""
                }
                header={
                    modalDetail.flag === "manageUser" ?
                        <h2 className="headingSmall_">
                            Manage User
                        </h2>
                        : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default ManageUser