import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { myReviewRespondentsOpportunity } from '../../../redux/slices/opportunity';
import { useOpportunitySelector } from '../../../redux/selector/opportunity';
import ReactPaginate from "react-paginate";
import CustomModal from '../../components/common/shared/CustomModal';
import MessageResponseBox from './messageResponseBox';
import { useAuthSelector } from '../../../redux/selector/auth';

const ReviewRespondents = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuthSelector();

  const companyLocation = auth?.userInfo?.user?.user_company_information?.global_service_areas
  const opportunitySelector = useOpportunitySelector();
  const [reviewRespondents, setReviewRespondents] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // API for get Review Responses Opportunity ............
  const ReviewRespondentsOpportunity = (page = 1, limit = 10) => {
    let params = {
      id: props.opportunityId,
      page: page,
      limit: limit,
    }
    dispatch(myReviewRespondentsOpportunity({
      ...params, cb(res) {
        if (res.status) {
          setReviewRespondents(res?.data?.payload?.data)
          setPageCount(res?.data?.payload?.total_pages)
        }
      }
    }))
  }

  //on change page pagination
  const handlePageClick = (e) => {
    ReviewRespondentsOpportunity(e.selected + 1);
  };

  const BackListing = () => {
  

  }

  useEffect(() => {
    ReviewRespondentsOpportunity()
    document.title = "opportunities";
  }, []);

  return (
    <div className='viewRespondents'>
      <h3 className="headingTitle_ mb-2">Review Respondents</h3>

      <div className='col-md-6 col-lg-6 text-end'>
        <button className='smallgreyBtn_' onClick={() => { BackListing() }}>Back to Listing</button>
      </div>
      <h4 className='bluesubHeadd_ mb-3'>{reviewRespondents[0]?.post_opportunity_details?.posting_title}
        {/* <span className='sgentbox_ ms-2'>Flexible Contract</span> */}
      </h4>
      {/* <div className='businessDesincontent_ mt-3'> */}
      {opportunitySelector.loading ?
        <>
          <div className="row">
            <div className="col-sm-12 text-center pt-5 pb-5">
              <div className="spinner-grow text-success" role="status"></div>
            </div>
          </div>
        </>
        :
        <>
          {reviewRespondents?.length > 0 ?
            <>
              {reviewRespondents?.map((data, index) => {
                return (
                  <>
                    <div className='businessDesincontent_ mt-3'>
                      <div className='row'>
                        <div className='col-lg-2 col-md-2'>
                          <div className='designImg_'>
                            <img className='imgproduct_' src={data?.user_details?.user_company_information?.company_logo ? data?.user_details?.user_company_information?.company_logo : Images.alantic} />
                          </div>
                        </div>
                        <div className='col-lg-10 col-md-10'>
                          <div className='designContent_'>
                            <div className='designcontentTop_'>
                              <div className='designLeft_'>
                                <h4 className='headingTitleSmall_'>{data?.user_details?.user_profile?.first_name} {data?.user_details?.user_profile?.last_name}</h4>
                                <h5 className='headingTitleSmall_'> Company : <span className='weeksTxt'>{data?.user_details?.user_company_information?.company_name}</span></h5>
                              </div>
                              <div className='designMid_'>
                                <p className='motionLoc'>
                                  <img src={Images.locationpin} alt='Location' className='me-1' />
                                  <span className='textInnerOuter'>{data?.user_details?.user_company_information?.global_service_areas === null ? "N/A" : data?.user_details?.user_company_information?.global_service_areas}</span>
                                  {/* <span className='textInnerOuter'>{companyLocation ? companyLocation : "N/A"}</span> */}
                                </p>
                              </div>
                              <div className='designbtnRight'>
                                <button onClick={() => {
                                  setModalDetail({ show: true, flag: "messageResponse", });
                                  setKey(Math.random());
                                }} className='subsmallBtngreen_'>Send Messsage</button>
                              </div>
                            </div>
                            <p className='innerSubtextSmall mt-2'>{data?.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
              }
            </>
            : <b>No Review Responses Found</b>
          }
          <div className="col-12 ">
            <div className="customPagination mt-4">
              <div className="pagination">
                {reviewRespondents?.length > 0 ? (
                  <ReactPaginate
                    breakLabel=""
                    // nextLabel=">"
                    nextLabel={<i className='fas fa-caret-right'></i>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={pageCount}
                    marginPagesDisplayed={0}
                    pageCount={pageCount}
                    previousLabel={<i className='fas fa-caret-left'></i>}
                    // previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                ) : ""}
              </div>
            </div>
          </div>
        </>
      }
      {/* </div> */}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "messageResponse" ? "commonWidth customContent" : ""}
        ids={modalDetail.flag === "messageResponse" ? "messageResponse" : "ComposeMessage"}
        child={
          modalDetail.flag === "messageResponse" ? (
            <MessageResponseBox
              close={() => handleOnCloseModal()}
            />
          ) :
            ""
        }
        header={
          <div className='modalHeader_'>
            <div className="common_">
              {modalDetail.flag === "messageResponse" ?
                <>
                  <h2 className="addLabels_heading">
                    Response
                  </h2>
                  <p>Cell Center Agesnts</p>
                </>
                : ""
              }
            </div>
          </div>
        }

        onCloseModal={() => handleOnCloseModal()}
      />
    </div>


  )
}

export default ReviewRespondents