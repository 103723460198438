import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useArchiveOpportunitySelector } from "../../../redux/selector/archiveOpportunity";
import { updateArchive, getMyArchiveOppertunity } from '../../../redux/slices/opportunity/archiveOpportunity';
import ReactPaginate from "react-paginate";
import {AdvReadMoreMore } from "read-more-more";

const Archived = () => {
    const dispatch = useDispatch();
    const archiveOpportunitySelector = useArchiveOpportunitySelector();
    const [myArchiveOppertunity, setMyArchiveOppertunity] = useState('');
    const [pageCount, setPageCount] = useState(1);

    // API for archive Update........................
    const archiveUpdate = (id, flag) => {
        let params = {
            id: id,
            is_archive: flag
        }  
        dispatch(updateArchive({
            ...params, cb(res) {
                if (res.status) {
                    getMyArchiveOppertunities();
                }
            }
        }))
    }
 
    // API for get MyArchive Oppertunities........................
    const getMyArchiveOppertunities = (page = 1,limit = 10) => {
        let params = {
            page: page,
            limit: limit,
        }
        dispatch(getMyArchiveOppertunity({
            ...params,cb(res) {
                if (res.status) {
                    setMyArchiveOppertunity(res?.data?.payload?.data)
                    setPageCount(res?.data?.payload?.total_pages)
                }
            }
        }))
    }

    //on change page pagination
    const handlePageClick = (e) => {
        getMyArchiveOppertunities(e.selected + 1);
    };

    useEffect(() => {
        getMyArchiveOppertunities()
        document.title = "opportunities";
    }, []);

    return (
        <>
            <div className='activeOpportunity_'>
                <h3 className="headingTitle_ mb-4">Archive </h3>
                {archiveOpportunitySelector.loading ?
                    <>
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {myArchiveOppertunity?.length ?
                            <>
                                {myArchiveOppertunity?.map((data, index) => {
                                    return (
                                        <>
                                            <div className='postContainerArea post-container-area' key={index}>
                                                <div className='posts align-items-center justify-content-between'>
                                                    <div className='leftOpportunitycontent'>
                                                        <p className='postHours_ mb-2'>{moment(data?.created_at).fromNow()}</p>
                                                        <h6 className='opportunityHead_ '>{data?.posting_title.length > 13.5 ? data?.posting_title.substring(0,13.5) + "...." : data?.posting_title}</h6>
                                                        {/* <h6 className='listHead_'>Fixed Term Contract</h6> */}
                                                    </div>
                                                    <div className='postsDetails'>
                                                    {/* <AdvReadMoreMore text={data?.description} linesToShow={3}
                                                            checkFor={100}
                                                            transDuration={2} /> */}
                                                        <p className='innerSubtextSmall'>{data?.description}</p>
                                                    </div>
                                                    <div className='opportunityBtn_'>
                                                        <button onClick={() => archiveUpdate(data?.id, 0)} className="subsmallBtnblue_ mb-2">Un-Archive</button>
                                                        <button className="subsmallBtnblack_ mb-2" type="button">Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                }
                            </>
                            : <b>No Archive Found</b>
                        }
                    </>
                }
                <div className="col-12 ">
                    <div className="customPagination mt-4">
                        <div className="pagination">
                            {myArchiveOppertunity?.length > 0 ? (
                                <ReactPaginate

                                    breakLabel=""
                                    // nextLabel=">"
                                    nextLabel={<i className='fas fa-caret-right'></i>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={pageCount}
                                    marginPagesDisplayed={0}
                                    pageCount={pageCount}
                                    previousLabel={<i className='fas fa-caret-left'></i>}
                                    // previousLabel="<"
                                    renderOnZeroPageCount={null}
                                />
                            ) : ""}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Archived