import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';

const Test = () => {

    const [connected, setConnected] = useState(false);

    const socket = io('https://api.getopps.itechnolabs.tech:4000',{
        path: "/api/v1/connect",
    });

    useEffect(() => {
        socket.on('connect', () => {
          setConnected(true);
          console.log('Socket is connected.');
        });
    
        socket.on('disconnect', () => {
          setConnected(false);
          console.log('Socket is disconnected.');
        });
    
        // Clean up the event listeners when the component unmounts
        return () => {
          socket.off('connect');
          socket.off('disconnect');
        };
        
    }, []);

    return (
        <div>
            
            {connected ? 'Socket is connected.' : 'Socket is not connected.'}

            <Link to="/">HOME</Link>
        
        </div>
    )

}
export default Test