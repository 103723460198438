import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as images from "../../../utilities/images";
import { useAuthSelector } from '../../../redux/selector/auth';
import { useDispatch } from 'react-redux';
import { userSocialLoginSignup } from '../../../redux/slices/auth';

const SocialLoginBusinessType = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const roleId = location?.state?.id ?? 1;
    const [activeTab, setActiveTab] = useState("");
    const [id, setId] = useState();
    const dispatch = useDispatch()
    //tab click
    const tabClick = (e, id) => {
        setId(id)
    }

    //create Account by select role
    const handleSubmit = (e, flag) => {
        e.preventDefault()
        if (!id) {
            toast.error("Please Select atleast one role.")
        }
        else {
            props.close()
            if (!props.responseData?.accessToken) {
                return;
            }

            let params = {
                social_token: props?.responseData?.accessToken,
                email: props?.responseData?.profileObj?.email,
                first_name: props?.responseData?.profileObj?.name,
                role_id: props?.roleID,
                social_type: 1,
                social_id: props?.responseData?.profileObj?.googleId,
                business_type: id
            }
            dispatch(userSocialLoginSignup({
                ...params, cb(res) {
                    if (res.data.payload.user.step === 6) {
                        navigate("/dashboard")
                    }
                    else if (res.data.payload.user.step) {
                        navigate('/registration', { state: { step: res?.data?.payload?.user?.step } });
                    }

                    else {
                        navigate("/dashboard")
                    }
                }
            }))
        }
    }

    return (
        <div className="row justify-content-center">
            <div className="col-md-10 text-center">
                <div className="detailsContainer">
                    <h3 className="headTxt mt-3 mb-4">Which best describes your business?</h3>
                    <form>
                        <div className='row justify-content-center'>
                            <>
                                <div onClick={(e) => tabClick(e, 1)} className='col-md-6 mb-3'>
                                    <div className={activeTab == 'EnBusiness' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("EnBusiness")}>
                                        <img src={images.buyer} alt='Buyer' className='img-fluid' />
                                        <h4 className="heading_ mt-3 mb-3">Enterprise Business</h4>
                                        <p className='textInner'>Over $5 million USD in yearly Revenue</p>
                                    </div>
                                </div>
                                <div onClick={(e) => tabClick(e, 2)} className='col-md-6 mb-3'>
                                    <div className={activeTab == 'SmBusiness' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("SmBusiness")}>
                                        <img src={images.supplier} alt='Supplier' className='img-fluid' />
                                        <h4 className="heading_ mt-3 mb-3">Small Business</h4>
                                        <p className='textInner'>Under $5 million USD in yearly Revenue</p>
                                    </div>
                                </div>
                            </>
                            
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-md-10'>
                                <div className='accountRelate'>
                                    <p className='innerTxt'><button className='primaryBtn w-100 mt-4' onClick={(e) => handleSubmit(e)}><b>Continue</b></button></p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SocialLoginBusinessType