import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import { createEndrosement, getCompanyInformation } from '../../../redux/slices/companyInformation';
import { Link, useNavigate, useLocation, useParams, useNavigation } from 'react-router-dom';
import { useConnectionSelector } from "../../../redux/selector/connection";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DatePicker from "react-datepicker";
import { updateRequestConnection, connectionFollow, connectionUnfollow, addConnection, connectionRequsetCancel, connectionRequsetUnfriend } from "../../../redux/slices/connection";
import CustomModal from "../../components/common/shared/CustomModal";
import SubmitEndrosment from "./submitEndrosment";
import moment from "moment";
import { userPostLike } from "../../../redux/slices/dashboard";
import ComposeMessage from "../accountMessage/ComposeMessages";
import { useAuthSelector } from "../../../redux/selector/auth";
import MembershipLevelComponent from "../manageMembershipAcessLevel";


const CompanyfrontProfile = () => {
    const { id } = useParams();
    const authSelector = useAuthSelector();

    let BusinessType = authSelector?.userStep?.user_membership_data && authSelector?.userStep?.user_membership_data[0]?.membership_details?.business_type;


    let current_membership_plan = authSelector?.userStep?.user_membership_data && authSelector?.userStep?.user_membership_data[0]?.membership_details?.name;

    // const [userMemberShipData, SetUserMemberShipData] = useState((authSelector?.userStep?.user_membership_data));
    // console.log(userMemberShipData, "userMemberShipData");
    // const matchingUsers = userMemberShipData.filter((user) => user?.membership_details && user?.membership_details?.name)
    //     .map((user) => user?.membership_details?.name);

    // console.log(matchingUsers, "matchingUsers");
    // const supportedPlans = matchingUsers;
    const connectionSelector = useConnectionSelector();
    // const followId = connectionSelector?.connectionfollow?.id
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    // const id = location?.state?.id;
    const [companyInfo, setCompanyInfo] = useState('');

    const [featurePost, setFeaturePost] = useState(null);

    const [companyCurrentOpportunity, setCompanyCurrentOpportunity] = useState();
    const [leaveEndorsements, setLeaveEndorsements] = useState(false);
    const [leaveEndorsementsCompanyName, setleaveEndorsementsCompanyName] = useState('');
    const [leaveEndorsementsDate, setLeaveEndorsementsDate] = useState(new Date());
    const [leaveEndorsementsDescription, setLeaveEndorsementsDescription] = useState('');
    const [leaveEndorsementsReceiverId, setleaveEndorsementsReceiverId] = useState('');
    const [leaveEndorsementsInfo, setLeaveEndorsementsInfo] = useState('');
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    // for close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    // company information 
    const CompanyInformation = (id) => {

        let params = {
            id: id,
        }
        dispatch(getCompanyInformation({
            ...params, cb(res) {
                if (res.status) {
                    setCompanyInfo(res?.data?.payload)
                    setFeaturePost(res.data?.payload?.feature_post)
                    setCompanyCurrentOpportunity(res?.data?.payload?.companyPostOpportunity?.data)
                    setleaveEndorsementsReceiverId(res?.data?.payload?.companyDetails?.user_details?.user_profile?.user_id)
                }
            }
        }))
    }

    const SubmitEndrosement = (e) => {
        // e.preventDefault();

        if (!leaveEndorsementsDate) {
            toast.error("Please select Endorsements Date");
            return false;
        }
        else if (!leaveEndorsementsDescription) {
            toast.error("Please enter Description");
            return false;
        }
        let params = {
            company_name: companyInfo?.companyDetails?.company_name,
            project_date: leaveEndorsementsDate,
            receiver_id: leaveEndorsementsReceiverId,
            endorsement: leaveEndorsementsDescription

        }
        dispatch(createEndrosement({
            ...params, cb(res) {
                if (res.status) {
                    setLeaveEndorsementsInfo(res?.data?.payload)
                    setleaveEndorsementsCompanyName("")
                    setLeaveEndorsementsDate("")
                    setLeaveEndorsementsDescription("")
                    // navigate('/business')
                    setLeaveEndorsements(false)
                    setModalDetail({ show: true, flag: "submitendorsement", });
                    setKey(Math.random());

                }
            }
        }))
    }

    // onchange date set date in the state
    const onChangeDateLeaveEndorsement = (date) => {
        setLeaveEndorsementsDate(date)
    }

    //update request
    const updateRequest = (flag) => {
        let params = {
            id: companyInfo?.connecetionRequestReceived?.id,
            status: flag
        }
        dispatch(updateRequestConnection({
            ...params, cb(res) {
                if (res.status) {
                    CompanyInformation(id)
                }
                else {
                }
            }
        }))
    }

    // API for follow connection
    const handleFollowing = () => {
        let params = {
            receiver_id: companyInfo?.companyDetails?.user_id,
            company_id: companyInfo?.companyDetails?.id
        }
        dispatch(connectionFollow({
            ...params, cb(res) {
                if (res.status) {
                    CompanyInformation(id)
                }
                else {
                }
            }
        }))

    }

    // API for unfollow connection
    const handleUnfollowing = () => {
        swal({
            text: `Are you sure want unfollow?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: companyInfo?.companyDetails?.is_following?.id ? companyInfo?.companyDetails?.is_following?.id : ""
                    }
                    dispatch(connectionUnfollow({
                        ...params, cb(res) {
                            if (res.status) {
                                CompanyInformation(id)
                            }
                            else {
                            }
                        }
                    }))
                }
            });

    }

    const CancleEndrosement = () => {
        setleaveEndorsementsCompanyName("")
        setLeaveEndorsementsDate("")
        setLeaveEndorsementsDescription("")
        setLeaveEndorsements(false)
    }

    //for add connection.........
    const handleSubmit = () => {
        let params = {
            receiver_id: companyInfo?.companyDetails?.user_id,
            company_id: companyInfo?.companyDetails?.id,
            message: "hello"
        }
        dispatch(addConnection({
            ...params, cb(res) {
                if (res.status) {
                    CompanyInformation(id)
                }
            }
        }))
    }

    const handleChangePage = () => {
        navigate("/resources")
    }

    // handle  page change 
    const handlePageRedirect = (name, id) => {
        if (id) {
            navigate(`/${name}/${id}`)
        }
    }

    // function for redirect the account page and send connection details
    const handleMessage = (name) => {
        navigate("/account-message", { state: { name: name, companyInfo: companyInfo } })



    }




    // API for Cancel connect request
    const updatePendingRequest = () => {
        swal({
            text: `Are you sure want cancel request?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: companyInfo?.companyDetails?.company_connection?.id ? companyInfo?.companyDetails?.company_connection?.id : ""
                    }
                    dispatch(connectionRequsetCancel({
                        ...params, cb(res) {
                            if (res.status) {
                                CompanyInformation(id)
                            }
                            else {
                            }
                        }
                    }))
                }
            });

    }

    // API for unfriend connect request
    const updateAcceptRequest = () => {
        swal({
            text: `Are you sure want disconnect connection?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: companyInfo?.companyDetails?.company_connection?.id ? companyInfo?.companyDetails?.company_connection?.id : ""
                    }
                    dispatch(connectionRequsetUnfriend({
                        ...params, cb(res) {
                            if (res.status) {
                                CompanyInformation(id)
                            }
                            else {
                            }
                        }
                    }))
                }
            });
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        CompanyInformation(id)
        document.title = "companyfrontProfile";
    }, [id]);
    return (
        <>
            <section className='companyProfile_ overflow-hidden'>
                <div className='topprofileBanner'>
                    <div className='container-fluid noPadding'>
                        <div className='row'>
                            <img className='profile-img companyprofilebanner' src={companyInfo?.companyDetails?.banner ? companyInfo?.companyDetails?.banner : Images.companyProfile} />
                        </div>
                    </div>
                </div>
                <div className='companyDetailspro_ mb-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='companyDetailsLeft'>
                                    <div className='containerBox'>
                                        <div className='row'>
                                            <div className='col-lg-2 col-md-3 p-0'>
                                                <div className='designImg_'>
                                                    <img className='imgproduct_' src={companyInfo?.companyDetails?.company_logo ? companyInfo?.companyDetails?.company_logo : Images.companyprofile} />
                                                </div>
                                            </div>
                                            <div className='col-lg-9 col-md-9'>
                                                <div className='designContent_'>
                                                    <div className='designcontentTop_'>
                                                        <div className='designLeft_'>
                                                            <h4 className='boldmainHeading_'>{companyInfo?.companyDetails?.company_name}</h4>
                                                            <h5 className='abouText_ mt-2'>{companyInfo?.companyDetails?.industries_group_details?.name}</h5>
                                                            <p className='motionLoc mt-3 mb-3'>
                                                                {companyInfo?.companyDetails?.domestic_service_areas &&
                                                                    <>
                                                                        <img src={Images.location_} alt='Location' className='me-1' />
                                                                        <span className='textInnerOuter'>{companyInfo?.companyDetails?.domestic_service_areas}
                                                                            {/* {companyInfo?.companyDetails?.main_company_country_location ? companyInfo?.companyDetails?.main_company_country_location : ""} */}
                                                                        </span>
                                                                    </>
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='designbtnRight'>
                                                        {companyInfo?.companyDetails?.is_following ?
                                                            companyInfo?.companyDetails?.is_following?.id ?
                                                                <button className='subsmallBtngreen_ me-2 mb-2' onClick={() => handleUnfollowing()}>Following -</button>
                                                                : <button className='subsmallBtngreen_ me-2 mb-2' onClick={() => handleFollowing()}>Follow +</button>
                                                            : <>
                                                                <button className='subsmallBtngreen_ me-2 mb-2' onClick={() => handleFollowing()}>Follow +</button>
                                                            </>
                                                        }
                                                        {companyInfo?.companyDetails?.company_connection ?
                                                            companyInfo?.companyDetails?.company_connection?.status == 1 ?
                                                                <button className='subsmallBtnblue_ me-2' onClick={() => { updatePendingRequest() }}>Pending</button>
                                                                : companyInfo?.companyDetails?.company_connection?.status == 2 ?
                                                                    <button className='subsmallBtnblue_ me-2' onClick={() => { updateAcceptRequest() }}>Connected</button> :
                                                                    companyInfo?.companyDetails?.company_connection?.status == 3 ?
                                                                        <button className='subsmallBtnblue_'>Request Connection</button>
                                                                        : ""
                                                            : <>
                                                                {companyInfo?.connecetionRequestReceived ?
                                                                    companyInfo?.connecetionRequestReceived?.status == 1 ?
                                                                        <>
                                                                            <button className='subsmallBtnblue_ me-2' onClick={() => { updateRequest(2) }}>accept</button>
                                                                            <button className='subsmallBtnblue_' onClick={() => { updateRequest(3) }}>Reject</button>
                                                                        </>
                                                                        : companyInfo?.connecetionRequestReceived?.status == 2 ?
                                                                            "" :
                                                                            companyInfo?.connecetionRequestReceived?.status == 3 ?
                                                                                <button className='subsmallBtnblue_ me-2 mb-2' onClick={() => { handleSubmit() }}>Request Connection</button>
                                                                                : ''


                                                                    : <button className='subsmallBtnblue_ me-2 mb-2' onClick={() => { handleSubmit() }}>Request Connection</button>
                                                                }

                                                            </>
                                                        }
                                                        {companyInfo?.companyDetails?.company_connection && companyInfo?.companyDetails?.company_connection?.status == 2 ?
                                                            <button className='subsmallBtnblue_ ' onClick={() => { handleMessage("ComposeMessage") }}>Message   </button>
                                                            :

                                                            current_membership_plan === "Gold" || current_membership_plan === "Platinum" || BusinessType === 1 ?
                                                                <button className='subsmallBtnblue_ ' onClick={() => { handleMessage("ComposeMessage") }}>Message   </button> :

                                                                <button onClick={() => MembershipLevelComponent("isFollowing")}
                                                                    className='secondaryBtnGrey2 '>Message
                                                                    <img src={Images.whiteInfo} alt="whiteInfo" className="img-fluid whiteInfo" />
                                                                </button>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <hr className="bottomBorder_"></hr>
                                        <div className='aboutprofile_ mt-4'>
                                            <h5 className='headingTitle_'><b>About </b>{companyInfo?.companyDetails?.company_name}</h5>
                                            <p className='innerSubtextSmall companyInfo mt-2'>{companyInfo?.companyDetails?.company_service_description}</p>
                                        </div>
                                    </div>
                                    <div className='containerBox mt-3 pb-5'>
                                        <div className='currentOpportunity_'>
                                            <div className='currentTop'>
                                                <h4 className='headingTitle_'><b>Current Opportunities</b></h4>
                                                <button className="subsmallBtnblue_ me-2">View All</button>
                                            </div>
                                            <div className='row mt-5'>
                                                {companyInfo?.length > 0 ?
                                                    <>
                                                        {companyCurrentOpportunity.slice(0, 3).map((data, index) => {

                                                            return (
                                                                <>
                                                                    <div className='col-lg-4'>
                                                                        <div className='opportunityComtent_ noOpportunity'>
                                                                            <img className='todoList_' src={data?.opportunity_media ? data?.opportunity_media : Images.todoList} />
                                                                            <h5 className='opportunityHead_ mt-3'>{data?.posting_title}</h5>
                                                                            <h6 className='headingTitleSmall_ mb-2'>Fixed Term Contract</h6>
                                                                            <p className='lightgreyHead_'>Additional Contract Details</p>
                                                                            <p className='postHours_ mb-3'>U.S. Veterans</p>
                                                                            <p className='innerSubtextSmall'>{data?.description}</p>
                                                                            <button className="subsmallBtngreen_ mt-3">Request Application</button>
                                                                        </div>
                                                                    </div>
                                                                </>

                                                            )
                                                        })
                                                        }
                                                    </> :
                                                    <>
                                                        <p>No Current Opportunity Found</p>
                                                    </>
                                                }

                                                {/* <div className='col-lg-4'>
                                                    <div className='opportunityComtent_'>
                                                        <img className='todoList_' src={Images.todoList} />
                                                        <h5 className='opportunityHead_ mt-3'>Atlanta Shipping</h5>
                                                        <h6 className='headingTitleSmall_ mb-2'>Monthly Contract</h6>
                                                        <p className='lightgreyHead_'>Additional Contract Details</p>
                                                        <p className='postHours_ mb-3'>U.S. Veterans</p>
                                                        <p className='innerSubtextSmall'>Monitors technician uniform inventory and ensures correct number of coveralls have been supplied for the week.</p>
                                                        <button className="subsmallBtngreen_ mt-3">Request Application</button>
                                                    </div>
                                                </div> */}
                                                {/* <div className='col-lg-4'>
                                                    <div className='opportunityComtent_'>
                                                        <img className='todoList_' src={Images.todoList} />
                                                        <h5 className='opportunityHead_ mt-3'>Plastic Packaging</h5>
                                                        <h6 className='headingTitleSmall_ mb-2'>Fixed Term Contract</h6>
                                                        <p className='lightgreyHead_'>Additional Contract Details</p>
                                                        <p className='postHours_ mb-3'>U.S. Veterans</p>
                                                        <p className='innerSubtextSmall'>Monitors technician uniform inventory and ensures correct number of coveralls have been supplied for the week.</p>
                                                        <button className="subsmallBtngreen_ mt-3">Request Application</button>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='containerBox mt-4'>
                                        <div className='newsBox_'>
                                            <div className='currentTop'>
                                                <h4 className='headingTitle_'><b>Featured Posts</b></h4>
                                                {/* <button className="subsmallBtnblue_ me-2">View All</button> */}
                                            </div>
                                            <div className='row mt-4'>
                                                {featurePost && featurePost?.length > 0 ?
                                                    <>
                                                        {featurePost.map((post, index) => {
                                                            return (
                                                                <div className='col-md-6 col-lg-6 curser-pointer' onClick={() => {
                                                                    navigate(`/dashboard/post/${post?.post_details?.id}`)
                                                                }}>
                                                                    <div className='bitesCrad'>
                                                                        {post?.post_details?.attachment &&
                                                                            <>
                                                                                <img className='soundbiteImg' src={post?.post_details?.attachment} />
                                                                            </>
                                                                        }
                                                                        <div className='soundbiteContent'>
                                                                            <h6 className='bluenewHead_'>{moment(post?.post_details?.created_at).fromNow()}</h6>
                                                                            {/* <h6 className='newsHead_ mb-2'>Employee Appreciation Day at PepsiCo Thank you to the PepsiCo employee.</h6> */}
                                                                            <p className='listHead_'>{post?.post_details?.post ?? ''}</p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </> :
                                                    <>
                                                        <p>No Featured Posts</p>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='containerBox mt-4'>
                                        {leaveEndorsements == true ?

                                            <div className='newsBox_ endorsement_' >
                                                <h4 className='headingTitle_'><b>Leave Endorsements</b></h4>
                                                <form className='signupForm row justify-content-center mt-3' >
                                                    <div className="form-group col-md-6 mb-3">
                                                        <input type="text" className="customFormControl" placeholder='Company Name' disabled value={companyInfo?.companyDetails?.company_name}
                                                        // onChange={e => setleaveEndorsementsCompanyName(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-5 offset-1 mb-3">

                                                        <DatePicker
                                                            className='customFormControl'
                                                            selected={leaveEndorsementsDate}
                                                            onChange={onChangeDateLeaveEndorsement}
                                                            dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                                            placeholderText="Month Day, Year" // Example placeholder text
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-12 mb-3">
                                                        <textarea name="description" type='text' className='customFormControl' placeholder='Type here...' rows='4' value={leaveEndorsementsDescription} onChange={e => setLeaveEndorsementsDescription(e.target.value)}></textarea>

                                                    </div>
                                                    <div className="endorsementsButton text-end">
                                                        <button type="button" className='subsmallBtnblue_' onClick={() => CancleEndrosement()}>Cancel Endorsements</button>
                                                        <button type="button" className='subsmallBtngreen_' onClick={() => SubmitEndrosement()}>Submit Endorsements</button>
                                                    </div>


                                                </form>
                                            </div> : <>
                                                <div className='newsBox_ endorsement_ '>

                                                    <Tabs defaultIndex={1}>
                                                        <div className="endorTabs">
                                                            <h4 className='headingTitle_'><b>Endorsements</b></h4>
                                                            <TabList>
                                                                <Tab>Given</Tab>
                                                                <Tab>Received</Tab>
                                                            </TabList>
                                                        </div>
                                                        <div className='row mt-4'>
                                                            <TabPanel>
                                                                {companyInfo?.endorsement_given?.length > 0 ?
                                                                    <>
                                                                        {companyInfo?.endorsement_given?.map((data, index) => {

                                                                            return (
                                                                                <>
                                                                                    <div className="messageDetail d-flex   mt-4">
                                                                                        <div className="imageDiv">
                                                                                            <img
                                                                                                onClick={() => handlePageRedirect(data?.receiver_details?.user_company_information?.company_name, data?.receiver_details?.user_company_information?.id)}
                                                                                                src={data?.receiver_details?.user_company_information?.company_logo ? data?.receiver_details?.user_company_information?.company_logo : Images.companyprofile} className="userProfile me-2" alt="UserImage" />
                                                                                        </div>
                                                                                        <div className="postsDetails">
                                                                                            <h5 className="innerSubtextSmall d-flex align-items-center mb-2">
                                                                                                <span
                                                                                                    onClick={() => handlePageRedirect(data?.receiver_details?.user_company_information?.company_name, data?.receiver_details?.user_company_information?.id)}
                                                                                                ><strong>{data?.receiver_details?.user_company_information?.company_name} </strong></span>
                                                                                                {/* <span className="timeingDetails">7:34PM</span> */}
                                                                                            </h5>
                                                                                            <p className="discription_">{data?.endorsement}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                        }
                                                                    </> : <p>No Endorsements Given</p>
                                                                }
                                                            </TabPanel>
                                                            <TabPanel>
                                                                {companyInfo?.endorsement_received?.length > 0 ?
                                                                    <>
                                                                        {companyInfo?.endorsement_received?.map((data, index) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="messageDetail d-flex   mt-4">
                                                                                        <div className="imageDiv">
                                                                                            <img
                                                                                                onClick={() => handlePageRedirect(data?.sender_details?.user_company_information?.company_name, data?.sender_details?.user_company_information?.id)}
                                                                                                src={data?.sender_details?.user_company_information?.company_logo ? data?.sender_details?.user_company_information?.company_logo : Images.companyprofile} className="userProfile me-2" alt="UserImage" />
                                                                                        </div>
                                                                                        <div className="postsDetails">
                                                                                            <h5 className="innerSubtextSmall d-flex align-items-center mb-2">
                                                                                                <span
                                                                                                    onClick={() => handlePageRedirect(data?.sender_details?.user_company_information?.company_name, data?.sender_details?.user_company_information?.id)}
                                                                                                ><strong>{data?.sender_details?.user_company_information?.company_name} </strong></span>
                                                                                                {/* <span className="timeingDetails">7:34PM</span> */}
                                                                                            </h5>
                                                                                            <p className="discription_">{data?.endorsement}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </>

                                                                            )
                                                                        })
                                                                        }
                                                                    </> : <p>No Endorsements Received</p>
                                                                }
                                                            </TabPanel>
                                                        </div>
                                                    </Tabs>

                                                </div>

                                                <p className="text-center mt-3">Have you worked with this company
                                                    <span className="docText mx-2" onClick={(id) => setLeaveEndorsements(true, id)} >
                                                        <u>Click here to leave endorsement</u>
                                                    </span>
                                                </p>

                                            </>}

                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 mt-5'>
                                <div className={`containerBoxright ${companyInfo?.companyDetails ? '' : 'd-none'}`}>
                                    <div className='companyDetailsRight'>
                                        <h5 className='headingTitle_ mb-2'><b>Company Details</b></h5>
                                        <ul className="companyDetailsList">
                                            <li className="flex-wrap">
                                                <span className="blueinnerSubtext">Website</span>
                                                <span className="listHead_">{companyInfo?.companyDetails?.company_website}</span>
                                            </li>
                                            <li className="flex-wrap">
                                                <span className="blueinnerSubtext">Industry</span>
                                                <span className="listHead_">{companyInfo?.companyDetails?.industries_group_details?.name}</span>
                                            </li>
                                            <li className="flex-wrap">
                                                <span className="blueinnerSubtext">Company Size</span>
                                                <span className="listHead_">{companyInfo?.companyDetails?.number_of_employees == 0 ? companyInfo?.companyDetails?.number_of_employees : companyInfo?.companyDetails?.number_of_employees + `+ employees`} </span>
                                            </li>
                                            <li className="flex-wrap">
                                                <span className="blueinnerSubtext">Locations</span>
                                                <span className="listHead_">{companyInfo.companyDetails?.main_company_country_location}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='containerBoxright mt-4'>
                                    <div className='deviserCompany_'>
                                        <div className='currentTop'>
                                            <h4 className='headingTitle_'><b>Diverse Supplier</b></h4>
                                            <button className="subsmallBtnblue_ me-2" onClick={() => handleChangePage()} >Learn More</button>
                                        </div>
                                        <p>A diverse supplier refers to a business or entity that is owned,
                                            operated, and controlled by individuals who belong to traditionally
                                            underrepresented or marginalized groups. These groups can
                                            include, but are not limited to, ethnic minorities, women, LGBTQ+
                                            individuals, veterans, and people with disabilities. Diverse suppliers
                                            play a crucial role in promoting inclusivity and equity in business
                                            practices and procurement processes, as they contribute to a
                                            broader range of perspectives, economic empowerment, and
                                            increased opportunities within various industries.</p>
                                        {/* <div className='designbottonContent_'>
                                            <span className='statusBtn_'>U.S. Veterans+</span>
                                            <span className='statusBtn_'>Ethnic Minority</span>
                                            <span className='statusBtn_'>LGBTQ+</span>
                                        </div> */}

                                    </div>
                                </div>
                                <div className='lookSuplier mt-4'>
                                    <div className='cardBox'>
                                        <div className="textBox">
                                            <h6>Business <b>Resources</b></h6>
                                            <p>Building, sustaining and growing a business is a never-ending journey that involves continuos infusions of knowledge and wisdom.</p>
                                            <button onClick={() => navigate("/resources")} className='primaryBtnSmall' >Start Learning</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='containerBoxright mt-3'>
                                    <div className='deviserCompany_'>
                                        <div className='currentTop'>
                                            <h4 className='headingTitle_'><b>Certifications</b></h4>
                                            <button className="subsmallBtnblue_ me-2">Learn More</button>
                                        </div>
                                        <div className='certificationContainer_'>
                                            {companyInfo?.user_company_information?.existing_certifications ?
                                                <div className='posts justify-content-between mt-3'>
                                                    <img src={Images.certifactionBadge} className='certifyBadge me-3' alt='UserImage' />
                                                    <div className='postsDetails'>
                                                        <h5 className='headingTitleSmall_ d-flex     justify-content-between mb-1'>
                                                            <span className='listHead_'>
                                                                {companyInfo?.user_company_information?.existing_certifications}
                                                                <span className='docText'>
                                                                    ({companyInfo?.user_company_information?.existing_certifications.split(" ").map(word => word.charAt(0)).join("")})
                                                                </span>
                                                            </span>
                                                        </h5>
                                                    </div>
                                                </div>
                                                : ""
                                            }
                                            {/* <div className='posts justify-content-between mt-3'>
                                                <img src={Images.certifactionBadge} className='certifyBadge me-3' alt='UserImage' />
                                                <div className='postsDetails'>
                                                    <h5 className='headingTitleSmall_ d-flex     justify-content-between mb-1'>
                                                        <span className='listHead_'>
                                                            Women’s Business Enterprise National Council <span className='docText'>(WBENC)</span>
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className='posts justify-content-between mt-3'>
                                                <img src={Images.certifactionBadge} className='certifyBadge me-3' alt='UserImage' />
                                                <div className='postsDetails'>
                                                    <h5 className='headingTitleSmall_ d-flex     justify-content-between mb-1'>
                                                        <span className='listHead_'>
                                                            National Minority Supplier Development Council <span className='docText'>(NMSDC)</span>
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className='posts justify-content-between mt-3'>
                                                <img src={Images.certifactionBadge} className='certifyBadge me-3' alt='UserImage' />
                                                <div className='postsDetails'>
                                                    <h5 className='headingTitleSmall_ d-flex     justify-content-between mb-1'>
                                                        <span className='listHead_'>
                                                            US Hispanic Chamber of Commerce <span className='docText'>(USHCC)</span>
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className='posts justify-content-between mt-3'>
                                                <img src={Images.certifactionBadge} className='certifyBadge me-3' alt='UserImage' />
                                                <div className='postsDetails'>
                                                    <h5 className='headingTitleSmall_ d-flex     justify-content-between mb-1'>
                                                        <span className='listHead_'>
                                                            US Hispanic Chamber of Commerce <span className='docText'>(USHCC)</span>
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className='posts justify-content-between mt-3'>
                                                <img src={Images.certifactionBadge} className='certifyBadge me-3' alt='UserImage' />
                                                <div className='postsDetails'>
                                                    <h5 className='headingTitleSmall_ d-flex     justify-content-between mb-1'>
                                                        <span className='listHead_'>
                                                            National Minority Supplier Development Council <span className='docText'>(NMSDC)</span>
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </section >

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "submitendorsement" ? "submitendorsement" : ""}
                ids={modalDetail.flag === "submitendorsement" ? "submitendorsement" : ""}
                child={
                    modalDetail.flag === "submitendorsement" ? (
                        <SubmitEndrosment
                            close={() => handleOnCloseModal()}

                        />
                    ) : modalDetail.flag === "ComposeMessage" ? (
                        <ComposeMessage
                            close={() => handleOnCloseModal()}
                            companyInfo={companyInfo}
                        // chatDraftsRefresh={() => getDrafts()}
                        />
                    ) :
                        ""
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "submitendorsement" ?
                                <h2 className="addLabels_heading">
                                    Endorsement
                                </h2> :
                                modalDetail.flag === "ComposeMessage" ?
                                    <h2 className="addLabels_heading">
                                        Compose Message
                                    </h2>
                                    : ""


                            }
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default CompanyfrontProfile