import { io } from 'socket.io-client';
import BaseUrl from '../../constants/baseUrl';
import store from '../../redux';

export const socket = () => {

    const userId = store.getState().auth?.userInfo?.user?.id;    
    const socketInstance =  io(BaseUrl.SOCKET_URL, {
        // path: "",
        path: "/api/v1/connect",
        query:  {userId} ,
    });

    return socketInstance
};