import React, { useState } from 'react'
import * as images from "../../../utilities/images";
import { toast } from "react-toastify"

const SocialLoginBusinessRole = (props) => {
    const [activeTab, setActiveTab] = useState("");
    const [id, setId] = useState("");

    //tab click
    const tabClick = (id) => {
        setId(id)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!id) {
            toast.error("Please Select atleast one role.")
        } else {
            props.setModalDetail('SocialLoginBusinessType')
            props.setRoleID(id)

        }
    }

    return (
        <div> <div className="row justify-content-center">
            <div className="col-md-10 text-center">
                <div className="detailsContainer">
                    <h3 className="headTxt mt-3 mb-4">Sign in as a <span className="highLightTxt">Buyer</span> or <span className="highLightTxt">Supplier</span>
                    </h3>
                    <div className='row justify-content-center'>
                        <div onClick={() => tabClick(1)} className='col-md-6 col-lg-6 mb-3'>
                            <div className={activeTab == 'Buyer' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("Buyer")}>
                                <img src={images.buyer} alt='Buyer' className='img-fluid' />
                                <h4 className="heading_ mt-3 mb-3">Buyer</h4>
                                <p className='textInner'>I'm a buyer, looking to connect with suppliers.</p>
                            </div>
                        </div>
                        <div onClick={() => tabClick(2)} className='col-md-6 col-lg-6 mb-3'>
                            <div className={activeTab == 'Supplier' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("Supplier")}>
                                <img src={images.supplier} alt='Supplier' className='img-fluid' />
                                <h4 className="heading_ mt-3 mb-3">Supplier</h4>
                                <p className='textInner'>I’m a supplier, looking to connect with buyers.</p>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center mt-4'>
                        <div className='col-md-10'>
                            <button onClick={(e) => handleSubmit(e)} className='primaryBtn w-100' type="submit">
                                <span>Continue</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default SocialLoginBusinessRole