import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { dashboardSlice } from "./dashboard";
import { userSlice } from "./user";
import { notificationSlice } from "./notification";
import { connectionSlice } from "./connection";
import { advanceSearchSlice } from "./advanceSearch";
import { opportunitySlice } from "./opportunity";
import { archiveOpportunitySlice } from "./opportunity/archiveOpportunity";
import { documentsSlice } from "./documents";
import { archiveDocumentSlice } from "./documents/archiveDocuments";
import { recentlyDeleteOpportunitySlice } from "./opportunity/recentlyDeleteOpportunity";
import { recentlyDeleteDocumentSlice } from "./documents/recentlyDeleteDocument";
import { companyInformationSlice } from "./companyInformation";
import { socketSlice } from "./socket";
import { messageSlice } from "./messages";
import { suggestionSlice } from "./resource";
import {contactUsSlice} from  './contactus';
import {mangeUsersSlice} from './manageusers'

import {certificationSlice} from './certification'

export const mainReducer = combineReducers({
    user: userSlice.reducer,
    auth: authSlice.reducer,
    dashboard: dashboardSlice.reducer,
    notification: notificationSlice.reducer,
    connection: connectionSlice.reducer,
    certification: certificationSlice.reducer,
    advanceSearch: advanceSearchSlice.reducer,
    opportunity: opportunitySlice.reducer,
    archiveOpportunity: archiveOpportunitySlice.reducer,
    documents: documentsSlice.reducer,
    archiveDocuments: archiveDocumentSlice.reducer,
    recentlyDeleteOpportunity: recentlyDeleteOpportunitySlice.reducer,
    recentlyDeleteDocument: recentlyDeleteDocumentSlice.reducer,
    companyInformation: companyInformationSlice.reducer,
    socket:socketSlice.reducer,
    message: messageSlice.reducer,
    resource: suggestionSlice.reducer,
    contactUs: contactUsSlice.reducer,
    mangeusers: mangeUsersSlice.reducer
})