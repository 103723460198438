import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import * as Images from "../../../utilities/images";
import ActiveOpportunity from '../activeOpportunity';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import RecentlyDelete from '../recentlyDeleteOpportunity';
import AddPostOpportunity from '../addPostOpportunity';
import EditPostOpportunity from '../editPostOpportunity';
import ReviewApplications from '../ReviewApplications';
import Archive from '../archiveOpportunity';
import InActiveOpportunity from "../inActiveOpportunity";
import ReviewResponses from "../ReviewApplications/reviewResponses";
import ReviewRespondents from "../ReviewApplications/viewRespondents";

const Opportunities = () => {
    const certificate_tooltip = " Say goodbye to tedious paperwork and complex requirements. GetOPP$ simplifies and streamlines the entire certification process, empowering you to focus on growing your business. With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier. Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity. "
    const maxLength = 60;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const tab = location?.state?.activeTab;
    const [activeTab, setActiveTab] = useState(tab === "activeOpportunity" ? "activeOpportunity" : tab === "inactiveOpportunity" ? "inactiveOpportunity" : 'addPostOpportunity');
    const [editOpportunitiesId, setEditOpportunitiesId] = useState('');


    const responceTab = (flag) => {
        setActiveTab(flag)
    }
    useEffect(() => {
        document.title = "opportunities";
    }, []);


    return (
        <>
            <div className='opportunityMain_'>
                <div className='row'>
                    <div className='col-lg-3 col-md-12'>
                        <div className='containerBoxleft'>
                            <button className="subsmallBtnblue_ mb-4" onClick={() => setActiveTab("addPostOpportunity")} >Post Opportunities</button>
                            <ul className='oppotunityMenu'>
                                <li onClick={() => setActiveTab("activeOpportunity")} className='settingItems'>
                                    <Link to='' className={`${activeTab === 'activeOpportunity' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.editopp} alt='activeOpportunity' />
                                        <span>Active Opportunities</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                                <li onClick={() => setActiveTab("inactiveOpportunity")} className='settingItems'>
                                    <Link to='' className={`${activeTab === 'inactiveOpportunity' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.editopp} alt='inactiveOpportunity' />
                                        <span>In-Active Opportunities</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                                <li onClick={() => setActiveTab("archived")} className='settingItems'>
                                    <Link to='' className={`${activeTab === 'archived' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.archived} alt='archived' />
                                        <span>Archived</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                                <li onClick={() => setActiveTab("reviewapplication")} className='settingItems'>
                                    <Link to='' className={`${activeTab === 'reviewapplication' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.reviewicon} alt='reviewapplication' />
                                        <span>Review Responses</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                                <li onClick={() => setActiveTab("recentlyDelete")} className='settingItems'>
                                    <Link to='' className={`${activeTab === 'recentlyDelete' ? 'settingLinks active' : ''}settingLinks`}>
                                        <img src={Images.deletedicon} alt='recentlyDelete' />
                                        <span>Recently Deleted</span>
                                    </Link>
                                </li>
                                <hr className='topBorder' />
                            </ul>
                        </div>
                        {/* <div className='cardContainer mt-4'>
                            <div className='cardImg'>
                                <img src={Images.featuremark} className='img-fluid' alt='CardImg' />
                            </div>
                            <div className='cardBodyArea'>
                                <h5>Featured</h5>
                                <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
                            </div>
                        </div> */}
                        <div className='cardContainer mt-4'>
                            <div className='cardImg'>
                                <img src={Images.certifyUser} className='img-fluid' alt='CardImg' />
                            </div>
                            <div className='cardBodyArea'>
                                <h5>Get Certified</h5>
                                <div className="infoiconText">
                                    <span className="showinfoText">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                                </div>
                                <p className='my-4'>GetOPP$ Diversity Certifications for inclusive business futures. </p>
                                <div className="infoiconText m-0">
                                    <Link to="/certificate">
                                        <button className='primaryBtn'>Learn More</button>
                                    </Link>
                                    <span className="showinfoText">
                                        {/* {data?.posting_title.length > 13.5 ? data?.posting_title.substring(0, 13.5) + "...." : data?.posting_title} */}

                                        {certificate_tooltip.substring(0, maxLength)}...

                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12'>
                        <div className='containerBox'>
                            {activeTab == 'activeOpportunity' ?
                                <ActiveOpportunity
                                    setEditOpportunitiesId={(id) => setEditOpportunitiesId(id)}
                                    setActiveTab={() => setActiveTab("editOpportunity")}
                                    setResponceTab={(flag) => responceTab(flag)}
                                />
                                : activeTab == 'archived' ?
                                    <Archive /> :
                                    activeTab == "recentlyDelete" ?
                                        <RecentlyDelete   setActiveTab={() => setActiveTab("activeOpportunity")} /> :
                                        activeTab == "addPostOpportunity" ?
                                            <AddPostOpportunity
                                                setActiveTab={() => setActiveTab("activeOpportunity")}
                                            /> :
                                            activeTab == "reviewapplication" ?
                                                <ReviewResponses
                                                    setActiveTab={() => setActiveTab("ReviewRespondents")}
                                                />
                                                :
                                                activeTab == "editOpportunity" ?
                                                    <EditPostOpportunity
                                                        setActiveTab={() => setActiveTab("activeOpportunity")}
                                                        id={editOpportunitiesId} /> :
                                                    activeTab == "inactiveOpportunity" ?
                                                        <InActiveOpportunity />

                                                        : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Opportunities