import React, { useEffect } from 'react'
import * as Images from "../../../utilities/images";

const TermsCondition = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "TermsCondition";
    }, []);
    return (
        <>
            <section className='termsCondition_ mt-5 mb-4'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='memeberHead_ col-md-10 '>
                            <h3 className='memberHead_'>Terms of <strong>Service</strong></h3>
                        </div>
                    </div>
                    <div className='memberHomeImage_ mt-5 mb-4'>
                        <img className='memberImage' src={Images.termsBanner} />
                        <div className='growBusiness_ mt-5 mb-4'>
                            <ul>
                                <h6 className="privacyHeading">User Agreement   </h6>
                                <p>Effective on May 30, 2023</p>
                                <p>Your Privacy Matters</p>
                                <p>GetOPPS’s mission is to have a profound impact on small businesses of all hues, as well as diversity, equity and inclusion for small businesses in a global economy. The vision is to help large, medium, small and diverse businesses thrive while providing efficiency, organization and exposure to a broader and comprehensive base of supppers to all businesses looking to buy.  </p>
                                <p>Our services are designed to promote economic opportunity for our members by enabpng your business and milpons of others to meet, exchange accomppshments, wisdom and skills, learn, and find opportunities in a network of trusted relationships.</p>
                                <h6 className="privacyHeading">Contract</h6>
                                <p>When your business uses our Services your business agrees to all of these terms. Your business use of our Services is also subject to our Privacy Popcy, which covers how we collect, use, share, and store your business personal information.</p>
                                <p>Your business agrees that by cpcking “Sign Up” or similar, registering, accessing or using our services (described below), your business is agreeing to enter into a legally binding contract with GetOPPS. If your business does not agree to this contract (“Contract” or “User Agreement”), do not cpck “Complete Payment” (or similar) and do not access or otherwise use any of our Services. If your business wishes to terminate this contract, at any time your business can do so by closing your business account and no longer accessing or using our Services.</p>
                                <h6 className="privacyHeading">Services</h6>
                                <p>This Contract apppes to GetOPPS.com, GetOPPS-branded apps, GetOPPS Learning and other GetOPPS-related sites, apps, communications and other services that state that they are offered under this Contract (“Services”), including the offsite collection of data for those Services, such as our ads and the “Apply with GetOPPS” and “Share with GetOPPS” plugins. Registered users of our Services are “Members” and unregistered users are “Visitors”.</p>
                                <h6 className="privacyHeading">GetOPPS</h6>
                                <p>Your business is entering into this Contract with GetOPPS (also referred to as “we” and “us”).</p>
                                <p>This Contract apppes to Members and Visitors. As a Visitor or Member of our Services, the collection, use and sharing of your business personal data is subject to this Privacy Popcy (which includes our Cookie Popcy and other documents referenced in this Privacy Popcy) and updates.
                                </p>
                                <h6 className="privacyHeading">Members and Visitors </h6>
                                <p>When your business registers and joins the GetOPPS Services, your business becomes a Member. If your business has chosen not to register for our Services, your business may access certain features as a “Visitor” on a promotional trial to test services.
                                </p>
                                <h6 className="privacyHeading">Change</h6>
                                <p>We may make changes to the Contract. We may modify this Contract, our Privacy Popcy and our Cookies Popcy from time to time. If we make material changes to it, we will provide your business notice through our Services, or by other means, to provide your business the opportunity to review the changes before they become effective. We agree that changes cannot be retroactive. If your business objects to any changes, your business may close your business account. Your business continued use of our Services after we pubpsh or send a notice about our changes to these terms means that your business is consenting to the updated terms as of their effective date.    </p>
                                <p>Obpgations and Service Epgibipty</p>
                                <p>Here are some promises that your business make to us in this Contract:</p>
                                <p>To use the Services, your business agree that:</p>
                                <p>(1) your business must be the "Minimum Age"(described below) or older; The Services are not for use by anyone under the age of 16. (2) your business will only have one GetOPPS account, which must be in your business real name; and (3) your business is not already restricted by GetOPPS from using the Services. </p>
<p>Creating an account with false information is a violation of our terms, including accounts registered on behalf of others or persons under the age of 16. “Minimum Age” means 16 years old. However, if law requires that your business’s registered users must be older in order for GetOPPS to lawfully provide the Services to your business without parental consent (including using of your business personal data) then the Minimum Age is such an older age.
</p>
<p>Your business Account. Your business will keep your business password a secret
</p>
<p>your business will not share an account with anyone else and will follow our rules and the law.
</p>
<h6 className="privacyHeading">Members are account holders. your business agrees to: 
</h6>
<p>(1) use a strong password and keep it confidential; (2) not transfer any part of your business account (e.g., connections) and (3) follow the law and our pst of Dos and Don’ts and Professional Community Popcies. 
    </p>
    <p>Your business is responsible for anything that happens through your business account unless your business closes it or reports misuse.
</p>
<p>As between your business and others (including your business employer), your business account belongs to your business. However, if the Services were purchased by another party for your business to use, the party paying for such Service has the right to control access to and get reports on your business use of such paid Service; 
</p>
<p>Payment. Your business will honor your business payment obpgations and your business is okay with us storing your business payment information. your business understands that there may be fees and taxes that are added to our prices.
</p>
<p>Refunds are subject to our popcy. If your business buys any of our paid Services, your business agrees to pay us the apppcable fees and taxes and to additional terms specific to the paid Services. 
</p>
<p>Failure to pay these fees will result in the termination of your business paid Services. Also, your business agree that:
</p>
<p>Your business purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates).
</p>
<p>We may store and continue bilpng your business payment method (e.g. credit card) even after it has expired, to avoid interruptions in your business Services and to use to pay other Services your business may buy.
</p>
<p>If your business purchase a subscription, your business payment method automatically will be charged at the start of each subscription period for the fees and taxes apppcable to that period. To avoid future charges, cancel before the renewal date. Learn how to cancel or suspend your business Premium Services.
</p>
<p>All of your business purchases of Services are subject to GetOPPS’s refund popcy.
</p>
<p>We may calculate taxes payable by your business based on the bilpng information that your business provide us at the time of purchase.
</p>
<p>Your business can get a copy of your business invoice through your business GetOPPS account settings under “Purchase History”.
</p>
<p>Notices and Messages. Your business is okay with us providing notices and messages to your business through our websites, apps, and contact information. If your business contact information is out of date, your business may miss out on important notices.
</p>
<p>Your business agrees that we will provide notices and messages to your business in the following ways:
</p>
<p>(1) within the Service, or (2) sent to the contact information your business provided us (e.g., email, mobile number, physical address). your business agrees to keep your business contact information up to date.
</p>
<p>Please review your business settings to control and pmit messages your business receives from us.
</p>
<p>Sharing. When your business shares information on our Services, others can see, copy and use that information.
</p>
<p>Content UPDATES 06/29/2023 - Google Sheets</p>
<p>Information and content that your business shares or posts may be seen by other Members or Visitors. 
</p>
<p>Where we have made settings available, we will honor the choices your business make about who can see content or information (e.g., message content to your business addressees, sharing content only to GetOPPS connections, restricting your business profile visibipty from search engines, or opting not to notify others of your business GetOPPS profile update). 
</p>
<p>We are not obpgated to pubpsh any information or content on our Service and can remove it with or without notice.
</p>
<h6 className="privacyHeading">Rights and Limits
</h6>
<p>Your business License to GetOPPS
</p>
<p>Your business owns all of the content, feedback and personal information your business provides to us, but your business also grants us a non-exclusive license to it.
</p>
<p>We’ll honor the choices your business makes about who gets to see your business information and content, including how it can be used for ads.
</p>
<p>As between your business and GetOPPS, your business own the content and information that your business submit or post to the Services, and your business are only granting GetOPPS and our affiliates the following non-exclusive license:
</p>
<p>A worldwide, transferable and sublicensable right to use, copy, modify, distribute, publish and process, information and content that your business provides through our Services and the services of others, without any further consent, notice and/or compensation to your business or others. These rights are limited in the following ways:
</p>
<p>Your business can end this license for specific content by deleting such content from the Services, or generally by closing your business account, except (a) to the extent your business shared it with others as part of the Service and they copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems.
</p>
<p>We will not include your business content in advertisements for the products and services of third parties to others without your business separate consent (including sponsored content). 
</p>
<p>However, we have the right, without payment to your business or others, to serve ads near your business content and information, and your business social actions may be visible and included with ads, as noted in the Privacy Policy. If your business uses a Service feature, we may mention that with your business name or photo to promote that feature within our Services, subject to your business settings.
</p>
<p>While we may edit and make format changes to your business content (such as translating or transcribing it, modifying the size, layout business or file type or removing metadata), we will not modify the meaning of your business expression.
</p>
<p>Because your business owns your business content and information and we only have non-exclusive rights to it, your business may choose to make it available to others, including under the terms of a Creative Commons license.
</p>
<p>Your business and GetOPPS agree that if content includes personal data, it is subject to our Privacy Policy.
</p>
<p>Your business and GetOPPS agree that we may access, store, process and use any information and personal data that your business provides in accordance with the terms of the Privacy Policy and your business choices (including settings).
</p>
<p>By submitting suggestions or other feedback regarding our Services to GetOPPS, your business agrees that GetOPPS can use and share (but does not have to) such feedback for any purpose without compensation to your business.
</p>
<p>Your business promises to only provide information and content that your business has the right to share, and that your business GetOPPS profile will be truthful.
</p>
<p>Your business agrees to only provide content or information that does not violate the law nor anyone’s rights (including intellectual property rights). your business also agrees that your business profile information will be truthful. GetOPPS may be required by law to remove certain information or content in certain countries.
</p>
<h6 className="privacyHeading">Service Availability
</h6>
<p>We may change or end any Service or modify our prices prospectively.
</p>
<p>We may change, suspend or discontinue any of our Services. We may also modify our prices effective prospectively upon reasonable notice to the extent allowed under the law.
</p>
<p>We don’t promise to store or keep showing any information and content that your business has posted. 
</p>
<p>GetOPPS is not a storage service. Your business agrees that we have no obligation to store, maintain or provide your business a copy of any content or information that your business or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.
</p>
<h6 className="privacyHeading">Other Content, Sites and Apps
</h6>
<p>Your business use of others’ content and information posted on our Services, is at your business own risk.
</p>
<p>Others may offer their own products and services through our Services, and we aren’t responsible for those third-party activities.
</p>
<p>By using the Services, your business may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. GetOPPS generally does not review content provided by our Members or others. your business agrees that we are not responsible for others’ (including other Members’) content or information. We cannot always prevent this misuse of our Services, and your business agrees that we are not responsible for any such misuse. Your business also acknowledges the risk that your business or your business organization may be mistakenly associated with content about others when we let connections and followers know your business or your business organization were mentioned in the news. Members have choices about this feature.
</p>
<p>GetOPPS may help connect Members offering their services with Members seeking services. GetOPPS does not perform nor employs individuals to perform these services. Your business must be at least 18 years of age to offer, perform or procure these services. your business acknowledge that GetOPPS does not supervise, direct, control or monitor Members in the performance of these services and agree that (1) GetOPPS is not responsible for the offering, performance or procurement of these services, (2) GetOPPS does not endorse any particular Member’s offered services, and (3) nothing shall create an employment, agency, or joint venture relationship between GetOPPS and any Member offering services. If your business is a Member offering services, your business represents and warrants that your business has all the required licenses and will provide services consistent with our Professional Community Policies.
</p>
<p>Similarly, GetOPPS may help your business register for and/or attend events organized by Members and connect with other Members who are attendees at such events. your business agree that (1) GetOPPS is not responsible for the conduct of any of the Members or other attendees at such events, (2) GetOPPS does not endorse any particular event listed on our Services, (3) GetOPPS does not review and/or vet any of these events, and (4) that your business will adhere to these terms and conditions that apply to such events.
</p>
<h6 className="privacyHeading">Limits</h6>
<p>We have the right to limit how your business connects and interacts on our Services.
    </p>
    <p>GetOPPS reserves the right to limit your business use of the Services, including the number of your business connections and your business ability to contact other Members. GetOPPS reserves the right to restrict, suspend, or terminate your business account if your business breach this Contract or the law or are misusing the Services (e.g., violating any of the Dos and Don’ts or Professional Community Policies).
</p>
<h6 className="privacyHeading">Intellectual Property Rights
</h6>
<p>We’re providing your business notice about our intellectual property rights.
</p>
<p>GetOPPS reserves all of its intellectual property rights in the Services. Trademarks and logos used in connection with the Services are the trademarks of their respective owners. GetOPPS, and “in” logos and other GetOPPS trademarks, service marks, graphics and logos used for our Services are trademarks or registered trademarks of GetOPPS.
</p>
<h6 className="privacyHeading">Automated Processing
</h6>
<p>We use data and information about your business to make relevant suggestions to your business and others.
</p>
<p>We use the information and data that your business provides and that we have about Members to make recommendations for connections, content and features that may be useful to your business. For example, we use data and information about your business to recommend jobs to your business and your business to recruiters. Keeping your business profile accurate and up to date helps us to make these recommendations more accurate and relevant. Learn More
</p>
<p>This is our disclaimer of legal liability for the quality, safety, or reliability of our Services.
</p>
<p>GetOPPS AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, GetOPPS AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
</p>
<h6 className="privacyHeading">Exclusion of Liability
</h6>
<p>These are the limits of legal liability we may have to your business.
</p>
<p>TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS GetOPPS HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), GetOPPS, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, your business INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.
</p>
<p>GetOPPS AND ITS AFFILIATES WILL NOT BE LIABLE TO your business IN CONNECTION WITH THIS CONTRACT FOR ANY AMOUNT THAT EXCEEDS (A) THE TOTAL FEES PAID OR PAYABLE BY your business TO GetOPPS FOR THE SERVICES DURING THE TERM OF THIS CONTRACT, IF ANY, OR (B) US $1000.
</p>
<p>Basis of the Bargain; Exclusions
</p>
<p>The limitations of liability in this Section 4 are part of the basis of the bargain between your business and GetOPPS and shall apply to all claims of liability (e.g., warranty, tort, negligence, contract and law) even if GetOPPS or its affiliates has been told of the possibility of any such damage, and even if these remedies fail their essential purpose.
</p>
<p>These limitations of liability do not apply to liability for death or personal injury or for fraud, gross negligence or intentional misconduct, or in cases of negligence where a material obligation has been breached, a material obligation being such which forms a prerequisite to our delivery of services and on which your business may reasonably rely, but only to the extent that the damages were directly caused by the breach and were foreseeable upon conclusion of this Contract and to the extent that they are typical in the context of this Contract.
</p>
<h6 className="privacyHeading">Termination</h6>
<p>We can each end this Contract, but some rights and obligations survive.
</p>
<p>Both your business and GetOPPS may terminate this Contract at any time with notice to the other. On termination, your business loses the right to access or use the Services. The following shall survive termination:
</p>
<p>Our rights to use and disclose your business feedback;
</p>
<p>Members and/or Visitors’ rights to further re-share content and information your business shared through the Services;
</p>
<p>Any amounts owed by either party prior to termination remain owed after termination.
</p>
<p>Governing Law and Dispute Resolution
</p>
<p>In the unlikely event we end up in a legal dispute, depending on where your business lives, your business and GetOPPS agree to resolve it in Nevada courts using Nevada law or in your business local courts using local law.
</p>
<h6 className="privacyHeading">General Terms
</h6>
<p>Here are some important details about the Contract.
</p>
<p>If a court with authority over this Contract finds any part of it unenforceable, your business and we agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, your business and we agree to ask the court to remove that unenforceable part and still enforce the rest of this Contract.
</p>
<p>This Contract (including additional terms that may be provided by us when your business engages with a feature of the Services) is the only agreement between us regarding the Services and supersedes all prior agreements for the Services.
</p>
<p>If we don't act to enforce a breach of this Contract, that does not mean that GetOPPS has waived its right to enforce this Contract. your business may not assign or transfer this Contract (or your business membership or use of Services) to anyone without our consent. However, your business agrees that GetOPPS may assign this Contract to its affiliates or a party that buys it without your business consent. There are no third-party beneficiaries to this Contract.
</p>
<p>Your business agrees that the only way to provide us legal notice is at the addresses provided. 
</p>
<h6 className="privacyHeading">GetOPPS “Dos and Don’ts”
</h6>
<p>GetOPPS is a community of professionals and businesses. This list of “Dos and Don’ts” along with our Professional Community Policies limit what your business can and cannot do on our Services.
</p>
<h6 className="privacyHeading">Dos</h6>
<p>Your business agree that your business will:
</p>
<p>Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;
</p>
<p>Provide accurate information to us and keep it updated;
</p>
<p>Use your business real name on your business profile; 
</p>
<p>Use the Services in a professional manner.
</p>
<p>Your business agree that your business will not:
</p>
<p>Create a false identity on GetOPPS, misrepresent your business identity, create a Member profile for anyone other than your business, or use or attempt to use another’s account;
</p>
<p>Develop, support or use software, devices, scripts, robots or any other means or processes (including crawlers, browser plugins and add-ons or any other technology) to scrape the Services or otherwise copy profiles and other data from the Services;
</p>
<p>Override any security feature or bypass or circumvent any access controls or use limits of the Service (such as caps on keyword searches or profile views);
</p>
<p>Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of GetOPPS;
</p>
<p>Disclose information that your business do not have the consent to disclose (such as confidential information of others (including your business employer));
</p>
<p>Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the posts or other content of others without their permission, which they may give by posting under a Creative Commons license;
</p>
<p>Violate the intellectual property or other rights of GetOPPS, including, without limitation, (i) copying or distributing our learning videos or other materials or (ii) copying or distributing our technology, unless it is released under open source licenses; (iii) using the word “GetOPPS” or our logos in any business name, email, or URL except as provided in the Brand Guidelines;
</p>
<p>Post anything that contains software viruses, worms, or any other harmful code;
</p>
<p>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;
</p>
<p>Imply or state that your business are affiliated with or endorsed by GetOPPS without our express consent (e.g., representing your business self as an accredited GetOPPS trainer);
</p>
<p>Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services or related data or access to the same, without GetOPPS’s consent;
</p>
<p>Deep-link to our Services for any purpose other than to promote your business profile or a Group on our Services, without GetOPPS’s consent;
</p>
<p>Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages;
</p>
<p>Monitor the Services’ availability, performance or functionality for any competitive purpose;
</p>
<p>Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services;
</p>
<p>Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included on the Services);
</p>
<p>Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms); and/or
    </p>
    <p>Violate the Professional Community Policies or any additional terms concerning a specific Service that are provided when your business sign up for or start using such Service, and the Bing Maps terms where applicable. (see below for Professional Community Policy)
</p>
<h6 className="privacyHeading">Complaints Regarding Content
</h6>
<p>Contact information for complaints about content provided by our Members.
</p>
<p>We respect the intellectual property rights of others. We require that information posted by Members be accurate and not in violation of the intellectual property rights or other rights of third parties. We provide a policy and process for complaints concerning content posted by our Members.
</p>
<h6 className="privacyHeading">How To Contact Us
</h6>
<p>Our Contact information. 
</p>
<p>For general inquiries, your business may contact us online. For legal notices or service of process, your business may write to us at these addresses. (add address)
</p>
<p>GetOPPS Professional Community Policies
</p>
<p>Be safe
</p>
<p>Only bring safe conversations to GetOPPS.
</p>
<p>Be trustworthy
</p>
<p>Use your business true identity and share information that is real and authentic.
</p>
<h6 className="privacyHeading">Be professional
</h6>
<p>We allow broad conversations about the world of work, but just keep it professional.
</p>
<p>Thank your business for using GetOPPS, where the world’s professionals come together to find opportunities, stay informed, learn new skills, and build productive relationships. The content that your business contributes should add to the GetOPPS community in a constructive manner. Additional information on what that means, is laid out below. Your business should also review our Publishing Platform Guidelines. Together we can make our community a place where everyone is able to learn, grow, and communicate, which, in turn, helps create economic opportunity for everyone.
</p>
<p>Tell us if your business see abusive content
</p>
<p>If your business sees something your business believes may violate our policies, whether in profiles, posts, messages, comments, or anywhere else, please report it to us. Combined with our automated defenses, these reports help us identify and prevent abuse. Please use the reporting tools responsibly and only for their intended purposes. To learn more about how to report inappropriate content, behavior, or members, visit our Safety Center.
</p>
<p>Do not post harassing content: We don’t allow bullying or harassment. This includes targeted personal attacks, intimidation, shaming, disparagement, and abusive language directed at other members. Do not reveal others' personal or sensitive information (aka "doxing"), or incite others to do the same. your business may not engage in trolling or other repetitive negative content that disrupts other members or conversations on the platform.
</p>
<p>Do not threaten, incite, or promote violence: We don’t allow threatening or inciting violence of any kind. We don’t allow individuals or groups that engage in or promote violence, property damage, or organized criminal activity. your business may not use GetOPPS to express support for such individuals or groups or to otherwise glorify violence.
</p>
<p>Do not share material depicting the exploitation of children: We have zero tolerance for content that depicts the sexual exploitation of children. Do not share, post, transmit, or solicit child exploitation material through or using our platform. Do not use GetOPPS in any way to facilitate, encourage, or engage in the abuse or exploitation of children. When we become aware of apparent child exploitation, we report it to the National Center for Missing and Exploited Children (NCMEC). Additionally, while we recognize that there may be instances where people share images of non-sexualized child nudity with good intentions, we generally remove these images due to the potential of misappropriation and abuse by others.
</p>
<p>Do not promote, sell or attempt to purchase illegal or dangerous goods or services. We don't allow content that facilitates the purchase of illegal or dangerous goods and/or services, prostitution, and escort services. We don't allow content that promotes or distributes fake educational and/or professional certifications, sale of scraped data, proxy test-taking, or instructions on creating forged official documents. your business may not use GetOPPS to hold lotteries, contests, sweepstakes, or giveaways. Do not use GetOPPS to sensationalize or capitalize on tragic events for commercial purposes.
</p>
<p>Do not share content promoting dangerous organizations or individuals. We don't allow any terrorist organizations or violent extremist groups on our platform. And we don't allow any individuals who affiliate with such organizations or groups to have a GetOPPS profile. Content that depicts terrorist activity, that is intended to recruit for terrorist organizations, or that threatens, promotes, or supports terrorism in any manner is not tolerated.
</p>
<p>Co-workers discussing a candidate
</p>
<h6 className="privacyHeading">Be Trustworthy
</h6>
<p>We require your business to use your business true identity on GetOPPS, provide accurate information about your business self or your business organization, and only share information that is real and authentic.
</p>
<p>Do not share false or misleading content: Do not share content in a way that your business knows is, or think may be, misleading or inaccurate, including misinformation or disinformation. Do not share content to interfere with or improperly influence an election or other civic process. We may prevent your business from posting content from sites that are known to produce or contain misinformation. Do not share content that directly contradicts guidance from leading global health organizations and public health authorities; including false information about the safety or efficacy of COVID-19 vaccines. Do not share false content or information, including news stories, as though they are true or likely true. Do not post “deepfake” images or videos of others or otherwise post content that has been manipulated to deceive. Do not share content or endorse someone or something in exchange for personal benefit (including personal or family relationships, monetary payment, free products or services, or other value), unless your business have included a clear and conspicuous notice of the personal benefit your business receive and have otherwise complied with our Advertising Policies. 
</p>
<p>Do not create a fake profile or falsify information about your business self: We don’t allow fake profiles or entities. Do not post misleading or deceptive information about your business self, your business business, your business qualifications, work experience, affiliations, or achievements. Do not use an image of someone else, or any other image that is not your business likeness, for your business profile photo. Do not associate your business self on GetOPPS with a business or organization that your business are not actually professionally associated with. Do not use or attempt to use another person's GetOPPS account or create a member profile for anyone other than your businessself. And do not share your business GetOPPS account with anyone else.
</p>
<p>Do not scam, defraud, deceive others. Do not use GetOPPS to facilitate romance scams, promote pyramid schemes, or otherwise defraud members. Do not share malicious software that puts our members, platform, or services at risk. Phishing attempts are not tolerated.
</p>
<p>GetOPPS’s mission is to have a profound impact on small businesses of all hues, as well as diversity, equity and inclusion for small businesses in a global economy. The vision is to help large, medium, small and diverse businesses thrive while providing efficiency, organization and exposure to a broader and comprehensive base of suppliers to all businesses looking to buy.   
</p>
<p>We allow broad conversations about the world of work, but require professional expression. To maintain a professional platform, members should treat each other with respect and civility. Do not interact with others or share content in a way that's uncivil, inappropriate, or disrespectful.
</p>
<p>Do not be hateful. We don't allow content that attacks, denigrates, intimidates, dehumanizes, incites or threatens hatred, violence, prejudicial or discriminatory action against individuals or groups because of their actual or perceived race, ethnicity, national origin, caste, gender, gender identity, sexual orientation, religious affiliation, age, or disability status. Hate groups are not permitted on GetOPPS. Do not use racial, religious, or other slurs that incite or promote hatred, or any other content intended to create division. Do not post or share content that denies a well-documented historical event such as the Holocaust or slavery in the United States.
</p>
<p>Do not engage in sexual innuendos or unwanted advances. We don't allow unwanted expressions of attraction, desire, requests for romantic relationship, marriage proposals, sexual advances or innuendo, or lewd remarks. GetOPPS is a professional networking platform, not a dating site. Do not use GetOPPS to pursue romantic connections, ask for romantic dates, or provide sexual commentary on someone's appearance or perceived attractiveness. Do not send unwanted advances in messages, posts, or comments or send sexually explicit images to anyone on the platform.
</p>
<p>Do not share harmful or shocking material. We don't allow content that is excessively gruesome or shocking. This includes content that is sadistic or gratuitously graphic, such as the depiction of bodily injury, severe physical or sexual violence. We don't allow content, activities, or events that promote, organize, depict, or facilitate criminal activity. We also don't allow content depicting or promoting instructional weapon making, drug abuse, and threats of theft. Do not engage in or promote escort services, prostitution, exploitation of children, or human trafficking. Do not share content or activities that promote or encourage suicide or any type of self-injury, including self-mutilation and eating disorders. If your business see signs that someone may be considering self-harm, please report it using our reporting tools and consider also reporting it to your business local law enforcement authorities. Do not share material depicting nudity or sexual activity.
</p>
<p>Do not spam members or the platform. We don't allow untargeted, irrelevant, obviously unwanted, unauthorized, in appropriately commercial or promotional, or gratuitously repetitive messages or similar content. Do not use our invitation feature to send promotional messages to people your business don't know or to otherwise spam people. Please make the effort to create original, professional, relevant, and interesting content in order to gain engagement. Don't do things to artificially increase engagement with your business content. Respond authentically to others’ content and don’t agree with others ahead of time to like or re-share each other’s content.
</p>


                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsCondition