import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recentlyDeleteOpportunities: {},
  loading: false,
};

export const recentlyDeleteOpportunitySlice = createSlice({
  name: "recentlyDeleteOpportunities",
  initialState,
  reducers: {
    recentlyDeleteOpportunity: (state) => {
      state.loading = true;
    },
    setRecentlyDeleteOpportunity: (state, action) => {
      state.loading = false;
      state.recentlyDeleteOpportunities = action.payload;
    },
    permanentlyDelete: (state) => {
      state.loading = true;
    },
    setPermanentlyDelete: (state, action) => {
      state.loading = false;
    },
    undeleteAction: (state) => {
      state.loading = true;
    },
    setUndeleteAction: (state, action) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  recentlyDeleteOpportunity,
  setRecentlyDeleteOpportunity,
  permanentlyDelete,
  setPermanentlyDelete,
  undeleteAction,
  setUndeleteAction,
  onErrorStopLoad,
} = recentlyDeleteOpportunitySlice.actions;

export default recentlyDeleteOpportunitySlice.reducer;
