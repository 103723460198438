import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import MultiStepProgressBar from "./component/multiStepProgressBar";
import { useDispatch } from 'react-redux';
import { diverseSupplierDescription, existingCertification } from '../../../redux/slices/advanceSearch';
import { signUpStep, stepThreeCompanyLogoUplaod, stepTwo, getAllIndustryGroups, getAllIndustrySubGroups, businessOwnershipType, companyListType, getSessionId, updateMemberShipAmount, getPaymentViaPromoCode, updateUserStep, updateUserStepInside } from '../../../redux/slices/auth';
import * as Images from "../../../utilities/images";
import { useAuthSelector } from '../../../redux/selector/auth';
import { getMemberShipPlans, getPaymentSession, verifySession } from '../../../redux/slices/dashboard';
import moment from 'moment';

const Registration = () => {

    const [activeTab, setActiveTab] = useState('quarterly');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    const queryParams = new URLSearchParams(location.search);

    const authSelector = useAuthSelector();
    const Businesstype = authSelector?.userInfo?.user?.business_type;

    const flag = authSelector?.userStep?.step || authSelector?.userInfo?.user?.step;
    const memberShipType = authSelector?.userStep?.membership_type || authSelector?.userInfo?.user?.membership_type;
    const membership_details = authSelector?.userStep?.membership_details || authSelector?.userInfo?.user?.membership_details;

    const [pageNumber, setPageNumber] = useState(flag ? flag + 1 : 2);
    const [page, setPage] = useState(flag == 2 ? "pagethree" : flag == 3 ? "pagefour" : flag == 4 ? "pagefive" : flag == 5 ? "pagefive" : flag == 6 ? "pagesix" : "pageone");
    const [photo, setPhotoUpload] = useState("");
    const [photoInfo, setPhotoInfo] = useState();
    const [companyName, setCompanyName] = useState("")
    const [companyWebsite, setCompanyWebsite] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [companyOtherDescription, setCompanyOtherDescription] = useState("")
    const [certificationOtherDescription, setCertificationOtherDescription] = useState("")
    const [companyDescriptionId, setCompanyDescriptionId] = useState("")
    const [companyDescriptionOtherId, setCompanyDescriptionOtherId] = useState("")
    const [industryGroup, setIndustryGroup] = useState([])
    const [subIndustryGroup, setSubIndustryGroup] = useState([])
    const [industryGroupId, setIndustryGroupId] = useState("")
    const [industrySubGroupId, setIndustrySubGroupId] = useState("")
    const [ownershipType, setOwnershipType] = useState("")
    const [ownershipTypeId, setOwnershipTypeId] = useState("")
    const [companyType, setCompanyType] = useState("")
    const [companyTypeId, setCompanyTypeId] = useState("")
    const [companyKeyWords, setCompanyKeyWords] = useState("")
    const [companyServicesDescription, setCompanyServicesDescription] = useState("")
    // const [existingCertificationData, setExistingCertificationData] = useState("");
    const [existingCertificationId, setExistingCertificationId] = useState("");

    const [memberShip, setMemberShip] = useState(memberShipType);

    const [memberShipPackage, setMemberShipPackage] = useState("");
    const [selected, setSelected] = useState([]);
    const [driversSupplierDescriptionId, setDriversSupplierDescriptionId] = useState([]);

    const [totalMembershipPrice, setTotalMembershipPrice] = useState("");
    const [totalMembershipPriceInteger, setTotalMembershipPriceInteger] = useState(0);

    const [selectedCertifications, setSelectedCertifications] = useState([]);
    const [existingCertificationData, setExistingCertificationData] = useState("")
    const [existingCertificationsId, setExistingCertificationsId] = useState();

    const [newMembershipPlans, setNewMembershipPlans] = useState([])
    const [activeTabMembership, setActiveTabMembership] = useState('enter')
    const [promoCode, setPromoCode] = useState(null)

    const resultIndicator = queryParams.get('resultIndicator');
    const sessionVersion = queryParams.get('sessionVersion');
    const checkoutVersion = queryParams.get('checkoutVersion');

    useEffect(() => {
        if (Businesstype) {
            getMemberShipPlansAction(Businesstype)
        }

    }, [Businesstype])

    useEffect(() => {
        if (membership_details) {
            handleMembershipForPageLoad(membership_details.id, membership_details.name, membership_details)
        }
    }, [membership_details])


    const getMemberShipPlansAction = (type) => {

        const params = {
            type: type
        }

        dispatch(getMemberShipPlans(({
            ...params, cb(res) {

                if (res.status == 200) {
                    setNewMembershipPlans(res?.data?.payload)
                }
                else {
                    toast.warning('Something went wrong')
                }

            }
        })))

    }

    useEffect(() => {
        if (resultIndicator && sessionVersion) {
            verifySess(resultIndicator)
        }

    }, [resultIndicator && sessionVersion])


    const verifySess = (resultIndicator) => {

        const params = {
            status: 2,
            success_token: resultIndicator
        }

        dispatch(verifySession(({
            ...params, cb(res) {

                if (res.status == 200) {

                    if (res.data?.payload?.status == 2) {
                        // setPageNumber(flag)
                        navigate('/dashboard');
                    }
                    else {
                        toast.warning('Something went wrong')
                    }


                }
                else {
                    toast.warning('Something went wrong')
                }

            }
        })))

    }


    //onDrop
    const onDrop = useCallback(acceptedFiles => {
        const imageFile = acceptedFiles[0];
        if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
            toast.error("Please select a valid image.");
            return false;
        }
        let params = {
            photo: imageFile,
        }
        dispatch(stepThreeCompanyLogoUplaod({
            ...params, cb(res) {
                if (res.status) {
                    setPhotoUpload(res?.data?.payload?.url);
                    setPhotoInfo(res?.data?.payload)
                }
                else {
                }
            }
        }))
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    //remove file
    const removeFile = file => () => {
        const newFiles = [photo]
        newFiles.splice(newFiles.indexOf(file), 1)
        setPhotoUpload(newFiles)
        setPhotoInfo("")

    }

    //next page
    const nextPage = (page) => {
        setPage(page);
    };

    //next page number
    const backPageNumber = () => {
        const newPageNumber = pageNumber.toString();
        switch (newPageNumber) {
            case "1":
                setPage("pagetwo");
                setPageNumber(2);
                break;
            case "2":
                setPage("pagetwo");
                setPageNumber(2);
                break;
            case "3":
                setPage("pagetwo");
                setPageNumber(2);
                break;
            case "4":
                setPage("pagethree");
                setPageNumber(3);
                break;
            case "5":
                setPage("pagefour");
                setPageNumber(4);
                break;
            case "6":
                setPage("pagefive");
                setPageNumber(5);
                break;
            default:
                setPage(1);

        }
    };

    //next page numbernextPageNumber
    const nextPageNumber = (pageNumber) => {
        ;
        switch (pageNumber) {
            case "1":
                setPage("pageone");
                setPageNumber(1);
                break;
            case "2":
                setPage("pagetwo");
                setPageNumber(2);
                break;
            case "3":
                setPage("pagethree");
                setPageNumber(3);
                break;
            case "4":
                setPage("pagefour");
                setPageNumber(4);
                break;
            case "5":
                setPage("pagefive");
                setPageNumber(5);
                break;
            case "6":
                setPage("pagesix");
                setPageNumber(6);
                break;
            default:
                setPage(1);

        }
    }


    //select membership
    const handleMembership = (event, flag, plan) => {

        setMemberShip(event.target.value)
        setMemberShipPackage(flag)
        setActiveTab("yearly");
        setTotalMembershipPriceInteger(plan.one_year_cost ?? 0)
        setTotalMembershipPrice(formatNumberToDollars(plan.one_year_cost ?? 0))

    }

    //select membership
    const handleMembershipForPageLoad = (id, flag, plan) => {

        setMemberShip(id)
        setMemberShipPackage(flag)
        setActiveTab("yearly");
        setTotalMembershipPriceInteger(plan.one_year_cost ?? 0)
        setTotalMembershipPrice(formatNumberToDollars(plan.one_year_cost ?? 0))

    }


    //form login
    const handleSubmit = (e, flag, save) => {
        const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
        e.preventDefault();

        if (flag == 3) {
            if (companyTypeId) {
                let params = {
                    step: -1,
                    company_id: parseInt(companyTypeId)
                }
                dispatch(signUpStep({
                    ...params, cb(res) {
                        if (save == "save&finish") {
                            navigate('/');
                            localStorage.removeItem('authToken');
                            localStorage.removeItem('persist:root');
                            navigate('/');
                        }
                        else if (res.status) {
                            setPageNumber(5)
                            nextPage("pagefive")
                        }
                    }
                }))
            }
            else {

                if (!companyName) {
                    toast.error("Please enter Company name");
                    return;
                }

                else if (companyName.length <= 2) {
                    toast.error("Company name should be maximum 3 character")
                    return;
                }

                else if (!companyWebsite) {
                    toast.error("Please enter company website url");
                    return;
                }
                else if (driversSupplierDescriptionId?.includes(16) && !companyOtherDescription) {
                    toast.error("please enter other description");
                    return;
                }

                // else if (!companyWebsite.match(URL)) {
                //     toast.error("Please enter the correct company url");
                //     return;
                // }
                // else if (!driversSupplierDescriptionId.length > 0) {
                //     toast.error("Please select diverse status");
                //     return;
                // }

                // else if (!driversSupplierDescriptionId.length > 0 || !companyOtherDescription ) {
                //     toast.error("Please select diverse supplier description");
                //     return;
                // }
                // else if (!companyOtherDescription) {
                //     toast.error("Please select diverse supplier other description");
                //     return;
                // }

                // else if (!ownershipTypeId) {
                //     toast.error("Please select business ownership Type");
                //     return;
                // }

                // else if (!companyDescriptionId && !companyDescriptionOtherId) {
                //     toast.error("Please select diverse supplier description");
                //     return;
                // }

                // else if (companyDescriptionOtherId && !companyOtherDescription) {
                //     toast.error("Please enter diverse supplier description");
                //     return;
                // }


                let params = {
                    step: 2,
                    company_name: companyName,
                    company_website: companyWebsite,
                    diverse_supplier_description: driversSupplierDescriptionId,
                    // diverse_supplier_description: parseInt(companyDescriptionOtherId) ? parseInt(companyDescriptionOtherId) : parseInt(companyDescriptionId),
                    business_ownership_type: parseInt(ownershipTypeId) ? parseInt(ownershipTypeId) : 0
                }
                if (companyOtherDescription) {
                    params = {
                        ...params,
                        diverse_supplier_description_other: companyOtherDescription,
                    }
                }

                dispatch(signUpStep({
                    ...params, cb(res) {
                        if (save == "save&finish") {
                            localStorage.removeItem('authToken');
                            localStorage.removeItem('persist:root');
                            navigate('/');
                        }
                        else if (res.status) {
                            setPageNumber(flag)
                            nextPage("pagethree")
                        }
                    }
                }))

            }
        }
        else if (flag == 4) {
            // if (!photo) {
            //     toast.error("Please select company logo ");
            //     return;
            // }

            let params = {
                step: 3,
                company_logo: photo.length ? photo : "",
                company_logo_name: photoInfo ? photoInfo.name : "",
                company_logo_size: photoInfo ? photoInfo.size : 0,
            }
            dispatch(signUpStep({
                ...params, cb(res) {
                    if (save == "save&finish") {
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('persist:root');
                        navigate('/');
                    }
                    else if (res.status) {
                        setPageNumber(flag)
                        nextPage("pagefour")
                    }
                }
            }))
        }
        else if (flag == 5) {
            // if (!industryGroupId) {
            //     toast.error("Please select industry Groups");
            //     return;
            // }
            // else if (!industrySubGroupId) {
            //     toast.error("Please select industry Sub Groups");
            //     return;
            // }
            // else if (!companyKeyWords) {
            //     toast.error("Please enter company Key Words");
            //     return;
            // }
            // else if (!companyServicesDescription) {
            //     toast.error("Please enter company about");
            //     return;
            // }
            if (existingCertificationsId?.includes(11) && !certificationOtherDescription) {
                toast.error("please enter other certificate description");
                return;
            }
            let params = {
                step: 4,
                industry_groups: parseInt(industryGroupId) ? parseInt(industryGroupId) : null,
                industry_sub_groups: parseInt(industrySubGroupId) ? parseInt(industrySubGroupId) : null,
                company_existing_certificates_id: existingCertificationsId ? existingCertificationsId : [],
                key_words: companyKeyWords,
                company_service_description: companyServicesDescription
            }
            if (certificationOtherDescription) {
                params = {
                    ...params,
                    company_existing_certificates_other: certificationOtherDescription,
                }
            }
            dispatch(signUpStep({
                ...params, cb(res) {
                    if (save == "save&finish") {
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('persist:root');
                        navigate('/');
                    }
                    else if (res.status) {
                        setPageNumber(flag)
                        nextPage("pagefive")
                    }
                }
            }))
        }
        else if (flag == 6) {

            //uncomment this code after approval for promo code
            
            // if(activeTabMembership =='enter' && authSelector.userInfo?.user?.business_type == 1){
            //     if(!promoCode) {
            //         toast.error("Please enter promo code");
            //         return;
            //     }
            //     const params = {
            //         promo_code:promoCode
            //     }
            //     dispatch(getPaymentViaPromoCode({
            //         ...params, cb(res) {
            //             if (res.status == 200) {
            //                 console.log(res,'res')
            //                 dispatch(updateUserStepInside(res.data?.payload?.step ?? 6))
            //                 toast.success('Payment verified successfully')
            //                 navigate('/dashboard');

            //             }
            //         }
            //     }))


            // }
            // else{
                if (!memberShip) {
                    toast.error("Please select member ship");
                    return;
                }
                let params = {
                    step: 5,
                    membership_type: parseInt(memberShip),
                }
                dispatch(signUpStep({
                    ...params, cb(res) {
                        if (save == "save&finish") {
                            localStorage.removeItem('authToken');
                            localStorage.removeItem('persist:root');
                            navigate('/');
                        }
                        else if (res.status) {
                            setPageNumber(flag)
                            nextPage("pagesix")
                        }
                    }
                }))
            // }

        }
        else if (flag == 7) {
            // setLoading(true)
            // const currentUrl = window.location.href;
            // const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            
            // let params = {
            //     authentication_time: 15,
            //     amount: parseFloat(totalMembershipPriceInteger) + 253,
            //     description: "Test",
            //     returnUrl: currentUrl,
            //     cancelUrl: `${baseUrl}/paymentCancelled`,
            //     timeoutUrl: `${baseUrl}/paymentCancelled`,
            //     membership_id: parseInt(memberShip)
            // }

            // dispatch(getPaymentSession(({
            //     ...params, cb(res) {


            //         if (res.status == 200) {
            //             window.location.href = res.data?.payload?.url ?? currentUrl
            //         }
            //         else {
            //             toast.warning('Something went wrong')
            //         }
            //     }
            // })))



            // let params = {
            //     authentication_time: 15,
            //     amount: parseFloat(totalMembershipPriceInteger) +253,
            //     description: "Test",
            //     returnUrl: currentUrl,
            //     cancelUrl: `${baseUrl}/paymentCancelled`,
            //     timeoutUrl: `${baseUrl}/paymentCancelled`,
            //     membership_id : parseInt(memberShip)
            // }

            // dispatch(getPaymentSession(({
            //     ...params, cb(res) {
            //         if(res.status == 200){
            //             window.location.href = res.data?.payload?.url ?? currentUrl
            //         }
            //         else{
            //             toast.warning('Something went wrong')
            //         }
            //     }
            // })))

            let params = {
                step: 6,
            }
            dispatch(signUpStep({
                ...params, cb(res) {
                    if (save == "save&finish") {
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('persist:root');
                        navigate('/');
                    }
                    else if (res.status) {
                        setPageNumber(flag)
                        navigate('/dashboard');
                    }
                }
            }))
        }


    }

    const industry = (e) => {
        dispatch(getAllIndustryGroups({
            cb(res) {
                if (res.status) {
                    setIndustryGroup(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    // onchange input 
    const handleChange = (id, flag) => {
        if (flag == "industry") {
            setIndustryGroupId(id)
            subIndustry(id)
        }
        else {
            setIndustrySubGroupId(id)
        }
    }

    const subIndustry = (id) => {
        if (id) {
            setIndustryGroupId(id)
            let params = {
                id: id,
            }
            dispatch(getAllIndustrySubGroups({
                ...params, cb(res) {
                    if (res.status) {
                        setSubIndustryGroup(res?.data?.payload)
                        setIndustrySubGroupId("")
                    }
                    else {

                    }
                }
            }))

        }

    }

    const businessOwnership = (id) => {
        setOwnershipTypeId(id)
        dispatch(businessOwnershipType({
            cb(res) {
                if (res.status) {
                    setOwnershipType(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    const companyListAll = (id) => {
        setCompanyTypeId(id)
        dispatch(companyListType({
            cb(res) {
                if (res.status) {
                    setCompanyType(res?.data?.payload?.data)
                }
                else {

                }
            }
        }))
    }

    //for add drivers Supplier Description........
    const supplierDescription = (id) => {
        if (id == 18) {
            setCompanyDescriptionOtherId(id)
            setCompanyDescriptionId("")
            setCompanyOtherDescription("")
        }
        else {
            setCompanyDescriptionId(id)
            setCompanyDescriptionOtherId("")
            setCompanyOtherDescription("")
        }

        dispatch(diverseSupplierDescription({
            cb(res) {
                if (res.status) {
                    setCompanyDescription(res?.data?.payload)
                }
            }
        }))
    }

    // driver supplier description
    const handleChanges = (data) => {
        setSelected(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDriversSupplierDescriptionId(arr)
    }

    //Update Memnber ship amount
    const memberShipAmount = (id) => {
        let params = {
            session_id: id,
            amount: 250
        }
        dispatch(updateMemberShipAmount({
            ...params, cb(res) {
                if (res.status) {
                }
                else {

                }
            }
        }))
    }

    // Existing certification
    const ExistingCertification = () => {
        dispatch(existingCertification({
            cb(res) {
                if (res.status) {
                    setExistingCertificationData(res.data.payload)
                }
                else {

                }
            }
        }))
    }


    // Existing Certifications
    const handleChangesCertifications = (data) => {
        setSelectedCertifications(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setExistingCertificationsId(arr)
    }
    // handle change certification
    const handleChangeCertification = (id) => {
        setExistingCertificationId(id)
    }


    //yearly membership
    const handleMemberShipCard = () => {
        setTotalMembershipPrice(memberShipPackage === "Bronze" && Businesstype == 1 ? 30000 : memberShipPackage === "Bronze" && Businesstype == 2 ? 2500 :
            memberShipPackage === "Gold" && Businesstype == 1 ? 40000 : memberShipPackage === "Gold" && Businesstype == 2 ? 5000 :
                memberShipPackage === "Platinum" && Businesstype == 1 ? 50000 : memberShipPackage === "Platinum" && Businesstype == 2 ? 7500 : "")
    }

    useEffect(() => {
        industry()
        businessOwnership()
        companyListAll()
        supplierDescription()
        ExistingCertification();
        document.title = "Sign-up-steps";
    }, []);


    function formatNumberToDollars(number) {
        // Convert the number to a string
        const numberString = number.toString();
        // Add commas to the number for thousands
        const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // Add a "$" symbol in front of the formatted number
        return '$' + formattedNumber;
    }


    return (
        <div className='registration'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-7' id="leftArea" >
                        <div className='formContainer mb-3 mb-md-0'>
                            <div className='mb-5'>
                                <div className='stepBox'>
                                    {pageNumber >= 3 ?
                                        <div className='text-end'>
                                            <button className='' onClick={() => backPageNumber()}><i className="las la-arrow-left backIcon"></i></button>
                                        </div>
                                        : ""}
                                    <button className='stepBtn'>Step {pageNumber}</button>
                                </div>
                                <div className='stepProgress mt-4 mb-4'>
                                    <MultiStepProgressBar
                                        onPageNumberClick={(e) => { return false }}
                                        page={page} />
                                    {
                                        {
                                            pageone:
                                                <div onButtonClick={nextPage} className='mt-4'>

                                                    <h3 className='headTxtBlue'><b>Company Information</b></h3>
                                                    {/* <h6 className='innerSubtext my-3'>"It always seems impossible until it's done." Nelson Mandela</h6> */}
                                                    <p className='company_info mt-1 mb-4'>Tell us about your company.</p>
                                                    <form onSubmit={(e) => handleSubmit(e, "3")} className='registrationForm row mt-3'>
                                                        {/* <label className='company_info mb-2'>Select A Company List</label>
                                                        <div className='form-group col-md-12 mb-3'>
                                                            <select className="cateSelectbox" required="" value={companyType?.id} onChange={(e) => { companyListAll(e.target.value) }}>

                                                                <option value="">Select a Company</option>
                                                                {companyType?.length > 0 ?
                                                                    <>
                                                                        {companyType?.map((data) => {
                                                                            return <option value={data.id}>{data?.company_name}</option>
                                                                        })}

                                                                    </>
                                                                    : ""}
                                                            </select>
                                                        </div>
                                                        <div className='text-center mb-3 company_option'>OR</div> */}

                                                        <div className={`${companyTypeId ? 'disable_' : ""}`} >
                                                            <label className='company_info mb-2'>Add </label>
                                                            <div className='form-group col-md-12 mb-3'>
                                                                <input name="companyName" type='text' className='customFormControl' value={companyName} placeholder='Company Name' onChange={e => setCompanyName(e.target.value)} disabled={companyTypeId} />
                                                            </div>
                                                            <div className='form-group col-md-12 mb-3'>
                                                                <input name="companyWebsite" type='text' className='customFormControl' value={companyWebsite} placeholder='Company Website' onChange={e => setCompanyWebsite(e.target.value)} disabled={companyTypeId} />
                                                            </div>
                                                            {/* <div className='form-group col-md-12 mb-3'>
                                                                <textarea name="email" type='text' className='customFormControl' placeholder='Diverse Supplier Description' value={companyDescription} onChange={e => setCompanyDescription(e.target.value)} disabled={companyTypeId} />
                                                            </div> */}
                                                            {/* <div className='form-group col-md-12 mb-3'>
                                                                <select className="cateSelectbox" required="" disabled={companyTypeId} value={companyDescription?.id} onChange={(e) => { supplierDescription(e.target.value) }}>
                                                                    <option value="">Select Diverse Supplier Description</option>
                                                                    {companyDescription?.length > 0 ?
                                                                        <>
                                                                            {companyDescription?.map((data) => {
                                                                                return <option value={data?.id}>{data?.name}</option>
                                                                            })}

                                                                        </>
                                                                        : ""}
                                                                </select>
                                                            </div> */}
                                                            <div className="tagsType mt-3 mb-3 col-md-12">
                                                                <label className="headingSmall_ mb-2" htmlFor="">Diverse Status</label>

                                                                {companyDescription &&
                                                                    <Multiselect
                                                                        options={companyDescription} // Options to display in the dropdown
                                                                        selectedValues={selected} // Preselected value to persist in dropdown
                                                                        onSelect={handleChanges} // Function will trigger on select event
                                                                        onRemove={handleChanges} // Function will trigger on remove event
                                                                        displayValue="name" // Property name to display in the dropdown options
                                                                    />
                                                                }
                                                            </div>
                                                            {/* {companyDescriptionOtherId ?
                                                                <div className='form-group col-md-12 mb-3'>
                                                                    <input name="companyDescription" type='text' className='customFormControl' value={companyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCompanyOtherDescription(e.target.value)} />
                                                                </div> : ""

                                                            } */}
                                                            {driversSupplierDescriptionId?.includes(16) ?
                                                                <div className='form-group col-md-12 mb-3'>
                                                                    <input name="companyDescription" type='text' className='customFormControl' value={companyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCompanyOtherDescription(e.target.value)} />
                                                                </div> : ""

                                                            }

                                                            <div className='form-group col-md-12 mb-3'>
                                                                <select className="cateSelectbox" required="" value={ownershipTypeId} onChange={(e) => { businessOwnership(e.target.value) }}>
                                                                    <option value="0">Types of Business</option>
                                                                    {ownershipType?.length > 0 ?
                                                                        <>
                                                                            {ownershipType?.map((data) => {

                                                                                return <option value={data?.id}>{data?.name}</option>
                                                                            })}

                                                                        </>
                                                                        : ""}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-12'>
                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                <p onClick={(e) => handleSubmit(e, "3", "save&finish")} className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                <input
                                                                    className='primaryBtn d-inline-block'
                                                                    type="submit"
                                                                    value="Continue"
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>,
                                            pagetwo:
                                                <div onButtonClick={nextPage} className='mt-4'>
                                                    <h3 className='headTxtBlue'><b>Tell us about your company.</b></h3>
                                                    {/* <h6 className='innerSubtext my-3'>"It always seems impossible until it's done." Nelson Mandela</h6> */}

                                                    {/* <p className='company_info mt-1 mb-4'>Tell us about your company.</p> */}
                                                    <form onSubmit={(e) => handleSubmit(e, "3")} className='registrationForm row mt-3'>
                                                        {/* <label className='company_info mb-2'>Select A Company List</label>
                                                        <div className='form-group col-md-12 mb-3'>
                                                            <select className="cateSelectbox" required="" value={companyType?.id} onChange={(e) => { companyListAll(e.target.value) }}>
                                                                <option value="">Select a Company</option>
                                                                {companyType?.length > 0 ?
                                                                    <>
                                                                        {companyType?.map((data) => {
                                                                            return <option value={data.id}>{data?.company_name}</option>
                                                                        })}

                                                                    </>
                                                                    : ""}
                                                            </select>
                                                        </div>
                                                        <div className='text-center mb-3 company_option'>OR</div> */}
                                                        <div className={`${companyTypeId ? 'disable_' : ""}`} >
                                                            <div className='form-group col-md-12 mb-3'>
                                                                <input name="companyName" type='text' className='customFormControl' disabled={companyTypeId} value={companyName} placeholder='Company Name' onChange={e => setCompanyName(e.target.value)} />
                                                            </div>
                                                            <div className='form-group col-md-12 mb-3'>
                                                                <input name="companyWebsite" type='text' className='customFormControl' disabled={companyTypeId} value={companyWebsite} placeholder='Company Website' onChange={e => setCompanyWebsite(e.target.value)} />
                                                            </div>
                                                            {/* <div className='form-group col-md-12 mb-3'>
                                                                <textarea disabled={companyTypeId} name="=diversesupplierdescription" type='text' className='customFormControl' placeholder='Diverse Supplier Description' value={companyDescription} onChange={e => setCompanyDescription(e.target.value)} />
                                                            </div> */}
                                                            {/* <div className='form-group col-md-12 mb-3'>
                                                                <select className="cateSelectbox" required="" disabled={companyTypeId} value={companyDescription?.id} onChange={(e) => { supplierDescription(e.target.value) }}>
                                                                    <option value="">Diverse Supplier Description</option>
                                                                    {companyDescription?.length > 0 ?
                                                                        <>
                                                                            {companyDescription?.map((data) => {
                                                                                return <option value={data?.id}>{data.name}</option>
                                                                            })}

                                                                        </>
                                                                        : ""}
                                                                </select>
                                                            </div> */}
                                                            <div className="tagsType mt-3 mb-3 col-md-12">
                                                                <label className="headingSmall_ mb-2" htmlFor="">Diverse Status</label>

                                                                {companyDescription &&
                                                                    <Multiselect
                                                                        options={companyDescription} // Options to display in the dropdown
                                                                        selectedValues={selected} // Preselected value to persist in dropdown
                                                                        onSelect={handleChanges} // Function will trigger on select event
                                                                        onRemove={handleChanges} // Function will trigger on remove event
                                                                        displayValue="name" // Property name to display in the dropdown options
                                                                    />
                                                                }
                                                            </div>
                                                            {driversSupplierDescriptionId?.includes(16) ?
                                                                <div className='form-group col-md-12 mb-3'>
                                                                    <input name="companyDescription" type='text' className='customFormControl' value={companyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCompanyOtherDescription(e.target.value)} />
                                                                </div> : ""

                                                            }
                                                            <div className='form-group col-md-12 mb-3'>
                                                                <select className="cateSelectbox" required="" disabled={companyTypeId} value={ownershipTypeId} onChange={(e) => { businessOwnership(e.target.value) }}>
                                                                    <option value="">Business Ownership type</option>
                                                                    {ownershipType?.length > 0 ?
                                                                        <>
                                                                            {ownershipType?.map((data) => {
                                                                                return <option value={data?.id}>{data.name}</option>
                                                                            })}

                                                                        </>
                                                                        : ""}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                <p onClick={(e) => handleSubmit(e, "3", "save&finish")} className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                <input
                                                                    className='primaryBtn d-inline-block'
                                                                    type="submit"
                                                                    value="Continue"
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>,
                                            pagethree:
                                                <div onButtonClick={nextPage} className='mt-4'>
                                                    {/* <h3 className='headTxtBlue'><b>Company </b></h3> */}
                                                    {/* <h6 className='innerSubtext my-3'>"I never dreamed about success. I worked for it." - Estée Lauder, Founder of Estée Lauder Companies</h6> */}
                                                    <p className='company_info mt-1 mb-4'>Upload Company Picture/Logo</p>
                                                    <form onSubmit={(e) => handleSubmit(e, "4")} className='registrationForm row mt-3'>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                {photo?.length > 0 ?
                                                                    "" :
                                                                    <div className="uploadImgebox">

                                                                        <div {...getRootProps({ className: "dropzone" })} >
                                                                            <input {...getInputProps()} />
                                                                            <p className='uploadbtnn'>Upload</p>
                                                                            <p className='uploadText mt-2'>PNG, JPG,JPEG,GIF file upload</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {photo?.length ?
                                                                    <aside >
                                                                        <h4 className='headingSmall_'> Uploaded Files</h4>
                                                                        <ul className='uploadProfile_'>
                                                                            <img className="w3-round" src={photo} ></img>
                                                                            <div className='profileDetail_'>
                                                                                <h5 className='profileboldHead'>{photoInfo.name}</h5>
                                                                                <p className='profileboldgraph'>
                                                                                    {(photoInfo?.size / (1024 * 1024)).toFixed(2)} MB
                                                                                </p>
                                                                            </div>
                                                                            <i className="fa fa-trash text-red" onClick={removeFile(photo)}></i>
                                                                        </ul>
                                                                    </aside>
                                                                    : ""
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                <p onClick={(e) => handleSubmit(e, "4", "save&finish")} className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                <input
                                                                    className='primaryBtn d-inline-block'
                                                                    type="submit"
                                                                    value="Continue"
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>,
                                            pagefour:
                                                <div className='mt-4'>
                                                    <h3 className='headTxtBlue'><b>Tell us about your company.</b></h3>
                                                    {/* <h6 className='innerSubtext my-3'>"If you want to go fast, go alone. If you want to go far, go together." - African Proverb</h6> */}
                                                    {/* <p className='innerSubtext mt-1 mb-4'>Tell us about your company.</p> */}
                                                    <form onSubmit={(e) => handleSubmit(e, "5")} className='registrationForm row mt-3'>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <select className="cateSelectbox" required="" value={industryGroupId} onChange={(e) => { handleChange(e.target.value, "industry") }}
                                                            >
                                                                <option value="" >Select Industry Groups</option>
                                                                {industryGroup?.length > 0 ?
                                                                    <>
                                                                        {industryGroup.map((data) => {
                                                                            return <option value={data.id}>{data.name}</option>
                                                                        })}

                                                                    </>
                                                                    : ""}
                                                            </select>
                                                        </div>
                                                        <div className='form-group col-md-6 mb-3'>
                                                            <select className="cateSelectbox" required="" value={industrySubGroupId}
                                                                onChange={(e) => { handleChange(e.target.value, "subindustry"); }}
                                                            // onChange={(e) => { subIndustry(e.target.value) }}

                                                            >
                                                                <option value="">Select Industry Sub Groups</option>
                                                                {subIndustryGroup?.length > 0 ?
                                                                    <>
                                                                        {subIndustryGroup.map((data) => {
                                                                            return <option value={data.id}>{data.name}</option>
                                                                        })}

                                                                    </>
                                                                    : ""}
                                                            </select>
                                                        </div>
                                                        {/* <div className='form-group col-md-12 mb-3'>
                                                            <input name="existingcertifications" type='text' className='customFormControl' placeholder='Existing Certifications' value={existingCertifications} onChange={e => setExistingCertifications(e.target.value)} ></input>
                                                        </div> */}
                                                        <div className='location_ mt-3 mb-2 '>
                                                            {/* <h5 className='postopportunity_label mb-2'>Existing Certification</h5>
                                                            <select className="cateSelectbox" required="" onChange={(e) => { handleChangeCertification(e.target.value) }} >
                                                                <option value="">Select Option</option>
                                                                {existingCertificationData?.length > 0 ?
                                                                    <>
                                                                        {existingCertificationData.map((option, id) => (
                                                                            <option value={option?.id}>{option?.name}</option>
                                                                        ))}
                                                                    </>
                                                                    : ""
                                                                }
                                                            </select> */}
                                                            {/* <div className='form-group col-md-5 mb-3'> */}
                                                            <label className='postopportunity_label'>Existing Certifications</label>
                                                            {existingCertificationData &&
                                                                <Multiselect
                                                                    options={existingCertificationData} // Options to display in the dropdown
                                                                    selectedValues={selectedCertifications} // Preselected value to persist in dropdown
                                                                    onSelect={handleChangesCertifications} // Function will trigger on select event
                                                                    onRemove={handleChangesCertifications} // Function will trigger on remove event
                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                />
                                                            }
                                                            {/* </div> */}
                                                        </div>
                                                        {existingCertificationsId?.includes(11) ?
                                                            <div className='form-group col-md-12 mb-3'>
                                                                <input name="companyDescription" type='text' className='customFormControl' value={certificationOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCertificationOtherDescription(e.target.value)} />
                                                            </div> : ""

                                                        }


                                                        <div className='form-group col-md-12 mb-3'>
                                                            <input name="keywords" type='text' className='customFormControl' placeholder='Key Words' value={companyKeyWords} onChange={e => setCompanyKeyWords(e.target.value)} />
                                                        </div>
                                                        <div className='form-group col-md-12 mb-3'>
                                                            <textarea name="companyservicesdescription" type='text' className='customFormControl' placeholder='About' value={companyServicesDescription} onChange={e => setCompanyServicesDescription(e.target.value)} ></textarea>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                <p onClick={(e) => handleSubmit(e, "5", "save&finish")} className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                {/* <Link  className='primaryBtn d-inline-block'>Continue</Link> */}
                                                                <input
                                                                    className='primaryBtn d-inline-block'
                                                                    type="submit"
                                                                    value="Continue"
                                                                // onClick={() => nextPage("pagetwo")}
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>,
                                            pagefive:
                                                <div className='mt-4'>
                                                
                                                    <h3 className='headTxtBlue'><b>Membership</b></h3>

                                                    {/* {authSelector.userInfo?.user?.business_type == 1 ? 
                                                    <>
                                                        <div className='tab-buttons-membership mt-2'>
                                                            <div className={`membershipTabs ${activeTabMembership == "enter" ? 'active':''}`} onClick={()=>{setActiveTabMembership('enter')}}>
                                                            <button>
                                                                Enter Code
                                                            </button>
                                                            </div>
                                                            <div className={`membershipTabs ${activeTabMembership == "choose" ? 'active':''}`} onClick={()=>{setActiveTabMembership('choose')}}>
                                                            <button>
                                                                Choose
                                                            </button>
                                                            </div>
                                                        </div>
                                                        {activeTabMembership == 'enter'?
                                                        <>
                                                            <form  onSubmit={(e) => handleSubmit(e, "6")} className='registrationForm row mt-3'>
                                                                <p className='innerSubtext mt-1 mb-4'>Enter Code you received on email.</p>

                                                                <div className='form-group col-md-12 mb-3'>
                                                                    <input name="companyWebsite" type='text' className='customFormControl' placeholder='Enter Code' onChange={(e)=>{
                                                                        if(e.target.value.length >= 20){
                                                                            toast.dismiss()
                                                                            toast.error('Promo code must be less than 20 characters')   
                                                                        }
                                                                        setPromoCode(e.target.value)
                                                                        }} />
                                                                </div>
                                                                <div className='col-md-12 mt-4'>
                                                                    <div className='d-flex justify-content-end align-items-center'>
                                                                        <input
                                                                            className='primaryBtn d-inline-block'
                                                                            type="submit"
                                                                            value="Continue"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </>
                                                        :
                                                        <>
                                                            <p className='innerSubtext mt-1 mb-4'>Select which membership level you would like.</p>
                                                            <form onSubmit={(e) => handleSubmit(e, "6")} className='registrationForm row mt-3'>
                                                                <div className='membershipRegistration_'>
                                                                    <div className="checkmark_ memberCheck_">
                                                                    </div>
                                                                    {newMembershipPlans.length > 0 ?
                                                                        <>
                                                                            {newMembershipPlans.map((plan, index) => {
                                                                                return (
                                                                                    <div className="checkmark_ memberCheck_" key={index}>
                                                                                        <input value={plan.id} onChange={(e) => handleMembership(e, plan.name, plan)} checked={memberShip == plan.id} type="radio" id={`member${plan.id}`} name="radio-group" className="businessType_" />
                                                                                        <label for={`member${plan.id}`} className="labelTxt_">
                                                                                            <div className='growMainbox_'>
                                                                                                <div className='growbusinessLeft_'>
                                                                                                    <div className='growbusinessAbout_'>
                                                                                                        <h3 className='memberblueHead_'>{plan.name ?? ''}
                                                                                                            <div className="infoiconText"><img className='info' src={Images.information} />
                                                                                                                <span className="showinfoText">

                                                                                                                    {index == 0 ?
                                                                                                                        <>
                                                                                                                            <ul>
                                                                                                                                <p className='m-0'>{plan.name} Features:</p>
                                                                                                                                <li> <span className='listHead_'>GetOpp$ Business Page</span></li>
                                                                                                                                <li> <span className='listHead_'>Direct Messaging with Connected Members</span></li>
                                                                                                                                <li> <span className='listHead_'>View Community Feed</span></li>
                                                                                                                                <li> <span className='listHead_'>Real Time Notifications</span></li>
                                                                                                                                <li> <span className='listHead_'>16 Searches Per Month Per User</span></li>
                                                                                                                                <li> <span className='listHead_'>4 Posting Contract Opportunities Per Month Per User </span></li>
                                                                                                                                <li> <span className='listHead_'>4 Contract Opportunities Per Month Per User</span></li>
                                                                                                                                <li> <span className='listHead_'>10 MB of Document Sharing and Storage</span></li>
                                                                                                                                <li> <span className='listHead_'><strong>Access to GetOpp$ Soundbites</strong></span></li>
                                                                                                                            </ul>
                                                                                                                        </> :
                                                                                                                        index == 1 ?
                                                                                                                            <>
                                                                                                                                <ul>
                                                                                                                                    <p className='m-0'>{plan.name} Features:</p>
                                                                                                                                    <li> <span className='listHead_'>GetOpp$ Business Page</span></li>
                                                                                                                                    <li> <span className='listHead_'>Direct Messaging with ALL Members</span></li>
                                                                                                                                    <li> <span className='listHead_'>Full Access to Community Feed</span></li>
                                                                                                                                    <li> <span className='listHead_'>Real Time Notifications</span></li>
                                                                                                                                    <li> <span className='listHead_'>8 Searches Per Month Per User</span></li>
                                                                                                                                    <li> <span className='listHead_'>8 Posting Contract Opportunities Per Month Per User</span></li>
                                                                                                                                    <li> <span className='listHead_'>8 Contract Opportunities Per Month Per User</span></li>
                                                                                                                                    <li> <span className='listHead_'>250 MB of Document Sharing and Storage</span></li>
                                                                                                                                    <li> <span className='listHead_'>Unlimited Member Endorsements</span></li>
                                                                                                                                    <li> <span className='listHead_'><strong>Full Access to GetOPP$ Business Resources</strong></span></li>
                                                                                                                                    <li> <span className='listHead_'><strong>Full Access to GetOpp$ Suggestion Box</strong></span></li>
                                                                                                                                    <li> <span className='listHead_'><strong>Priority Listing in Target Searches</strong></span></li>
                                                                                                                                </ul>
                                                                                                                            </> :
                                                                                                                            index == 2 ?
                                                                                                                                <>
                                                                                                                                    <ul>
                                                                                                                                        <p className='m-0'>{plan.name} Features:</p>
                                                                                                                                        <li> <span className='listHead_'>GetOpp$ Business Page</span></li>
                                                                                                                                        <li> <span className='listHead_'>Direct Messaging with ALL Members</span></li>
                                                                                                                                        <li> <span className='listHead_'>Full Access to Community Feed</span></li>
                                                                                                                                        <li> <span className='listHead_'>Real Time Notifications</span></li>
                                                                                                                                        <li> <span className='listHead_'> Unlimited Searches</span></li>
                                                                                                                                        <li> <span className='listHead_'>Post Unlimited Contract Opportunitiess</span></li>
                                                                                                                                        <li> <span className='listHead_'>Unlimited Contract Opportunities</span></li>
                                                                                                                                        <li> <span className='listHead_'>750 MB of Document Sharing and Storage</span></li>
                                                                                                                                        <li> <span className='listHead_'>Unlimited Member Endorsements</span></li>
                                                                                                                                        <li> <span className='listHead_'><strong>Full Access to GetOPP$ Business Resources</strong></span></li>
                                                                                                                                        <li> <span className='listHead_'><strong>First Priority Listing in Target Searches</strong></span></li>
                                                                                                                                        <li> <span className='listHead_'><strong>Sponsor and Featured Host for GetOPP$ Video Education and Podcast series each month</strong></span></li>
                                                                                                                                        <li> <span className='listHead_'><strong>Featured Spotlight Call to Action Advertising Space</strong></span></li>
                                                                                                                                    </ul>
                                                                                                                                </> :
                                                                                                                                <>
                                                                                                                                    <ul>
                                                                                                                                        <p className='m-0'>{plan.name} Features:</p>
                                                                                                                                        <li> <span className='listHead_'>GetOpp$ Business Page</span></li>
                                                                                                                                        <li> <span className='listHead_'>Direct Messaging with Connected Members</span></li>
                                                                                                                                        <li> <span className='listHead_'>View Community Feed</span></li>
                                                                                                                                        <li> <span className='listHead_'>Real Time Notifications</span></li>
                                                                                                                                        <li> <span className='listHead_'>16 Searches Per Month Per User</span></li>
                                                                                                                                        <li> <span className='listHead_'>4 Posting Contract Opportunities Per Month Per User </span></li>
                                                                                                                                        <li> <span className='listHead_'>4 Contract Opportunities Per Month Per User</span></li>
                                                                                                                                        <li> <span className='listHead_'>10 MB of Document Sharing and Storage</span></li>
                                                                                                                                        <li> <span className='listHead_'><strong>Access to GetOpp$ Soundbites</strong></span></li>
                                                                                                                                    </ul>
                                                                                                                                </>}

                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </h3>
                                                                                                        <p className='memberblueSub_'>{plan.description ?? ''} </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='growbusinessRight_'>
                                                                                                    <p className='yearBadge text-end'>1 Year {formatNumberToDollars(plan.one_year_cost)}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    : null}

                                                                    <div className='col-md-12 mt-4'>
                                                                        <div className='d-flex justify-content-end align-items-center'>
                                                                            <p onClick={(e) => handleSubmit(e, "6", "save&finish")} className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                            <input
                                                                                className='primaryBtn d-inline-block'
                                                                                type="submit"
                                                                                value="Continue"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </>
                                                        }
                                                    </>
                                                    :
                                                    <> */}
                                                        <p className='innerSubtext mt-1 mb-4'>Select which membership level you would like.</p>
                                                        <form onSubmit={(e) => handleSubmit(e, "6")} className='registrationForm row mt-3'>
                                                            <div className='membershipRegistration_'>
                                                                <div className="checkmark_ memberCheck_">
                                                                </div>

                                                                {newMembershipPlans.length > 0 ?
                                                                    <>
                                                                        {newMembershipPlans.map((plan, index) => {
                                                                            return (
                                                                                <div className="checkmark_ memberCheck_" key={index}>
                                                                                    <input value={plan.id} onChange={(e) => handleMembership(e, plan.name, plan)} checked={memberShip == plan.id} type="radio" id={`member${plan.id}`} name="radio-group" className="businessType_" />
                                                                                    <label for={`member${plan.id}`} className="labelTxt_">
                                                                                        <div className='growMainbox_'>
                                                                                            <div className='growbusinessLeft_'>
                                                                                                <div className='growbusinessAbout_'>
                                                                                                    <h3 className='memberblueHead_'>{plan.name ?? ''}
                                                                                                        <div className="infoiconText"><img className='info' src={Images.information} />
                                                                                                            <span className="showinfoText">

                                                                                                                {index == 0 ?
                                                                                                                    <>
                                                                                                                        <ul>
                                                                                                                            <p className='m-0'>{plan.name} Features:</p>
                                                                                                                            <li> <span className='listHead_'>GetOpp$ Business Page</span></li>
                                                                                                                            <li> <span className='listHead_'>Direct Messaging with Connected Members</span></li>
                                                                                                                            <li> <span className='listHead_'>View Community Feed</span></li>
                                                                                                                            <li> <span className='listHead_'>Real Time Notifications</span></li>
                                                                                                                            <li> <span className='listHead_'>16 Searches Per Month Per User</span></li>
                                                                                                                            <li> <span className='listHead_'>4 Posting Contract Opportunities Per Month Per User </span></li>
                                                                                                                            <li> <span className='listHead_'>4 Contract Opportunities Per Month Per User</span></li>
                                                                                                                            <li> <span className='listHead_'>10 MB of Document Sharing and Storage</span></li>
                                                                                                                            <li> <span className='listHead_'><strong>Access to GetOpp$ Soundbites</strong></span></li>
                                                                                                                        </ul>
                                                                                                                    </> :
                                                                                                                    index == 1 ?
                                                                                                                        <>
                                                                                                                            <ul>
                                                                                                                                <p className='m-0'>{plan.name} Features:</p>
                                                                                                                                <li> <span className='listHead_'>GetOpp$ Business Page</span></li>
                                                                                                                                <li> <span className='listHead_'>Direct Messaging with ALL Members</span></li>
                                                                                                                                <li> <span className='listHead_'>Full Access to Community Feed</span></li>
                                                                                                                                <li> <span className='listHead_'>Real Time Notifications</span></li>
                                                                                                                                <li> <span className='listHead_'>8 Searches Per Month Per User</span></li>
                                                                                                                                <li> <span className='listHead_'>8 Posting Contract Opportunities Per Month Per User</span></li>
                                                                                                                                <li> <span className='listHead_'>8 Contract Opportunities Per Month Per User</span></li>
                                                                                                                                <li> <span className='listHead_'>250 MB of Document Sharing and Storage</span></li>
                                                                                                                                <li> <span className='listHead_'>Unlimited Member Endorsements</span></li>
                                                                                                                                <li> <span className='listHead_'><strong>Full Access to GetOPP$ Business Resources</strong></span></li>
                                                                                                                                <li> <span className='listHead_'><strong>Full Access to GetOpp$ Suggestion Box</strong></span></li>
                                                                                                                                <li> <span className='listHead_'><strong>Priority Listing in Target Searches</strong></span></li>
                                                                                                                            </ul>
                                                                                                                        </> :
                                                                                                                        index == 2 ?
                                                                                                                            <>
                                                                                                                                <ul>
                                                                                                                                    <p className='m-0'>{plan.name} Features:</p>
                                                                                                                                    <li> <span className='listHead_'>GetOpp$ Business Page</span></li>
                                                                                                                                    <li> <span className='listHead_'>Direct Messaging with ALL Members</span></li>
                                                                                                                                    <li> <span className='listHead_'>Full Access to Community Feed</span></li>
                                                                                                                                    <li> <span className='listHead_'>Real Time Notifications</span></li>
                                                                                                                                    <li> <span className='listHead_'> Unlimited Searches</span></li>
                                                                                                                                    <li> <span className='listHead_'>Post Unlimited Contract Opportunitiess</span></li>
                                                                                                                                    <li> <span className='listHead_'>Unlimited Contract Opportunities</span></li>
                                                                                                                                    <li> <span className='listHead_'>750 MB of Document Sharing and Storage</span></li>
                                                                                                                                    <li> <span className='listHead_'>Unlimited Member Endorsements</span></li>
                                                                                                                                    <li> <span className='listHead_'><strong>Full Access to GetOPP$ Business Resources</strong></span></li>
                                                                                                                                    <li> <span className='listHead_'><strong>First Priority Listing in Target Searches</strong></span></li>
                                                                                                                                    <li> <span className='listHead_'><strong>Sponsor and Featured Host for GetOPP$ Video Education and Podcast series each month</strong></span></li>
                                                                                                                                    <li> <span className='listHead_'><strong>Featured Spotlight Call to Action Advertising Space</strong></span></li>
                                                                                                                                </ul>
                                                                                                                            </> :
                                                                                                                            <>
                                                                                                                                <ul>
                                                                                                                                    <p className='m-0'>{plan.name} Features:</p>
                                                                                                                                    <li> <span className='listHead_'>GetOpp$ Business Page</span></li>
                                                                                                                                    <li> <span className='listHead_'>Direct Messaging with Connected Members</span></li>
                                                                                                                                    <li> <span className='listHead_'>View Community Feed</span></li>
                                                                                                                                    <li> <span className='listHead_'>Real Time Notifications</span></li>
                                                                                                                                    <li> <span className='listHead_'>16 Searches Per Month Per User</span></li>
                                                                                                                                    <li> <span className='listHead_'>4 Posting Contract Opportunities Per Month Per User </span></li>
                                                                                                                                    <li> <span className='listHead_'>4 Contract Opportunities Per Month Per User</span></li>
                                                                                                                                    <li> <span className='listHead_'>10 MB of Document Sharing and Storage</span></li>
                                                                                                                                    <li> <span className='listHead_'><strong>Access to GetOpp$ Soundbites</strong></span></li>
                                                                                                                                </ul>
                                                                                                                            </>}

                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </h3>
                                                                                                    <p className='memberblueSub_'>{plan.description ?? ''} </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='growbusinessRight_'>
                                                                                                <p className='yearBadge text-end'>1 Year {formatNumberToDollars(plan.one_year_cost)}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </>
                                                                : null}

                                                                <div className='col-md-12 mt-4'>
                                                                    <div className='d-flex justify-content-end align-items-center'>
                                                                        <p onClick={(e) => handleSubmit(e, "6", "save&finish")} className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                        <input
                                                                            className='primaryBtn d-inline-block'
                                                                            type="submit"
                                                                            value="Continue"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    {/* </>
                                                    } */}
                                                    
                                                </div>,
                                            pagesix:
                                                <div className='mt-4'>
                                                    <h3 className='headTxtBlue'><b>Billing</b></h3>
                                                    {/* <h6 className='innerSubtext my-3'>"It is not because things are difficult that we do not dare; it is because we do not dare that they are difficult." Seneca the Elder </h6> */}
                                                    <p className="innerSubtext mt-1 mb-4">Verify your payment and process further</p>
                                                    <form onSubmit={(e) => handleSubmit(e, "7")} className='registrationForm row mt-3'>
                                                        <div className='billingBox'>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className={`penCard mb-4 ${activeTab === 'yearly' ? 'active' : ''}`}>
                                                                        <input checked type="radio" id="test2" name="radio-group" className='customRadio' />
                                                                        <label for="test2">
                                                                            <p className='headingTitleSmall_ mt-2'>Yearly</p>
                                                                            <div className='amountArea'>
                                                                                <p className='amountvalue mb-1'>
                                                                                    {totalMembershipPrice} / 1 year</p>
                                                                                {/* <Link to='' className='amountMsg'>Cancel account anytime.</Link> */}
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className='cardDetails_ row'>
                                                                <div className='form-group col-md-6 mb-3'>
                                                                    <label className='cardLabel'>Credit Card Number</label>
                                                                    <input name="companyWebsite" type='text' className='customFormControl' placeholder='xxxx xxxx xxxx xxxx' />
                                                                </div>
                                                                <div className='form-group col-lg-3 col-md-6 mb-3'>
                                                                    <label className='cardLabel'>Expiry Date</label>
                                                                    <input name="companyWebsite" type='text' className='customFormControl' placeholder='MM/YY' />
                                                                </div>
                                                                <div className='form-group col-lg-3 col-md-6 mb-3'>
                                                                    <label className='cardLabel'>CVV</label>
                                                                    <input name="companyWebsite" type='text' className='customFormControl' placeholder='xxx' />
                                                                </div>
                                                            </div> */}
                                                            <div className='companyDetails mt-3'>
                                                                <ul className='companyDetailsMenus'>
                                                                    <li className='billingItems mt-3'>
                                                                        <span className='billingItemslinks'>Sub Total</span>
                                                                        <span className="billingItemslinks"><b>{totalMembershipPrice}</b></span>
                                                                    </li>
                                                                    <li className='billingItems mt-3'>
                                                                        <span className='billingItemslinks'>Tax</span>
                                                                        <span className="billingItemslinks"><b>$253</b></span>
                                                                    </li>
                                                                    <hr className='membertopboeder'></hr>
                                                                    <li className='billingItems mt-3'>
                                                                        <span className='billingItemslinks'>Total</span>
                                                                        <span className="billingItemslinks"><b>{formatNumberToDollars(totalMembershipPriceInteger + 253)}</b></span>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className='col-md-12 mt-4'>

                                                                <div className='d-flex justify-content-end align-items-center'>

                                                                    {loading ?
                                                                        <>
                                                                            <div class="spinner-border text-success" role="status">
                                                                                <span class="sr-only">Loading...</span>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <input
                                                                                className='primaryBtn d-inline-block'
                                                                                type="submit"
                                                                                value="Process Payment"
                                                                            />
                                                                        </>
                                                                    }


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                        }[page]
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5' id='rightArea'>

                        {pageNumber == 2 ?

                            <div className='rightContentArea'>
                                <p>
                                    "It always seems impossible until it's done".
                                </p>
                                <b>Nelson Mandela</b>
                                {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                            </div>
                            : pageNumber == 3 ?
                                <div className='rightContentArea stepBoxMain'>
                                    <p>
                                        "I never dreamed about success. I worked for it." -
                                    </p>
                                    <b>Estée Lauder, Founder of Estée Lauder Companies</b>
                                    {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                                </div>

                                : pageNumber == 4 ?
                                    <div className='rightContentArea stepBoxFourMain'>
                                        <p>
                                            "If you want to go fast, go alone. If you want to go far, go together." -
                                        </p>
                                        <b>African Proverb</b>
                                        {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                                    </div>
                                    : pageNumber == 5 ?
                                        <div className='rightContentArea stepBoxfiveMain'>
                                            <p>
                                                "Never allow a person to tell you no who doesn’t have the power to say yes."
                                            </p>
                                            <b>Eleanor Roosevelt</b>
                                            {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                                        </div>

                                        : pageNumber == 6 ?
                                            <div className='rightContentArea stepBoxsixMain'>
                                                <p>
                                                    "It is not because things are difficult that we do not dare; it is because we do not dare that they are difficult."
                                                </p>
                                                <b>Seneca the Elder</b>
                                                {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                                            </div>
                                            : pageNumber == 1 ?

                                                <div className='rightContentArea'>
                                                    <p>
                                                        "It always seems impossible until it's done".
                                                    </p>
                                                    <b>Nelson Mandela</b>
                                                    {/* <p>co-founder, chairman and CEO of Apple Inc.</p> */}
                                                </div> : ""

                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registration