import React, { useState } from 'react';
import { toast } from 'react-toastify';

export default async function MembershipLevelComponent(arg) {

    console.log(arg, "fhdgjhfgdh");

    switch (arg) {
        case "isFollowing":
            toast.info(`Create connection with company or Please upgrdae your plan.`);
            break;
        case "Nice":
            toast.info(`Please update your plan.`);
            break;
        default:
            toast.error("Unknown or unsupported membership plan. Please select a valid plan.");
    }
}
