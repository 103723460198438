import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import * as Images from "../../../utilities/images";
import moment from 'moment';
import { getTrashChat, trashChatUpdate } from "../../../redux/slices/messages";
const Trash = () => {
    const dispatch = useDispatch();
    const [chatTrash, setChatTrash] = useState("");

    // apply API for get Trash Chats list

    const getTrashChats = () => {
        dispatch(getTrashChat({
            cb(res) {
                if (res?.data) {
                    setChatTrash(res?.data?.payload?.data)
                }
            }
        }))
    };

    // apply API for recover the chat head and permanent delete chat head

    const chatTrashUpdated = (id, flag) => {
        let params = {
            id: id,
            status: flag
        }
        dispatch(trashChatUpdate({
            ...params, cb(res) {
                if (res?.data) {
                    getTrashChats();
                }
            }
        }))

    };

    useEffect(() => {
        getTrashChats();
    }, []);
    return (
        <>
            <div className='messageInboxcontent'>
                <div className='row'>
                    <div className='col-lg-5 ps-0'>
                        <div className='containerBox messageContain'>
                            <h3 className='mainHeading'>Trash Messages</h3>
                            <div className='MessageInbox_'>
                                {chatTrash?.length > 0 ?
                                    <>
                                        {
                                            chatTrash?.map((val, index) => {
                                                return (
                                                    <div className='postContainerArea' >
                                                        <div className='posts messageListing align-items-center' >
                                                            <img src={val?.company_details?.company_logo ? val?.company_details?.company_logo : Images.chatprofileimage} className='postUser me-2' alt='UserImage' />
                                                            <div className='postsDetails'>
                                                                <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                                                                    <span><strong>{val?.company_details?.company_name}</strong></span>
                                                                </h5>
                                                                <p className='discription_'>{val?.last_message}</p>
                                                            </div>
                                                            <div className='postionlabel'>
                                                                <div className="dropdown">
                                                                    <button className="" type="button" id="moreMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img src={Images.Moreoption} className='moreOption me-2' alt='UserImage' />
                                                                    </button>
                                                                    <ul className="dropdown-menu" aria-labelledby="moreMenu">
                                                                        <li><a className="dropdown-item" onClick={() => chatTrashUpdated(val?.id, 1)}>Recover Chat</a></li>
                                                                        <li><a className="dropdown-item" onClick={() => chatTrashUpdated(val?.id, 2)}>Permanent Delete Chat</a></li>
                                                                    </ul>
                                                                </div>
                                                                <p className='loadMOre'>{moment(val?.updated_at).fromNow()}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                    </>
                                    : "No Data Founds"
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Trash