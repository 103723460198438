import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useAuthSelector } from "../../../redux/selector/auth";
import CustomModal from '../../components/common/shared/CustomModal';
import WaitList from "./waitList";

const Home = () => {
    const authSelector = useAuthSelector();
    const logout_witjout_complete_steps = authSelector?.userStep ? Object.keys(authSelector?.userStep).length === 0 || authSelector?.userStep?.step < 6 || authSelector?.userStep?.user?.step < 6 : "";
    const login_with_steps = authSelector?.userInfo?.steps == 6;
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("");
    const plusStyle = {
        color: "#388E1E",
        fontSize: "14px"
    }

    if(window && localStorage){
        localStorage.removeItem('choosenPlan')
    }

    const [homeSectionVideoStatus, setHomeSectionVideoStatus] = useState(false)
    const [memberVideoStatus, setMemberVideoStatus] = useState(false)
    const [buyerVideoStatus, setBuyerVideoStatus] = useState(false)
    const [supplierVideoStatus, setSupplierVideoStatus] = useState(false)
    const [opporVideoStatus, setOpporVideoStatus] = useState(false)
    const [commVideoStatus, setCommVideoStatus] = useState(false)

    const handleChange = () => {
        navigate('/frequent-question');
    }

    //create Account by select role
    const handleSubmit = (flag) => {



        if (flag === "Buyer") {

            navigate('/membershipchooseperrole');

            // navigate('/businessneed', { state: { id: 1 } });
        }
        else if (flag === 'Suppliers') {

            navigate('/membershipchooseperrole');

            // navigate('/businessneed', { state: { id: 2 } });
        }
        else if (flag === "opportunitesDetails") {

            navigate('/membershipchooseperrole');

            // navigate('/chooserolesfor=signin');
        }
        else if (flag === "community") {
            navigate('/membershipchooseperrole');

            // navigate('/chooserolesfor=signin');
        }
        else if (flag == "membership") {
            
            navigate('/membershipchooseperrole');
        }
        else {
            navigate('/signup', { state: { id: 2 } });
        }

    }

    useEffect(() => {
        if (location.hash) {
            let element = document.getElementById(location.hash.replace("#", ""));
            element?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
        else if (logout_witjout_complete_steps) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('persist:root');
        }
        document.title = "Home-page"
    }, []);

    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    return (
        <>
            <div className="homePage">
                {/* <RunOneSignal/> */}
                {/* Start HomePage Section */}
                <section className="homeBanner landingHead_">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="leftSec">
                                    <h3 className="heading_Blue mb-3">Connecting <b>Business Buyers</b>  with Small & Large <b>Business Suppliers</b></h3>
                                    <p className="innerSubtext boldhead_"><b className=""> Getopp$</b> is the bridge!</p>
                                    <p className="innerSubtext boldhead_ mb-3"><b>No Middle Women, No Middle Men, No Middle People.</b></p>
                                    <p className="innerSubtext boldhead_">The <b className="">premier global B2B platform</b> seamlessly integrating procurement requirements with a business-focused social networking system.</p>
                                    {/* <p className="bannerTxt mb-5">
                                        GetOPP$ is a bridge for large, medium, small and diverse businesses looking to source and supply their goods and services from and to each other. A safe space where Buyers and Suppliers create trusted relationships.
                                        The bridge connecting Buyers with Suppliers<br />
                                        <b> No Middle Women, No Middle Men, No Middle People. </b><br />A safe space where Buyers and Suppliers create trusted relationships.
                                    </p> */}
                                    {/* <b className="bannerTxt boldhead_">
                                        We are the first platform to fuse procurement needs and business networking activities with a business social networking platform.
                                        GetOpp$ is the first B-to-B platform fusing procurement needs with a business social networking platform.
                                        GetOpp$ is the first B2B platform fusing procurement needs with a business social networking platform.
                                    </b> */}
                                    <div className="btn-group d-block d-sm-flex">
                                        {/* <a href="#buyers">
                                            <button
                                                // onClick={(e) => handleSubmit("Buyer")}
                                                className="secondaryBtn mb-2 me-3 d-flex justify-content-between align-items-center">
                                                <span className="me-5">
                                                    Buyers
                                                </span>
                                                <span><i className="las la-angle-right"></i></span>
                                            </button>
                                        </a> */}
                                        <a href="#suppliers">
                                            <button
                                                // onClick={(e) => handleSubmit("Suppliers")}
                                                className="primaryBtnOther d-flex justify-content-between align-items-center">
                                                <span className="me-3">Get Started</span>
                                                <i className="las la-angle-right"></i>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6  d-none d-lg-block">
                                {homeSectionVideoStatus
                                    ?
                                    <>
                                        <video className='w-100 mt-5 videoBox newBox' controls autoPlay>
                                            <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/GetOPPS_Video_for_Homepage_Landing.mp4" type="video/mp4" className='soundbiteImg' />
                                        </video>
                                    </>
                                    :
                                    <>
                                        <img src={Images.bannerImg} className='img-fluid rightImg cursor-pointer' alt="RightImg" onClick={() => { setHomeSectionVideoStatus(true) }} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {/* End HomePage Section */}
                {/* Start Relevance Section */}
                <section className="buyerService homeRelevance" id="membership" >
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-6">
                                <div className="imgContainer mb-3 mb-md-0">
                                    {memberVideoStatus
                                        ?
                                        <>
                                            <video controls className='w-100 mt-2 videoBox newBox' autoPlay>
                                                <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/GetOPPS_Member_Services_Video_for_Homepage.mp4" type="video/mp4" className='soundbiteImg' />
                                            </video>
                                        </>
                                        :
                                        <>
                                            <img src={Images.buyerService} className='secImg' alt='BuyerService' />
                                            <img src={Images.goIcon} className='goIcon' alt='goIcon' onClick={() => { setMemberVideoStatus(true) }} />
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <p class="outerBtn mb-5">MEMBER SERVICES</p>
                                    <h4 className="heading_Blue mb-4 fw-normal">
                                        The <b>GetOpp$ Relevance,</b> the solution you knew you <b> needed!</b>
                                    </h4>
                                    <p className="innerSubtext mt-2 mb-4">
                                        GetOpp$ is equally beneficial to Buyers and Suppliers. <br/> A holistic approach that bridges the gap between contract and procurement relationships.
                                        {/* Small businesses account for 99.9% of U.S. businesses, employing 61.7 million workers. Regrettably, 20% fail in the first year, and almost 50% fail within five years, often due to a lack of visibility. */}
                                    </p>
                                    <p className="innerSubtext mt-2 mb-2">
                                        A safe space where <b> Buyers </b>and  <b>Suppliers</b> can create trusted relationships.
                                        {/* <b>The GetOpp$ goal is to put all of these small businesses on the global procurement radar screen </b>so that they now have, at their fingertips, access to international B2B markets to sell their goods and services! */}
                                    </p>

                                    <button onClick={(e) => handleSubmit("membership")} className="primaryBtnOther mt-5 d-flex justify-content-between align-items-center">
                                        <span className="me-3">Membership</span>
                                        <i className="las la-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Relevance Section */}
                {/* Start BuyerService Section */}
                <section className="buyerService" id="buyers">
                    <div className="container">
                        <div className="row align-items-start align-items-md-center">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <p className="outerBtn">BUYER SERVICES</p>
                                <h4 className="heading_Blue my-3">
                                    {/* A revolutionary tool to <b>Identify Suppliers</b> for all of your <b>Contracting Needs.</b> */}
                                    A revolutionary tool to <b>identify Suppliers</b> for all of your <b>contracting</b> needs.

                                </h4>
                                {/* <p className="innerSubtext mt-2 mb-2">
                                    GetOpp$ is a professional network and social communication platform where Buyers can search for Suppliers based on a diverse range of filters with direct access for communication and due diligence research. GetOPP$ creates a safe space for Buyers and Suppliers to contract.
                                </p> */}
                                <ul>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Save time and money</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Post contracting opportunities</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Search, identify and connect with Suppliers</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Showcase your business</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Eliminate inefficiencies and frustrations with static databases</li>
                                    </artical>
                                    {/* <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext"><b>Communicate with active Suppliers</b> that are continuously updating information</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext"><b>Lead educational programs </b>for GetOPP$ Business Resources and share company expertise to educate and connect with others on the platform</li>
                                    </artical> */}
                                </ul>
                                <p className="innerSubtext mt-5 mb-3"><b>GetOpp$ </b>is merging business operations and social<br/> networking into the procurement process.</p>

                                {/* <button  onClick={(e) => handleSubmit("membership")}
                                 className="primaryBtnOther mt-5 d-flex justify-content-between align-items-center">
                                    <span>Get Started</span>
                                    <i className="las la-angle-right"></i>
                                </button> */}
                            </div>
                            <div className="col-md-6">
                                <div className="imgContainer buyerimgContainer">
                                    {buyerVideoStatus
                                        ?
                                        <>
                                            <video controls className='w-100 mt-2 videoBox newBox' autoPlay>
                                                <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/GetOPPS_Buyer_Services_Video_for_Homepage.mp4" type="video/mp4" className='soundbiteImg' />
                                            </video>
                                        </>
                                        :
                                        <>
                                            <img src={Images.buyerImg_} className='secImg' alt='BuyerService' />
                                            <img src={Images.goIcon} className='goIconLeft' alt='goIcon' onClick={() => { setBuyerVideoStatus(true) }} />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End BuyerService Section */}
                {/* Start SupplierService Section */}
                <section className="supplierService" id="suppliers">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <div className="imgContainer">
                                    {supplierVideoStatus
                                        ?
                                        <>
                                            <video controls className='w-100 mt-2 videoBox newBox' autoPlay>
                                                <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/GetOPPS_Supplier_Services_Video_for_Homepage.mp4" type="video/mp4" className='soundbiteImg' />
                                            </video>
                                        </>
                                        :
                                        <>
                                            <img src={Images.supplierService} className='secImg' alt='BuyerService' />
                                            <img src={Images.goIcon} className='goIcon' alt='goIcon' onClick={() => { setSupplierVideoStatus(true) }} />
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <p className="outerBtn">SUPPLIER SERVICES</p>
                                <h4 className="heading_Blue mt-3 mb-3 fw-normal">
                                    {/* GetOpp$ is the revolutionary way to be informed of and <b>seek new contracting opportunities.</b> */}
                                    <b>Direct Access</b> to <b>Business Buyers</b>
                                </h4>
                                {/* <p className="innerSubtext mt-2 mb-2">
                                    Suppliers receive direct access to large, medium, small and diverse Buyers. The platform tools provide a guide to assist in the quest to achieve prospective long term success, sustainable business models and revenue generation.
                                </p> */}
                                <ul>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Search and reply to targeted contracting opportunities</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Save time and money on marketing</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Network and reach out to Buyers with direct messages</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Highlight certifications, products and services</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Participate in Masterclass Business Resources</li>
                                    </artical>
                                    {/* <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext"><b>Highlight certifications, and services</b> through your business profile and the GetOPP$ community live feed</li>
                                    </artical> */}
                                </ul>
                                <p className="innerSubtext mt-3 mb-3"><b>GetOpp$</b> provides a guide to help your business achieve <br/> success and <b>revenue growth</b>.
                                </p>
                                {/* <button onClick={(e) => handleSubmit("membership")} className="primaryBtnOther mt-5 mt-md-3 d-flex justify-content-between align-items-center">
                                    <span>Get Started</span>
                                    <i className="las la-angle-right"></i>
                                </button> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* End SupplierService Section */}
                {/* Start Opportunites Section */}
                <section className="opportunites" id="opportunities">
                    <div className="container">
                        <div className="row align-items-start align-items-md-center">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <p className="outerBtn mb-3">OPPORTUNITIES</p>
                                <h4 className="heading_Blue mb-3 fw-normal">
                                   Trusted relationships between <b>Buyers</b> and <b>Suppliers</b> lead to opportunities

                                    {/* <b>GetOpp$</b> aims to eliminate the void of no response and lack of communication between  */}
                                </h4>
                                <ul>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Contracts</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Relationships</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Endorsements</li>
                                    </artical>
                                    <artical className="listMain">
                                        <img src={Images.Mark} alt="mark" className="tickMark" />
                                        <li className="innerSubtext">Growth</li>
                                    </artical>
                                </ul>
                                <p className="innerSubtext mt-3 mb-3">
                                    <b>GetOpp$</b> aims to eliminate the void of communication and connection between <b>Buyers</b> and <b>Suppliers.</b>
                                </p>
                                {/* <p className="innerSubtext mt-3 mb-3">
                                    <b>GetOpp$</b> aims to <b>eliminate the void</b> of communication and connection <b>between Buyers and Suppliers.</b>
                                </p> */}

                                <button onClick={(e) => handleSubmit("membership")} className="primaryBtnOther mt-3 d-flex justify-content-between align-items-center">
                                    <span className="me-3">Get Started</span>
                                    <i className="las la-angle-right"></i>
                                </button>
                            </div>

                            {/* <ul className="opportunitesDetails" onClick={(e) => handleSubmit("opportunitesDetails")} >
                                    <li className={`opptItems ${activeTab == 'Accounting' ? 'active' : ''}`} onClick={() => setActiveTab("Accounting")}>
                                        <span className="innerSubtext"><b>Accounting</b></span>
                                        <span className="innerSubtext"><b className="me-2">45</b> Companies</span>
                                    </li>
                                    <li className={`opptItems ${activeTab == 'Automotive' ? 'active' : ''}`} onClick={() => setActiveTab("Automotive")}>
                                        <span className="innerSubtext"><b>Automotive</b></span>
                                        <span className="innerSubtext"><b className="me-2">21</b>Companies</span>
                                    </li>
                                    <li className={`opptItems ${activeTab == 'Insurance' ? 'active' : ''}`} onClick={() => setActiveTab("Insurance")}>
                                        <span className="innerSubtext"><b>Insurance</b></span>
                                        <span className="innerSubtext"><b className="me-2">6</b>Companies</span>
                                    </li>
                                    <li className={`opptItems ${activeTab == 'Marketing & Ads' ? 'active' : ''}`} onClick={() => setActiveTab("Marketing & Ads")}>
                                        <span className="innerSubtext"><b>Marketing & Ads</b></span>
                                        <span className="innerSubtext"><b className="me-2">13</b>Companies</span>
                                    </li>
                                    <li className={`opptItems ${activeTab == 'PublicRelations' ? 'active' : ''}`} onClick={() => setActiveTab("PublicRelations")}>
                                        <span className="innerSubtext"><b>Public Relations</b></span>
                                        <span className="innerSubtext"><b className="me-2">9</b>Companies</span>
                                    </li>
                                    <li className={`opptItems ${activeTab == 'Software' ? 'active' : ''}`} onClick={() => setActiveTab("Software")}>
                                        <span className="innerSubtext"><b>Software</b></span>
                                        <span className="innerSubtext"><b className="me-2">6</b>Companies</span>
                                    </li>
                                </ul> */}
                            <div className="col-md-6 mb-3 mb-md-0">
                                <div className="imgContainer">
                                    {opporVideoStatus
                                        ?
                                        <>
                                            <video controls className='w-100 mt-2 videoBox newBox' autoPlay>
                                                <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/GetOPPS_Opportunities_Video_for_Homepage.mp4" type="video/mp4" className='soundbiteImg' />
                                            </video>
                                        </>
                                        :
                                        <>
                                            <img src={Images.opportunity} className='secImg' alt='BuyerService' />
                                            <img src={Images.goIcon} className='goIconLeft' alt='goIcon' onClick={() => { setOpporVideoStatus(true) }} />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Opportunites Section */}
                {/* Start BussinessRelation Css */}
                <section className="bussinessRelation py-5" id="community">
                    <div className="container">
                        <div className="row pb-4">
                            <div className="col-md-5">
                                <div className="row   pe-0 pe-md-4 ps-0">
                                    <div className="col-6">
                                        <div className="bussinessRelLeftImg">
                                            <div className="img-container mb-4 me-1 me-md-0">
                                                <div className="commonResponse">
                                                    <img src={Images.msgWhite}/>
                                                </div>
                                                <img src={Images.people} className='peopleImg' alt="People" />
                                                {/* <img src={Images.messageFill} className='outerIcon outerIcon1' alt='Message' /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="img-container mb-4">
                                            <div className="commonResponse commonResponse2">
                                                <img src={Images.likeWhite}/>
                                            </div>
                                            <img src={Images.people1} className='peopleImg' alt="People" />
                                            {/* <img src={Images.checkedThumb} className='outerIcon outerIcon2' alt="CheckedThumb" /> */}
                                        </div>
                                    </div>

                                    {/* <div className="row pt-4 pb-4 pe-0 pe-md-1 ps-0"> */}
                                    <div className="col-md-12 ps-2 p-0">
                                        <div className="position-relative">
                                        <div className="commonResponse commonResponse2">
                                            <img src={Images.fileWhite}/>
                                        </div>
                                        <div className="img-container lastImg mb-3 mb-md-0">
                                            {commVideoStatus
                                                ?
                                                <>
                                                    <video controls className='w-100 mt-1 ml-2 videoBox newBox' autoPlay>
                                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/GetOPPS_Community_Video_for_Homepage.mp4" type="video/mp4" className='soundbiteImg' />
                                                    </video>
                                                </>
                                                :
                                                <>
                                                    <img src={Images.people2} className='peopleImg' alt="People" />
                                                    {/* <img src={Images.goIcon} className='goIcon' alt='goIcon' onClick={() => { setCommVideoStatus(true) }} /> */}
                                                </>
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>

                            <div className="col-md-6" >
                                {/* <p className="otherLink pb-3"><b>Community</b></p> */}
                                <p className="outerBtn">COMMUNITY</p>

                                <h4 className="heading_Blue my-4">
                                    <b>Strengthening</b> Buyers' and Suppliers <b>relationships</b>
                                    {/* <b>GetOpp$</b> is reinforcing meaningful <b>professional</b> <b>relationships</b> between <b>Buyers</b> and <b>Suppliers.</b> Communities */}
                                    {/* Value and importance of <b>connection</b> and <b>community</b> in business relationships. */}
                                </h4>
                                {/* <p className="innerSubtext mt-4 mb-4">
                                    GetOpp$ is <b>Leveraging Technology</b> and Social Media <b>for Network Creation</b> by providing:
                                    The GetOpp$ platform provides community, professional sharing, education and opportunity to fortify that critical link in contract relationships.  All users are encouraged to post relevant company information to raise their companies’ visibility and drive valuable connections with other users on the platform.

                                    The GetOpp$ platform provides community, professional sharing, education and opportunities to bridge the missing link in contract relationships. All users are encouraged to post relevant company information to raise their companies’ visibility with other users on the platform.
                                </p> */}
                                <p className="innerSubtext">
                                    <ul className="mb-5">
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext"><b>Exposure</b> to a broad set of Buyers and Suppliers </li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext"><b>Real time visibility</b> in a global marketplace </li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext"><b>Connections</b> with users via direct messaging communications</li>
                                        </artical>
                                        <artical className="listMain">
                                            <img src={Images.Mark} alt="mark" className="tickMark" />
                                            <li className="innerSubtext"><b>Ability</b> to highlight company information and accolades on the Community Feed</li>
                                        </artical>
                                    </ul>

                                    <p className="innerSubtext"><b>GetOpp$</b> is a global B2B professional networking platform that is bridging the gap in <b> contract relationships.</b></p>

                                    {/* <b className="ColoredText">GetOpp$</b> is the Premier Global B2B Platform for professional networking that is <b>bridging the gap in contract relationships.</b> */}
                                </p>
                                <button className="primaryBtnOther mt-5 d-flex justify-content-between align-items-center">
                                    <span onClick={(e) => handleSubmit("membership")} className="me-3">Get Started</span>

                                    {/* <span onClick={() => {
                                        setModalDetail({ show: true, flag: "waitList", });
                                        setKey(Math.random());
                                    }}>Get Started</span> */}

                                    <i className="las la-angle-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section >
                {/* End BussinessRelation Css */}
                {/* Start WhatPeopleSay Section */}
                <section className="whatPeopleSay">
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-12 text-center">
                                <p className="outerBtn">TESTIMONIALS</p>
                                <h4 className="heading_Blue">
                                    What <b>businesses</b>  are saying <b>about us</b>
                                </h4>
                            </div>
                        </div>
                        <OwlCarousel className='owl-theme' loop margin={10} nav
                            dots={true}
                            responsiveClass={true}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 1
                                },
                                991: {
                                    items: 1
                                },
                                1000: {
                                    items: 1
                                }
                            }}
                        >
                            {/* <div className='item'>
                                <div className="row">
                                    <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="slideContent">
                                            <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4>
                                            <h5 className="innerSubtext mt-5 mb-5">
                                                Tai Savet <i className="fas fa-plus" style={plusStyle}></i> Luxury Real Estate Industry
                                            </h5>
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    Marilyn is a trailblazer in diversity and inclusion. With unwavering determination, she has fearlessly broken barriers and transformed mindsets on a global scale. Her legacy will forever echo through generations, a testament to the remarkable impact one person can have on the world.
                                                </i>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    Marilyn's relentless pursuit of a more inclusive society has empowered individuals from all walks of life to embrace their uniqueness without fear or judgment. Her compassionate heart and open-minded spirit have paved the way for a future where diversity is celebrated as the very essence of our humanity.
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                Karl Tomback <i className="fas fa-plus" style={plusStyle}></i><b>Graphic Design Professional Services Industry</b> 
                                            </h5>
                                            <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    Through her visionary leadership and tireless efforts, Marilyn has shown us the power of fostering an environment where differences are not only accepted but cherished. As we bear witness to her remarkable journey, we are reminded that greatness lies not just in achievements but in the hearts we touch along the way.
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                Keri Kilty <i className="fas fa-plus" style={plusStyle}></i><b> Project Management Professional Services Industry</b>
                                            </h5>
                                            <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    “We have been looking to work with diverse suppliers in our industry GetOpps helped us connect and network with local people and help grow our company to what it is today!”
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                KEITH THOMAS <i className="fas fa-plus" style={plusStyle}></i> <b>PACKAGING INDUSTRY</b>
                                            </h5>
                                            <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    Marilyn's relentless pursuit of a more inclusive society has empowered individuals from all walks of life to embrace their uniqueness without fear or judgment. Her compassionate heart and open-minded spirit have paved the way for a future where diversity is celebrated as the very essence of our humanity.
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                Karl Tomback <i className="fas fa-plus" style={plusStyle}></i> <b>Graphic Design Professional Services Industry</b>
                                            </h5>
                                            <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    Through her visionary leadership and tireless efforts, Marilyn has shown us the power of fostering an environment where differences are not only accepted but cherished. As we bear witness to her remarkable journey, we are reminded that greatness lies not just in achievements but in the hearts we touch along the way.
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                Keri Kilty <i className="fas fa-plus" style={plusStyle}></i><b> Project Management Professional Services Industry</b>
                                            </h5>
                                            <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="sliderContainer row">
                                    <div className="sliderItems col-md-12 col-lg-12">
                                        <div className="slideContent">
                                            {/* <h4 className="heading_Blue">
                                                What <b>people</b>  are saying<br /> <b>about us</b>
                                            </h4> */}
                                            <p className="innerSubtext aboutusSubtext mt-2 mb-2 ">
                                                <i>
                                                    “We have been looking to work with diverse suppliers in our industry period. GetOpps helped us connect and network with local people and help grow our company to what it is today!”
                                                </i>
                                            </p>
                                            <h5 className="innerSubtext my-3 text-center">
                                                KEITH THOMAS <i className="fas fa-plus" style={plusStyle}></i> <b>PACKAGING INDUSTRY</b>
                                            </h5>
                                            <div className="text-center">
                                                <img src={Images.juiceBar} className="img-fluid juiceBarImg"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="sliderItems col-md-12 col-lg-6">
                                        <div className="imgContainer">
                                            <img src={Images.clipImg} className="secImg" alt="User" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </section>
                {/* End WhatPeopleSay Section */}
                {/* Have Questions start */}
                <div className="questionBox">
                    <div className="container">
                        <div className="queryBox">
                            <div className="row align-items-center">
                                <div className="col-lg-4">
                                    <h4 className="heading_Blue">
                                        Have Questions?<br></br><b>We Have Answers!</b>
                                    </h4>
                                    <button onClick={() => handleChange()} className="primaryBtnOther mt-5 d-flex justify-content-between align-items-center">
                                        <span>Learn More</span>
                                        <i className="las la-angle-right"></i>
                                    </button>
                                </div>
                                <div className="col-lg-8">
                                    <figure>
                                        <img src={Images.haveQuestion} className="img-fluid haveQuestion" alt="haveQuestion" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Have Questions End */}
            </div >

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "waitList" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "waitList" ? "waitListModal" : "ComposeMessage"}
                child={
                    modalDetail.flag === "waitList" ? (
                        <WaitList
                            close={() => handleOnCloseModal()}
                        />
                    ) :
                        ""
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "waitList" ?
                                <>
                                    <h2 className="addLabels_heading">
                                        Join the Waitlist
                                    </h2>
                                    <p>Join the community for buyers & suppliers</p>
                                </>
                                : ""
                            }
                        </div>
                    </div>
                }

                onCloseModal={() => handleOnCloseModal()}
            />

        </>
    )

}

export default Home;