import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { setAddOpportunity, setMyPostOpportunity, setUpdateOpportunity, setGetOpportunity, setDeleteOpportunity, setActiveInactiveOppertunity,setApplyOpportunity,setGetOpportunityApplication,setGetOpportunityViewDetail, onErrorStopLoad, setMyReviewResponsesOpportunity, setMyReviewRespondentsOpportunity, setAddPostOpportunityUploadPhotoOrPdf, setAllCurriencies, SetReviewResponseDelete } from '../../slices/opportunity';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';


function* addOpportunities(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.PostOpportunityApiPath.POST_OPPORTUNITY, action.payload = action.payload);
    if (resp.status) {
      yield put(setAddOpportunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
       toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAddOpportunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* PostOpportunityUploadPhotoOrPdf(action) {
  try {
    const resp = yield call(ApiClient.postFormData, action.url = ApiPath.DashboardApiPath.ATTACHMENT_UPLOAD, action.payload = action.payload);
    if (resp.status) {
      yield put(setAddPostOpportunityUploadPhotoOrPdf(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAddPostOpportunityUploadPhotoOrPdf({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* myPostOpportunities(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PostOpportunityApiPath.MY_POST_OPPORTUNITY}?is_active=${action.payload.is_active}&page=${action.payload.page}&limit=${action.payload.limit}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setMyPostOpportunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setMyPostOpportunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* getAllCurriencies(action) {
  try {

    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PostOpportunityApiPath.GET_CURRIENCIES}`, action.params = {});
    if (resp.status) {
      yield put(setAllCurriencies(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAllCurriencies({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* myResponsesOpportunityList(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PostOpportunityApiPath.OPPORTUNITY_APPLICATION_LIST}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setMyReviewResponsesOpportunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setMyReviewResponsesOpportunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* myReviewRespondentsOpportunityList(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PostOpportunityApiPath.OPPORTUNITY_REVIEW_RESPONDENTS}/${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setMyReviewRespondentsOpportunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setMyReviewRespondentsOpportunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}



function* updateOpportunities(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.PostOpportunityApiPath.UPDATE_OPPORTUNITY}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setUpdateOpportunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
       toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUpdateOpportunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getOpportunities(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PostOpportunityApiPath.GET_OPPORTUNITY}${action.payload.id}`);
    if (resp.status) {
      yield put(setGetOpportunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetOpportunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* activeInactiveOppertunities(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.PostOpportunityApiPath.ACTIVE_INACTIVE_OPPORTUNITY}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setActiveInactiveOppertunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
       toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setActiveInactiveOppertunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* deleteOpportunities(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.PostOpportunityApiPath.DELETE_OPPORTUNITY}${action.payload.id}`);
    if (resp.status) {
      yield put(setDeleteOpportunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setDeleteOpportunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* ReviewResponseDelete(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.PostOpportunityApiPath.DELETE_REVIEW_RESPONSE_OPPORTUNITY}${action.payload.id}`);
    if (resp.status) {
      yield put(SetReviewResponseDelete(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(SetReviewResponseDelete({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* ApplyOpportunities(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.PostOpportunityApiPath.APPLY_OPPORTUNITY, action.payload = action.payload);
    if (resp.status) {
      yield put(setApplyOpportunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setApplyOpportunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* opportunityApplication(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PostOpportunityApiPath.REVIEW_OPPORTUNITY_APPLICATION}${action.payload.id}?page=${action.payload.page}&limit=${action.payload.limit}`,action.payload = action.payload);
    if (resp.status) {
      yield put(setGetOpportunityApplication(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetOpportunityApplication({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* postOpportunityViewDetails(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PostOpportunityApiPath.VIEW_DETAILS_POST_OPPORTUNITY}${action.payload.id}`);
    if (resp.status) {
      yield put(setGetOpportunityViewDetail(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetOpportunityViewDetail({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}
function* userOpportunity() {
  yield all([
    takeLatest('opportunity/addOpportunity', addOpportunities),
    takeLatest('opportunity/myPostOpportunity', myPostOpportunities),
    takeLatest('opportunity/myReviewResponsesOpportunity', myResponsesOpportunityList),
    takeLatest('opportunity/myReviewRespondentsOpportunity', myReviewRespondentsOpportunityList),
    takeLatest('opportunity/updateOpportunity', updateOpportunities),
    takeLatest('opportunity/getOpportunity', getOpportunities),
    takeLatest('opportunity/activeInactiveOppertunity', activeInactiveOppertunities),
    takeLatest('opportunity/deleteOpportunity', deleteOpportunities),
    takeLatest('opportunity/applyOpportunity', ApplyOpportunities),
    takeLatest('opportunity/getOpportunityApplication', opportunityApplication),
    takeLatest('opportunity/getOpportunityViewDetail', postOpportunityViewDetails),
    takeLatest('opportunity/addPostOpportunityUploadPhotoOrPdf', PostOpportunityUploadPhotoOrPdf),
    takeLatest('opportunity/getAllCurriencies', getAllCurriencies),
    takeLatest('opportunity/reviewResponseDelete', ReviewResponseDelete),
    
  ])
}

export default userOpportunity;