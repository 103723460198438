import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import * as Images from "../../../utilities/images";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Multiselect from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
import { businessOwnershipType, getAllIndustryGroups, getAllIndustrySubGroups, } from '../../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import { addPostOpportunityUploadPhotoOrPdf, getAllCurriencies, getOpportunity, updateOpportunity } from "../../../redux/slices/opportunity";
import { diverseSupplierDescription, existingCertification } from '../../../redux/slices/advanceSearch';
import { useOpportunitySelector } from '../../../redux/selector/opportunity';

const EditPostOpportunity = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const opportunitySelector = useOpportunitySelector()
    const [postingtitle, setPostingtitle] = useState("");
    const [responseDeadline, setResponseDeadline] = useState(new Date());
    const [awardDate, setAwardDate] = useState("");
    const [description, setDescription] = useState("");

    const [currency, setCurrency] = useState('USD');
    const [allCurriencies, setAllCurriencies] = useState([]);

    const [contractAmount, setContractAmount] = useState("");
    const [contractLength, setContractLength] = useState("");
    const [industryGroup, setIndustryGroup] = useState([])
    const [industryGroupId, setIndustryGroupId] = useState("");
    const [industrySubGroupId, setIndustrySubGroupId] = useState("")
    const [subIndustryGroup, setSubIndustryGroup] = useState([])
    const [specificServiceLocationRequired, setSpecificServiceLocationRequired] = useState(true);
    const [serviceLocation, setServiceLocation] = useState("");
    const [ownershipTypeId, setOwnershipTypeId] = useState("");
    const [ownershipType, setOwnershipType] = useState("");
    const [companyDescription, setCompanyDescription] = useState("");
    const [selectedDiverseStatus, setSelectedDiverseStatus] = useState([]);
    const [driversSupplierDescriptionId, setDriversSupplierDescriptionId] = useState();
    const [existingCertificationData, setExistingCertificationData] = useState("");
    const [selectedCertifications, setSelectedCertifications] = useState([]);
    const [existingCertificationsId, setExistingCertificationsId] = useState();
    const [serviceLicensesRequires, setServiceLicensesRequires] = useState(true);

    const [photo, setPhotoUpload] = useState("");
    const [photoInfo, setPhotoInfo] = useState({
        "imagename": "",
        "imagemimetype": "",
        "imagesize": "",
    });

    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    const selectedValue = selectedDiverseStatus?.map((data) => { return data?.diversity_status_details })
    const certificationSelected = selectedCertifications?.map((data) => { return data?.existing_certificates_details })

    //remove file
    const removeFile = file => () => {
        const newFiles = [photo]
        newFiles.splice(newFiles.indexOf(file), 1)
        setPhotoUpload(newFiles)
        setPhotoInfo("")
    }

    // onchange date set date in the state
    const onChangeDateAward = (date) => {
        setAwardDate(date)
    }


    // onchange date set date in the state
    const onChangeDateResponse = (date) => {
        setResponseDeadline(date)
    }

    const industry = (e) => {
        dispatch(getAllIndustryGroups({
            cb(res) {
                if (res.status) {
                    setIndustryGroup(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }


    const getAllCurrienciesAction = () => {
        dispatch(getAllCurriencies({
            cb(res) {
                if (res.status == 200) {
                    setAllCurriencies(res.data.payload)
                }
            }
        }))
    }

    // onchange input 
    const handleChange = (id, flag) => {
        if (flag == "industry") {
            setIndustryGroupId(id)
            subIndustry(id)
        }
        else {
            setIndustrySubGroupId(id)
        }
    }

    const subIndustry = (id) => {
        if (id) {
            setIndustryGroupId(id)
            let params = {
                id: id,
            }
            dispatch(getAllIndustrySubGroups({
                ...params, cb(res) {
                    if (res.status) {
                        setSubIndustryGroup(res?.data?.payload)
                        // setIndustrySubGroupId("")
                    }
                    else {

                    }
                }
            }))

        }

    }

    // handle change address
    const handleSelect = async value => {
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        setServiceLocation(value)
        // setCordinates(ll)
    }

    const businessOwnership = (id) => {
        setOwnershipTypeId(id)
        dispatch(businessOwnershipType({
            cb(res) {
                if (res.status) {
                    setOwnershipType(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    // Existing certification
    const ExistingCertification = () => {
        dispatch(existingCertification({
            cb(res) {
                if (res.status) {
                    setExistingCertificationData(res.data.payload)
                }
                else {

                }
            }
        }))
    }


    //for add drivers Supplier Description........
    const SupplierDescription = (id) => {
        setDriversSupplierDescriptionId(id)
        // if (id == 16) {
        //     setCompanyDescriptionOtherId(id)
        //     setCompanyDescriptionId("")
        //     setCompanyOtherDescription("")
        // }
        // else {
        //     setCompanyDescriptionId(id)
        //     setCompanyDescriptionOtherId("")
        //     setCompanyOtherDescription("")
        // }

        dispatch(diverseSupplierDescription({
            cb(res) {
                if (res.status) {
                    setCompanyDescription(res?.data?.payload)
                }
            }
        }))
    }

    // Existing Certifications
    const handleChangesCertifications = (data) => {
        // setSelectedCertifications(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setExistingCertificationsId(arr)
    }


    // driver supplier description
    const handleChanges = (data) => {
        // setSelectedDiverseStatus(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDriversSupplierDescriptionId(arr)
    }


    //onDrop
    const onDrop = useCallback(acceptedFiles => {
        const imageFile = acceptedFiles[0];
        // if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
        //     toast.error("Please select a valid image.");
        //     return false;
        // }
        let params = {
            photo: imageFile,
        }
        dispatch(addPostOpportunityUploadPhotoOrPdf({
            ...params, cb(res) {
                if (res.status) {
                    setPhotoUpload(res?.data?.payload?.url);
                    setPhotoInfo(({
                        imagename: res?.data?.payload?.name,
                        imagemimetype: res?.data?.payload?.format,
                        imagesize: res.data.payload.size

                    }));
                }
                else {
                }
            }
        }))

    }, [])

    const { getRootProps, getInputProps } =

        useDropzone({
            onDrop,
            accept: {
                'image/jpeg': [],
                'image/jpg': [],
                'image/png': [],
                'application/pdf': [],
                'application/msword': [], // .doc files
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] //docx file
            }
        });



    //for update Opportunity
    const getOpportunityDetail = (e) => {
        let params = {
            id: props.id
        }
        dispatch(getOpportunity({
            ...params, cb(res) {

                if (res.status) {
                    setPostingtitle(res?.data?.payload?.posting_title)
                    setResponseDeadline(new Date(res?.data?.payload?.response_deadline_date))
                    if (res?.data?.payload?.award_date == null) {
                        setAwardDate(null)
                    }
                    else {
                        setAwardDate(new Date(res?.data?.payload?.award_date))
                    }
                    setDescription(res?.data?.payload?.description)
                    setContractAmount(res?.data?.payload?.opportunity_amount)
                    setContractLength(res?.data?.payload?.contract_length)
                    setIndustryGroupId(res?.data?.payload?.industries_group_details?.id)
                    setSubIndustryGroup(res?.data?.payload?.industries_sub_group_details)
                    setIndustrySubGroupId(res?.data?.payload?.industries_sub_group_details?.id)
                    subIndustry(res?.data?.payload?.industries_group_details?.id)
                    setSpecificServiceLocationRequired(res?.data?.payload?.specific_service_locations_required)
                    setServiceLocation(res?.data?.payload?.hq_location)
                    setOwnershipTypeId(res?.data?.payload?.business_ownership_type)
                    setServiceLicensesRequires(res?.data?.payload?.service_licenses_required)
                    setSelectedCertifications(res?.data?.payload?.opportunity_existing_certificate_details)
                    const selectedValues = res?.data?.payload?.opportunity_existing_certificate_details?.map((data) => { return data?.existing_certificates_id })
                    setExistingCertificationsId(selectedValues)
                    setSelectedDiverseStatus(res?.data?.payload?.post_opportunity_diversity_details)
                    const selectedValuesDiverseStatus = res?.data?.payload?.post_opportunity_diversity_details?.map((data) => { return data?.diversity_status_id })
                    setDriversSupplierDescriptionId(selectedValuesDiverseStatus)
                    setPhotoUpload(res?.data?.payload?.opportunity_media);
                    setPhotoInfo(({
                        imagename: res?.data?.payload?.image_name,
                        imagemimetype: res?.data?.payload?.image_mime_type,
                        imagesize: res.data.payload.image_size
                    }));
                    setCurrency(res?.data?.payload?.currency)
                }
                else {
                }
            }
        }))
    }


    //for add Opportunity
    const handleSubmit = (e) => {
        e.preventDefault();
        const regex = /^[a-z|A-Z]+(?: [a-z|A-Z]+)*$/
        if (!postingtitle) {
            toast.error("Please enter posting title");
            return;
        }
        // else if (postingtitle.length <= 2) {
        //     toast.error("Posting title should be maximum 3 character")
        //     return;
        // }
        // else if (!postingtitle.match(regex)) {
        //     toast.error("Please enter only character and single space in posting title");
        //     return;
        // }
        else if (!responseDeadline) {
            toast.error("Please select response deadline");
            return;
        }
        // else if (!awardDate) {
        //     toast.error("Please select award date");
        //     return;
        // }
        else if (!description) {
            toast.error("Please enter description of contract opportunity");
            return;
        }
        else if (description.length <= 2) {
            toast.error("description should be maximum 3 character")
            return;
        }
        // else if (postingtitle.length <= 2) {
        //     toast.error("Posting Title should be maximum 3 character")
        //     return;
        // }
        // else if (!postingtitle.match(regex)) {
        //     toast.error("Please enter only character and single space in Posting Title");
        //     return;
        // }
        // else if (!responseDeadline) {
        //     toast.error("Please select response Deadline");
        //     return;
        // }
        // else if (!awardDate) {
        //     toast.error("Please select award Date");
        //     return;
        // }
        // else if (!description) {
        //     toast.error("Please enter Description");
        //     return;
        // }
        // else if (description.length <= 2) {
        //     toast.error("Description should be maximum 3 character")
        //     return;
        // }
        // // else if (!description.match(regex)) {
        // //     toast.error("Please enter only character and single space in Description");
        // //     return;
        // // }
        // else if (!opportunityAmount) {
        //     toast.error("Please select opportunity Amount");
        //     return;
        // }
        // else if (!contractLength) {
        //     toast.error("Please select contract Length");
        //     return;
        // }
        // else if (!photo || !photoInfo) {
        //     toast.error("Please select opportunity media");
        //     return;
        // }
        // // else if (!photo.length) {
        // //     toast.error("Please select opportunity Media");
        // //     return;
        // // }
        // else if (!preferencesSupplier) {
        //     toast.error("Please select preferences for Supplier");
        //     return;
        // }
        // else if (!serviceCategories) {
        //     toast.error("Please select Product and Service Categories");
        //     return;
        // }
        // // else if (!serviceLocation) {
        // //     toast.error("Please select service Location");
        // //     return;
        // // }
        // else if (!annualRevenue) {
        //     toast.error("Please select annual Revenue");
        //     return;
        // }

        // else if (!numberOfEmployees) {
        //     toast.error("Please select number of Employees");
        //     return;
        // }
        // else if (!yearsInBusiness) {
        //     toast.error("Please select years in Business");
        //     return;
        // }
        // else if (!industriesServed) {
        //     toast.error("Please enter industries Served");
        //     return;
        // }
        // else if (industriesServed.length <= 2) {
        //     toast.error("Industries Served should be maximum 3 character")
        //     return;
        // }
        // else if (!industriesServed.match(regex)) {
        //     toast.error("Please enter only character and single space in Industries Served");
        //     return;
        // }
        // else if (!businessTypesServed) {
        //     toast.error("Please enter business types Served");
        //     return;
        // }
        // else if (businessTypesServed.length <= 2) {
        //     toast.error("Business Types Served should be maximum 3 character")
        //     return;
        // }
        // else if (!businessTypesServed.match(regex)) {
        //     toast.error("Please enter only character and single space in business Types Served");
        //     return;
        // }
        // else if (!certifications) {
        //     toast.error("Please enter certifications");
        //     return;
        // }
        // else if (certifications.length <= 2) {
        //     toast.error("Certifications should be maximum 3 character")
        //     return;
        // }
        // else if (!certifications.match(regex)) {
        //     toast.error("Please enter only character and single space in Certifications");
        //     return;
        // }
        let params = {
            id: props.id,
            posting_title: postingtitle.trim(),
            response_deadline_date: responseDeadline,
            award_date: awardDate ? awardDate : "",
            description: description.trim(),
            opportunity_amount: contractAmount ? contractAmount : 0,
            contract_length: contractLength ? contractLength : "",
            industry_group: industryGroupId ? industryGroupId : 0,
            industry_sub_group: industrySubGroupId ? industrySubGroupId : 0,
            specific_service_locations_required: specificServiceLocationRequired,
            hq_location: serviceLocation,
            business_ownership_type: ownershipTypeId ? ownershipTypeId : null,
            diversity_status: driversSupplierDescriptionId,
            existing_certificates: existingCertificationsId,
            service_licenses_required: serviceLicensesRequires,
            opportunity_media: photo.toString() ? photo.toString() : "",
            image_name: photoInfo?.imagename ? photoInfo?.imagename : "",
            image_size: photoInfo?.imagesize ? photoInfo?.imagesize : 0,
            image_mime_type: photoInfo?.imagemimetype ? photoInfo?.imagemimetype : "",

        }
        dispatch(updateOpportunity({
            ...params, cb(res) {
                if (res.status) {
                    props.setActiveTab("activeOpportunity");
                    // setUpdateOpportunities(res?.data?.payload)
                }
                else {
                }
            }
        }))
    }

    useEffect(() => {
        getOpportunityDetail();
        industry();
        businessOwnership();
        SupplierDescription();
        ExistingCertification();
        getAllCurrienciesAction();
        document.title = "opportunities";

    }, []);
    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)} className='editprofileForm_ row'>
                <h6 className='postHeading'>Edit Opportunity </h6>
                <div className='row'>
                    <div className='form-group col-lg-6 col-md-12 mb-4'>
                        <label className='postopportunity_label'>Contract Opportunity Title <span className='ColoredText'>*</span></label>
                        <input type='text' name="postingTitle" className='customFormControl' placeholder='Enter Title' value={postingtitle} onChange={e => setPostingtitle(e.target.value)} />
                    </div>
                    <div className='form-group col-lg-3  col-md-6 mb-4'>
                        <div className='position-relative'>
                        <label className='postopportunity_label'>Response Deadline <span className='ColoredText'>*</span></label>
                        <DatePicker
                            className='customFormControl'
                            selected={responseDeadline}
                            onChange={onChangeDateResponse}
                            // maxDate={moment().toDate()}
                            dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                            placeholderText="Month Day, Year" // Example placeholder text
                        />
                       <img src={Images.calanderIcon} alt='calendar' className='img-fluid inputIcon newCalander' />
                       </div>
                    </div>
                    <div className='form-group col-lg-3 col-md-6 mb-4'>
                    <div className='position-relative'>
                        <label className='postopportunity_label'>Award Date</label>
                        <DatePicker
                            className='customFormControl'
                            selected={awardDate}
                            onChange={onChangeDateAward}
                            dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                            placeholderText="Month Day, Year" // Example placeholder text
                        />
                        <img src={Images.calanderIcon} alt='calendar' className='img-fluid inputIcon newCalander' />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-md-12 mb-3'>
                        <label className='postopportunity_label'>Description of Contract opportunity <span className='ColoredText'>*</span></label>
                        <textarea value={description} onChange={e => setDescription(e.target.value)} name="description" type='text' className='customFormControl' placeholder='Type here...' rows='7'></textarea>
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-md-2 mb-5'>
                        <label className='postopportunity_label'>Currency</label>
                        <select className="cateSelectbox" required="" value={currency} onChange={(e) => { setCurrency(e.target.value) }} >
                            {allCurriencies.length > 0 ?
                                <>
                                    {
                                        allCurriencies.map((data) => {
                                            return <option value={data.code}>{data.code}</option>
                                        })
                                    }
                                </>
                                :
                                null
                            }
                        </select>
                    </div>
                    <div className='form-group col-lg-4 col-md-6 mb-5'>
                        <label className='postopportunity_label'>Contract Amount</label>
                        <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} value={contractAmount} onChange={e => setContractAmount(e.target.value)} type="number" className='customFormControl' pattern="[0-9]+" placeholder='Enter' />
                    </div>
                    <div className='form-group col-lg-4 col-md-6 mb-5'>
                        <label className='postopportunity_label'>Contract Length</label>
                        <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} value={contractLength} onChange={e => {
                            if (e.target.value.length > 20) {
                                toast.warning('Not more than 20 characters allowed in Contract Length')
                            }
                            setContractLength(e.target.value)
                        }
                        } type="text" className='customFormControl' placeholder='Enter' />
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-md-5 mb-5'>
                        <select className="cateSelectbox" required="" value={industryGroupId} onChange={(e) => { handleChange(e.target.value, "industry") }}
                        >
                            <option value="" >Select Industry Groups</option>
                            {industryGroup?.length > 0 ?
                                <>
                                    {industryGroup.map((data) => {
                                        return <option value={data?.id}>{data?.name}</option>
                                    })}
                                </>
                                : ""}
                        </select>
                    </div>
                    <div className='form-group col-md-5 mb-5'>
                        <select className="cateSelectbox" required="" value={industrySubGroupId}
                            onChange={(e) => { handleChange(e.target.value, "subindustry"); }}
                        >
                            <option value="">Select Industry Sub Groups</option>
                            {subIndustryGroup?.length > 0 ?
                                <>
                                    {subIndustryGroup.map((data) => {
                                        return <option value={data.id}>{data?.name}</option>
                                    })}
                                </>
                                : ""}
                        </select>
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-md-5 mb-5'>
                        <label className='postopportunity_label'>Specific Service Location Required?</label>
                        <div className="form-group col-md-12 mt-3 d-flex">
                            <div className="checkmark_ radioCheck_" >
                                <input
                                    type="radio"
                                    id="Location"
                                    className="businessType_"
                                    checked={specificServiceLocationRequired === true}
                                    name="radio-group"
                                    onChange={() => {
                                        setSpecificServiceLocationRequired(true);
                                    }}
                                />
                                <label className="labelTxt_" for="Location">
                                    <span className='innerSubtextSmall'>Yes</span>
                                </label>
                            </div>
                            <div className="checkmark_ radioCheck_" >
                                <input
                                    type="radio"
                                    id="useriD"
                                    className="businessType_"
                                    checked={specificServiceLocationRequired === false}
                                    name="radio-group"
                                    onChange={() => {
                                        setSpecificServiceLocationRequired(false);
                                    }}
                                />
                                <label className="labelTxt_" for="useriD">
                                    <span className='innerSubtextSmall'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className='row'>
                            <div className='form-group col-lg-6 col-md-12 mb-4'>
                                <label className='postopportunity_label'>Contract Opportunity Title <span className='ColoredText'>*</span></label>
                                <input type='text' name="postingTitle" className='customFormControl' placeholder='Enter Title' value={postingtitle} onChange={e => setPostingtitle(e.target.value)} />
                            </div>
                            <div className='form-group col-lg-3  col-md-6 mb-4'>
                                <label className='postopportunity_label'>Response Deadline <span className='ColoredText'>*</span></label>
                                <DatePicker
                                    className='customFormControl'
                                    selected={responseDeadline}
                                    onChange={onChangeDateResponse}
                                    // maxDate={moment().toDate()}
                                    dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                    placeholderText="Month Day, Year" // Example placeholder text
                                />
                                <img src={Images.calendaratach} alt='calendar' className='img-fluid inputIcon' />
                            </div>
                            <div className='form-group col-lg-3 col-md-6 mb-4'>
                                <label className='postopportunity_label'>Award Date</label>
                                <DatePicker
                                    className='customFormControl'
                                    selected={awardDate}
                                    onChange={onChangeDateAward}
                                    dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                    placeholderText="Month Day, Year" // Example placeholder text
                                />
                                <img src={Images.calendaratach} alt='calendar' className='img-fluid inputIcon' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group col-md-12 mb-3'>
                                <label className='postopportunity_label'>Description of Contract opportunity <span className='ColoredText'>*</span></label>
                                <textarea value={description} onChange={e => setDescription(e.target.value)} name="description" type='text' className='customFormControl' placeholder='Type here...' rows='7'></textarea>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group col-md-2 mb-5'>
                                <label className='postopportunity_label'>Currency</label>
                                <select className="cateSelectbox" required="" value={currency} onChange={(e) => { setCurrency(e.target.value) }} >
                                    {allCurriencies.length > 0 ?
                                        <>
                                            {
                                                allCurriencies.map((data) => {
                                                    return <option value={data.code}>{data.code}</option>
                                                })
                                            }
                                        </>
                                        :
                                        null
                                    }
                                </select>
                            </div>
                            <div className='form-group col-lg-4 col-md-6 mb-5'>
                                <label className='postopportunity_label'>Contract Amount</label>
                                <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} value={contractAmount} onChange={e => setContractAmount(e.target.value)} type="number" className='customFormControl' pattern="[0-9]+" placeholder='Enter' />
                            </div>
                            <div className='form-group col-lg-4 col-md-6 mb-5'>
                                <label className='postopportunity_label'>Contract Length</label>
                                <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} value={contractLength} onChange={e => {
                                    if (e.target.value.length > 20) {
                                        toast.warning('Not more than 20 characters allowed in Contract Length')
                                    }
                                    setContractLength(e.target.value)
                                }
                                } type="text" className='customFormControl' placeholder='Enter' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group col-md-5 mb-5'>
                                <select className="cateSelectbox" required="" value={industryGroupId} onChange={(e) => { handleChange(e.target.value, "industry") }}
                                >
                                    <option value="" >Select Industry Groups</option>
                                    {industryGroup?.length > 0 ?
                                        <>
                                            {industryGroup.map((data) => {
                                                return <option value={data?.id}>{data?.name}</option>
                                            })}
                                        </>
                                        : ""}
                                </select>
                            </div>
                            <div className='form-group col-md-5 mb-5'>
                                <select className="cateSelectbox" required="" value={industrySubGroupId}
                                    onChange={(e) => { handleChange(e.target.value, "subindustry"); }}
                                >
                                    <option value="">Select Industry Sub Groups</option>
                                    {subIndustryGroup?.length > 0 ?
                                        <>
                                            {subIndustryGroup.map((data) => {
                                                return <option value={data.id}>{data?.name}</option>
                                            })}
                                        </>
                                        : ""}
                                </select>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group col-md-5 mb-5'>
                                <label className='postopportunity_label'>Specific Service Location Required?</label>
                                <div className="form-group col-md-12 mt-3 d-flex">
                                    <div className="checkmark_ radioCheck_" >
                                        <input
                                            type="radio"
                                            id="Location"
                                            className="businessType_"
                                            checked={specificServiceLocationRequired === true}
                                            name="radio-group"
                                            onChange={() => {
                                                setSpecificServiceLocationRequired(true);
                                            }}
                                        />
                                        <label className="labelTxt_" for="Location">
                                            <span className='innerSubtextSmall'>Yes</span>
                                        </label>
                                    </div>
                                    <div className="checkmark_ radioCheck_" >
                                        <input
                                            type="radio"
                                            id="useriD"
                                            className="businessType_"
                                            checked={specificServiceLocationRequired === false}
                                            name="radio-group"
                                            onChange={() => {
                                                setSpecificServiceLocationRequired(false);
                                            }}
                                        />
                                        <label className="labelTxt_" for="useriD">
                                            <span className='innerSubtextSmall'>No</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-5 mb-5'>
                            <label className='postopportunity_label'>Service Location</label>
                            <PlacesAutocomplete
                                value={serviceLocation}
                                onChange={setServiceLocation}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Search Places ...',
                                                className: ' customFormControl location-search-input form-control textArea',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>

                        <div className='row'>
                            <div className='form-group col-md-5 mb-5'>
                                <label className="headingSmall_ mb-2" htmlFor="">Business Ownership type</label>
                                <select className="cateSelectbox" required="" value={ownershipTypeId} onChange={(e) => { businessOwnership(e.target.value) }}>
                                    <option value="">Business Ownership type</option>
                                    {ownershipType?.length > 0 ?
                                        <>
                                            {ownershipType?.map((data) => {
                                                return <option value={data?.id}>{data?.name}</option>
                                            })}

                                        </>
                                        : ""}
                                </select>
                            </div>
                            <div className='form-group col-md-5 mb-5'>
                                <label className="headingSmall_ mb-2" htmlFor="">Diverse Status</label>
                                {companyDescription &&
                                    <Multiselect
                                        options={companyDescription} // Options to display in the dropdown
                                        selectedValues={selectedValue} // Preselected value to persist in dropdown
                                        onSelect={handleChanges} // Function will trigger on select event
                                        onRemove={handleChanges} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                }
                            </div>
                            {/* {driversSupplierDescriptionId?.includes(16) ?

                        <div className='form-group col-md-5 mb-5'>
                            <label className="headingSmall_ mb-2" htmlFor="">Diverse Status Other</label>
                            <input name="companyDescription" type='text' className='customFormControl' value={companyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCompanyOtherDescription(e.target.value)} />
                        </div> : ""

                    } */}
                        </div>
                        <div className='row'>
                            <div className='form-group col-md-5 mb-3'>
                                <label className='postopportunity_label'>Existing Certifications</label>
                                {existingCertificationData &&
                                    <Multiselect
                                        options={existingCertificationData} // Options to display in the dropdown
                                        selectedValues={certificationSelected} // Preselected value to persist in dropdown
                                        onSelect={handleChangesCertifications} // Function will trigger on select event
                                        onRemove={handleChangesCertifications} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                }
                            </div>
                        </div>
                        <div className='form-group col-md-4 mb-3'>
                            <label className='postopportunity_label'>Service Licenses Requires?</label>
                            <div className="form-group col-md-12 mt-3 d-flex">
                                <div className="checkmark_ radioCheck_" >
                                    <input

                                        type="radio"
                                        id="Service"
                                        className="businessType_"
                                        checked={serviceLicensesRequires === true}
                                        value="true"
                                        name="radio-group2"
                                        onChange={() => {
                                            4(true);
                                        }}
                                    />
                                    <label className="labelTxt_" for="Service">
                                        <span className='innerSubtextSmall'>Yes</span>
                                    </label>
                                </div>
                                <div className="checkmark_ radioCheck_" >
                                    <input
                                        type="radio"
                                        id="ServiceLicenses"
                                        className="businessType_"
                                        checked={serviceLicensesRequires === false}
                                        value="false"
                                        name="radio-group2"
                                        onChange={() => {
                                            setServiceLicensesRequires(false);
                                        }} />
                                    <label className="labelTxt_" for="ServiceLicenses">
                                        <span className='innerSubtextSmall'>Yes</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <h6 class="postHeading"><b>Opportunity Media</b> </h6>
                            <div className='form-group col-md-12 mb-3'>
                                {photo != "" ?
                                    "" :
                                    <div className="uploadImgeboxNew">

                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input {...getInputProps()} />
                                            <img src={Images.uploadIcon} alt="uploadIcon" className='img-fluid uploadfileIcon hjgfhjfhj' />
                                            <p className='uploadbtn'>Drag & Drop or <span className='ColoredText'>browse</span></p>
                                            <p className='uploadText mt-2'>Supports: JPEG, JPG, PNG, PDF, DOC or DOCX</p>
                                        </div>
                                    </div>
                                }
                                {photo != "" ?
                                    <aside >
                                        <h4 className='headingSmall_'> Uploaded Files</h4>
                                        <ul className='uploadProfile_'>
                                            {/* {photoInfo.imagemimetype == "application/pdf" ?

                                        <img className="w3-round" src={Images.PDFImage} />
                                        : <img className="w3-round" src={photo} />
                                    } */}
                                            {photoInfo.imagemimetype == "application/pdf" ?
                                                <img className="w3-round" src={Images.PDFImage} />
                                                : photoInfo.imagemimetype == "application/msword" || photoInfo.imagemimetype == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                    <img className="w3-round" src={Images.DocImage} />
                                                    : <img className="w3-round" src={photo} />
                                            }

                                            <div className='profileDetail_'>
                                                <h5 className='profileboldHead'>{photoInfo.imagename}</h5>
                                                <p className='profileboldgraph'> {(photoInfo?.imagesize / (1024 * 1024)).toFixed(2)} MB</p>
                                            </div>
                                            <i className="fa fa-trash text-red" onClick={removeFile(photo)}></i>
                                        </ul>
                                    </aside>
                                    : ""
                                }
                            </div>
                        </div>
                        <button className='submitopportunity opportunityButton  mt-2 '>Update</button>
                    </>
                </div>
            </form>
        </>

    )
}

export default EditPostOpportunity