import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {addLabels, getLabels } from "../../../redux/slices/messages";
const AddLabels = (props) => { 
    const dispatch = useDispatch();
    const [label, setLabel] = useState("");

    const addLabel = () => {
        if (!label) {
            toast.error("Please enter Label");
            return;
        }
        else if (label?.length <= 2) {
            toast.error("Label should be maximum 3 character")
            return;
        }
        let params = {
            name: label
        };
        dispatch(addLabels({
            ...params, cb(res) {
                if (res?.data) {
                    props.dataList()
                    props.close()
                 
                }
            }
        }))
        
    }; 

    return (
        <>
            <div className='form-group col-md-12 mb-4'>
                <label className='postopportunity_label'>Please enter new label name</label>
                <input type='text' name="postingTitle" className='customFormControl' placeholder='Enter Name' onChange={e => setLabel(e.target.value)} />
            </div>
            <div className='button_box text-end  mb-2'>
            <button onClick={ () => props.close()} className='CancelLabel' >Cancel</button>
            <button className='subsmallBtnblue_' onClick={ () => addLabel()}>Create</button>
            </div>
        </>

    )
}

export default AddLabels