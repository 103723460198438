import React, { useEffect, useState } from 'react'


const MessageResponseBox = (props) => {


  useEffect(() => {

  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-3 col-md-3">
          <div className="designImg_">
            <img className="imgproduct_" src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/photo-05244e21-39a0-4104-9186-64996f1389e0.png"/>
          </div>
        </div>
        <div className="col-lg-5 col-md-5">
          <div className="designContent_">
            <div className="designcontentTop_">
              <div className="designLeft_">
                <h4 className="companyDetailsLinks">Frankie G</h4>
                <span className="headingTitleSmall_ d-flex"> Company : <span className="weeksTxt"> itechnolabs</span></span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-4'>
        <div className="designMid_">
                <p className="motionLoc">
                  <img src="/static/media/location-pin.352440e5edeba0ca1fc366189905a7db.svg" alt="Location" className="me-1"/>
                    <span className="textInnerOuter">mohali</span>
                </p>
              </div>
        </div>
        <div className='form-group col-md-12 my-3'>
            <label className='postopportunity_label'>Introduction</label>

            <textarea name="email" type='text' rows='4' className='customFormControl' placeholder='Type here...'/>
          </div>
        <div className="designbtnRight text-end">
                <button className="subsmallBtngreen_">Send Messsage</button>
              </div>
      </div>
    </>
  )
}

export default MessageResponseBox