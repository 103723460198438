import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import * as Images from "../../../utilities/images";
import { getChatDraft } from "../../../redux/slices/messages";
import moment from 'moment';
import CustomModal from '../../components/common/shared/CustomModal';
import ComposeMessage from '../accountMessage/ComposeMessages';


const Drafts = () => {
    const dispatch = useDispatch();
    const [chatDraft, setChatDraft] = useState("");
    const [selectedChatDraft, setSelectedChatDraft] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    //for closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    // apply API for get all Drafts list

    const getDrafts = () => {
        dispatch(getChatDraft({
            cb(res) {
                if (res?.data) {
                    setChatDraft(res?.data?.payload)
                }
            }
        }))

    };

    useEffect(() => {
        getDrafts();
    }, []);

    return (
        <>
            <div className='messageInboxcontent'>
                <div className='row'>
                    <div className='col-lg-5 ps-0'>
                        <div className='containerBox messageContain'>
                            <h3 className='mainHeading'>Drafts Messages</h3>
                            <div className='MessageInbox_'>
                                {chatDraft?.length > 0 ?
                                    <>
                                        {
                                            chatDraft?.map((val, index) => {
                                                return (
                                                    <div className='postContainerArea' >
                                                        <div className='posts messageListing align-items-center' >
                                                            <img src={val?.company_details?.company_logo ? val?.company_details?.company_logo : Images.companyprofile} className='postUser me-2' alt='UserImage' />
                                                            <div className='postsDetails'>
                                                                <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                                                                    <span><strong>{val?.company_details?.company_name}</strong></span>
                                                                </h5>
                                                                <p className='discription_'>{val?.message ? val?.message : ""}</p>
                                                            </div>
                                                            <div className='postionlabel'>
                                                                <div className="dropdown">
                                                                    <button className="" type="button" id="moreMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img src={Images.Moreoption} className='moreOption me-2' alt='UserImage' />
                                                                    </button>
                                                                    <ul className="dropdown-menu" aria-labelledby="moreMenu">
                                                                        <li><a className="dropdown-item" onClick={() => {
                                                                            setModalDetail({ show: true, flag: "ComposeMessage" });
                                                                            setKey(Math.random());
                                                                            setSelectedChatDraft(val)
                                                                        }}>Message</a></li>

                                                                    </ul>
                                                                </div>
                                                                <p className='loadMOre'>{moment(val?.updated_at).fromNow()}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                    </>
                                    : "No Data Founds"
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*=== Start compose message Modal ===*/}

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "ComposeMessage" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "ComposeMessage" ? "ComposeMessage" : "ComposeMessage"}
                child={modalDetail.flag === "ComposeMessage" ? (
                    <ComposeMessage
                        close={() => handleOnCloseModal()}
                        chatDraft={selectedChatDraft}
                        chatDraftsRefresh={() => getDrafts()}
                    />
                ) :
                    ""
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "ComposeMessage" ?
                                <h2 className="addLabels_heading">
                                    Compose Message
                                </h2>
                                : ""
                            }
                        </div>
                    </div>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
            {/*=== End compose message Modal ===*/}
        </>
    )
}

export default Drafts