import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { deleteSearch, getSavedSearches } from '../../../redux/slices/advanceSearch';
import * as Images from "../../../utilities/images";


const SavedSearches = (props) => {

    const dispatch = useDispatch()
    const [slides, setSlides] = useState([])

    const getSavedSearchesAction = (page = 1, limit = 50) => {

        const params = {
            page: page,
            limit: limit
        }

        dispatch(getSavedSearches({
            ...params, cb(res) {
                if (res.status == 200) {
                    setSlides(res?.data?.payload?.data ?? [])
                }
            }
        }))
    }

    useEffect(() => {
        getSavedSearchesAction()
    }, [])



    const handleDelete = (id) => {

        const params = {
            id: id,
        }
        dispatch(deleteSearch({
            ...params, cb(res) {
                if (res.status == 200) {
                    getSavedSearchesAction()
                    props.ResetFilter("empty")
                }

            }
        }))
    }

    const handlePlaySearch = (data) => {
        props.playSearch(data)
        props.close()
        props.setScrollTop(true)
    }

    return (

        <>
            {slides.length > 0 ?
                <>
                    {slides.map((val, i) => {
                        return (
                            <>
                                <div className='saveSearchBox'>
                                    <div className='flexBox'>
                                        <h6 className='savedHeading'>{val.name}</h6>
                                        <article className='flexBox'>
                                            <h6 className='savedTimimg'>{moment(val?.created_at).fromNow()}</h6>
                                            <figure className='flexBox'>
                                                <img src={Images.playVideo} className='img-fluid SavedIcon cursor-pointer' alt='playVideo' onClick={() => handlePlaySearch(val.data)} />
                                                <img src={Images.deleteSaved} className='img-fluid SavedIcon cursor-pointer' alt='deleteSaved' onClick={() => handleDelete(val.id)} />
                                            </figure>
                                        </article>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </>
                :
                <>
                    <div className='saveSearchBox'>
                        <div className='flexBox text-center'>
                            <h6 className='savedHeading'>No Saved Searches</h6>

                        </div>
                    </div>
                </>
            }
        </>
    )
}
export default SavedSearches