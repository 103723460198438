import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const EndorsementsText = () => {

    return (
        <>
            <div className="row justify-content-center">
                <p>Business endorsements play a crucial role in achieving success
                by bolstering credibility and visibility.</p>
                <p>When other businesses endorse your work, products, or
                services, it builds trust among potential customers,
                establishing a positive reputation</p>
                <p>Additionally, receiving endorsements from reputable sources
                can significantly expand your reach and open doors to new
                opportunities in the competitive business landscape.</p>
                <p>To give an endorsement to another GetOPP$ Member visit their profile page.</p>
            </div>
        </>
    )
}

export default EndorsementsText