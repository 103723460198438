import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { manageUsers } from '../../../redux/slices/manageusers';

const ManageUserModal = (props) => {
    const dispatch = useDispatch();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordShown, setPasswordShown] = useState(false);

    //for add manage user
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name) {
            toast.error("Please enter name");
            return;
        }
        else if (!email) {
            toast.error("Please enter email");
            return;
        }
        else if (email && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            toast.error("Please enter valid email address");
            return;
        }
        else if (!password) {
            toast.error("Please enter password");
            return;
        }
        else if (password.length <= 7) {
            toast.error("Password should be maximum 8 character")
            return;
        }
        else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(password)) {
            toast.error("Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character");
            return;
        }
        let params = {
            name: name,
            email: email,
            password: password
        }
        dispatch(manageUsers({
            ...params, cb(res) {
                if (res.status) {
                    props.close();
                    props.companyUsers();
                }
                else {
                }
            }
        }))
    }

    //password field show or hide
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };



    return (

        <div className='manageUserModal'>
            <form onSubmit={(e) => handleSubmit(e)} className='editprofileForm_ row'>
                <div className='row'>
                    <div className='form-group col-md-12 mb-4'>
                        <label className='postopportunity_label'>Name </label>
                        <input value={name} onChange={e => setName(e.target.value)} type='text' name="postingTitle" className='customFormControl' placeholder='Enter Name' />
                    </div>
                    <div className='form-group col-md-12 mb-4'>
                        <label className='postopportunity_label'>Email </label>
                        <input value={email} onChange={e => setEmail(e.target.value)} type='text' name="postingTitle" className='customFormControl' placeholder='Enter Email' />
                    </div>
                    <div className='form-group col-md-12 mb-4'>
                        <label className='postopportunity_label'>Password </label>
                        {/* <input value={email}  type='text' name="postingTitle" className='customFormControl' placeholder='Enter Email' /> */}
                        <input autoComplete="on" value={password} name="password" type={passwordShown ? "text" : "password"} className='customFormControl' placeholder='Password' onChange={e => setPassword(e.target.value)} />
                        <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblity(!passwordShown) }}>
                            <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                        </span>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        <button className='submitopportunity w-100'>Send Invitation</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ManageUserModal