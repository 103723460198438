import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { setGetUserDeatails, onErrorStopLoad, setGetAdvanceSearch, setDiverseSupplierDescription, setDiverseSupplierCertification, setTradeMembership, setExistingCertification, setTradeMemberShip, setSaveSearch, setSavedSearches, setDeleteSearch } from '../../slices/advanceSearch';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';

function* getAdvanceSearchList(action) {
  try {
    var dataToSend = { ...action.payload }
    delete dataToSend.cb
    const resp = yield call(ApiClient.get, action.url = ApiPath.AdvanceSearchApiPath.ADVANCE_SEARCH, action.params = { params: dataToSend });
    if (resp.status) {
      yield put(setGetAdvanceSearch(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetAdvanceSearch({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getUserinfo(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.AdvanceSearchApiPath.USER_DETAILS}${action.payload.id}`);
    if (resp.status) {
      yield put(setGetUserDeatails(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetUserDeatails({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* DiverseSupplierDescription(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AdvanceSearchApiPath.DRIVERS_SUPPLIER_DESCRIPTION, action.payload = action.payload);
    if (resp.status) {
      yield put(setDiverseSupplierDescription(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setDiverseSupplierDescription({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* DiverseSupplierCertification(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AdvanceSearchApiPath.DRIVERS_SUPPLIER_CERTIFICATION, action.payload = action.payload);
    if (resp.status) {
      yield put(setExistingCertification(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setExistingCertification({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* TradeMembership(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AdvanceSearchApiPath.TRADE_MEMBERSHIP, action.payload = action.payload);
    if (resp.status) {
      yield put(setTradeMemberShip(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setTradeMemberShip({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* getSavedSearches(action) {
  try {
    const dataToSend = {...action.payload}
    delete dataToSend.cb

    const resp = yield call(ApiClient.get, action.url = ApiPath.AdvanceSearchApiPath.GET_SEARCH, action.params = {params:dataToSend} );
    if (resp.status) {
      yield put(setSavedSearches({}));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setSavedSearches({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* saveSearch(action) {
  try {

    const dataToSend = {...action.payload}
    delete dataToSend.cb

    const resp = yield call(ApiClient.post, action.url = ApiPath.AdvanceSearchApiPath.SAVE_SEARCH, action.params = dataToSend);
    if (resp.status) {
      yield put(setSaveSearch({}));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setSaveSearch({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* deleteSearch(action) {
  try {

    const dataToSend = {...action.payload}
    delete dataToSend.cb

    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.AdvanceSearchApiPath.DELETE_SEARCH}/${dataToSend.id}`, action.params = {});
    if (resp.status) {
      yield put(setDeleteSearch({}));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setDeleteSearch({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* userAdvanceSearch() {
  yield all([
    takeLatest('advanceSearch/getAdvanceSearch', getAdvanceSearchList),
    takeLatest('advanceSearch/getUserDeatails', getUserinfo),
    takeLatest('advanceSearch/diverseSupplierDescription', DiverseSupplierDescription),
    takeLatest('advanceSearch/existingCertification', DiverseSupplierCertification),
    takeLatest('advanceSearch/tradeMemberShip', TradeMembership),

    takeLatest('advanceSearch/getSavedSearches', getSavedSearches),
    takeLatest('advanceSearch/saveSearch', saveSearch),
    takeLatest('advanceSearch/deleteSearch', deleteSearch),
  ])


}

export default userAdvanceSearch;