import React, { useCallback, useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { companyEditProfile, getCompanyProfileInfo, userPhotoUpload, userProfileUpdate } from '../../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useAuthSelector } from '../../../redux/selector/auth';

const AccountUserDetails = (props) => {
    const dispatch = useDispatch();
    const authSelector = useAuthSelector()
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [photo, setPhotoUpload] = useState("");
    const [photoInfo, setPhotoInfo] = useState({
        format: "",
        name: "",
        size: "",
        url: ""
    });

    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(getCompanyProfileInfo({
            cb(res) {
                if (res.status) {
                    setUserFirstName(res?.data?.payload?.userDetails?.user_profile.first_name)
                    setUserLastName(res?.data?.payload?.userDetails?.user_profile.last_name)
                    let detail = res?.data?.payload?.userDetails?.user_profile
                    setPhotoInfo(detail => ({
                        ...detail,
                        url: res?.data?.payload?.userDetails?.user_profile?.profile_photo,
                    }));
                }
                else {

                }
            }
        }))
    }

    const handleChange = (e, flag) => {
        const { value } = e.target;
        if (flag == "firstname") {
            setUserFirstName(value);
        }
        else if (flag == "lastname") {
            setUserLastName(value);
        }

    }

    //onDrop
    const onDrop = useCallback(acceptedFiles => {
        const imageFile = acceptedFiles[0];
        if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
            toast.error("Please select a valid image.");
            return false;
        }
        let params = {
            photo: imageFile,
        }
        dispatch(userPhotoUpload({
            ...params, cb(res) {
                if (res.status) {
                    setPhotoUpload(res?.data?.payload?.url);
                    setPhotoInfo(res?.data?.payload)
                }
                else {
                }
            }
        }))
    }, [])


    // company edit profile
    const handleSubmit = (e) => {
        e.preventDefault()

        let letterVal = /^[a-zA-Z\s]*$/;
        if (!userFirstName) {
            toast.error("Please enter first name");
            return;
        }
        else if (userFirstName.length <= 2) {
            toast.error("First Name should be maximum 3 character")
            return;
        }
        else if (!userFirstName.match(letterVal)) {
            toast.error('Please enter in first name alphabet characters only')
            return;
        }

        else if (!userLastName) {
            toast.error("Please enter last name");
            return;
        }
        else if (userLastName.length <= 2) {
            toast.error("Last Name should be maximum 3 character")
            return;
        }

        else if (!userLastName.match(letterVal)) {
            toast.error('Please enter in last name alphabet characters only')
            return;
        }

        let params = {
            first_name: userFirstName ? userFirstName : "",
            last_name: userLastName ? userLastName : "",

        };
        if (photoInfo.name != "") {
            params = {
                ...params,
                profile_photo: photoInfo.url ? photoInfo.url : "",
                profile_photo_name: photoInfo.name ? photoInfo.name : "",
                profile_photo_size: photoInfo.size ? photoInfo.size : 0,
                profile_photo_format: photoInfo.format ? photoInfo.format : "image/jpeg"
            }
        }
        dispatch(userProfileUpdate({
            ...params, cb(res) {
                if (res.status) {
                    props.refreshUserDetail();
                    props.close();
                }
            }
        }))
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    useEffect(() => {
        companyProfileInfo()
    }, []);


    return (
        <>

            {authSelector.userDeatil ?
                <>
                    <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                </>
                :

                <div className='row'>
                    <form onSubmit={(e) => handleSubmit(e)} className='signupForm row justify-content-center mt-5'>
                        <div className='form-group col-md-12 mb-3 text-center'>
                                <div class="custom-file-input">
                                    <label for="fileInput">
                                        <div {...getRootProps({ className: "dropzone" })} >
                                            <input {...getInputProps()} />
                                            <img src={photoInfo?.url ? photoInfo?.url : Images.companyprofile} alt='AddDocument' className='me-2 uploadProfile' />
                                            <span class="upload-icon">
                                                {/* <img src={Images.adddocument} alt='AddDocument' className='me-2 uploadprofileIcon' /> */}
                                                <div className='uploadprofileIcon'>
                                                    <span><i class="fas fa-pen"></i></span>
                                                </div>
                                            </span>
                                        </div>
                                    </label>
                                </div>
                        </div>
                        <div className='form-group col-md-12 mb-3'>
                            <label className='postopportunity_label'> First Name</label>
                            <input type="text" className='customFormControl' placeholder='' value={userFirstName} name='username' onChange={(e) => handleChange(e, "firstname")} />
                        </div>
                        <div className='form-group col-md-12 mb-3'>
                            <label className='postopportunity_label'> Last Name</label>
                            <input type="text" className='customFormControl' placeholder='' value={userLastName} name='username' onChange={(e) => handleChange(e, "lastname")} />
                        </div>
                        <div className='button_box text-center  my-2'>
                            <button className='primaryBtn mx-2' type="submit">
                                Create
                            </button>
                            <button className='subsmallBtnblue_ mx-2' onClick={() => props.close()}  >Cancel</button>
                        </div>
                    </form>
                </div>
            }

        </>
    )
}

export default AccountUserDetails