import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as images from "../../../utilities/images";


const BusinessNeed = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const roleId = location?.state?.id ?? 1;
    const [activeTab, setActiveTab] = useState("");
    const [id, setId] = useState(roleId);

    //tab click
    const tabClick = (id) => {
        setId(id)
    }
    //create Account by select role
    const handleSubmit = (e, flag) => {
        e.preventDefault();
        if (!id) {
            toast.error("Please Select atleast one role.")
        }
        else if (flag === "login") {
            navigate('/chooserolesfor=signin');
            // navigate('/login', { state: { id: id, roleId: roleId } });
        }
        else if (flag === "signup") {
            navigate("/signup", { state: { id: id, roleId: roleId } });

        }
        else {
            navigate('/signup', { state: { id: id, roleId: roleId } });
        }
    }
  return (
    <>
     <div className="row justify-content-center">
            <div className="col-md-10 text-center">
                <div className="detailsContainer">
                    <h3 className="headTxt mt-3 mb-4">Which best describes your business?</h3>
                    <form>
                        <div className='row justify-content-center'>

                            <>
                                <div onClick={() => tabClick(1)} className='col-md-6 col-lg-5 mb-3'>
                                    <div className={activeTab == 'EnBusiness' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("EnBusiness")}>
                                        <img src={images.buyer} alt='Buyer' className='img-fluid' />
                                        <h4 className="heading_ mt-3 mb-3">Enterprise Business</h4>
                                        <p className='textInner'>Over $5 million USD in yearly Revenue</p>
                                    </div>
                                </div>
                                <div onClick={() => tabClick(2)} className='col-md-6 col-lg-5 mb-3'>
                                    <div className={activeTab == 'SmBusiness' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("SmBusiness")}>
                                        <img src={images.supplier} alt='Supplier' className='img-fluid' />
                                        <h4 className="heading_ mt-3 mb-3">Small Business</h4>
                                        <p className='textInner'>Under $5 million USD in yearly Revenue</p>
                                    </div>
                                </div>
                            </> 
                        

                            {/* <div onClick={() => tabClick(3)} className='col-md-6 col-lg-4 mb-3'>
                                <div className={activeTab == 'Both' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("Both")}>
                                    <img src={images.boths} alt='Both' className='img-fluid' />
                                    <h4 className="heading_ mt-3 mb-3">Both</h4>
                                    <p className='textInner'>I’m a supplier & buyer, looking to connect with businesses.</p>
                                </div>
                            </div> */}
                                <div className='col-12 col-md-12 col-lg-7 mt-3 mb-3'>
                                    <>
                                        <button onClick={(e) => handleSubmit(e, "signup")} className='primaryBtn w-100 mt-2' type="submit">
                                            &nbsp;&nbsp;
                                            <span>Create Account</span>
                                        </button>
                                        <div className='accountRelate'>
                                            <p className='innerTxt mt-4 w-100'>Don't have an account? <button className='linkTxt' onClick={(e) => handleSubmit(e, "login")}><b>Log In</b></button></p>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessNeed