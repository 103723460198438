import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Images from "../../../utilities/images";
import CustomModal from '../../components/common/shared/CustomModal';
import SuggestionBox from './suggestionBox';
import SoundbytesThumbnail from './soundbytesThumbnailsModal/soundbytesThumbnail';
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Resources = () => {
    const [videoUrl, setVideoUrl] = useState("");
    // console.log(videoUrl, "videoUrl");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const plusStyle = {
        color: "#388E1E",
        fontSize: "14px"
    }

    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    // go to particular section
    const SectionToScroll = (flag) => {
        var section = document.getElementById(flag);
        if (section.id == "Knowledgeexchange") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Mainstage") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Paneldiscussion") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "spotlight") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "soundbytes") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Sundayfunday") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Virtualtrade") {
            section.scrollIntoView({ behavior: 'smooth' });
        }

    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Resources";
    }, []);
    return (
        <>
            <section className='commonContentBox'>
                {/* Start HomePage Section */}
                <div className='homeBanner resourceBanner'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='aboutContainer'>
                                    <div className='mb-3 mb-xl-4'>
                                        <h3 className='bannerHead mb-5'>
                                            Business <b>Resources</b>
                                        </h3>
                                        <p className='bannerTxt'>
                                            Building, sustaining and growing a business is a never-ending journey that involves continuous infusions of knowledge and wisdom.

                                            {/* Building, sustaining and growing a business is a never-ending journey that involves continuous infusions of knowledge and wisdom. the most successful companies are those run by people who are committed to being lifelong learners. */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-lg-5'>
                                <video controls className='w-100 mt-5 videoBox'>
                                    <source src=" https://api.getopps.itechnolabs.tech:4000/uploads/videos/business_resources.mp4" type="video/mp4" className='soundbiteImg' />
                                </video>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* End HomePage Section */}
                {/* Start AboutMenus Section */}
                <section className='aboutMenus resourceMenu p-0'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8 col-sm-7'>
                                {/* <div className='d-flex justify-content-space-between '> */}
                                <ul className='aboutMenusContainer'>
                                    <li className='aboutItem'>
                                        <button onClick={() => SectionToScroll("soundbytes")} className='aboutLinks'>Soundbytes</button>
                                    </li>
                                    <li className='aboutItem'>
                                        <button onClick={() => SectionToScroll("spotlight")} className='aboutLinks'>Spotlight</button>
                                    </li>
                                    <li className='aboutItem'>
                                        <button onClick={() => SectionToScroll("Sundayfunday")} className='aboutLinks'>Sunday Funday</button>
                                    </li>
                                    <div className="dropdown">
                                        <li className='aboutItem dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <Link className='aboutLinks'>Browse Resources</Link>
                                        </li>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Knowledgeexchange")} >Knowledge Exchange</button></li>
                                            <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Mainstage")} >Mainstage</button></li>
                                            <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Paneldiscussion")} >Panel Discussion</button></li>
                                            <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Virtualtrade")} >Virtual Trade Show</button></li>
                                            {/* <li className="dropdownBox"><Link className="dropdown-item" href="#">Mainstage</Link></li> */}
                                        </ul>
                                    </div>
                                </ul>
                            </div>
                            <div className='col-md-4 col-sm-5 text-end'>
                                <div className='suggestionBox m-0'>
                                    <button className='primaryBtn' onClick={() => {
                                        setModalDetail({ show: true, flag: "suggestionbox", });
                                        setKey(Math.random());
                                    }} >Suggestion Box
                                        <img src={Images.Chat} alt="Icon" className="img-fluid suggestionIcon" />
                                    </button>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </section>
                {/* Start AboutMenus Section */}
                {/* Start AboutFounder Section */}
                <section className='aboutFounder sucessCompny_'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-6 order-last'>
                                <div className='liveStreame'>
                                    <img className='streamImg' src={Images.livestreamImg} />
                                    <img className='streamImgchat' src={Images.streamchat} />
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-6 pe-4'>
                                <div className='mb-4'>
                                    <h5 className='anotherHeading'>
                                        The <strong className='ColoredText'>most successful companies</strong> are those run by people who are committed to being <strong className='ColoredText'>lifelong learners.</strong>
                                        {/* GetOpp$ supports the evolutionary process of a business and of learning by <b>offering educational resources,</b> best practices and case studies that range from <b>video learning</b> to <b>articles</b> to <b>livestreams. </b> */}
                                    </h5>
                                </div>
                                <p className='bannerTxt mb-4'>
                                    GetOPP$ supports the evolutionary process of a business and of learning by offering educational resources, best practices and case studies that range from video learning to articles to livestreams.
                                </p>
                                <p className='bannerTxt mb-5'>
                                    Topics are offered in a wide range of expertise: how to enhance contracting opportunities, how to secure funding, selecting the right corporate structure, navigating company certification processes, and more.
                                </p>

                                <p className='bannerTxt Boldtxt ColoredText'><strong>GetOPP$ is a Bridge for learning and for opportunity!</strong></p>

                            </div>

                        </div>
                    </div>
                </section>
                {/* End AboutFounder Section */}
                {/* Start AboutFounder Section */}
                <section className='aboutFounder relevenceMain_'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-6 pe-4'>
                                <div className='liveStreame'>
                                    <img className='streamImg' src={Images.relavenceImg} />
                                    <img className='streamImgchat' src={Images.streamchat} />
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-6 '>
                                <div className='ralevanceRight_'>
                                    <div className='mb-3'>
                                        <h5 className='anotherHeading'>Why Supplier <strong className='ColoredText'>Diversity Matters</strong>

                                        </h5>
                                    </div>
                                    <p className='bannerTxt mb-4'>
                                        Diverse Suppliers contribute to Buyers and Suppliers success because they:
                                    </p>
                                    {/* <p className='bannerTxt'>
                                        Choosing Diverse Suppliers contributes to both Buyers and Suppliers success because:
                                    </p> */}
                                    <ul className='diversityList_ mb-5'>
                                        <li className='bannerTxt'>
                                            Serve untapped niche markets
                                            {/* Diverse Companies Have 2.5 Times Higher Cash Flow Per Employee */}
                                        </li>
                                        <li className='bannerTxt'>
                                            {/* Diverse Companies See Higher Revenues */}
                                            Provide untapped location demographics
                                        </li>
                                        <li className='bannerTxt'>
                                            {/* Diverse Companies are more likely to be profitable */}
                                            Provide alternative sourcing strategies
                                        </li>
                                        <li className='bannerTxt'>
                                            Reduce the risks of supply chain disruptions

                                            {/* Diverse Management Outperforms Less-Diverse Management */}
                                            {/* Companies With Diverse Boards Can See Higher Profits */}
                                        </li>
                                        <li className='bannerTxt'>
                                            Supports the growth of underrepresented businesses
                                        </li>
                                        <li className='bannerTxt'>
                                            Demonstrates a commitment to social responsibility
                                        </li>
                                        <li className='bannerTxt'>
                                            Demonstrates a commitment to inclusion
                                        </li>
                                        <li className='bannerTxt'>
                                            Increases brand loyalty
                                        </li>
                                        <li className='bannerTxt'>
                                            Increases positive public perception
                                        </li>
                                    </ul>
                                    <p className='bannerTxt'>One of the goals of GetOPP$ is to put diverse businesses on global procurement radar screens. </p>

                                    <p className='bannerTxt ColoredText'><strong className='fontNineteen'>That is the GetOPP$ Win-Win!!</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End AboutFounder Section */}
                {/* Start Premium SoundBites Section  */}
                {/* <section className='premiumSoundbite_'>
                    <div className='container'>
                        <div className='row'>
                            <div className='premiumbite_'>
                                <div className='row'>
                                    <div className='col-mg-5 col-lg-6 noPadding order-last'>
                                        <div className='bitesLeftImg'>
                                            <img className='biteImg' src={Images.diversityCertificate} />
                                        </div>
                                    </div>
                                    <div className='col-md-12 col-lg-6'>
                                        <div className='bitesRight_'>

                                            <div className='workDealing mt-5'>
                                                <h4 className='headingBluemain_ mb-4'><strong>GetOPP$ Diversity Certifications</strong> <br />
                                                    for inclusive business futures
                                                </h4>
                                                <p className='termspara_'>Say goodbye to tedious paperwork and complex requirements. GetOPP$ simplifies and streamlines the entire certification process, empowering you to focus on growing your business. </p>
                                                <p className='termspara_'>
                                                    With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier.
                                                </p>
                                                <p className='termspara_'>
                                                    Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity.
                                                </p>
                                                <h5 className='subHeading__'>Aliquam vehicula nisl nisi, sed pretium eros feugiat et.</h5>
                                                <button className="subsmallBtngreen_ mt-4">Click here to play</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* End Premium SoundBites Section  */}

                {/* Start Waveless section */}
                <section className='waveless mt-5' id="spotlight">
                    <div className='container'>
                        <div className='motionSec memberShipSpot'>
                            <div className='row'>
                                <div className='col-lg-3 text-center'>
                                    <img src={Images.ColoredLogo} alt='ColoredLogo' className='img-fluid ColoredLogo mb-4' />
                                    <img src={Images.WaveLessLogo} alt='WaveLessLogo' className='img-fluid WaveLessLogo mb-2' />
                                    <img src={Images.WaveLess} alt='WaveLess' className='img-fluid WaveLess' />
                                </div>
                                <div className='col-lg-8 offset-1'>
                                    <div className='text-end'>
                                        <button className='primaryBtnSmall mb-4'>Member Spotlight</button>
                                    </div>
                                    <div className='mb-3'>
                                        <p className='wavesubText'>Each Week GetOPPS Highlights one of our Members, Sharing their unique talents and services with our entire community.</p>
                                        <p className='wavesubText'>Waveless provide solar energy to coporate warehouses and distribution centers around the world. They have the most urban solar farms to date and joined GetOPPS because they wanted a networking tool that would expand their footprint in the United States and Globally.</p>
                                    </div>
                                    <div className='text-end'>
                                        <button className='secondaryBtnOuter me-2'>View Profile</button>
                                        <button className='secondaryBtnGrey'>View More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Waveless section */}

                {/* Start Previous Spotlights section */}
                <div className='spotlights mt-5'>
                    <div className="container">
                        <h3 className='otherHeading_  mb-3'><b>Previous Spotlights</b></h3>
                        <OwlCarousel items={4}
                            className="owl-theme"
                            loop
                            nav
                            dots={false}
                            margin={15}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 2
                                },
                                991: {
                                    items: 3
                                },
                                1000: {
                                    items: 4
                                }
                            }}
                        >
                            <div className='motionSec text-center'>
                                <img src={Images.SpotlightsOne} alt='Spotlights' className='img-fluid Spotlights mb-2' />
                                <h4>Elevate</h4>
                                <p>Elevating Brands to realize all of their brand and marketing goals</p>
                                <button className='secondaryBtnOuter me-2'>View Profile</button>
                            </div>
                            <div className='motionSec text-center'>
                                <img src={Images.SpotlightsTwo} alt='Spotlights' className='img-fluid Spotlights mb-2' />
                                <h4>Elevate</h4>
                                <p>Elevating Brands to realize all of their brand and marketing goals</p>
                                <button className='secondaryBtnOuter me-2'>View Profile</button>
                            </div>
                            <div className='motionSec text-center'>
                                <img src={Images.SpotlightsThree} alt='Spotlights' className='img-fluid Spotlights mb-2' />
                                <h4>Elevate</h4>
                                <p>Elevating Brands to realize all of their brand and marketing goals</p>
                                <button className='secondaryBtnOuter me-2'>View Profile</button>
                            </div>
                            <div className='motionSec text-center'>
                                <img src={Images.SpotlightsFour} alt='Spotlights' className='img-fluid Spotlights mb-2' />
                                <h4>Elevate</h4>
                                <p>Elevating Brands to realize all of their brand and marketing goals</p>
                                <button className='secondaryBtnOuter me-2'>View Profile</button>
                            </div>
                            <div className='motionSec text-center'>
                                <img src={Images.SpotlightsOne} alt='Spotlights' className='img-fluid Spotlights mb-2' />
                                <h4>Elevate</h4>
                                <p>Elevating Brands to realize all of their brand and marketing goals</p>
                                <button className='secondaryBtnOuter me-2'>View Profile</button>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
                {/* End Previous Spotlights section */}

                {/* Start SoundBites Section  */}
                <section className='mainSoundbites_ mt-5' id='soundbytes'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Soundbytes</b></h3>
                                    <p>Soundbytes are short sessions on a variety of topics curated by the GetOPP$ team that subscribers can watch/listen to on demand.</p>
                                </div>
                            </div>
                        </div>
                        <OwlCarousel items={4}
                            className="owl-theme"
                            loop
                            nav
                            dots={false}
                            margin={15}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 2
                                },
                                991: {
                                    items: 3
                                },
                                1000: {
                                    items: 4
                                }
                            }}
                        >
                            <div className='bitesCrad'>
                                {/* <video controls>
                                    <source
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/certification_process.mp4"
                                        type="video/mp4" className='soundbiteImg' />
                                </video> */}
                                <div onClick={() => {
                                    setVideoUrl('https://api.getopps.itechnolabs.tech:4000/uploads/users/images/certification_process.mp4')
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/video_thumb/thumb_certificationprocess.png"
                                        alt="Image"
                                        class="soundbiteImg"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>GO$ Certification Process</b></h6>
                                    <p className='listHead_'>Check out this SoundByte to assist in Making
                                        informed decisions Certifications</p>
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                {/* <video controls>
                                    <source
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/doing_a_joint_venture.mp4"
                                        type="video/mp4" className='soundbiteImg' />
                                </video> */}
                                <div onClick={() => {
                                    setVideoUrl('https://api.getopps.itechnolabs.tech:4000/uploads/users/images/doing_a_joint_venture.mp4')
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/video_thumb/thumb_jointventure.png"
                                        alt="Image"
                                        class="soundbiteImg"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>GO$ Doing a Joint Venture</b></h6>
                                    <p className='listHead_'>Check out this SoundByte to assist in understanding
                                        joint ventures.</p>
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                {/* <video controls>
                                    <source
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/avoiding_being_victimized_by_phishing_scams.mp4"
                                        type="video/mp4" className='soundbiteImg' />
                                </video> */}
                                <div onClick={() => {
                                    setVideoUrl('https://api.getopps.itechnolabs.tech:4000/uploads/users/images/avoiding_being_victimized_by_phishing_scams.mp4')
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/video_thumb/thumb_phisingscams.png"
                                        alt="Image"
                                        class="soundbiteImg"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>GO$ Avoid Being Victimized by Scams</b></h6>
                                    <p className='listHead_'>Check out this SoundByte to learn how to avoid
                                        being victimized by scams</p>
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                {/* <video controls>
                                    <source
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/social_media_marketing.mp4"
                                        type="video/mp4" className='soundbiteImg' />
                                </video> */}
                                <div onClick={() => {
                                    setVideoUrl('https://api.getopps.itechnolabs.tech:4000/uploads/users/images/social_media_marketing.mp4')
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/video_thumb/thumb_socialmedia.png"
                                        alt="Image"
                                        class="soundbiteImg"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>GO$ Social Media</b></h6>
                                    <p className='listHead_'>Check out this SoundByte to learn how to leverage
                                        Social Media as a marketing and branding tool.</p>
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                {/* <video controls>
                                    <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/building_a_support_system_for_your_business.mp4" type="video/mp4" className='soundbiteImg' />
                                </video> */}
                                <div onClick={() => {
                                    setVideoUrl('https://api.getopps.itechnolabs.tech:4000/uploads/users/images/building_a_support_system_for_your_business.mp4')
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/video_thumb/thumb_buildingasupportsystem.png"
                                        alt="Image"
                                        class="soundbiteImg"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>GO$ Building A Support System for your Business</b></h6>
                                    <p className='listHead_'>Check out this SoundByte to assist in building a
                                        support system for your business</p>
                                </div> 
                            </div>
                            <div className='bitesCrad'>
                                {/* <video controls>
                                    <source
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/marketing_your_business.mp4"
                                        type="video/mp4" className='soundbiteImg' />
                                </video> */}
                                <div onClick={() => {
                                    setVideoUrl('https://api.getopps.itechnolabs.tech:4000/uploads/users/images/marketing_your_business.mp4')
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/video_thumb/thumb_marketingyourbusiness.png"
                                        alt="Image"
                                        class="soundbiteImg"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>GO$ Marketing your Business</b></h6>
                                    <p className='listHead_'>Check out this SoundByte a quick guide to Marketing
                                        101.</p>
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                {/* <video controls>
                                    <source
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/how_not_to_go_bankrupt.mp4"
                                        type="video/mp4" className='soundbiteImg' />
                                </video> */}
                                <div onClick={() => {
                                    setVideoUrl('https://api.getopps.itechnolabs.tech:4000/uploads/users/images/how_not_to_go_bankrupt.mp4')
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/video_thumb/thumb_bankrupt.png"
                                        alt="Image"
                                        class="soundbiteImg"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>GO$ How Not to Go Bankrupt</b></h6>
                                    <p className='listHead_'>Check out this SoundByte to assist in best
                                        practices for your business’ financial
                                        management.</p>
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                {/* <video controls>
                                    <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/contracting_opportunities.mp4" type="video/mp4" className='soundbiteImg' />
                                </video> */}
                                <div onClick={() => {
                                    setVideoUrl('https://api.getopps.itechnolabs.tech:4000/uploads/users/images/contracting_opportunities.mp4')
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/video_thumb/thumb_contractingopportunities.png"
                                        alt="Image"
                                        class="soundbiteImg"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>GO$ Contracting Opportunities</b></h6>
                                    <p className='listHead_'>Check out this SoundByte to find insight on
                                        contracting opportunities.</p>
                                </div>
                            </div> 
                            <div className='bitesCrad'>
                                {/* <video controls>
                                    <source
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/to_incorporate_your_business_or_not.mp4"
                                        type="video/mp4" className='soundbiteImg' />
                                </video> */}
                                <div onClick={() => {
                                    setVideoUrl('https://api.getopps.itechnolabs.tech:4000/uploads/users/images/to_incorporate_your_business_or_not.mp4')
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                    <img
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/video_thumb/thumb_identifyingopportunities.png"
                                        alt="Image"
                                        class="soundbiteImg"
                                    />
                                </div>
                                <div className='soundbiteContent'>
                                    <h6 className='headingSmall_mb-2'> <b>GO$ Identifying Opportunities</b></h6>
                                    <p className='listHead_'>Check out this SoundByte to assist in how to
                                        identify the best opportunities for your business.</p>
                                </div>
                            </div>
                            <div className='bitesCrad'>
                                {/* <video controls>
                                    <source
                                        src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/identifying_opportunities_that_fit_your_business_capabilities.mp4"
                                        type="video/mp4" className='soundbiteImg' />
                                </video> */}

                                <div onClick={() => {
                                    setVideoUrl('https://api.getopps.itechnolabs.tech:4000/uploads/users/images/identifying_opportunities_that_fit_your_business_capabilities.mp4')
                                    setModalDetail({ show: true, flag: "soundbytesThumbnail", });
                                    setKey(Math.random());
                                }}>
                                <img
                                    src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/video_thumb/thumb_toincorporateyourbusinessornot.png"
                                    alt="Image"
                                    class="soundbiteImg"
                                />
                            </div>

                            <div className='soundbiteContent'>
                                <h6 className='headingSmall_mb-2'> <b>GO$ To incorporate Your Business or Not</b></h6>
                                <p className='listHead_'>Check out this SoundByte to understand a quick
                                    breakdown of business structures and their benefits.</p>
                            </div>
                    </div>
                    {/* <div className='bitesCrad'>
                                     <img className='soundbiteImg' src={Images.soundbiteImg} />
                                        <div className='soundbiteContent'>
                                            <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                        </div>
                                    </div>
                                    <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the Web</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                   <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div> */}
                </OwlCarousel>
                {/* <div className='row mt-4'>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                     <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/CertificationProcess1.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>GO$ Certification Process</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to assist you in making an informed decision as to whether you should venture into the world of Certifications.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/Marilyn.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>GO$ Marketing </b></h6>
                                        <p className='listHead_'>Check out this SoundByte for insights and guidance on how to maximize marketing results on any budget.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                   <img className='soundbiteImg' src={Images.soundbite3} />
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/victims.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>GO$ Avoid Being Vicitimized By Phishing Scam</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to learn some of the things that you can do to avoid becoming the next victim of a Phishing Scam. </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo' >
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/CertificationProcess4.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>GO$ How NOT to go bankrupt</b></h6>
                                        <p className='listHead_'>Check out this SoundByte for tips to assist in guiding you through the land mines of running a successful and profitable business.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
        </section >
            {/* End SoundBites Section  */ }
    {/* Start Knowledge Section  */ }
    <section className='mainSoundbites_' id='Sundayfunday'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-10'>
                    <div className='topheadingBites'>
                        <h3 className='otherHeading_ '><b>Sunday Funday</b></h3>
                        <p>Get to know others on the GetOPP$ platform in a fun, virtual, interactive monthly game session where companies compete against each other for a prize sponsored by GetOPP$.</p>
                    </div>
                </div>
            </div>
            <OwlCarousel items={4}
                className="owl-theme"
                loop
                nav
                dots={false}
                margin={15}
                responsive={{
                    0: {
                        items: 1
                    },
                    600: {
                        items: 2
                    },
                    991: {
                        items: 3
                    },
                    1000: {
                        items: 4
                    }
                }}
            >
                <div className='bitesCrad'>
                    <img className='soundbiteImg' src={Images.familyGameNight} />
                    <div className='soundbiteContent'>
                        <h6 className='headingSmall_mb-2'> <b>
                            {/* Recover lost emails */}
                            Sunday Funday
                        </b></h6>
                        {/* <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p> */}
                    </div>
                </div>
                <div className='bitesCrad'>
                    <img className='soundbiteImg' src={Images.sundayFunday} />
                    <div className='soundbiteContent'>
                        <h6 className='headingSmall_mb-2'> <b>
                            {/* Navigating the Web */}
                            Sunday Funday
                        </b></h6>
                        {/* <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p> */}
                    </div>
                </div>
                <div className='bitesCrad'>
                    <img className='soundbiteImg' src={Images.sundayFundayOne} />
                    <div className='soundbiteContent'>
                        <h6 className='headingSmall_mb-2'> <b>
                            {/* Creating a safe work area */}
                            Sunday Funday
                        </b></h6>
                        {/* <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p> */}
                    </div>
                </div>
                <div className='bitesCrad'>
                    {/* <img className='soundbiteImg' src={Images.soundbite4} /> */}
                    <video controls>
                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/sunday_funday_video_editted_08072023.mp4" type="video/mp4" className='soundbiteImg' />
                    </video>
                    <div className='soundbiteContent'>
                        <h6 className='headingSmall_mb-2'> <b>
                            {/* GO$ How NOT to go bankrupt */}
                            Sunday Funday
                        </b></h6>
                        {/* <p className='listHead_'>Check out this SoundByte for tips to assist in guiding you through the land mines of running a successful and profitable business.</p> */}
                    </div>
                </div>
                <div className='bitesCrad'>
                    {/* <img className='soundbiteImg' src={Images.soundbite4} /> */}
                    <video controls>
                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/sunday_funday_1.mp4" type="video/mp4" className='soundbiteImg' />
                    </video>
                    <div className='soundbiteContent'>
                        <h6 className='headingSmall_mb-2'> <b>
                            {/* GO$ How NOT to go bankrupt */}
                            Sunday Funday
                        </b></h6>
                        {/* <p className='listHead_'>Check out this SoundByte for tips to assist in guiding you through the land mines of running a successful and profitable business.</p> */}
                    </div>
                </div>
                <div className='bitesCrad'>
                    <img className='soundbiteImg' src={Images.familyGameNight} />
                    <div className='soundbiteContent'>
                        <h6 className='headingSmall_mb-2'> <b>
                            {/* Recover lost emails */}
                            Sunday Funday
                        </b></h6>
                        {/* <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p> */}
                    </div>
                </div>
                <div className='bitesCrad'>
                    <img className='soundbiteImg' src={Images.sundayFunday} />
                    <div className='soundbiteContent'>
                        <h6 className='headingSmall_mb-2'> <b>
                            {/* Navigating the Web */}
                            Sunday Funday
                        </b></h6>
                        {/* <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p> */}
                    </div>
                </div>
                <div className='bitesCrad'>
                    <img className='soundbiteImg' src={Images.sundayFundayOne} />
                    <div className='soundbiteContent'>
                        <h6 className='headingSmall_mb-2'> <b>
                            {/* Creating a safe work area */}
                            Sunday Funday
                        </b></h6>
                        {/* <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p> */}
                    </div>
                </div>
                <div className='bitesCrad'>
                    {/* <img className='soundbiteImg' src={Images.soundbite4} /> */}
                    <video controls>
                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/sunday_funday_video_editted_08072023.mp4" type="video/mp4" className='soundbiteImg' />
                    </video>
                    <div className='soundbiteContent'>
                        <h6 className='headingSmall_mb-2'> <b>
                            {/* GO$ How NOT to go bankrupt */}
                            Sunday Funday
                        </b></h6>
                        {/* <p className='listHead_'>Check out this SoundByte for tips to assist in guiding you through the land mines of running a successful and profitable business.</p> */}
                    </div>
                </div>
                <div className='bitesCrad'>
                    {/* <img className='soundbiteImg' src={Images.soundbite4} /> */}
                    <video controls>
                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/users/images/sunday_funday_1.mp4" type="video/mp4" className='soundbiteImg' />
                    </video>
                    <div className='soundbiteContent'>
                        <h6 className='headingSmall_mb-2'> <b>
                            {/* GO$ How NOT to go bankrupt */}
                            Sunday Funday
                        </b></h6>
                        {/* <p className='listHead_'>Check out this SoundByte for tips to assist in guiding you through the land mines of running a successful and profitable business.</p> */}
                    </div>
                </div>

            </OwlCarousel>
            {/* <div className='row mt-4'>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                     <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/sundayfunday.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Sunday Funday</b></h6>
                                         <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg sudayFunday' src={Images.sudayFunday1} />
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/CertificationProcess2.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Sunday Funday</b></h6>
                                         <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg sudayFunday' src={Images.sudayFunday2} />
                                    {/* <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/CertificationProcess3.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Sunday Funday</b></h6>
                                         <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> 
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg sudayFunday' src={Images.sudayFunday3} />
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/CertificationProcess4.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video> 
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Sunday Funday</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
        </div>
    </section>
    {/* End Knowledge Section  */ }
    <section className='mainSoundbites_ mainstagebox_ '>
        <div id="Knowledgeexchange" className='container '>
            <div className='row '>
                <div className='col-lg-10'>
                    <div className='topheadingBites'>
                        <h3 className='otherHeading_ '><b>Knowledge Exchange</b></h3>
                        <p>Knowledge Exchange are 45-minute sessions on a variety of topics curated by the GetOPP$ team that subscribers can watch/listen to on demand
                        </p>
                    </div>
                </div>
            </div>
            {/* <div className='row mt-4 sectionDisable'>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the web</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <div className='sectionDisable'>
                <OwlCarousel items={4}
                    className="owl-theme"
                    loop
                    nav
                    dots={false}
                    margin={15}
                    responsive={{
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        991: {
                            items: 3
                        },
                        1000: {
                            items: 4
                        }
                    }}
                >
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbiteImg} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbite2} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Navigating the Web</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbite3} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbite4} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbite4} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbite4} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </div>
        <div className='mainstagebtn'>
            <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
        </div>
    </section>
    {/* Start Mainstage Learning Section  */ }
    <section className='mainSoundbites_ mainstagebox_ ' id="Mainstage">
        <div className='container '>
            <div className='row '>
                <div className='col-lg-10'>
                    <div className='topheadingBites'>
                        <h3 className='otherHeading_ '><b>Mainstage Learning</b></h3>
                        <p>Attend 60 minute virtual learning sessions hosted by Platinum members on a topic of their choosing that will be both live and pre-recorded, with subsequent on-demand viewing.</p>
                    </div>
                </div>
            </div>
            <div className='sectionDisable'>
                <OwlCarousel items={4}
                    className="owl-theme"
                    loop
                    nav
                    dots={false}
                    margin={15}
                    responsive={{
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        991: {
                            items: 3
                        },
                        1000: {
                            items: 4
                        }
                    }}
                >
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbiteImg} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbite2} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Navigating the Web</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbite3} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbite4} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbite4} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                    <div className='bitesCrad'>
                        <img className='soundbiteImg' src={Images.soundbite4} />
                        <div className='soundbiteContent'>
                            <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                            <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
            {/* <div className='row mt-4 sectionDisable'>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the web</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
        </div>
        <div className='mainstagebtn'>
            <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
        </div>
    </section>
    {/* End Mainstage Learning Section  */ }
                <section className='mainSoundbites_ mainstagebox_ ' id="Paneldiscussion">
                    <div className='container '>
                        <div className='row '>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Panel Discussions
                                    </b></h3>
                                    <p>Increase your knowledge via panel discussions of experts on a variety of topics that can help companies enhance their business performance and their chances of success in the procurement process.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='sectionDisable'>
                            <OwlCarousel items={4}
                                className="owl-theme"
                                loop
                                nav
                                dots={false}
                                margin={15}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    991: {
                                        items: 3
                                    },
                                    1200: {
                                        items: 4
                                    }
                                }}
                            >
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the Web</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                        {/* <div className='row mt-4 sectionDisable'>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the web</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className='mainstagebtn'>
                        <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
                    </div>
                </section>
                <section className='mainSoundbites_ mainstagebox_ ' id='Virtualtrade'>
                    <div className='container '>
                        <div className='row '>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Virtual Trade Show
                                    </b></h3>
                                    <p>Save precious time and money by foregoing in-person conferences and attending virtual trade fairs on the GetOPP$ platform that can help “match-make”  Buyers and Suppliers.</p>
                                </div>
                            </div>
                        </div>
                        <div className='sectionDisable'>
                            <OwlCarousel items={4}
                                className="owl-theme"
                                loop
                                nav
                                dots={false}
                                margin={15}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 2
                                    },
                                    991: {
                                        items: 3
                                    },
                                    1000: {
                                        items: 4
                                    }
                                }}
                            >
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the Web</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                        {/* <div className='row mt-4 sectionDisable'>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the web</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3 cardsVideo'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className='mainstagebtn'>
                        <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
                    </div>
                </section>


            </section >
    <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "suggestionbox" ? "commonWidth customContent" : ""}
        ids={modalDetail.flag === "suggestionbox" ? "suggestionbox" : "ComposeMessage"}
        child={
            modalDetail.flag === "suggestionbox" ? (
                <SuggestionBox
                    close={() => handleOnCloseModal()}
                />
            ) : modalDetail.flag === "soundbytesThumbnail" ? (
                <SoundbytesThumbnail
                    close={() => handleOnCloseModal()}
                    videoThumbnail={videoUrl}
                />
            )
                :
                ""
        }
        header={
            <div className='modalHeader_'>
                <div className="common_">
                    {modalDetail.flag === "suggestionbox" ?
                        <>
                            <h2 className="addLabels_heading">
                                Have a Suggestion?
                            </h2>
                            <p>We are always looking for input please provide below</p>
                        </>
                        : ""
                    }
                </div>
            </div>
        }

        onCloseModal={() => handleOnCloseModal()}
    />
        </>
    )
}

export default Resources