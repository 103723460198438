import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { onErrorStopLoad, setLogout, setUserLogin, setUserOtp, setUserVerifyOtp, setUserSignUp, setUserSocialLoginSignup, setStepThreeCompanyLogoUplaod, setSignUpStep, setUserSiteAccess, setGetAllIndustryGroups, setGetAllIndustrySubGroups, setBusinessOwnershipType, setCompanyListType, setGetCompanyProfileInfo, setCompanyEditProfile, setCompanyBannerUplaod, setEditProfile, setAddCertificates, setDeleteCertificates, setCertificatesList, setCompanyUpdateLogoBanner, setUserForgetPassword, setForgetPasswodVerifyOtp, setResetPassword, setGetSessionId, setUpdateMemberShipAmount, setChangePassword, setUserPhotoUpload, setUserProfileUpdate, subscriptionStatusSet, subscriptionIDSet, setSearchCompanyUser, setUserExistingCertificationsUpdate, setUserTradeMembershipsUpdate, setBusinessGenesis, setUpdateSettings, setInvitedUserSignUp, setPaymentViaPromoCode, setEthnicity, setUserModalSocialLoginSignup } from '../../slices/auth';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import AddCertificates from '../../../app/containers/editProfile/addCertificates';

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* userLogin(action) {
  try {
    // const resp = yield call(ApiClient.post, action.url = `${ApiPath.CREATE_USER}/name=${action.payload.name}`);
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.LOGIN_USER, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp.data?.payload.token ? resp.data.payload.token : "")

      yield put(setUserLogin(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserLogin({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data?.msg);
  }
}

function* userSiteAccess(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.SITE_ACCESS, action.payload = action.payload);
    if (resp.status) {
      yield put(setUserSiteAccess(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserSiteAccess({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* MemberShipGetSessionId(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.MEMBERSHIP_GET_SESSION_ID, action.payload = action.payload);
    if (resp.status) {
      yield put(setGetSessionId(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetSessionId({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* UpdateMemberShipAmount(action) {
  try {
    const resp = yield call(ApiClient.put, action.url = `${ApiPath.AuthApiPath.UPDATE_MEMBERSHIP_AMOUNT}/${action.payload.session_id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setUpdateMemberShipAmount(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUpdateMemberShipAmount({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* userSignUp(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.SIGNUP_USER, action.payload = action.payload);
    if (resp.status) {
      yield put(setUserSignUp(resp.data));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* invitedUserSignup(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.INVITED_USER_SIGNUP, action.payload = action.payload);
    if (resp.status) {
      yield put(setInvitedUserSignUp(resp.data));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* userSocialLoginSignup(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.SOCIAL_LOGIN_SIGNUP, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp.data.payload.token ? resp.data.payload.token : "")
      yield put(setUserSocialLoginSignup(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(onErrorStopLoad())
    yield call(action.payload.cb, action.res = "true")
    // toast.error(e.response.data.msg);
  }
}


function* userOtp(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.OTP_USER, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp.data.payload.token ? resp.data.payload.token : "")
      yield put(setUserOtp(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserOtp({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* ChangePassword(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.CHANGE_PASSWORD, action.payload = action.payload);
    if (resp.status) {
      yield put(setChangePassword(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setChangePassword({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* verifyOtp(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.OTP_VERIFY_USER, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("authToken", resp.data.payload.token ? resp.data.payload.token : "")
      yield put(setUserVerifyOtp(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action?.res?.data?.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserVerifyOtp({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data?.msg);
  }
}
// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* userSignUpStep(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.STEP_USER, action.payload = action.payload);
    if (resp.status) {
      yield put(setSignUpStep(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setSignUpStep({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}



function* SearchCompanyUser(action) {

  try {

    var dataToSend = { ...action.payload }
    delete dataToSend.cb

    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.SEARCH_COMPANY_USER, action.params = { params: dataToSend });

    if (resp.status) {

      yield put(setSearchCompanyUser(resp.data.payload.data));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {

      throw resp
    }
  } catch (e) {
    yield put(setGetAllIndustryGroups({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* SetSubscriptionID(action) {

  try {
    const dataToSend = { ...action.payload }
    delete dataToSend.cb

    const resp = yield call(ApiClient.patch, action.url = ApiPath.AuthApiPath.UPDATE_SUBSCRIPTION_ID, action.params = dataToSend);
    if (resp.status) {
      yield put(subscriptionIDSet(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(subscriptionIDSet());
    yield put(onErrorStopLoad())
    // toast.error(e.response.data.msg);
  }
}




// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* CompanyLogoUpload(action) {
  try {
    const resp = yield call(ApiClient.postFormData, action.url = ApiPath.DashboardApiPath.ATTACHMENT_UPLOAD, action.payload = action.payload);
    if (resp.status) {
      yield put(setStepThreeCompanyLogoUplaod(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setStepThreeCompanyLogoUplaod({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* UserPhotoUpload(action) {
  try {
    const resp = yield call(ApiClient.postFormData, action.url = ApiPath.DashboardApiPath.ATTACHMENT_UPLOAD, action.payload = action.payload);
    if (resp.status) {
      yield put(setUserPhotoUpload(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserPhotoUpload({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* UserProfileUpdate(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = ApiPath.AuthApiPath.EDIT_PROFILE, action.payload = action.payload);
    if (resp.status) {
      yield put(setUserProfileUpdate(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserProfileUpdate({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* UserCompanyProfileUpdate(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.AuthApiPath.COMPANY_EDIT_PROFILE}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setCompanyEditProfile(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setCompanyEditProfile({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}




// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* companyBanner(action) {
  try {
    const resp = yield call(ApiClient.postFormData, action.url = ApiPath.DashboardApiPath.ATTACHMENT_UPLOAD, action.payload = action.payload);
    if (resp.status) {
      yield put(setCompanyBannerUplaod(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setCompanyBannerUplaod({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* industryGroups(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.INDUSTRY_GROUPS, action.payload = action.payload);
    if (resp.status) {
      yield put(setGetAllIndustryGroups(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetAllIndustryGroups({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* industrySubGroups(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.AuthApiPath.INDUSTRY_SUB_GROUPS}${action.payload.id}`);
    if (resp.status) {
      yield put(setGetAllIndustrySubGroups(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetAllIndustrySubGroups({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* ownershipType(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.BUSINESS_OWNERSHIP_TYPE, action.payload = action.payload);
    if (resp.status) {
      yield put(setBusinessOwnershipType(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setBusinessOwnershipType({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* genesisType(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.BUSINESS_GENESIS, action.payload = action.payload);
    if (resp.status) {
      yield put(setBusinessGenesis(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setBusinessGenesis({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* ethnicity(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.ETHNICITY, action.payload = action.payload);
    if (resp.status) {
      yield put(setEthnicity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setEthnicity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* companyListType(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.COMPANY_LIST_TYPE, action.payload = action.payload);
    if (resp.status) {
      yield put(setCompanyListType(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setCompanyListType({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data?.msg);
  }
}
function* CompanyProfileInfo(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.COMPANY_PROFILE_INFORMATION, action.payload = action.payload);
    if (resp.status) {
      yield put(setGetCompanyProfileInfo(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetCompanyProfileInfo({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data?.msg);
  }
}

function* editProfile(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.AuthApiPath.COMPANY_EDIT_PROFILE}${action.payload.company_id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setCompanyEditProfile(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setCompanyEditProfile({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* editUserProfile(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = ApiPath.AuthApiPath.EDIT_PROFILE, action.payload = action.payload);
    if (resp.status) {
      yield put(setEditProfile(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setEditProfile({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* AddCertificate(action) {
  try {
    const resp = yield call(ApiClient.postFormData, action.url = `${ApiPath.AuthApiPath.COMPANY_ADD_CERTIFICATES}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setAddCertificates(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      //  toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAddCertificates({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* DeleteCertificate(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.AuthApiPath.COMPANY_DELETE_CERTIFICATES}?id=${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setDeleteCertificates(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      //  toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setDeleteCertificates({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* CertificateList(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.AuthApiPath.COMPANY_CERTIFICATES_LIST}?company_id=${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setCertificatesList(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      //  toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setCertificatesList({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* userLogout(action) {
  console.log(action, "sdsdfdsf");
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.LOGOUT_USER, action.payload = action.payload);
    if (resp.status) {
      yield call(action.payload.cb, action.res = resp)
      localStorage.removeItem('authToken');
      localStorage.removeItem('persist:root');
      // localStorage.removeItem("oneSignalId")
      // toast.success(action.res.data.msg);
      yield put(setLogout())
    }
    else {
      throw resp
    }
  } catch (e) {
    toast.error(e.response.data.msg);
  }
}

function* updateCompanyLogoBanners(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.AuthApiPath.COMPANY_UPDATE_LOGO_BANNER}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setCompanyUpdateLogoBanner(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setCompanyUpdateLogoBanner({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* userForgetPasswords(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.FORGET_PASSWORD_USER, action.payload = action.payload);
    if (resp.status) {
      yield put(setUserForgetPassword(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserForgetPassword({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* forgetPassswordVerifyOtp(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.FORGET_PASSWORD_VERIFY, action.payload = action.payload);
    if (resp.status) {
      yield put(setForgetPasswodVerifyOtp(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setForgetPasswodVerifyOtp({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* userExistingCertificationsUpdate(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.UPDATE_USER_EXISTING_CERTIFICATIONS, action.payload = action.payload);
    if (resp.status) {
      yield put(setUserExistingCertificationsUpdate(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      //  toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserExistingCertificationsUpdate({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* userTradeMembershipsUpdate(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.UPDATE_USER_TRADE_MEMBERSHIP, action.payload = action.payload);
    if (resp.status) {
      yield put(setUserTradeMembershipsUpdate(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      //  toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserTradeMembershipsUpdate({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* resetPassword(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.RESET_PASSWORD, action.payload = action.payload);
    if (resp.status) {
      yield put(setResetPassword(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setResetPassword({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* updateSettings(action) {
  try {

    const dataToSend = { ...action.payload }
    delete dataToSend.cb

    const resp = yield call(ApiClient.patch, action.url = ApiPath.AuthApiPath.UPDATE_SETTINGS, action.params = dataToSend);
    if (resp.status) {

      yield put(setUpdateSettings(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)

    }
    else {
      throw resp
    }
  } catch (e) {

    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* paymentViaPromoCode(action) {
  try {

    const dataToSend = {...action.payload}
    delete dataToSend.cb

    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.PAYMENT_VIA_PROMO_CODE, action.params = dataToSend);
    
    if (resp.status) {
      yield put(setPaymentViaPromoCode());
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }

  } catch (e) {
    
    console.log(e ,'error')

    yield put(setPaymentViaPromoCode())
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  
  }
}



function* authSaga() {
  yield all([
    takeLatest('auth/userLogin', userLogin),
    takeLatest('auth/userForgetPassword', userForgetPasswords),
    takeLatest('auth/userSiteAccess', userSiteAccess),
    takeLatest('auth/userSignUp', userSignUp),
    takeLatest('auth/invitedUserSignup', invitedUserSignup),
    takeLatest('auth/userSocialLoginSignup', userSocialLoginSignup),
    takeLatest('auth/userOtp', userOtp),
    takeLatest('auth/changePassword', ChangePassword),
    takeLatest('auth/userVerifyOtp', verifyOtp),
    takeLatest('auth/forgetPasswodVerifyOtp', forgetPassswordVerifyOtp),
    takeLatest('auth/resetPassword', resetPassword),
    takeLatest('auth/signUpStep', userSignUpStep),
    takeLatest('auth/stepThreeCompanyLogoUplaod', CompanyLogoUpload),
    takeLatest('auth/companyBannerUplaod', companyBanner),
    takeLatest('auth/getAllIndustryGroups', industryGroups),
    takeLatest('auth/getAllIndustrySubGroups', industrySubGroups),
    takeLatest('auth/businessOwnershipType', ownershipType),
    takeLatest('auth/businessGenesis', genesisType),
    takeLatest('auth/ethnicity', ethnicity),
    takeLatest('auth/companyListType', companyListType),
    takeLatest('auth/getCompanyProfileInfo', CompanyProfileInfo),
    takeLatest('auth/companyEditProfile', editProfile),
    takeLatest('auth/companyUpdateLogoBanner', updateCompanyLogoBanners),
    takeLatest('auth/editProfile', editUserProfile),
    takeLatest('auth/addCertificates', AddCertificate),
    takeLatest('auth/deleteCertificates', DeleteCertificate),
    takeLatest('auth/certificatesList', CertificateList),
    takeLatest('auth/getSessionId', MemberShipGetSessionId),
    takeLatest('auth/updateMemberShipAmount', UpdateMemberShipAmount),
    takeLatest('auth/userPhotoUpload', UserPhotoUpload),
    takeLatest('auth/companyLogoUpload', CompanyLogoUpload),
    takeLatest('auth/userProfileUpdate', UserProfileUpdate),
    takeLatest('auth/userExistingCertificationsUpdate', userExistingCertificationsUpdate),
    takeLatest('auth/userTradeMembershipsUpdate', userTradeMembershipsUpdate),
    takeLatest('auth/userCompanyProfileUpdate', UserCompanyProfileUpdate),
    takeLatest('auth/logout', userLogout),
    takeLatest('auth/setSubscriptionID', SetSubscriptionID),
    takeLatest('auth/searchCompanyUserFun', SearchCompanyUser),
    takeLatest('auth/updateSettings', updateSettings),
    takeLatest('auth/getPaymentViaPromoCode', paymentViaPromoCode)
  ])
}

export default authSaga;

