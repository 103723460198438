import React, { useEffect } from 'react'
import * as Images from "../../../utilities/images";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "PrivacyPolicy";
    }, []);

    return (
        <>
            <section className='privacyPolicy_ mt-5 mb-4'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='memeberHead_ col-md-10 '>
                            <h3 className='memberHead_'>Privacy <strong>Policy</strong></h3>
                        </div>
                    </div>
                    <div className='memberHomeImage_ mt-5 mb-4'>
                        <img className='memberImage' src={Images.privacybanner} />
                        <div className='growBusiness_ mt-5 mb-4'>
                            <ul>
                                <h6 className="privacyHeading">Privacy Policy</h6>
                                <li>Effective May 30, 2023</li>
                                <li>Your Privacy Matters</li>
                                <li>GetOPPS’s mission is to have a profound impact on small businesses of all hues, as well as diversity, equity and inclusion for small businesses in a global economy. The vision is to help large, medium, small and diverse businesses thrive while providing efficiency, organization and exposure to a broader and comprehensive base of suppliers to all businesses looking to buy.  Fundamental to this mission is our commitment to be transparent about the data we collect about your business, how it is used and with whom it is shared.</li>
                                <li>This Privacy Policy applies when your business use our Services (described below).</li>
                                <h6 className="privacyHeading">Introduction</h6>
                                <li>GetOpp$ provides a safe space for buyers and suppliers to create genuine relationships and trust with each other. Currently, many procurement processes for finding suppliers are searching for the human element of connection and accountability and often must rely too heavily on algorithms and checklists. Technology is a great tool, but at the end of the day, people are the value everyone is looking for. In order to tap into the human behind the desktop there needs to be a bridge for connection and communication. GetOpp$ is that bridge. Connecting real people to real opportunities to real businesses, large, medium, small and diverse.</li>
                                <li>Our Privacy Policy applies to any Member or Our registered users (“Members”) share their professional identities, engage with their network, exchange knowledge and professional insights, post and view relevant content, learn and develop skills, and find business opportunities. Content and data on some of our Services is viewable to non-members (“Visitors”).</li>
                                <h6 className="privacyHeading">Services</h6>
                                <li>This Privacy Policy, including our Cookie Policy applies to your business’ use of our Services.</li>
                                <li>This Privacy Policy applies to GetOPPS.com, GetOPPS-branded apps, GetOPPS Learning and other GetOPPS-related sites, apps, communications and services (“Services”), including off-site Services, such as our ad services and the “Apply with GetOPPS” and “Share with GetOPPS” plugins, but excluding services that state that they are offered under a different privacy policy.</li>
                                <li>GetOPPS Company will be the controller of your business’ data provided to, or collected by or for, or processed in connection with, our Services.</li>
                                <li>As a Visitor or Member of our Services, the collection, use and sharing of your business’ data is subject to this Privacy Policy and other documents referenced in this Privacy Policy, as well as updates.</li>
                                <h6 className="privacyHeading">Change</h6>
                                <li>Changes to the Privacy Policy apply to your business’ use of our Services after the “effective date.”</li>
                                <li>GetOPPS (“we” or “us”) can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide your business the opportunity to review the changes before they become effective. If your business objects to any changes, your business may close their account.</li>
                                <li>Your business acknowledges that your business’ continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your business’ data is subject to the updated Privacy Policy, as of its effective date.</li>
                                <h6 className="privacyHeading">Registration</h6>
                                <li>To create an account Your business needs to provide data including your business name, email address and/or website, and a password. If Your business registers for a premium Service, Your business will need to provide payment (e.g., credit card) and billing information.</li>
                                <li>Your business creates your GetOPPS profile (a complete profile helps your business get the most from our Services).</li>
                                <h6 className="privacyHeading">Profile</h6>
                                <li>Your business will have choices about the information on your business profile, such as education, work experience, skills, photo, city or area and endorsements. Your business doesn't have to provide additional information on your business profile; however, profile information helps your business to get more from our Services, including helping buyers and other businesses find your business. It’s your business’ choice whether to include sensitive information on your business profile and to make that sensitive information available to other members.</li>
                                <h6 className="privacyHeading">Posting and Uploading</h6>
                                <li>We collect  data from your business when your business provides, posts or uploads it to our Services, such as when your business fills out a form, (e.g., with demographic data), respond to a survey, or submit an opportunity or fill an application on our Services.</li>
                                <li>Your business doesn't have to post or upload  data; though if your business doesn't, it may limit your business’ ability to grow and engage with your business’ network over our Services.</li>
                                <h6 className="privacyHeading">Data From Others</h6>
                                <li>Others may post or write about your business.</li>
                                <h6 className="privacyHeading">Content and News</h6>
                                <li>your business and others may post content that includes information about your business (as part of articles, posts, comments, videos) on our Services.</li>
                                <h6 className="privacyHeading">Service Use </h6>
                                <li>We log your business’ visits and use of our Services, including mobile apps.</li>
                                <li>We log usage data when your business visits or otherwise use our Services, including our sites, app and platform technology, such as when your business views or clicks on content (e.g., learning video) or ads (on or off our sites and apps), perform a search, install or update one of our mobile apps, share articles or apply for jobs. We use log-ins, cookies, device information and internet protocol (“IP”) addresses to identify your business and log your business’ use. </li>
                                <h6 className="privacyHeading">Cookies and Similar Technologies</h6>
                                <li>We collect data through cookies and similar technologies.</li>
                                <li>We use cookies and similar technologies (e.g., pixels and ad tags) to collect data (e.g., device IDs) to recognize your business and your business device(s) on, off and across different services and devices where your business have engaged with our Services.
                                    We use cookies and similar technologies (e.g., pixels and ad tags) to collect data (e.g., device IDs) to recognize your business and your business device(s) on, off and across different services and devices where your business have engaged with our Services. </li>
                                <h6 className="privacyHeading">Your business Device and Location</h6>
                                <li>We receive data through cookies and similar technologies</li>
                                <li>When your business visits or leaves our Services (including some plugins and our cookies or similar technology on the sites of others), we receive the URL of both the site your business came from and the one your business goes to and the time of your visit. We also get information about your business network and device (e.g., IP address, proxy server, operating system, web browser and add-ons, device identifier and features, cookie IDs and/or ISP, or your business mobile carrier). If your business uses our Services from a mobile device, that device will send us data about your business location based on your business phone settings. We will ask your business to opt-in before we use GPS or other tools to identify your business precise location.</li>
                                <h6 className="privacyHeading">Messages</h6>
                                <li>If your business communicates through our Services, we learn about that.</li>
                                <li>We collect information about your business when your business sends, receives, or engages with messages in connection with our Services. For example, if your business gets a GetOPPS connection request, we track whether your business has acted on it and will send your business reminders. We also use automatic scanning technology on messages to support and protect our site. For example, we use this technology to suggest possible responses to messages and to manage or block content that violates our User Agreement or Professional Community Policies from our Services.</li>
                                <h6 className="privacyHeading">Sites and Services of Others</h6>
                                <li>We get data when your business visits sites that include our ads, cookies or some of our plugins or when your business log-in to others’ services with your business GetOPPS account.</li>
                                <li>We receive information about your visits and interaction with services provided by others when you log-in with GetOPPS or visit others’ services that include some of our plugins (such as “Apply with GetOPPS”) or our ads, cookies or similar technologies.</li>
                                <h6 className="privacyHeading">Other</h6>
                                <li>We are improving our Services, which means we get new data and create new ways to use data.</li>
                                <li>Our Services are dynamic, and we often introduce new features, which may require the collection of new information. If we collect materially different  data or materially change how we collect, use or share your data, we will notify you and may also modify this Privacy Policy.</li>
                                <h6 className="privacyHeading">How We Use Your Data  </h6>
                                <li>We use your data to provide, support, customize and develop our Services.</li>
                                <li>How we use your data will depend on which Services your business uses, how your business uses those Services and the choices your business makes in your business’ settings. We use the data that we have about your business to provide and customize our Services, including with the help of automated systems and inferences we make, so that our Services (including ads) can be more relevant and useful to your business and others.</li>
                                <h6 className="privacyHeading">Services</h6>
                                <li>Our Services help your business connect with others, find and be found for work and business opportunities, stay informed, get training and be more productive.</li>
                                <li>We use your business’ data to authorize access to our Services and honor your business’ settings.</li>
                                <h6 className="privacyHeading">Stay Connected</h6>
                                <li>Our Services allow your business to stay in touch and up to date with businesses, partners, clients, and other professional contacts. To do so, your business can “connect” with the businesses who your business chooses, and who also wish to “connect” with your business. Subject to your business and their settings, when your business connects with other Members, your business will be able to search each others’ connections in order to exchange professional opportunities.</li>
                                <li>We use data about your business (such as your business’ profile, profiles your business have viewed or data provided through address book uploads or partner integrations) to help others find your business profile, suggest connections for your business and others (e.g. Members who share your business contacts or job experiences) and enable you to invite others to become a Member and connect with your business. your business can also opt-in to allow us to use your business’ precise location or proximity to others for certain tasks (e.g. to suggest other nearby Members for your business to connect with, calculate the commute to a new job, or notify your business’ connections that your business are at a professional event).</li>
                                <li>It is your business choice whether to invite someone to our Services, send a connection request, or allow another Member to become your business connection. When your business invites someone to connect with your business, your business invitation will include your business’ network and basic profile information (e.g., name, profile photo, job title, region). We will send invitation reminders to the business your business invited. your business can choose whether or not to share your business own list of connections with your business’ connections.</li>
                                <h6 className="privacyHeading">Visitors have choices about how we use their data.</h6>
                                <h6 className="privacyHeading">Stay Informed</h6>
                                <li>Our Services allow your business to stay informed about news, events and ideas regarding professional topics your business cares about, and from professionals your business respects. Our Services also allow your business to improve your business professional skills, or learn new ones. We use the data we have about your business (e.g., data your business provides, data we collect from your business engagement with our Services and inferences we make from the data we have about your business), to customize our Services for your business, such as by recommending or ranking relevant content and conversations on our Services. We also use the data we have about your business to suggest skills your business could add to your business’ profile and skills that your business might need to pursue your business’ next opportunity. So, if your business lets us know that your business are interested in a new skill (e.g., by watching a learning video), we will use this information to customize content in your business feed, suggest that your business follow certain members on our site, or suggest related learning content to help your business towards that new skill. We use your business content, activity and other data, including your business name and photo, to provide notices to your business’ network and others. For example, subject to your business’ settings, we may notify others that your business’ have updated your business’ profile, posted content, took a social action, used a feature, made new connections or were mentioned in the news.</li>
                                <h6 className="privacyHeading">Productivity</h6>
                                <li>Our Services allows your business to search for potential clients, customers, partners and others to do business with. Our Services allow your business to communicate with other Members and schedule and prepare meetings with them. </li>
                                <h6 className="privacyHeading">Communications</h6>
                                <li>We contact your business and enable communications between Members. We offer settings to control what messages your business receives and how often your business receives some types of messages.</li>
                                <li>We will contact your business through email, mobile phone, notices posted on our websites or apps, messages to your business’ GetOPPS inbox, and other ways through our Services, including text messages and push notifications. We will send your business messages about the availability of our Services, security, or other service-related issues. We also send messages about how to use our Services, network updates, reminders, job suggestions and promotional messages from us and our partners. your business may change your business communication preferences at any time. Please be aware that your business cannot opt out of receiving service messages from us, including security and legal notices.</li>
                                <li>We also enable communications between your business and others through our Services, including for example invitations, InMail, groups and messages between connections.</li>
                                <h6 className="privacyHeading">2.4 Advertising</h6>
                                <li>We serve your business tailored ads both on and off our Services. We offer your business choices regarding customized ads, but your business cannot opt-out of seeing other ads.</li>
                                <li>We target (and measure the performance of) ads to Members, Visitors and others both on and off our Services directly or through a variety of partners, using the following data, whether separately or combined:</li>
                                <h6 className="privacyHeading">Marketing</h6>
                                <li>We promote our Services to your business and others.</li>
                                <li>In addition to advertising our Services, we use Members’ data and content for invitations and communications promoting membership and network growth, engagement and our Services, such as by showing your business’ connections that your business has used a feature on our Services.</li>
                                <li>Developing Services and Research</li>
                                <li>We develop our Services and conduct research</li>
                                <h6 className="privacyHeading">Service Development</h6>
                                <li>We use data, including public feedback, to conduct research and development for our Services in order to provide your business and others with a better, more intuitive and customized experience, drive membership growth and engagement on our Services, and help connect professionals to each other and to economic opportunity.</li>
                                <h6 className="privacyHeading">Other Research</h6>
                                <li>We seek to create economic opportunities for Members of the global economic landscape and to help them be more productive and successful. We use the  data available to us to research social, economic and workplace trends, such as contract availability and skills needed for these opportunities and policies that help bridge the gap in various industries and geographic areas. In some cases, we work with trusted third parties to perform this research, under controls that are designed to protect your business privacy. We publish or allow others to publish economic insights, presented as aggregated data rather than  data.</li>
                                <h6 className="privacyHeading">Surveys</h6>
                                <li>Polls and surveys are conducted by us and others through our Services. Your business is not obligated to respond to polls or surveys, and your business has choices about the information your business provides. Your business may opt-out of survey invitations.</li>
                                <h6 className="privacyHeading">Customer Support</h6>
                                <li>We use data to help your business and fix problems.</li>
                                <li>We use data (which can include your business communications) to investigate, respond to and resolve complaints and for Service issues (e.g., bugs).</li>
                                <li>Insights That Do Not Identify Your business</li>
                                <li>We use data to generate insights that do not identify your business.</li>
                                <li>We use your business data to produce and share insights that do not identify your business. For example, we may use your business data to generate statistics about our members, their profession or industry, to calculate ad impressions served or clicked on, or to publish visitor demographics for a Service or create demographic workforce insights.</li>
                                <li>We use data for security, fraud prevention and investigations.</li>
                                <li>We use your business data (including your business communications) for security purposes or to prevent or investigate possible fraud or other violations of our User Agreement and/or attempts to harm our Members, Visitors or others.</li>
                                <li>Any data that your business includes on your business’ profile and any content your business’ posts or social actions (e.g., likes, follows, comments, shares) your business take on our Services will be seen by others, consistent with your business settings.</li>
                                <h6 className="privacyHeading">Profile</h6>
                                <li>Your business’ profile is fully visible to all Members and customers of our Services. </li>
                                <li>Posts, Likes, Follows, Comments, Messages Our Services allow viewing and sharing information including through posts, likes, follows and comments.</li>
                                <li>When your business shares an article or a post (e.g., an update, image, video or article) it can be viewed by all members on the platform. Members, Visitors and others will be able to find and see your business’ shared content, including your business name (and photo if your business has provided one).</li>
                                <li>When your business like or comment on another’s content (including ads), others will be able to view these “social actions” and associate it with your business (e.g., your business name, profile and photo if your business provided it).
                                </li>
                                <h6 className="privacyHeading">Additional Users
                                </h6>
                                <li>Your business employer may offer you access to GetOPPS Services. Your employer can review and manage your use of such Services.
                                </li>
                                <li>Depending on the Service Membership level, before you use such Service, we will ask for permission to share with your business employer relevant data from your business profile.
                                </li>
                                <h6 className="privacyHeading">Related Services
                                </h6>
                                <li>We share your business’ data across our different Services and GetOPPS affiliated entities.
                                </li>
                                <li>We will share your business’ data with our affiliates to provide and develop our Services. We may combine information internally across the different Services covered by this Privacy Policy to help our Services be more relevant and useful to your business and others. For example, we may customize your business feed or job recommendations based on your business learning history.
                                </li>
                                <h6 className="privacyHeading">Service Providers
                                </h6>
                                <li>We may use others to help us with our Services.
                                </li>
                                <li>We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud detection, marketing and development). They will have access to your business information as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.
                                </li>
                                <h6 className="privacyHeading">Legal Disclosures
                                </h6>
                                <li>We may need to share your business data when we believe it’s required by law or to help protect the rights and safety of your business, us or others.
                                </li>
                                <li>It is possible that we will need to disclose information about your business when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with your business; (3) investigate and defend ourselves against any third-party claims or allegations; (4) protect the security or integrity of our Services (such as by sharing with companies facing similar threats); or (5) exercise or protect the rights and safety of GetOPPS, our Members, personnel or others. We attempt to notify Members about legal demands for their  data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but we do not promise to challenge every demand. To learn more see our Data Request Guidelines and Transparency Report.
                                </li>
                                <h6 className="privacyHeading">Change in Control or Sale
                                </h6>
                                <li>We may share your business’ data when our business is sold to others, but it must continue to be used in accordance with this Privacy Policy.
                                </li>
                                <li>We can also share your business’ data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your business’ data, but only in the manner set out in this Privacy Policy unless your business agrees otherwise.
                                </li>
                                <h6 className="privacyHeading">Data Retention
                                </h6>
                                <li>We keep most of your business’ data for as long as your business account is open.
                                </li>
                                <li>We generally retain your business’ data as long as your business keeps your business’ account open or as needed to provide your business’ services. This includes data your business or others provided to us and data generated or inferred from your business use of our Services. Even if your business only uses our Services when looking for a new job every few years, we will retain your business information and keep your business’ profile open, unless your business closes your business account. In some cases we choose to retain certain information (e.g., insights about Services use) in a depersonalized or aggregated form.
                                </li>
                                <li>Rights to Access and Control Your business Data
                                    Your business can access or delete your business’ data.
                                    For data that we have about you, you can:
                                    Delete Data: Your business can ask us to erase or delete all or some of your business  data (e.g., if it is no longer necessary to provide Services to your business).</li>
                                <li>Change or Correct Data: Your business can edit some of your business data through your business account. Your business can also ask us to change, update or fix your business data in certain cases, particularly if it’s inaccurate.
                                </li>
                                <li>Right to Access and/or Take Your Business’ Data: Your business can ask us for a copy of your business’ data and can ask for a copy of  data your business provided in machine readable form.
                                </li>
                                <h6 className="privacyHeading">Account Closure
                                </h6>
                                <li>We keep some of your business’ data even after your business closes your business’ account.
                                </li>
                                <li>If your business chooses to close your business GetOPPS account, your business data will generally stop being visible to others on our Services within 24 hours. We generally delete closed account information within 30 days of account closure, except as noted below.
                                </li>
                                <li>We retain your business data even after your business have closed your business’ account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse (e.g., if we have restricted your business account for breach of our Professional Community Policies), enforce our User Agreement, or fulfill your business request to "unsubscribe" from further messages from us. We will retain de-ized information after your business account has been closed.
                                </li>
                                <li>Information your business has shared with others (e.g., through InMail, updates or group posts) will remain visible after your business closes your business account or deletes the information from your business own profile or mailbox, and we do not control data that other Members have copied out of our Services. Groups content and ratings or review content associated with closed accounts will show an unknown user as the source. Your business’ profile may continue to be displayed in the services of others (e.g., search engine results) until they refresh their cache.
                                </li>
                                <h6 className="privacyHeading">Security</h6>
                                <li>We monitor for and try to prevent security breaches. Please use the security features available through our Services.
                                </li>
                                <li>We implement security safeguards designed to protect your business’ data, such as HTTPS. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that your business sends us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.
                                </li>
                                <h6 className="privacyHeading">Cross-Border Data Transfers</h6>
                                <li>We store and use your business data outside your business country.
                                </li>
                                <li>We process data both inside and outside of the United States and rely on legally-provided mechanisms to lawfully transfer data across borders. Learn more. Countries where we process data may have laws which are different from, and potentially not as protective as, the laws of your business own country.
                                </li>
                                <h6 className="privacyHeading">Lawful Bases for Processing</h6>
                                <li>We have lawful bases to collect, use and share data about your business. Your business has choices about our use of your business data. At any time, your business can withdraw consent your business has provided by going to settings.
                                </li>
                                <li>We will only collect and process  data about your business where we have lawful bases. Lawful bases include consent(where your business has given consent), contract (where processing is necessary for the performance of a contract with your business (e.g., to deliver the GetOPPS Services your business has requested) and “legitimate interests.” Learn more.
                                </li>
                                <li>Where we rely on your business consent to process  data, your business has the right to withdraw or decline your business consent at any time and where we rely on legitimate interests, your business has the right to object. Learn More. If your business has any questions about the lawful bases upon which we collect and use your business’ data, please contact our Data Protection Officer here.
                                </li>
                                <li>Direct Marketing and Do Not Track Signals
                                </li>
                                <li>Our statements regarding direct marketing and “do not track” signals.
                                </li>
                                <li>We currently do not share  data with third parties for their direct marketing purposes.
                                </li>
                                <h6 className="privacyHeading">Contact Information
                                </h6>
                                <li>Your business can contact us or use other options to resolve any complaints.
                                </li>
                                <li>If your business has questions or complaints regarding this Policy, please first contact GetOPPS online. Your business can also reach us by physical mail.
                                </li>











                            </ul>
                            {/* <p className='termspara_'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</li>
                            <p className='termspara_'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</li>
                            <p className='termspara_'> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</li>
                            <p className='termspara_'> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</li>
                            <p className='termspara_'> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non- characteristic words etc.</li>
                            <p className='termspara_'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</li>
                            <p className='termspara_'> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</li>
                            <p className='termspara_'> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</li>
                            <p className='termspara_'> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</li>
                            <p className='termspara_'> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non- characteristic words etc.</li> */}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy