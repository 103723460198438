
import React, { useState } from 'react';


const Payment = () => {
    const [cardNumber, setCardNumber] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCvc] = useState('');


    return (
        <>
            {/* // <!-- CREATE THE HTML FOR THE PAYMENT PAGE --> */}



            {/* <div><input type="text" id="card-number" class="input-field" title="card number" aria-label="enter your card number" value="" tabindex="1"  /></div>
            <div><input type="text" id="expiry-month" class="input-field" title="expiry month" aria-label="two digit expiry month" value="" tabindex="2"  /></div>
            <div><input type="text" id="expiry-year" class="input-field" title="expiry year" aria-label="two digit expiry year" value="" tabindex="3"  /></div>
            <div><input type="text" id="security-code" class="input-field" title="security code" aria-label="three digit CCV security code" value="" tabindex="4"  /></div>
            <div><input type="text" id="cardholder-name" class="input-field" title="cardholder name" aria-label="enter name on card" value="" tabindex="5"  /></div>
            <div><button id="payButton"onclick="pay('card');">Pay Now</button></div> */}
            <div className=''>
                <div></div>
                {/* <h3>Credit Card</h3> */}
                <div className='form-group col-md-12 mb-3'>
                    <label className='postopportunity_label'>Card Number: </label>
                    <input type="text" className='customFormControl' placeholder='Karl Tombak' />
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <label className='postopportunity_label'>Expiry Month:</label>
                    <input type="text" className='customFormControl' placeholder='Karl Tombak' />
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <label className='postopportunity_label'>Expiry Year:</label>
                    <input type="text" className='customFormControl' placeholder='Karl Tombak' />
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <label className='postopportunity_label'>Security Code:</label>
                    <input type="text" className='customFormControl' placeholder='Karl Tombak' />
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <label className='postopportunity_label'>Cardholder Name:</label>
                    <input type="text" className='customFormControl' placeholder='Karl Tombak' />
                </div>
                <div>
                    <button id="payButton" onclick="pay('card');">Pay Now</button>
                </div>
            </div>

        </>

    )
}
export default Payment