import { all, call, put, takeLatest } from 'redux-saga/effects'
import { setDeleteUserInfo, setPatchUserInfo, setPostUserInfo, setPutUserInfo, setUserInfo } from '../../slices/user';
import { ApiClient } from '../../../utilities/api';
import ApiPath from '../../../constants/apiPath';

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* fetchUser(action) {
  try {
    const params = {
      page: 1
    }
    const resp = yield call(ApiClient.get, action.url = ApiPath.GET_USER, action.params = params);
    yield put(setUserInfo(resp.data));
  } catch (e) {
    yield put(setUserInfo({}));
  }
}

function* updateUser(action) {
  try {
    // const params = {
    //   page: 1
    // }
    const resp = yield call(ApiClient.put, action.url = ApiPath.PUT_USER, action.payload = action.payload);
    yield put(setPutUserInfo(resp.data));
  } catch (e) {
    yield put(setPutUserInfo({}));
  }
}

function* userCreate(action) {
  try {
    // const resp = yield call(ApiClient.post, action.url = `${ApiPath.CREATE_USER}/name=${action.payload.name}`);
    const resp = yield call(ApiClient.post, action.url = ApiPath.CREATE_USER, action.payload = action.payload);
    yield put(setPostUserInfo(resp.data));
  } catch (e) {
    yield put(setPostUserInfo({}));
  }
}

function* patchUser(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = ApiPath.PATCH_USER, action.payload = action.payload);
    yield put(setPatchUserInfo(resp.data));
  }
  catch (e) {
    yield put(setPatchUserInfo({}));
  }
}

function* deleteUser(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = ApiPath.DELETE_USER, action.payload = action.payload);
    yield put(setDeleteUserInfo(resp.data))
  }
  catch (e) {
    yield put(setDeleteUserInfo({}))
  }
}

function* userSaga() {
  yield all([
    takeLatest('user/getUserInfo', fetchUser),
    takeLatest('user/putUserInfo', updateUser),
    takeLatest('user/postUserInfo', userCreate),
    takeLatest('user/patchUserInfo', patchUser),
    takeLatest('user/deleteUserInfo', deleteUser)
  ])
}

export default userSaga;