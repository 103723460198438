const ApiPath = {
    GET_USER: '/api/users',
    PUT_USER: '/api/users/2',
    CREATE_USER: '/api/users',
    PATCH_USER: '/api/users/2',
    DELETE_USER: '/api/users/2',

    AuthApiPath: {
        LOGIN_USER: '/api/v1/users/login',
        FORGET_PASSWORD_USER: '/api/v1/users/forgot/password',
        FORGET_PASSWORD_VERIFY: '/api/v1/users/forgot/password/verify/otp',
        RESET_PASSWORD: '/api/v1/users/reset/password',
        SIGNUP_USER: '/api/v1/users/create',
        INVITED_USER_SIGNUP: '/api/v1/users/encrypted/user/data',
        OTP_USER: '/api/v1/users/verify-otp',
        CHANGE_PASSWORD: '/api/v1/users/change/password',
        OTP_VERIFY_USER: '/api/v1/users/resend-otp',
        SOCIAL_LOGIN_SIGNUP: '/api/v1/users/social/login-signup',
        LOGOUT_USER: '/api/v1/users/logout',
        STEP_USER: '/api/v1/company_information/add',
        SITE_ACCESS: '/api/v1/users/site_access',
        INDUSTRY_GROUPS: '/api/v1/industries',
        INDUSTRY_SUB_GROUPS: '/api/v1/industries/sub_industries/',
        BUSINESS_OWNERSHIP_TYPE: '/api/v1/business_ownership_type',
        COMPANY_LIST_TYPE: '/api/v1/company_information/get/all',
        EDIT_PROFILE : '/api/v1/user_profiles/',
        COMPANY_EDIT_PROFILE: '/api/v1/user_profiles/company/profile/',
        COMPANY_UPDATE_LOGO_BANNER: '/api/v1/user_profiles/company/logo/banner/',
        COMPANY_PROFILE_INFORMATION:'/api/v1/users/info',
        COMPANY_ADD_CERTIFICATES:'/api/v1/company_certificates/upload/',
        COMPANY_DELETE_CERTIFICATES:'/api/v1/company_certificates/',
        COMPANY_CERTIFICATES_LIST:'/api/v1/company_certificates/by/company/id/',
        MEMBERSHIP_GET_SESSION_ID: '/api/v1/payment/get/session/id',
        UPDATE_MEMBERSHIP_AMOUNT: '/api/v1/payment/update/session',
        UPDATE_SUBSCRIPTION_ID: '/api/v1/users/update/one/signal/details',
        SEARCH_COMPANY_USER: '/api/v1/users/search/all',
        UPDATE_USER_EXISTING_CERTIFICATIONS:'/api/v1/common/existing/certification/data',
        UPDATE_USER_TRADE_MEMBERSHIP:'/api/v1/common/membership/data',
        BUSINESS_GENESIS:"/api/v1/common/business/genesis",
        ETHNICITY:"/api/v1/common/ethnicity", 
        UPDATE_SETTINGS:"/api/v1/user_settings",
        PAYMENT_VIA_PROMO_CODE:"/api/v1/users/promo/code/payment"
    }, 

    DashboardApiPath: {
        GET_ALL_POST: '/api/v1/post',
        GET_ALL_DASHBOARD_DATA: '/api/v1/dashboard/index',
        ADD_POST: '/api/v1/post/add',
        EDIT_POST:'/api/v1/post/update',
        LIKE_POST: '/api/v1/post/like',
        POST_LIKE_USER_LIST : '/api/v1/post/like/list',
        COMMENT_POST: '/api/v1/post/comment',
        ATTACHMENT_UPLOAD: '/api/v1/user_photos/upload',
        POST_ALL_COMMENT_LIST: 'api/v1/post/get_comments',
        NESTED_COMMENT_LIST:'api/v1/post/nested_comments',
        LIKE_COMMENT: '/api/v1/comment_like',
        DASHBOARD_POST_OPPORTUNITY: '/api/v1/post_opportunity/my',
        POST_FEATURE: 'api/v1/post/feature',
        PENDING_ENDORSEMENT: 'api/v1/endorsement/endorsement-list-to-approve',
        UPDATE_STATUS_ENDORSEMENT: '/api/v1/endorsement/update-status',
        ALL_USER_WITH_SEARCH: `/api/v1/users/all`,
        USER_UPDATE_PROFILE_STATUS: '/api/v1/users/update/status',
        GET_SINGLE_POST:'/api/v1/post/by_id'
    },

    NotificationApiPath: {
        GET_ALL_NOTIFICATION: '/api/v1/notifications',
    },

    ConnectionApiPath: {
        GET_CONNECTION_LIST: '/api/v1/connections',
        ADD_CONNECTION: '/api/v1/connections/add',
        PENDING_CONNECTION: '/api/v1/connections/pending_connections',
        UPDATE_CONNECTION: '/api/v1/connections/update/request_status/',
        FOLLOW_CONNECTION: '/api/v1/connection_following/follow',
        UNFOLLOW_CONNECTION: '/api/v1/connection_following/unfollow/',
        CANCEL_CONNECTION_REQUEST: '/api/v1/connections/request/withdraw/',
        UNFRIEND_CONNECTION_REQUEST: '/api/v1/connections/unfriend/',
    },
    CertificationApiPath: {
        DIVERSITY_CERTIFICATION:"/api/v1/user_diversity_certification",
        GET_DIVERSITY_CERTIFICATION:"/api/v1/user_diversity_certification",
        DIVERSITY_OWNED:"/api/v1/common/diversity/owned/business"
    },



    AdvanceSearchApiPath: {
        ADVANCE_SEARCH: '/api/v1/users/advance_search',
        USER_DETAILS: '/api/v1/users/user_details/',
        DRIVERS_SUPPLIER_DESCRIPTION:'/api/v1/common/description/data',
        DRIVERS_SUPPLIER_CERTIFICATION:'/api/v1/common/existing/certification/data',
        TRADE_MEMBERSHIP:'/api/v1/common/membership/data',
        GET_SEARCH:'/api/v1/advance_search_data',
        SAVE_SEARCH:'/api/v1/advance_search_data',
        DELETE_SEARCH:'/api/v1/advance_search_data'
    },

    PostOpportunityApiPath: {
        POST_OPPORTUNITY: '/api/v1/post_opportunity',
        MY_POST_OPPORTUNITY: '/api/v1/post_opportunity/my',
        UPDATE_OPPORTUNITY: '/api/v1/post_opportunity/update/',
        GET_OPPORTUNITY: '/api/v1/post_opportunity/by/id/',
        ACTIVE_INACTIVE_OPPORTUNITY: '/api/v1/post_opportunity/update/active/',
        DELETE_OPPORTUNITY: '/api/v1/post_opportunity/delete/',
        DELETE_REVIEW_RESPONSE_OPPORTUNITY: '/api/v1/post_opportunity/delete/application/',
        
        APPLY_OPPORTUNITY:'/api/v1/post_opportunity/apply',
        REVIEW_OPPORTUNITY_APPLICATION:'/api/v1/post_opportunity/applications/',
        VIEW_DETAILS_POST_OPPORTUNITY:'/api/v1/post_opportunity/by/id/',
        OPPORTUNITY_APPLICATION_LIST:'/api/v1/post_opportunity/opportunity_application_list',
        OPPORTUNITY_REVIEW_RESPONDENTS: '/api/v1/post_opportunity/applications',

        ArchiveApiPath: {
            ARCHIVE_OPPERTUNITIES: '/api/v1/post_opportunity/update/archive/',
            GET_MY_ARCHIVE_OPPERTUNITIES: '/api/v1/post_opportunity/my/archived'
        },

        RecentlyDeleteApiPath: {
            RECENTLY_DELETE_OPPORTUNITY: '/api/v1/post_opportunity/get/deleted',
            PERMANENTLY_DELETE: 'api/v1/post_opportunity/permanent/delete',
            UNDELETE_ACTION: 'api/v1/post_opportunity/recover/opportunity',
        },
        GET_CURRIENCIES:'/api/v1/currency'
    },

    DocumentApiPath: {
        GET_MY_DOCUMENTS: '/api/v1/user_document/my',
        DELETE_DOCUMENTS: '/api/v1/user_document/delete/',
        UPLOAD_DOCUMENTS: '/api/v1/user_document/upload',

        ArchiveApiPath: {
            UPDATE_ARCHIVE_DOCUMENT: '/api/v1/user_document/update/archive/',
            GET_ARCHIVE_DOCUMENT: '/api/v1/user_document/archive',
        },
        
        RecentlyDeleteApiPath: {
            RECENTLY_DELETE_DOCUMENTS: '/api/v1/user_document/get/deleted',
        }
    },

    CompanyInformationApiPath: {
        COMPANY_INFORMATION: '/api/v1/company_information/by/id/',
        CREATE_ENDROSEMENT: '/api/v1/endorsement/'
    },

    MessagePath:{
        GET_LABELS: '/api/v1/labels',
        ADD_LABELS: '/api/v1/labels/',
        GET_CHAT_HEAD: '/api/v1/chat/head',
        GET_CONVERSATION: '/api/v1/chat/info/',
        GET_ALL_COMPANY_INFORMATION: '/api/v1/company_information/get/',
        CREATE_DRAFT:'/api/v1/chat_draft',
        GET_CHAT_DRAFT:'/api/v1/chat_draft',
        DELETE_MESSAGE:'/api/v1/chat/info/',
        DELETE_CHAT:'/api/v1/chat/head/',
        GET_TRASH_CHAT:'/api/v1/chat/head/deleted',
        CHAT_TRASH_UPDATE:'/api/v1/chat/update/status/'

    },
    SuggestionApiPath:{
        ADD_SUGGESTION: '/api/v1/suggestion/',
    },
    ContactUsApiPath:{
        ADD_CONTACT_US: '/api/v1/users/contact/us'
    },
    SupportApiPath:{
        ADD_SUPPORT: '/api/v1/users/support'
    },
    Payments:{
        GET_PAYMENT_SESSION: '/api/v1/payment/get/session/id',
        GET_PAYMENT_SESSION_UPGRADE_DEGRADE: '/api/v1/payment/get/session/id/upgrade/degrade',
        VERIFY_SESSION:'/api/v1/payment/verify',
        GET_MEMBERSHIP_PLANS:'/api/v1/payment_plans'
    },
    ManageUsers:{
        ADD_MANAGE_USER: '/api/v1/users/send-invitation',
        GET_MANAGE_USER_LIST: '/api/v1/users/my/company/users',
        DELETE_MANAGE_USER: 'api/v1/users/delete/company/user'
    }

}

export default ApiPath;
