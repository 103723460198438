import React from 'react'

const WaitList = () => {
    return (
        <>
            <div className=''>
                <form >
                    <div className="row">
                        <div className="form-group col-md-12 mb-3">
                            <label className="headingSmall_ mb-2" for="">Company Name</label>
                            <input type="text" className="customFormControl" placeholder='' />
                        </div>
                        <div className="form-group col-md-12 mb-3">
                            <label className="headingSmall_ mb-2" for="">Company</label>
                            <input type="text" className="customFormControl" placeholder='' />
                        </div>
                        <div className='form-group col-md-12 mb-3'>
                            <p className="headingSmall_ mb-3" for="">Are you buyer or a supplier?</p>
                            <div className='customRadioBox'>
                                <p className='radio'>
                                    <input type="radio" id="test1" name="radio-group"/>
                                        <label for="test1">Apple</label>
                                </p>
                                <p className='radio'> 
                                    <input type="radio" id="test2" name="radio-group"/>
                                        <label for="test2">Peach</label>
                                </p>
                                <p className='radio'>
                                    <input type="radio" id="test3" name="radio-group"/>
                                        <label for="test3">Orange</label>
                                </p>
                            </div>
                        </div>
                        <div className="form-group col-md-12 mb-4">
                            <label className="headingSmall_ mb-2" for="">Email Address</label>
                            <input type="text" className="customFormControl" placeholder='' />
                        </div>
                        <div className="form-group mb-3">
                            <button className="primaryBtn">Add me to the waitlist<i className="las la-angle-right"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default WaitList