import { io } from 'socket.io-client';
import BaseUrl from '../../constants/baseUrl';
import store from '../../redux';

const userId = store.getState().auth?.userInfo?.user?.id;    

export const socketInstance =  io(BaseUrl.SOCKET_URL, {
    path: "/api/v1/connect",
    query:  {userId} ,
});
