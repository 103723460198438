import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as Images from "../../../utilities/images";
import { useRecentlyDeleteOpportunitySelector } from "../../../redux/selector/recentlyDeleteOpportunity";
import { permanentlyDelete, recentlyDeleteOpportunity, undeleteAction } from '../../../redux/slices/opportunity/recentlyDeleteOpportunity';
import ReactPaginate from "react-paginate";
import swal from 'sweetalert';

const RecentlyDelete = (props) => {
    const dispatch = useDispatch();
    const recentlyDeleteOpportunitySelector = useRecentlyDeleteOpportunitySelector();
    const [recentlyDelete, setRecentlyDelete] = useState('');
    const [pageCount, setPageCount] = useState(1);


    // API for get Recently Delete Opportunity list........................
    const getRecentlyDeleteOppertunities = (page = 1, limit = 10) => {
        let params = {
            page: page,
            limit: limit,
        }
        dispatch(recentlyDeleteOpportunity({
            ...params, cb(res) {
                if (res.status) {
                    setRecentlyDelete(res?.data?.payload?.data)
                    setPageCount(res?.data?.payload?.total_pages)
                }
            }
        }))
    }

    //on change page pagination
    const handlePageClick = (e) => {
        getRecentlyDeleteOppertunities(e.selected + 1);
    };

    // permanetly delete action
    const permanetlyDeleteAction = (id) => {
        swal({
            text: `Are you sure want to permanently remove  this opportunity?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    const params = {
                        id: id
                    }
                    dispatch(permanentlyDelete({
                        ...params, cb(res) {
                            if (res.status) {
                                setPageCount(1)
                                getRecentlyDeleteOppertunities()
                                
                            }
                        }
                    }))
                }
            })

    }

     // undelete action
     const UndeleteAction = (id) => {
        swal({
            text: `Are you sure want to recover this opportunity?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    const params = {
                        id: id
                    }
                    dispatch(undeleteAction({
                        ...params, cb(res) {
                            if (res.status) {
                                props.setActiveTab("activeOpportunity");
                            }
                        }
                    }))
                }
            })

    }



    useEffect(() => {
        getRecentlyDeleteOppertunities()
        document.title = "opportunities";
    }, []);

    return (
        <>
            <div className='activeOpportunity_'>
                <h3 className="headingTitle_ mb-4">Recently Deleted</h3>
                {recentlyDeleteOpportunitySelector.loading ?
                    <>
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {recentlyDelete?.length ?
                            <>
                                {recentlyDelete?.map((data, index) => {
                                    return (
                                        <>
                                            <div className='postContainerArea recentlyDeleted' key={index}>
                                                <div className='posts align-items-center '>
                                                    <div className='leftOpportunitycontent'>
                                                        <p className='postHours_ mb-2'>{moment(data?.created_at).fromNow()}</p>
                                                        <h6 className='opportunityHead_ '>{data?.posting_title.length > 13.5 ? data?.posting_title.substring(0, 13.5) + "...." : data?.posting_title}</h6>

                                                    </div>
                                                    <div className='postsDetails deleteDetails'>


                                                        <p className='innerSubtextSmall'>
                                                            {data?.description}
                                                        </p>
                                                    </div>
                                                    <div className='opportunityBtn_'>
                                                        <button className="subsmallBtnblue_" onClick={() => permanetlyDeleteAction(data.id)}>Delete</button>
                                                    </div>
                                                    <div className='opportunityBtn_ m-2'>
                                                        <button className="subsmallBtnblue_" onClick={() => UndeleteAction(data.id)}>Undelete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                }
                            </>
                            : <b>No Recently Delete Opportunity Found</b>
                        }
                    </>
                }

                <div className="col-12 ">
                    <div className="customPagination mt-4">
                        <div className="pagination">
                            {recentlyDelete?.length > 0 ? (
                                <ReactPaginate

                                    breakLabel=""
                                    // nextLabel=">"
                                    nextLabel={<i className='fas fa-caret-right'></i>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={pageCount}
                                    marginPagesDisplayed={0}
                                    pageCount={pageCount}
                                    previousLabel={<i className='fas fa-caret-left'></i>}
                                    // previousLabel="<"
                                    renderOnZeroPageCount={null}
                                />
                            ) : ""}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecentlyDelete