import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import './public/css/custom.css';
import './public/css/customNew.css';
import './public/css/customNew2.css';
import './public/css/login.css';
import './public/css/responsive.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import store from './redux';
import { persistor } from './redux';
import { PersistGate } from 'redux-persist/integration/react';
import HomeScreenPassword from './app/components/common/homeScreenForm';
import { companyInformationSlice } from './redux/slices/companyInformation';
import OneSignal from 'react-onesignal';
import RunOneSignal from './utilities/oneSignal';
import MembershipLevelComponent from './app/containers/manageMembershipAcessLevel';
// import { store, persistor } from './app/store';
import NetworkService from "./utilities/api"

NetworkService.setupInterceptors(store);

function App() {
 
  console.log(OneSignal,"appOneSignal");
  return (
    <>
      {/* <React.StrictMode> */}
      <RunOneSignal>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </PersistGate>
        <HomeScreenPassword />
      </Provider>
      <ToastContainer
      // position="top-right"
      // autoClose={5000}
      // hideProgressBar={false}
      // newestOnTop={true}
      // closeOnClick
      // rtl={false}
      // pauseOnFocusLoss
      // draggable
      // pauseOnHover
      />
      </RunOneSignal>
      {/* </React.StrictMode> */}
    </>
  );
}

export default App;
