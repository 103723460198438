import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CustomModal from "../../components/common/shared/CustomModal";
import { allNotificationList } from '../../../redux/slices/notification';
import { updateRequestConnection, pendingConnectionListing } from "../../../redux/slices/connection";
import * as Images from "../../../utilities/images";
import { getCompanyProfileInfo, logout } from '../../../redux/slices/auth';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { useAuthSelector } from '../../../redux/selector/auth';
import EndorsementsText from "../dashboard/modals/endorsementsText";
import userNotification from '../../../redux/saga/notification';
import { useNotificationSelector } from '../../../redux/selector/notification';

const Notifications = () => {

    const certificate_tooltip = " Say goodbye to tedious paperwork and complex requirements. GetOPP$ simplifies and streamlines the entire certification process, empowering you to focus on growing your business. With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier. Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity. "
    const endorsements_tooltp = "   Make your mark on the vibrant GetOpp$ buyer and supplier social networking platform by endorsing fellow members and igniting a powerful network of support. Share your positive experiences, highlight exceptional skills, and build credibility within the community. Endorsements strengthen professional relationships, boost visibility, and open doors to exciting collaboration opportunities. Let your voice be heard, cultivate a thriving network, and be part of a community that values recognition and growth. "
    const maxLength = 60;
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const navigate = useNavigate();
    const data = [1, 2, 3, 4, 5, 6, 7];
    const authSelector = useAuthSelector()
    const notificationSelector = useNotificationSelector()
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [userData, setUserData] = useState()

    const dataFetchedRef = useRef(false);
    const dispatch = useDispatch();


    const [notification, setNotification] = useState([]);
    const [pendingConnection, setpendingConnection] = useState([])

    const [userDeatil, setUserDeatil] = useState();


    const userSigninInfo = authSelector?.userInfo?.user?.user_company_information !== null ? authSelector?.userInfo?.user?.user_company_information : authSelector?.userInfo?.user?.user_selected_company_information?.user_company_information;

    const userStepInfo = authSelector?.userStep?.user_company_information;

    let ComapnyLogo;
    if (userDeatil?.company_logo) {
        ComapnyLogo = userDeatil?.company_logo;
    }
    else {
        ComapnyLogo = Images.companyprofile;
    }

    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());

    };
    const handleEndorsements = () => {
        setModalDetail({ show: true, flag: "Endorsements" });
        setKey(Math.random());
    }
    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(getCompanyProfileInfo({
            cb(res) {
                if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                    setUserData(res?.data?.payload?.userDetails)
                    setUserDeatil(res?.data?.payload?.userDetails?.user_company_information)
                }
                else {
                    setUserData(res?.data?.payload?.userDetails)
                    setUserDeatil(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
                }

            }
        }))
    }


    // get all notification
    const getNotification = (page = 1, limit = 10) => {

        const params = {
            page: page,
            limit: 10
        }

        dispatch(allNotificationList({
            ...params,
            cb(res) {
                if (res.status) {
                    setNotification(res?.data?.payload?.notification ?? [])
                    setPage(res?.data?.payload?.current_page ?? 1)
                    setTotalPage(res?.data?.payload?.total_pages ?? 1)

                    if (window)
                        window.scrollTo(0, 0);

                };
            }
        }))
    }

    const handlePageClick = (e) => {
        getNotification(e.selected + 1)
        setPage(e.selected + 1)

    }
    // handle change page
    const handleChangePage = (flag) => {
        if (flag == "edit") {
            navigate('/setting', { state: { activeTab: "editProfile" } })
        }
        else if (flag == "plan") {
            navigate('/setting', { state: { activeTab: "subscription" } })
            localStorage.setItem("activeTabSetting", "subscription")
        }
        else if (flag == "allOpportunities") {
            navigate('/opportunities', { state: { activeTab: "activeOpportunity" } })
            localStorage.setItem("activeTabSetting", "activeOpportunity")
        }
        else if (flag == "setting") {
            navigate('/setting', { state: { activeTab: "privacy" } })
            localStorage.setItem("activeTabSetting", "setting")
        }
        else {
            navigate('/opportunities', { state: { activeTab: "activeOpportunity" } })
            localStorage.setItem("activeTabSetting", "activeOpportunity")
        }
    }
    // for all pending connection list .............
    const pendingConnectionList = () => {
        dispatch(pendingConnectionListing({
            cb(res) {
                if (res.status) {
                    setpendingConnection(res?.data?.payload?.connections)
                }
                else {
                }

            }
        }))
    }
    //user logout
    const userLogout = () => {
        dispatch(logout({
            cb(res) {
                if (res.status) {
                    navigate("/")
                }
                else {
                }
            }
        }))
    }

    //update request
    const updateRequest = (id, flag) => {
        let params = {
            id: id,
            status: flag
        }
        dispatch(updateRequestConnection({
            ...params, cb(res) {
                if (res.status) {
                    pendingConnectionList();
                }
                else {
                }
            }
        }))
    }


    // for accept @ reject connection ............
    const AcceptRejectConnection = (id, flag) => {
        updateRequest(id, flag);
    }


    // handle  page change 
    const handlePageRedirect = (name, id) => {
        if (id) {
            navigate(`/${name}/${id}`)
        }
    }

    // handle  page change 
    const handleNotificationRedirect = (notiData) => {

        if (notiData.notification_type === 1) {
            navigate('/dashboard')
        }
        else if (notiData.notification_type === 2) {
            navigate('/account-message')
        }
        else if (notiData.notification_type === 3) {
            navigate('/business', { state: { selectedFilter: 'opportunity' } })
        }
        else {
            return false
        }

    }


    useEffect(() => {
        pendingConnectionList();
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getNotification();
        companyProfileInfo();
        document.title = "Notification";
    }, []);

    return (
        <>
            <div className='notifications'>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-3'>
                        {/* <h3 className='headingTitle_ mb-3 invisible'>Company Profile</h3> */}
                        <h3 className='mainHeading'>Company Profile</h3>
                        <div className='companyContainerArea'>
                            <div className='companyProfile'>
                                {/* <div className='motionSec ps-4 pe-4 '>
                                <img src={Images.motionImg} className='img-fluid motionImg' alt='motionImg' />
                                <div className='motionSecData'>
                                    <h5 className='headingSmall_'><b>3 Motion Creative</b></h5>
                                    <p className='motionLoc'>
                                        <img src={Images.locationpin} alt='Location' className='me-1' />
                                        <span className='textInnerOuter'>Westlake Village CA.</span>
                                    </p>
                                    <button className='secondaryBtnOuter text-end'>Basic Plan</button>
                                </div>
                            </div> */}
                                <div className='motionSec ps-4 pe-4 text-center'>
                                    {!userDeatil ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12 text-center pt-5 pb-5">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </>
                                        :

                                        <img src={ComapnyLogo} className='img-fluid motionImg' alt='motionImg' />
                                    }
                                    {/* <img src={userDeatil?.user_company_information?.company_logo ? userDeatil?.user_company_information?.company_logo : Images.companyprofile} className='img-fluid motionImg' alt='motionImg' /> */}
                                    {/* <img src={Images.motionImg} className='img-fluid motionImg' alt='motionImg' /> */}
                                    <div className='motionSecData mt-4 text-center'>
                                        <h5 className='headingSmall_'><b>{userSigninInfo?.company_name ? userSigninInfo?.company_name : userStepInfo?.company_name}</b></h5>
                                        <p className='motionLoc'>
                                            {userDeatil?.domestic_service_areas ?
                                                <>
                                                    <img src={Images.locationpin} alt='Location' className='me-1' />
                                                    <span className='textInnerOuter'>{userDeatil?.domestic_service_areas}
                                                        {/* {userSigninInfo?.main_company_country_location ? userSigninInfo?.main_company_country_location : userStepInfo?.main_company_country_location} */}
                                                    </span>
                                                </>
                                                : ""
                                            }
                                        </p>
                                        {
                                            userData?.user_company_information !== null ?
                                                <div className="connectionbtn_">
                                                    <button className='secondaryBtnOuter mt-2' onClick={() => { handleChangePage("plan") }}>{userData?.membership_type == 1 || userData?.membership_type == 4 ? "Platinum" : userData?.membership_type == 2 ? "Gold" || userData?.membership_type == 5 : userData?.membership_type == 3 || userData?.membership_type == 6 ? "Bronze" : ""} Plan
                                                    </button>
                                                </div>
                                                : ""}
                                    </div>
                                </div>

                                <div className='companyDetails mt-3'>
                                    <ul className='companyDetailsMenus'>
                                        <li className='companyDetailsItems'>
                                            <button onClick={() => { handleChangePage("edit") }} className='companyDetailsLinks'>Edit Profile</button>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            {/* <Link to="/setting" className='companyDetailsLinks'></Link> */}
                                            <button onClick={() => { handleChangePage("setting") }} className='companyDetailsLinks'>Settings & Privacy</button>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <button onClick={() => handleChangePage("plan")} className='companyDetailsLinks'>Manage Membership</button>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/all-connections" className='companyDetailsLinks'>View Connections</Link>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/account-message" className='companyDetailsLinks'>View Messages</Link>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/opportunities" className='companyDetailsLinks'>Manage Opportunities</Link>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/business" className='companyDetailsLinks'>Targeted   Search</Link>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/resources" className='companyDetailsLinks'>Resources</Link>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/document-view" className='companyDetailsLinks'>Documents</Link>
                                        </li>
                                        <li className='companyDetailsItems' onClick={() => { userLogout() }}>
                                            <Link to="/resources" className='companyDetailsLinks'>Logout</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className='cardContainer mt-4'>
                            <div className='cardImg'>
                                <img src='https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=400' className='img-fluid' alt='CardImg' />
                            </div>
                            <div className='cardBodyArea'>
                                <h5>Get Certified</h5>
                                <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
                                <button className='primaryBtn'>Learn More</button>
                            </div>
                        </div> */}
                            <div className='cardContainer mt-4'>
                                <div className='cardImg'>
                                    <img src={Images.certifyUser} className='img-fluid' alt='CardImg' />
                                </div>
                                <div className='cardBodyArea'>
                                    <h5>Get Certified</h5>
                                    <div className="infoiconText">
                                        <span className="showinfoText">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                                    </div>
                                    <p className='my-4'>GetOPP$ Diversity Certifications for inclusive business futures. </p>
                                    <div className="infoiconText m-0">
                                        <Link to="/certificate">
                                            <button className='primaryBtn'>Learn More</button>
                                        </Link>
                                        <span className="showinfoText">
                                            {/* {data?.posting_title.length > 13.5 ? data?.posting_title.substring(0, 13.5) + "...." : data?.posting_title} */}

                                            {certificate_tooltip.substring(0, maxLength)}...

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-5'>
                        <h3 className='mainHeading'>Notifications</h3>

                        <div className='postSec'>
                            <div className='row'>
                                {
                                    notificationSelector?.loading ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12 text-center pt-5 pb-5">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className='col-md-12'>
                                            <div className='postContainer'>
                                                {
                                                    notification.length > 0 ?
                                                        <>
                                                            {
                                                                notification.map((val, index) => {
                                                                    return (
                                                                        <div className='postContainerArea' key={index}>
                                                                            <div className='posts align-items-center justify-content-between'>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <img onClick={() => handlePageRedirect(val?.sender_details?.user_company_information?.company_name, val?.sender_details?.user_company_information?.id)} src={val.sender_details?.user_profile?.profile_photo ?? Images.companyprofile} className='postUser me-3' alt='UserImage' />

                                                                                    <div className='postsDetails' onClick={() => handleNotificationRedirect(val)}>
                                                                                        <h5 className='headingTitleSmall_ d-flex align-items-center justify-content-between mb-1'>
                                                                                            <span >{val.sender_details?.user_profile ? val.sender_details?.user_profile?.first_name + " " + val.sender_details?.user_profile?.last_name : "--"}</span>
                                                                                            &nbsp;&nbsp;
                                                                                            <span className='innerSubtextSmall'>
                                                                                                {val.short_description ?? '--'}
                                                                                            </span>
                                                                                        </h5>
                                                                                        <span className='weeksTxt'><b>{moment(val?.created_at).fromNow()}</b></span>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <img src={Images.link} className='img-fluid' /> */}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <div className='postContainerArea' >
                                                            <div className='posts align-items-center justify-content-between'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='postsDetails'>
                                                                        <h5 className='headingTitleSmall_ d-flex align-items-center justify-content-between mb-1'>
                                                                            <span>No Notifications found</span>
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                }

                            </div>
                        </div>

                        <div className="col-12 ">
                            <div className="customPagination mt-4">
                                <div className="pagination">
                                    {notification?.length > 0 ? (
                                        <ReactPaginate
                                            breakLabel=""
                                            nextLabel={<i className='fas fa-caret-right'></i>}
                                            onPageChange={(e) => { handlePageClick(e) }}
                                            marginPagesDisplayed={0}
                                            pageCount={totalPage}
                                            previousLabel={<i className='fas fa-caret-left'></i>}
                                            renderOnZeroPageCount={null}
                                        />
                                    ) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        {/* <h3 className='headingTitle_ mb-3 mt-3 mt-md-0'>Connection</h3> */}
                        <aside className='dashboardRightArea'>
                            <h3 className='mainHeading'>Business Resources</h3>
                            {/*  <div className='messageContainer'>
                            
                           {
                                pendingConnection ?
                                    <>
                                        {
                                            pendingConnection.map((val, index) => {
                                                return (
                                                    <div className='postContainerArea' key={index}>
                                                        <div className='posts align-items-center justify-content-between'>
                                                            <div className='d-flex align-items-center'>
                                                                <img src={val?.sender_details?.user_profile?.profile_photo ? val?.sender_details?.user_profile?.profile_photo : Images.companyprofile} className='postUser me-3' alt='UserImage' />
                                                                <div className='postsDetails'>
                                                                    <h5 className='headingTitleSmall_ d-flex align-items-center justify-content-between mb-0'>
                                                                        <span>{val?.sender_details?.user_profile?.first_name} {val?.sender_details?.user_profile?.last_name}</span>
                                                                    </h5>
                                                                    <p className='innerSubtextSmall'>
                                                                        {val?.sender_details?.user_company_information?.company_name}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='btn-group'>
                                                                <button className='secondaryBtnSmall me-2' onClick={() => { AcceptRejectConnection(val?.id, 2) }}>
                                                                    Active
                                                                </button>
                                                                <span className='crossIcon'>
                                                                    <img src={Images.crossIcon} className='img-fluid' onClick={() => { AcceptRejectConnection(val?.id, 3) }} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })
                                        } 
                                    </>
                                    : <b>No Pending Connection Found</b>
                            } 
                        </div>*/}
                            {/* <div className='CardContainer mt-4 d-flex'>
                            <div className='cardImg d-flex'>
                                <img src='https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=400' className='img-fluid' alt='CardImg' />
                            </div>
                            <div className='cardBodyArea'>
                                <h5>Endorsements</h5>
                                <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt.</p>
                                <button className='primaryBtn'>Learn More</button>
                            </div>
                        </div>
                        <div className='cardContainer mt-4 d-flex'>
                            <div className='cardImg d-flex'>
                                <img src='https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=400' className='img-fluid' alt='CardImg' />
                            </div>
                            <div className='cardBodyArea'>
                                <h5>Endorsements</h5>
                                <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt.</p>
                                <button className='primaryBtn'>Learn More</button>
                            </div>
                        </div> */}
                            {/* <div className='lookSuplier'>
                            <div className='whatnextBox'>
                                <img className="suplierImg_" src={Images.pepcicoImg} alt="suplierImg" />
                                <div className='supText_'>
                                        <h6><strong>What's Next?</strong></h6>
                                        <p>In the coming year, our supplier diversity program will continue to focus on building a Positive Value Chain.</p>
                                    </div>
                            </div>
                        </div> */}
                            <div className='cardContainer mt-4 d-flex'>
                                <div className='cardImg d-flex'>
                                    <img src={Images.bannerendsor} className='img-fluid' alt='CardImg' />
                                    <div className='cardBodyArea'>
                                        <h5>Endorsements</h5>
                                        <p className='my-5'>Elevate Connections and Inspire Growth with GetOPP$ Endorsements. </p>
                                        <div className="infoiconText m-0">
                                            <button onClick={() => { handleEndorsements() }} className='primaryBtn' >Learn More</button>
                                            <span className="showinfoText">
                                                {endorsements_tooltp.substring(0, maxLength)}...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "Addlabels" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "Addlabels" ? "Addlabels" : "ComposeMessage"}
                child={modalDetail.flag === "Endorsements" ? (
                    <EndorsementsText


                        close={() => handleOnCloseModal()}
                    />
                )

                    : ""

                }
                header={
                    modalDetail.flag === "CommentBox" ?
                        <h2 className="headingSmall_">
                            Comments
                        </h2>
                        :
                        <div className='modalHeader_'>
                            <div className="common_">
                                {modalDetail.flag === "CompanyLogo" ?
                                    <h2 className="headingSmall_">
                                        Company Logo
                                    </h2>
                                    : modalDetail.flag == "allPostLikeUser" ?
                                        <h2 className="headingSmall_">
                                            Post Like List
                                        </h2>
                                        : modalDetail.flag == "addTagToPost" ?
                                            <h2 className="headingSmall_">
                                                {modalDetail.header}
                                            </h2>
                                            :
                                            ""}
                            </div>
                        </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default Notifications