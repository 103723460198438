import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { getLabels, getAllCompanyInfo, createDraft } from "../../../redux/slices/messages";
import { uploadAllDocuments } from "../../../redux/slices/documents";
import { useSocketSelector } from "../../../redux/selector/socket";
import { useDispatch } from 'react-redux';
import { socket } from "../../../app/components/socket";
import { useAuthSelector } from "../../../redux/selector/auth";
import { toast } from 'react-toastify';

const ComposeMessage = (props) => {
    const socketSelector = useSocketSelector();
    const socketInstance = socket();
    const authSelector = useAuthSelector()
    const userInfo = authSelector?.userInfo?.user
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState(props?.chatDraft?.company_details?.company_name ? props?.chatDraft?.company_details?.company_name : props?.chatConnectionCompanyName?.user_company_information?.company_name ? props?.chatConnectionCompanyName?.user_company_information?.company_name : props?.companyinfo?.companyDetails?.company_name ? props?.companyinfo?.companyDetails?.company_name : props?.opportunityCompanyName ? props?.opportunityCompanyName :"");
    const [getAllLabel, setGetAllLabel] = useState("");
    const [selectedLabel, setSelectedLabel] = useState(props?.chatDraft?.label ? props?.chatDraft?.label : "");
    const [messages, setMessages] = useState(props?.chatDraft?.message ? props?.chatDraft?.message : "");
    const [customLabel, setCustomLabel] = useState("");
    const [loading, setLoading] = useState(false);
    const [allCompanyList, setAllCompanyList] = useState("");
    const [selectedCompaniesId, setSelectedCompaniesId] = useState(props?.chatDraft?.company_id ? props?.chatDraft?.company_id : props?.chatConnectionCompanyName?.user_company_information?.id ? props?.chatConnectionCompanyName?.user_company_information?.id : props?.companyinfo?.companyDetails?.id ? props?.companyinfo?.companyDetails?.id : props?.opportunityCompanyName ? props?.opportunityCompanyName : "");
    // Company id and Sender id came out of Redux

    const company_id = userInfo?.user_company_information ? userInfo?.user_company_information?.id : userInfo?.user_selected_company_information?.id
    const sender_id = userInfo.id

    //    start work on socket and firstMesage function for start communication
    const firstMesage = (e) => {
        e.preventDefault();
        const selectedId = props.chatHeadListing?.data?.find(item => item?.company_id == selectedCompaniesId)
        if (!selectedCompaniesId) {
            toast.error("Please select company")
            return;
        }
        else if (!messages) {
            toast.error("Please enter message");
            return;
        }
        else if (!selectedLabel) {
            toast.error("Please select label");
            return;
        }
        let params = {
            sender_id: sender_id,
            company_id: selectedCompaniesId,
            last_message: messages,
            message_type: 1,
            label: customLabel ? customLabel : selectedLabel
        }
        if (selectedId?.id) {
            params = {
                ...params,
                temp_string: selectedId?.temp_string.toString()
            }
        }
        socketInstance.emit('first_message', params)
        if (props?.chatHeadSearchRefresh) {
            props?.chatHeadSearchRefresh();
            props?.dataList();
            toast.success("message Sent Sucessfully");
            props.close()
        }
        else {
            toast.success("message Sent Sucessfully");
            props.close()
        }
        // props?.chatHeadSearchRefresh();
        // props.activeTab("messageInbox")
        // setLoading(true)
    }

    socketInstance.on('first_message', (response) => {
        if (response) {
            props?.firstMessage(response?.data[0])
            props?.dataList()
        }
    })
    //    end work on socket ///////////////

    // // apply API for get all Lebels List
    // const getLebelsListData = () => {
    //     dispatch(
    //         getLabels({
    //             cb(res) {
    //                 if (res?.data) {

    //                 }
    //             },
    //         })
    //     );
    // };


    // apply API for find out all companies to start communication
    const searchAllCompanyList = () => {
        let params = {
            search: searchInput ? searchInput : ""
        }
        dispatch(getAllCompanyInfo({
            ...params, cb(res) {
                if (res?.data) {
                    setAllCompanyList(res?.data?.payload?.data)
                }
            }
        }))
    };

    // apply API for find out all Lebels
    const getLebelsList = (data) => {
        setSelectedLabel(data)
        dispatch(getLabels({
            cb(res) {
                if (res?.data) {
                    setGetAllLabel(res?.data?.payload)
                }
            }
        }))
    };

    // apply API for save data in draft
    const createDrafts = () => {
        if (!selectedCompaniesId) {
            toast.error("Please select company")
            return;
        }
        let params = {
            company_id: selectedCompaniesId,
            label: selectedLabel
        }
        if (messages) {
            params = {
                ...params,
                message: messages,
                message_type: 1,
            }
        }
        dispatch(createDraft({
            ...params, cb(res) {
                if (res?.data) {
                    // props.chatDraftsRefresh();
                    props.close()
                }
            }
        }))
    };

    // selectedCompany function for save company data in state 
    const selectedCompany = (name, id) => {
        setSearchInput(name)
        setSelectedCompaniesId(id)
        setAllCompanyList("")
    }

    // handleChnage function for change company to communication
    const handleChnage = (e) => {
        if (!e.target.value) {
            setSelectedCompaniesId("")
        }
        setSearchInput(e.target.value)
    }


    useEffect(() => {
        getLebelsList(props?.chatDraft?.label ? props?.chatDraft?.label : "");
        document.title = "messages";
    }, []);

    useEffect(() => {
        if(props?.opportunityCompanyName){
            setSelectedCompaniesId(props?.opportunityCompanyId)
        }
    })


    useEffect(() => {
        searchAllCompanyList();
    }, [searchInput]);

    return (
        <>
            <form onSubmit={(e) => firstMesage(e)}>
                <div className="dropdown">
                    <div className="business_search mb-4" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img className="searchIcon_" src={Images.search_} alt="Search" />
                        <input value={searchInput} onChange={(e) => handleChnage(e)} type="text" className="custoInput" placeholder="Search Company" aria-label="discount code" aria-describedby="basic-addon2" />
                        {searchInput && <button className='inputClose' type="reset" onClick={() => { setSearchInput("") }}> </button>}
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {allCompanyList?.length > 0 ?
                            <>
                                {allCompanyList?.map((data, index) => {
                                    return <li className='composeProfileBox' onClick={() => { selectedCompany(data?.company_name, data?.id) }} key={index}>
                                        <img src={data.company_logo ? data.company_logo : Images.companyprofile} alt='companyProfile' className='img-fluid composeProfile' />
                                        <a className="dropdown-item" href="#">
                                            {data?.company_name}
                                        </a>
                                    </li>
                                    // <div className='companyList' onClick={() => { selectedCompany(data?.company_name, data?.id) }} value={data?.id}>{data?.company_name}</div>
                                })}
                            </>
                            : ""}
                        {/* <li><a className="dropdown-item" href="#">Action</a></li> */}
                    </ul>
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <textarea name="email" type='text' className='customFormControl' placeholder='message' value={messages} onChange={e => setMessages(e.target.value)}></textarea>
                </div>
                <div className='form-group col-md-12 mb-3'>
                    <select className="cateSelectbox" value={selectedLabel} required="" onChange={(e) => { getLebelsList(e.target.value) }}>
                        <option value="">Please Select Label</option>
                        {getAllLabel?.length > 0 ?
                            <>
                                {getAllLabel?.map((data, index) => {
                                    return <option value={data?.name} key={index}>{data?.name}</option>
                                })}
                            </>
                            : ""}
                    </select>
                </div>
                {selectedLabel == "Custom Label" ?
                    <div className='form-group col-md-12 mb-3'>
                        <input name="labelcustom" type='text' className='customFormControl' placeholder='Type in a Topic Title for Label' value={customLabel} onChange={e => setCustomLabel(e.target.value)}></input>
                    </div> : ""
                }
                <div className='sendMessage text-center'>
                    <button type="button" className='subsmallBtnblue_' onClick={() => createDrafts()}>Save in Draft</button>
                    {/* <button className='primaryBtnSmall' onClick={() => firstMesage()}>Send</button> */}
                    <button className='primaryBtnSmall' type="submit">
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span >Send</span>
                    </button>
                </div>
            </form>
        </>
    )
}

export default ComposeMessage