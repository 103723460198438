import React, { useCallback, useEffect, useState } from 'react'
import MultiStepProgressBar from './component/multiStepProgressBar';
import * as Images from "../../../utilities/images";
import { toast } from "react-toastify"
import { businessGenesis, businessOwnershipType, ethnicity, getAllIndustryGroups, getAllIndustrySubGroups, userExistingCertificationsUpdate, userTradeMembershipsUpdate } from '../../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Multiselect from 'multiselect-react-dropdown';
import swal from 'sweetalert';
import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { diverseSupplierDescription } from '../../../redux/slices/advanceSearch';
import { myDocuments, setMyDocuments, uploadAllDocuments } from '../../../redux/slices/documents';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthSelector } from '../../../redux/selector/auth';
import { diversityCertification, getDiversityCertification, setDiverseStatusEmpty } from '../../../redux/slices/certification';
import { useCertificationSelector } from '../../../redux/selector/certification';


const CertificateStep = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const businessOwner = location ? location?.state?.ownerList : ""

    const authSelector = useAuthSelector();

    const navigate = useNavigate();

    const companyId = authSelector?.userInfo?.user?.user_company_information?.id;

    const certificationData = useCertificationSelector();

    const flag = certificationData?.diversityCertification?.step;

    const getCertificationData = certificationData?.diversityCertification
    const certificationId = getCertificationData?.id

    const [pageNumber, setPageNumber] = useState(flag ? flag + 1 : 1);

    const [page, setPage] = useState(flag == 1 ? 'pagetwo' : flag == 2 ? 'pagethree' : flag == 3 ? "pagefour" : flag == 4 ? "pagefive" : flag == 5 ? "pagesix" : flag == 6 ? "pageseven" : 'pageone');

    const [businessId, setBusinessId] = useState(null)

    const [ownershipType, setOwnershipType] = useState("")
    const [genesisType, setGenesisType] = useState("")

    const [ethnicityType, setEthnicityType] = useState("")

    const [industryGroup, setIndustryGroup] = useState([])
    const [subIndustryGroup, setSubIndustryGroup] = useState([])
    const [industryGroupId, setIndustryGroupId] = useState("")
    const [industrySubGroupId, setIndustrySubGroupId] = useState("")
    const [myDocumentList, setMyDocumentList] = useState([]);
    const [documentList, setDocumentList] = useState([])
    const [selected, setSelected] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [driversSupplierDescriptionId, setDriversSupplierDescriptionId] = useState([]);
    const [businessGenesisId, setBusinessGenesisId] = useState([]);
    const [companyDescription, setCompanyDescription] = useState("")
    const [selectedCertification, setSelectedCertification] = useState("")
    const [selectedFormerCertification, setSelectedFormerCertification] = useState("")
    const [selectedTradeMembership, setSelectedTradeMembership] = useState("")
    const [selectedEthnicity, setSelectedEthnicity] = useState("")
    const [selectedEthnicityId, setSelectedEthnicityId] = useState()
    const [selectedBusinessGenesis, setSelectedBusinessGenesis] = useState("")
    const [companyOtherDescription, setCompanyOtherDescription] = useState("")
    const [existingCertificationsId, setExistingCertificationsId] = useState();
    const [formerCertificationId, setFormerCertificationId] = useState();
    const [formerOtherDescription, setFormerOtherDescription] = useState("")
    const [tradeCertificationId, setTradeCertificationId] = useState();
    const [tradeOtherDescription, setTradeOtherDescription] = useState("")
    const [certificationOtherDescription, setCertificationOtherDescription] = useState("")
    const [businessGenesisOtherDescription, setBusinessGenesisOtherDescription] = useState("")
    const [ethnictyOtherDescription, setEthnicityOtherDescription] = useState("")
    const [certificateData, setCertificateData] = useState("")

    const [additionalOwners, setAdditionalOwners] = useState([{ name: '', ownership: '', email: '', title: '' }]);


    const [companyOfficer, setCompanyOfficer] = useState([{ name: '', email: '', title: '' }]);

    const [getExistingCertifications, setGetExistingCertifications] = useState("");
    const [getTradeMemberships, setGetTradeMemberships] = useState("");

    const [boardMember, setBoardMember] = useState([{ name: '', email: '', title: '' }]);

    const [info, setInfo] = useState({
        companyName: "",
        businessAds: "",
        businessAddress: "",
        mailingAddress: "",
        websiteUrl: "",
        taxId: "",
        socialMedia: ""
    })
    const [infoCont, setInfoCont] = useState({
        businessOwnership: "",
        businessGenesis: [],
        professionalLicense: "",
        occupationalLicense: "",
        formationYear: "",
        numberOfEmployees: "",
        geographicMarket: ""
    })

    const [infoFinal, setInfoFinal] = useState({
        industry: "",
        subIndustry: "",
        products: "",
        coreSkills: "",
        businessHistory: "",
    })

    const [diversityInfo, setDiversityInfo] = useState({
        businessStatus: "",
        existingCertification: "",
        formerCertification: "",
        tradeCertification: ""
    })

    const [diversityInfoCont, setDiversityInfoCont] = useState({
        ownerName: "",
        ownershipPercentage: "",
        ownershipDate: "",
        // ownerDob: "",
        ownerEmail: "",
        ownerGender: "",
        ownerEthnicity: "",
        ownerLicense: ""
    })

    //next page
    const nextPage = (page) => {
        setPage(page);
    };

    //next page number
    const backPageNumber = () => {
        const newPageNumber = pageNumber.toString();

        switch (newPageNumber) {

            case "2":
                setPage("pageone");
                setPageNumber(1);
                break;
            case "3":
                setPage("pagetwo");
                setPageNumber(2);
                businessGenesisType();
                getDiversityCertificationData();
                break;
            case "4":
                setPage("pagethree");
                setPageNumber(3);
                break;
            case "5":
                setPage("pagefour");
                setPageNumber(4);
                break;
            case "6":
                setPage("pagefive");
                setPageNumber(5);
                break;
            case "7":
                setPage("pagesix");
                setPageNumber(6);
                break;
            default:
                setPageNumber(1);
                setPage('pageone');

        }
    };

    //next page numbernextPageNumber
    const nextPageNumber = (pageNumber) => {
        switch (pageNumber) {
            case "1":
                setPage("pageone");
                setPageNumber(1);
                break;
            case "2":
                setPage("pagetwo");
                setPageNumber(2);
                break;
            case "3":
                setPage("pagethree");
                setPageNumber(3);
                break;
            case "4":
                setPage("pagefour");
                setPageNumber(4);
                break;
            case "5":
                setPage("pagefive");
                setPageNumber(5);
                break;
            case "6":
                setPage("pagesix");
                setPageNumber(6);
                break;
            case "7":
                setPage("pageseven");
                setPageNumber(7);
                break;
            default:
                setPage(1);

        }
    }

    const businessOwnership = (id) => {
        setInfoCont({
            ...infoCont,
            businessOwnership: id,
        })
        dispatch(businessOwnershipType({
            cb(res) {
                if (res.status) {
                    setOwnershipType(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    const businessGenesisType = (id) => {
        setInfoCont({
            ...infoCont,
            businessGenesis: id
        })
        dispatch(businessGenesis({
            cb(res) {
                if (res.status) {
                    setGenesisType(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    const ethnicityListType = (id) => {
        setDiversityInfoCont({
            ...diversityInfoCont,
            ownerEthnicity: id
        })
        dispatch(ethnicity({
            cb(res) {
                if (res.status) {
                    setEthnicityType(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    const handleAddOwner = () => {

        const checkEmptyInputName = additionalOwners[additionalOwners.length - 1].name === "";
        const checkEmptyInputPercentage = additionalOwners[additionalOwners.length - 1].ownership === "";
        const checkEmptyInputEmail = additionalOwners[additionalOwners.length - 1].email === "";
        const checkEmptyInputTitle = additionalOwners[additionalOwners.length - 1].title === "";

        if (checkEmptyInputName || checkEmptyInputPercentage || checkEmptyInputEmail || checkEmptyInputTitle) {
            toast.dismiss();
            toast.error("Please enter the last owner details");
            return;
        }

        setAdditionalOwners([...additionalOwners, { name: '', ownership: '', email: '', title: '' }]);
    };

    const handleRemoveOwner = (index) => {
        // Create a new array without the item at the specified index
        const updatedOwners = [...additionalOwners];
        updatedOwners.splice(index, 1);
        setAdditionalOwners(updatedOwners);
    };

    const handleRemoveOfficer = (index) => {
        // Create a new array without the item at the specified index
        const updatedOfficer = [...companyOfficer];
        updatedOfficer.splice(index, 1);
        setCompanyOfficer(updatedOfficer);
    };

    const handleRemoveBoardMember = (index) => {
        // Create a new array without the item at the specified index
        const updatedMembers = [...boardMember];
        updatedMembers.splice(index, 1);
        setBoardMember(updatedMembers);
    };



    const handleInputChange = (index, field, value) => {
        const updatedOwners = [...additionalOwners];
        updatedOwners[index][field] = value;
        setAdditionalOwners(updatedOwners);
    };


    const handleAddCompanyOfficer = () => {

        const checkEmptyInputName = companyOfficer[companyOfficer.length - 1].name === "";
        const checkEmptyInputEmail = companyOfficer[companyOfficer.length - 1].email === "";
        const checkEmptyInputTitle = companyOfficer[companyOfficer.length - 1].title === "";

        if (checkEmptyInputName || checkEmptyInputEmail || checkEmptyInputTitle) {
            toast.dismiss();
            toast.error("Please enter the last company officer details");
            return;
        }

        setCompanyOfficer([...companyOfficer, { name: '', email: '', title: '' }]);
    };

    const handleInputChangeCompanyOfficer = (index, field, value) => {
        const updatedOwners = [...companyOfficer];
        updatedOwners[index][field] = value;
        setCompanyOfficer(updatedOwners);
    };

    const handleAddBoardMember = () => {

        const checkEmptyInputName = boardMember[boardMember.length - 1].name === "";
        const checkEmptyInputEmail = boardMember[boardMember.length - 1].email === "";
        const checkEmptyInputTitle = boardMember[boardMember.length - 1].title === "";

        if (checkEmptyInputName || checkEmptyInputEmail || checkEmptyInputTitle) {
            toast.dismiss();
            toast.error("Please enter the last board member details");
            return;
        }

        setBoardMember([...boardMember, { name: '', email: '', title: '' }]);
    };

    const handleInputChangeBoardMember = (index, field, value) => {
        const updatedOwners = [...boardMember];
        updatedOwners[index][field] = value;
        setBoardMember(updatedOwners);
    };


    const industry = (e) => {
        dispatch(getAllIndustryGroups({
            cb(res) {
                if (res.status) {
                    setIndustryGroup(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    const supplierDescription = (id) => {

        dispatch(diverseSupplierDescription({
            cb(res) {
                if (res.status) {
                    setCompanyDescription(res?.data?.payload)
                }
            }
        }))
    }


    // onchange input 
    const handleChangeIndustry = (id, flag) => {
        if (flag == "industry") {
            setIndustryGroupId(id)
            subIndustry(id)

            setInfoFinal({
                ...infoFinal,
                industry: id
            })
        }
        else {
            setIndustrySubGroupId(id)
            setInfoFinal({
                ...infoFinal,
                subIndustry: id
            })
        }
    }

    const subIndustry = (id) => {
        if (id) {
            setIndustryGroupId(id)
            let params = {
                id: id,
            }
            dispatch(getAllIndustrySubGroups({
                ...params, cb(res) {

                    if (res.status) {

                        setSubIndustryGroup(res?.data?.payload)
                        // setIndustrySubGroupId("")
                    }
                    else {

                    }
                }
            }))

        }

    }

    // const handleDateChange = (date) => {

    //     setDiversityInfoCont({
    //         ...diversityInfoCont,
    //         ownerDob: date
    //     })

    // };

    const handleDateAccquired = (date) => {

        setDiversityInfoCont({
            ...diversityInfoCont,
            ownershipDate: date
        })

    };

    const UpdateExistingCertifications = (id) => {
        dispatch(userExistingCertificationsUpdate({
            cb(res) {
                if (res.status) {
                    setGetExistingCertifications(res?.data?.payload)
                }
            }
        }))
    }

    const UpdateTradeMemberships = (id) => {
        dispatch(userTradeMembershipsUpdate({
            cb(res) {
                if (res.status) {
                    setGetTradeMemberships(res?.data?.payload)
                }
            }
        }))
    }

    // driver supplier description
    const handleChanges = (data) => {
        setSelected(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDiversityInfo({
            ...diversityInfo,
            businessStatus: arr
        })
        setDriversSupplierDescriptionId(arr)

        if (driversSupplierDescriptionId.includes(16) && !arr.includes(16)) {
            setCompanyOtherDescription(""); // Clear the description.
        }

    }

    const handleCertificationsChanges = (data) => {
        setSelectedCertification(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDiversityInfo({
            ...diversityInfo,
            existingCertification: arr
        })
        setExistingCertificationsId(arr)

        if (existingCertificationsId?.includes(11) && !arr?.includes(11)) {
            setCertificationOtherDescription(""); // Clear the description.
        }
    }

    const handleCertificationsFormerChanges = (data) => {
        setSelectedFormerCertification(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDiversityInfo({
            ...diversityInfo,
            formerCertification: arr
        })
        setFormerCertificationId(arr)

        if (formerCertificationId?.includes(11) && !arr?.includes(11)) {
            setFormerOtherDescription(""); // Clear the description.
        }
    }

    const handleTradeMembership = (data) => {
        setSelectedTradeMembership(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDiversityInfo({
            ...diversityInfo,
            tradeCertification: arr
        })
        setTradeCertificationId(arr)

        if (tradeCertificationId?.includes(11) && !arr?.includes(11)) {
            setTradeOtherDescription(""); // Clear the description.
        }

    }

    const handleBusinessGenesisChanges = (id) => {
        setInfoCont({
            ...infoCont,
            businessGenesis: id
        })
        // setSelectedBusinessGenesis(data)
        // let arr = [];
        // for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        // setInfoCont({
        //     ...infoCont,
        //     businessGenesis: arr
        // })

        // setBusinessGenesisId(arr)

        // if (businessGenesisId?.includes(5) && !arr?.includes(5)) {
        //     setBusinessGenesisOtherDescription(""); // Clear the description.
        // }
    }

    const handleEthnicityChanges = (data) => {
        setSelectedEthnicity(data)

        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDiversityInfoCont({
            ...diversityInfoCont,
            ownerEthnicity: arr
        })

        setSelectedEthnicityId(arr)

        if (selectedEthnicityId?.includes(8) && !arr?.includes(8)) {
            setEthnicityOtherDescription(""); // Clear the description.
        }
    }

    //onDrop
    const onDrop = useCallback(acceptedFiles => {
        const imageFile = acceptedFiles[0];
        if (!imageFile.name.match(/\.(pdf|jpeg|jpg|png|doc|docx)$/)) {
            toast.error("Please select a valid image.");
            return false;
        }

        const newFiles = [...documents];

        for (let i = 0; i < acceptedFiles.length; i++) {
            newFiles.push(acceptedFiles[i]);
        }

        const previousData = [myDocumentList];

        setDocuments(newFiles);

        let params = {
            document: newFiles,
        }
        dispatch(uploadAllDocuments({
            ...params, cb(res) {
                if (res.status) {
                    // setDocuments(res?.data?.payload?.documents)
                    dummyFun(res?.data?.payload)
                }
                else {
                }
            }
        }))
    }, [])

    const dummyFun = (file, type) => {
        setMyDocumentList((pre) => [...pre, file])

        setDocumentList((pre) => [...pre, file.document])
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.jpeg, .pdf, .doc, .png, .docx, .jpg',

    })

    const documentDelete = (id, e) => {
        e.preventDefault();
        swal({
            // title: "Are you sure?",
            text: `Are you sure want to remove the selected file?`,

            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    const updatedDocumentList = myDocumentList.filter(file => file.id !== id);
                    // Update the state with the new array
                    setMyDocumentList(updatedDocumentList);
                }
            });
    }

    const handleSubmit = (e, flag, save) => {
        const Url = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
        e.preventDefault();

        if (flag == 1) {

            if (!info.companyName) {
                toast.error("Please enter Company name");
                return;
            }
            else if (info.companyName.trim() === "") {
                toast.error("Company name can't be empty");
                return;
            }
            else if (info.companyName.length <= 2) {
                toast.error("Company name should be maximum 3 character")
                return;
            }
            else if (!info.businessAds) {
                toast.error("Please enter business as")
                return;
            }
            else if (info.businessAds.trim() === "") {
                toast.error("Business as can't be empty")
                return;
            }
            else if (!info.businessAddress) {
                toast.error("Please enter business address")
                return;
            }
            else if (info.businessAddress.trim() === "") {
                toast.error("Business address can't be empty")
                return;
            }
            else if (!info.mailingAddress) {
                toast.error("Please enter mailing address")
                return;
            }
            else if (info.mailingAddress.trim() === "") {
                toast.error("Mailing address can't be empty.")
                return;
            }
            else if (!info.websiteUrl) {
                toast.error("Please enter website url")
                return;
            }
            else if (info.websiteUrl.trim() === "") {
                toast.error("Website url can't be empty")
                return;
            }
            else if (!info.websiteUrl.match(Url)) {
                toast.error("Please enter valid website url")
                return;
            }
            else if (!info.taxId) {
                toast.error("Please enter tax id")
                return;
            }
            else if (info.taxId.trim() === "") {
                toast.error("Tax id can't be empty")
                return;
            }
            else if (!info.socialMedia) {
                toast.error("Please enter social media detail")
                return;
            }
            else if (info.socialMedia.trim() === "") {
                toast.error("Social media handles can't be empty")
                return;
            }
            else {
                if (companyId) {
                    let params = {
                        step: 1,
                        company_id: companyId,
                        company_name: info.companyName,
                        doing_business_as: info.businessAds,
                        website: info.websiteUrl,
                        business_address: info.businessAddress,
                        mailing_address: info.mailingAddress,
                        social_media_handles: info.socialMedia,
                        tax_id: info.taxId,
                        diversity_owned_business_id: businessOwner ? businessOwner : businessId
                    }
                    dispatch(diversityCertification({
                        ...params, cb(res) {
                            if (save == "save&finish") {
                                navigate('/dashboard');
                                // nextPage("pagetwo")
                                // setPageNumber(2)
                            }
                            else if (res.status) {
                                // setCertificationId(res?.data?.payload?.id)
                                nextPage("pagetwo")
                                setPageNumber(2)

                            }
                        }
                    }))
                }
            }
        }
        else if (flag == 2) {
            if (!infoCont.businessOwnership) {
                toast.error("Please choose business owner")
                return;
            }
            else if (!infoCont.businessGenesis) {
                toast.error("Please choose business genesis")
                return;
            }
            else if (businessGenesisId?.includes(5) && !businessGenesisOtherDescription) {
                toast.error("please enter other description");
                return;
            }
            else if (businessGenesisId?.includes(5) && businessGenesisOtherDescription.trim() === "") {
                toast.error("Business Genesis Other Description  details can't be empty")
                return;
            }
            else if (!infoCont.professionalLicense) {
                toast.error("Please enter professional or operational license")
                return;
            }
            else if (infoCont.professionalLicense.trim() === "") {
                toast.error("Professional or operational license can't be empty")
                return;
            }
            else if (!infoCont.occupationalLicense) {
                toast.error("Please enter occupational license")
                return;
            }
            else if (infoCont.occupationalLicense.trim() === "") {
                toast.error("Occupational license can't be empty")
                return;
            }
            else if (!infoCont.formationYear) {
                toast.error("Please enter year of formation")
                return;
            }
            else if (!infoCont.numberOfEmployees) {
                toast.error("Please enter number of employees")
                return;
            }
            else if (!infoCont.geographicMarket) {
                toast.error("Please enter geographic market")
                return;
            }
            else if (infoCont.geographicMarket.trim() === "") {
                toast.error("Geographic Market can't be empty")
                return;
            }
            else {

                let params = {
                    step: 2,
                    user_diversity_certificates_id: certificationId,
                    business_ownership_type: parseInt(infoCont.businessOwnership),
                    professional_licenses: infoCont.professionalLicense,
                    business_genesis: [infoCont.businessGenesis],
                    occupational_licenses: infoCont.occupationalLicense,
                    year_of_information: parseInt(infoCont.formationYear),
                    number_of_employees: parseInt(infoCont.numberOfEmployees),
                    geographic_market: infoCont.geographicMarket
                }
                if (businessGenesisOtherDescription) {
                    params = {
                        ...params,
                        business_genesis_other: businessGenesisOtherDescription,
                    }
                }
                dispatch(diversityCertification({
                    ...params, cb(res) {
                        if (save == "save&finish") {
                            navigate('/dashboard');
                            // localStorage.removeItem('authToken');
                            // localStorage.removeItem('persist:root');
                            // navigate('/');
                        }
                        else if (res.status) {
                            nextPage("pagethree")
                            setPageNumber(3)
                            setBusinessGenesisOtherDescription("")
                        }
                    }
                }))
            }
        }
        else if (flag == 3) {
            if (!infoFinal.industry) {
                toast.error("Please choose industry")
                return;
            }
            else if (!infoFinal.subIndustry) {
                toast.error("Please choose sub industry")
                return;
            }
            else if (!infoFinal.products) {
                toast.error("Please enter products and services details")
                return
            }
            else if (infoFinal.products.trim() === "") {
                toast.error("Products and services details can't be empty")
                return;
            }
            else if (!infoFinal.coreSkills) {
                toast.error("Please enter core skills ")
                return
            }
            else if (infoFinal.coreSkills.trim() === "") {
                toast.error("Core skills  details can't be empty")
                return;
            }
            else if (!infoFinal.businessHistory) {
                toast.error("Please enter business history")
                return
            }
            else if (infoFinal.businessHistory.trim() === "") {
                toast.error("Business History  details can't be empty")
                return;
            }
            else {
                let params = {
                    step: 3,
                    user_diversity_certificates_id: certificationId,
                    industry: parseInt(infoFinal.industry),
                    sub_industry: parseInt(infoFinal.subIndustry),
                    products_and_services: infoFinal.products,
                    core_competencies_and_skills: infoFinal.coreSkills,
                    business_history: infoFinal.businessHistory,
                }
                dispatch(diversityCertification({
                    ...params, cb(res) {
                        if (save == "save&finish") {
                            navigate('/dashboard');
                            // localStorage.removeItem('authToken');
                            // localStorage.removeItem('persist:root');
                            // navigate('/');
                        }
                        else if (res.status) {
                            nextPage("pagefour")
                            setPageNumber(4)
                        }
                    }
                }))
            }

        }
        else if (flag == 4) {
            if (!diversityInfo.businessStatus) {
                toast.error("Please enter business status");
                return;
            }
            else if (driversSupplierDescriptionId?.includes(16) && !companyOtherDescription) {
                toast.error("please enter other description");
                return;
            }
            else if (driversSupplierDescriptionId?.includes(16) && companyOtherDescription.trim() === "") {
                toast.error("Company Other Description  details can't be empty")
                return;
            }
            else if (!diversityInfo.existingCertification) {
                toast.error("Please choose exisiting certification");
                return;
            }
            else if (existingCertificationsId?.includes(11) && !certificationOtherDescription) {
                toast.error("please enter other certificate description");
                return;
            }
            else if (existingCertificationsId?.includes(11) && certificationOtherDescription.trim() === "") {
                toast.error("Certification Other Description  details can't be empty")
                return;
            }
            else if (!diversityInfo.formerCertification) {
                toast.error("Please enter former certification");
                return;
            }

            else if (formerCertificationId?.includes(11) && !formerOtherDescription) {
                toast.error("please enter former other  description");
                return;
            }
            else if (formerCertificationId?.includes(11) && formerOtherDescription.trim() === "") {
                toast.error("Former Other Description  details can't be empty")
                return;
            }
            else if (!diversityInfo.tradeCertification) {
                toast.error("Please enter trade certification");
                return;
            }
            else if (tradeCertificationId?.includes(11) && !tradeOtherDescription) {
                toast.error("please enter trade other  description");
                return;
            }
            else if (tradeCertificationId?.includes(11) && tradeOtherDescription.trim() === "") {
                toast.error(" Trade other  description can't be empty");
                return;
            }
            else {
                let params = {
                    step: 4,
                    user_diversity_certificates_id: certificationId,
                    diverse_business_status: diversityInfo.businessStatus,
                    existing_certifications: (diversityInfo.existingCertification),
                    former_certification: diversityInfo.formerCertification,
                    trade_certification: diversityInfo.tradeCertification,
                }
                if (companyOtherDescription) {
                    params = {
                        ...params,
                        diverse_business_status_other: companyOtherDescription,
                    }
                }
                if (certificationOtherDescription) {
                    params = {
                        ...params,
                        existing_certifications_other: certificationOtherDescription,
                    }
                }
                if (formerOtherDescription) {
                    params = {
                        ...params,
                        former_certification_other: formerOtherDescription,
                    }
                }
                if (tradeOtherDescription) {
                    params = {
                        ...params,
                        trade_certification_other: tradeOtherDescription,
                    }
                }

                dispatch(diversityCertification({
                    ...params, cb(res) {
                        if (save == "save&finish") {
                            navigate('/dashboard');
                        }
                        else if (res.status) {
                            nextPage("pagefive")
                            setPageNumber(5)
                        }
                    }
                }))
            }
        }
        else if (flag == 5) {
            if (!diversityInfoCont.ownerName) {
                toast.error("Please enter owner full name");
                return;
            }
            else if (diversityInfoCont.ownerName.trim() === "") {
                toast.error("Owner full name can't be empty");
                return;
            }
            else if (!diversityInfoCont.ownershipPercentage) {
                toast.error("Please enter percentage of ownership");
                return;
            }
            // else if (diversityInfoCont.ownershipPercentage > 1 && diversityInfoCont.ownershipPercentage  )  {
            //     toast.error("Percentage can't be more than 100");
            //     return;
            // }
            else if (!diversityInfoCont.ownershipDate) {
                toast.error("Please enter date of ownership acquired");
                return;
            }
            else if (!diversityInfoCont.ownerEmail) {
                toast.error("Please enter email");
                return;
            }
            else if (!diversityInfoCont.ownerGender) {
                toast.error("Please choose gender");
                return;
            }
            else if (!diversityInfoCont.ownerEthnicity) {
                toast.error("Please enter ethnicity");
                return;
            }
            else if (selectedEthnicityId?.includes(8) && !ethnictyOtherDescription) {
                toast.error("please enter ethnicity other  description");
                return;
            }
            else if (selectedEthnicityId?.includes(8) && ethnictyOtherDescription.trim() === "") {
                toast.error("Ethnicity other  description can't be empty");
                return;
            }
            else if (!diversityInfoCont.ownerLicense) {
                toast.error("Please enter license");
                return;
            }
            else if (diversityInfoCont.ownerLicense.trim() === "") {
                toast.error("License details can't be empty");
                return;
            }
            else {
                let params = {
                    step: 5,
                    user_diversity_certificates_id: certificationId,
                    business_owner_full_name: diversityInfoCont.ownerName,
                    percentage_of_ownership: parseInt(diversityInfoCont.ownershipPercentage),
                    date_ownership_acquired: moment(diversityInfoCont.ownershipDate).format('YYYY-MM-DD'),
                    // date_of_birth: moment(diversityInfoCont.ownerDob).format('YYYY-MM-DD'),
                    email: diversityInfoCont.ownerEmail,
                    gender: parseInt(diversityInfoCont.ownerGender),
                    ethnicity: diversityInfoCont.ownerEthnicity,
                    licenses_and_accreditations: diversityInfoCont.ownerLicense
                }
                if (ethnictyOtherDescription) {
                    params = {
                        ...params,
                        ethnicity_other: ethnictyOtherDescription,
                    }
                }
                dispatch(diversityCertification({
                    ...params, cb(res) {
                        if (save == "save&finish") {
                            navigate('/dashboard');
                        }
                        else if (res.status) {
                            nextPage("pagesix")
                            setPageNumber(6)
                        }
                    }
                }))
            }
        }
        else if (flag == 6) {
            const additionalOwnersNotEmpty = additionalOwners.some(owner => { return Object.values(owner).some(value => value === "") });
            const AdditionalOwnerHasEmptySpaces = additionalOwners.some(officer => {
                return Object.values(officer).some(value => {
                    const isString = typeof value === 'string';
                    const hasEmptySpace = isString && value.trim() === "";

                    if (hasEmptySpace) {
                        console.error(`Empty space found in company officer: ${value}`);
                    }

                    return isString && hasEmptySpace;
                });
            });

            const boardMembersNotEmpty = boardMember.some(member => { return Object.values(member).some(value => value === "") });
            const BoardMemberHasEmptySpaces = boardMember.some(officer => {
                return Object.values(officer).some(value => {
                    const isString = typeof value === 'string';
                    const hasEmptySpace = isString && value.trim() === "";

                    if (hasEmptySpace) {
                        console.error(`Empty space found in company officer: ${value}`);
                    }

                    return isString && hasEmptySpace;
                });
            });

            const companyOfficersNotEmpty = companyOfficer.some(officer => { return Object.values(officer).some(value => value === "") });
            const CompanyOfficerHasEmptySpaces = companyOfficer.some(officer => {
                return Object.values(officer).some(value => {
                    const isString = typeof value === 'string';
                    const hasEmptySpace = isString && value.trim() === "";

                    if (hasEmptySpace) {
                        console.error(`Empty space found in company officer: ${value}`);
                    }

                    return isString && hasEmptySpace;
                });
            });

            const totalOwnershipPercentage = additionalOwners.reduce((total, obj) => total + parseInt(obj.ownership), 0);
            const totalPercentage = parseInt(diversityInfoCont?.ownershipPercentage) + parseInt(totalOwnershipPercentage)

            if (additionalOwnersNotEmpty || boardMembersNotEmpty || companyOfficersNotEmpty !== "") {
                let params = {
                    step: 6,
                    user_diversity_certificates_id: certificationId,
                }
                if (additionalOwnersNotEmpty) {
                    // Display an error message for empty fields
                    toast.error("Please fill in all fields for additional owners");
                    return; // Stop further processing
                }
                else if (AdditionalOwnerHasEmptySpaces) {
                    toast.error("Additional Owner fields can't be empty");
                    return; // Stop further processing
                }
                else if (totalPercentage > 100) {
                    toast.error("Kindly check the ownership percentage. It can't be more than 100!!");
                    return; // Stop further processing
                }
                else {
                    params.additional_company_owner = additionalOwners;
                }

                if (companyOfficersNotEmpty) {
                    toast.error("Please fill in all fields for company officer");
                    return; // Stop further processing
                }
                else if (CompanyOfficerHasEmptySpaces) {
                    toast.error("Company officer fields can't be empty");
                    return; // Stop further processing
                }
                else {
                    params.company_officer = companyOfficer;
                }
                if (boardMembersNotEmpty) {
                    toast.error("Please fill in all fields for board member");
                    return; // Stop further processing
                }
                else if (BoardMemberHasEmptySpaces) {
                    toast.error("Board Member fields can't be empty");
                    return; // Stop further processing
                }
                else {
                    params.board_member = boardMember;
                }

                dispatch(diversityCertification({
                    ...params, cb(res) {
                        if (save == "save&finish") {
                            navigate('/dashboard');
                        }
                        else if (res.status) {
                            nextPage("pageseven")
                            setPageNumber(7)
                        }
                    }
                }))
            }

            else {
                nextPage("pageseven")
                setPageNumber(7)
            }
        }
        else if (flag == 7) {
            if (myDocumentList.length === 0) {
                toast.error("Please choose atleast 1 image");
                return;
            }
            else {
                let params = {
                    step: 7,
                    user_diversity_certificates_id: certificationId,
                    verification_documents: documentList
                }
                dispatch(diversityCertification({
                    ...params, cb(res) {
                        toast.success("Thank you for completing certifications")
                        navigate("/dashboard")
                        //   nextPage("pageone")
                        emptyDiverseCertificates()
                    }
                }))
            }
        }
    }

    const emptyDiverseCertificates = () => {
        let params = {}
        dispatch(setDiverseStatusEmpty({
            ...params, cb(res) {

            }
        }))
    }


    const getDiversityCertificationData = () => {
        dispatch(getDiversityCertification({
            cb(res) {
                if (res.status) {
                    setCertificateData(res?.data?.payload[0])
                    setBusinessId(res?.data?.payload[0]?.diversity_owned_business)
                    setInfo({
                        ...info,
                        companyName: res?.data?.payload[0]?.company_name,
                        businessAddress: res?.data?.payload[0]?.business_address,
                        businessAds: res?.data?.payload[0]?.doing_business_as,
                        mailingAddress: res?.data?.payload[0]?.mailing_address,
                        websiteUrl: res?.data?.payload[0]?.website,
                        taxId: res?.data?.payload[0]?.tax_id,
                        socialMedia: res?.data?.payload[0]?.social_media_handles
                    })

                    const selectedBusinessGenesisId = res?.data?.payload[0]?.diversity_business_genesis.map((data) => { return data?.business_genesis_id })
                    const selectedBusinessGenesis = res?.data?.payload[0]?.diversity_business_genesis.map(item => item.business_genesis_details);
                    if (res?.data?.payload[0]?.business_ownership_type !== null) {
                        if (selectedBusinessGenesisId[0] == 5) {
                            setInfoCont({
                                ...infoCont,
                                businessOwnership: res?.data?.payload[0]?.business_ownership_type,
                                businessGenesis: selectedBusinessGenesisId[1],
                                professionalLicense: res?.data?.payload[0]?.professional_licenses,
                                occupationalLicense: res?.data?.payload[0]?.occupational_licenses,
                                formationYear: res?.data?.payload[0]?.year_of_information,
                                numberOfEmployees: res?.data?.payload[0]?.number_of_employees,
                                geographicMarket: res?.data?.payload[0]?.geographic_market,
                            })
                            setSelectedBusinessGenesis(selectedBusinessGenesis)


                        }
                        else  {
                            setInfoCont({
                                ...infoCont,
                                businessOwnership: res?.data?.payload[0]?.business_ownership_type,
                                // businessGenesis:  [otherSelectedBusinessGenesis[1].id] ? [otherSelectedBusinessGenesis[1].id] :  selectedBusinessGenesisId ,

                                businessGenesis: selectedBusinessGenesisId[0],
                                professionalLicense: res?.data?.payload[0]?.professional_licenses,
                                occupationalLicense: res?.data?.payload[0]?.occupational_licenses,
                                formationYear: res?.data?.payload[0]?.year_of_information,
                                numberOfEmployees: res?.data?.payload[0]?.number_of_employees,
                                geographicMarket: res?.data?.payload[0]?.geographic_market,
                            })
                            setSelectedBusinessGenesis(selectedBusinessGenesis)

                        }

                    }

                    if (res?.data?.payload[0]?.industry !== null) {
                        setIndustryGroupId(res?.data?.payload[0]?.industry)
                        setIndustrySubGroupId(res?.data?.payload[0]?.sub_industry)
                        setInfoFinal({
                            ...infoFinal,
                            industry: res?.data?.payload[0]?.industry,
                            subIndustry: res?.data?.payload[0]?.sub_industry,
                            products: res?.data?.payload[0]?.products_and_services,
                            coreSkills: res?.data?.payload[0]?.core_competencies_and_skills,
                            businessHistory: res?.data?.payload[0]?.business_history,
                        })

                    }


                    const selectedDiverseStatusId = res?.data?.payload[0]?.diverse_business_status_details.map((data) => { return data?.diverse_business_status_id })

                    const selectedDiverseStatus = res?.data?.payload[0]?.diverse_business_status_details.map(item => item.diverse_business_status_details);

                    const selectedExistingCertificationId = res?.data?.payload[0]?.diversity_existing_certificates.map((data) => { return data?.existing_certification_id })

                    const selectedExistingCertification = res?.data?.payload[0]?.diversity_existing_certificates.map(item => item.existing_certificates_details);

                    const selectedFormerCertificationId = res?.data?.payload[0]?.diversity_former_certification_details.map((data) => { return data?.former_certification_id })

                    const selectedFormerCertification = res?.data?.payload[0]?.diversity_former_certification_details.map(item => item.former_certificates_details);

                    const selectedTradeCertificationId = res?.data?.payload[0]?.diversity_trade_certification_details.map((data) => { return data?.trade_certification_id })

                    const selectedTradeCertification = res?.data?.payload[0]?.diversity_trade_certification_details.map(item => item.trade_certification_details);


                    if (selectedDiverseStatus.length > 0) {
                        setSelected(selectedDiverseStatus)
                        setSelectedCertification(selectedExistingCertification)
                        setSelectedFormerCertification(selectedFormerCertification)
                        setSelectedTradeMembership(selectedTradeCertification)
                        setDiversityInfo({
                            ...diversityInfo,
                            businessStatus: selectedDiverseStatusId,
                            existingCertification: selectedExistingCertificationId,
                            formerCertification: selectedFormerCertificationId,
                            tradeCertification: selectedTradeCertificationId
                        })
                    }

                    const companyOfficerName = res?.data?.payload[0]?.diversity_company_officer_details.map((data) => { return data?.company_officer_full_name })
                    const additionalOwnerName = res?.data?.payload[0]?.diversity_additional_owners_details.map((data) => { return data?.company_owner_full_name })
                    const boardMemberName = res?.data?.payload[0]?.diversity_board_member_details?.map((data) => { return data?.board_member_full_name })

                    if (res?.data?.payload[0]?.diversity_additional_owners_details.length > 0) {

                        const updatedAdditionalOwners = res.data.payload[0].diversity_additional_owners_details.map((owner, index) => ({
                            ...owner,
                            name: additionalOwnerName[index] // Add the corresponding name from additionalOwnerName
                        }));

                        setAdditionalOwners(updatedAdditionalOwners);
                    }

                    if (res?.data?.payload[0]?.diversity_company_officer_details.length > 0) {
                        const updatedAdditionalOwners = res.data.payload[0].diversity_company_officer_details.map((owner, index) => ({
                            ...owner,
                            name: companyOfficerName[index] // Add the corresponding name from additionalOwnerName
                        }));
                        setCompanyOfficer(updatedAdditionalOwners)
                    }

                    if (res?.data?.payload[0]?.diversity_board_member_details.length > 0) {
                        const updatedAdditionalOwners = res.data.payload[0].diversity_board_member_details.map((owner, index) => ({
                            ...owner,
                            name: boardMemberName[index] // Add the corresponding name from additionalOwnerName
                        }));
                        setBoardMember(updatedAdditionalOwners)

                    }

                    const selectedEthnicityId = res?.data?.payload[0]?.user_diversity_ethnicity.map((data) => { return data?.ethnicity_id })

                    const selectedEthnicity = res?.data?.payload[0]?.user_diversity_ethnicity.map(item => item.ethnicity_details);

                    if (res?.data?.payload[0]?.business_owner_full_name !== null) {

                        const dobDate = moment.utc(res?.data?.payload[0]?.date_of_birth);
                        const ownerDate = dobDate.toDate()

                        const ownershipDate = moment.utc(res?.data?.payload[0]?.date_ownership_acquired)
                        const ownershipAccquiredDate = ownershipDate.toDate()
                        setSelectedEthnicity(selectedEthnicity)

                        setDiversityInfoCont({
                            ...diversityInfoCont,
                            ownerName: res?.data?.payload[0]?.business_owner_full_name,
                            ownershipPercentage: res?.data?.payload[0]?.percentage_of_ownership,
                            // ownerDob:`${moment(res?.data?.payload[0]?.date_of_birth).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ ')}(India Standard Time)`,
                            ownerEmail: res?.data?.payload[0]?.email,
                            // ownershipDate:`${moment(res?.data?.payload[0]?.date_ownership_acquired).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ ')}(India Standard Time)`,
                            ownershipDate: ownershipAccquiredDate,
                            ownerGender: res?.data?.payload[0]?.gender,
                            ownerEthnicity: selectedEthnicityId,
                            ownerLicense: res?.data?.payload[0]?.licenses_and_accreditations
                        })
                    }
                }
                else {

                }
            }
        }))
    }

    const openPdf = (e, format) => {
        e.preventDefault();
        // Replace 'your_pdf_file.pdf' with the actual file path or URL of the PDF you want to open.
        window.open(format, '_blank');
    };

    useEffect(() => {
        if (flag >= 1) {
            getDiversityCertificationData()
        }
    }, [getCertificationData])


    useEffect(() => {
        UpdateExistingCertifications()
        UpdateTradeMemberships()
        businessOwnership()
        businessGenesisType()
        ethnicityListType()
        supplierDescription()
        industry()
    }, []);

    useEffect(() => {
        if (industryGroupId) {
            subIndustry(industryGroupId)
        }
    }, [industryGroupId])

    return (
        <>
            <div className='registrationStep'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7' id="leftArea" >

                            <div className='formContainer'>
                                <div className='mb-3'>
                                    <div className='stepBox'>
                                        {pageNumber > 1 ?
                                            <div className='text-end'>
                                                <button className='' onClick={() => backPageNumber()}><i className="las la-arrow-left backIcon"></i></button>
                                            </div>
                                            : ""}
                                        <button className='stepBtn'>Step {pageNumber}</button>
                                    </div>
                                    <div className='stepProgress mt-4 mb-4'>
                                        <MultiStepProgressBar
                                            onPageNumberClick={(e) => { return false }}
                                            page={page} />
                                        {
                                            certificationData.loading ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-sm-12 text-center pt-5 pb-5">
                                                            <div className="spinner-grow text-success" role="status"></div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        {
                                                            pageone:
                                                                <div className='row mt-4'>
                                                                    <h3 className='headTxtBlue mb-4'><b>Business Information</b></h3>
                                                                    <form onSubmit={(e) => handleSubmit(e, "1")} className='registrationForm row mt-3'>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <input name="companyName" type='text' className='customFormControl' value={info.companyName} placeholder='Company Name'
                                                                                onChange={(e) => setInfo({
                                                                                    ...info,
                                                                                    companyName: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <input name="businessAds" type='text' className='customFormControl' value={info.businessAds} placeholder='DBA (Doing Business As)'
                                                                                onChange={(e) => setInfo({
                                                                                    ...info,
                                                                                    businessAds: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <input name="businessAddress" type='text' className='customFormControl' value={info.businessAddress} placeholder='Business Address'
                                                                                onChange={(e) => setInfo({
                                                                                    ...info,
                                                                                    businessAddress: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <input name="mailingAddress" type='text' className='customFormControl' value={info.mailingAddress} placeholder='Mailing Address (if different from business address)'
                                                                                onChange={(e) => setInfo({
                                                                                    ...info,
                                                                                    mailingAddress: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <input name="websiteUrl" type='text' className='customFormControl' value={info.websiteUrl} placeholder='Website'
                                                                                onChange={(e) => setInfo({
                                                                                    ...info,
                                                                                    websiteUrl: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <input name="taxId" type='number' className='customFormControl' value={info.taxId} placeholder='Tax ID'
                                                                                onChange={(e) => setInfo({
                                                                                    ...info,
                                                                                    taxId: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <input name="socialMedia" type='text' className='customFormControl' value={info.socialMedia} placeholder='Social Media Handles'
                                                                                onChange={(e) => setInfo({
                                                                                    ...info,
                                                                                    socialMedia: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='col-md-12 mt-3'>
                                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                                <p
                                                                                    onClick={(e) => handleSubmit(e, "1", "save&finish")}
                                                                                    className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                                <input
                                                                                    className='primaryBtn d-inline-block'
                                                                                    type="submit"
                                                                                    value="Continue"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            ,
                                                            pagetwo:
                                                                <div className='row mt-4'>
                                                                    <h3 className='headTxtBlue mb-4'><b>Business Information (cont)</b></h3>
                                                                    <form onSubmit={(e) => handleSubmit(e, "2")} className='registrationForm row mt-3'>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <select className="cateSelectbox" required="" onChange={(e) => { businessOwnership(e.target.value) }} value={infoCont?.businessOwnership}>
                                                                                <option value="0">Types of Business</option>
                                                                                {ownershipType?.length > 0 ?
                                                                                    <>
                                                                                        {ownershipType?.map((data) => {
                                                                                            return <option value={data?.id}>{data?.name}</option>
                                                                                        })}
                                                                                    </>
                                                                                    : ""}
                                                                            </select>
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            {/* {genesisType &&
                                                                                <Multiselect
                                                                                    options={genesisType} // Options to display in the dropdown
                                                                                    selectedValues={selectedBusinessGenesis} // Preselected value to persist in dropdown
                                                                                    onSelect={handleBusinessGenesisChanges} // Function will trigger on select event
                                                                                    onRemove={handleBusinessGenesisChanges} // Function will trigger on remove event
                                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                                    placeholder="Diverse Business Genesis"
                                                                                    className='selectIndex'
                                                                                />
                                                                            } */}


                                                                            <select className="cateSelectbox" required="" onChange={(e) => { handleBusinessGenesisChanges(e.target.value) }} value={infoCont?.businessGenesis}>
                                                                                <option value="0">Select Business Genesis</option>
                                                                                {genesisType?.length > 0 ?
                                                                                    <>
                                                                                        {genesisType?.map((data) => {
                                                                                            return <option value={data?.id}>{data?.name}</option>
                                                                                        })}
                                                                                    </>
                                                                                    : ""}
                                                                            </select>



                                                                            {/* {businessGenesisId?.includes(5) ?
                                                                                <div className='form-group col-md-12 mb-3 mt-2'>
                                                                                    <input name="companyDescription" type='text' className='customFormControl' value={businessGenesisOtherDescription} placeholder='Other Description' disabled="" onChange={e => setBusinessGenesisOtherDescription(e.target.value)} />
                                                                                </div> : ""

                                                                            } */}
                                                                            {infoCont?.businessGenesis == "5" ?
                                                                                <div className='form-group col-md-12 mb-3 mt-2'>
                                                                                    <input name="companyDescription" type='text' className='customFormControl'
                                                                                        value={businessGenesisOtherDescription} placeholder='Other Description' disabled=""
                                                                                        onChange={e => setBusinessGenesisOtherDescription(e.target.value)}
                                                                                    />
                                                                                </div> : ""

                                                                            }
                                                                        </div>


                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <input name="professionalLicense" type='text' className='customFormControl' value={infoCont.professionalLicense} placeholder='Professional/Operating Licenses '
                                                                                onChange={(e) => setInfoCont({
                                                                                    ...infoCont,
                                                                                    professionalLicense: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <input name="occupationalLicense" type='text' className='customFormControl' value={infoCont.occupationalLicense} placeholder='Occupational licenses'
                                                                                onChange={(e) => setInfoCont({
                                                                                    ...infoCont,
                                                                                    occupationalLicense: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <input name="formationYear" type='number' className='customFormControl' value={infoCont.formationYear} placeholder='Year of Formation'
                                                                                onChange={(e) => setInfoCont({
                                                                                    ...infoCont,
                                                                                    formationYear: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <input name="numberOfEmployees" type='number' className='customFormControl' value={infoCont.numberOfEmployees} placeholder='Number of Employees'
                                                                                onChange={(e) => setInfoCont({
                                                                                    ...infoCont,
                                                                                    numberOfEmployees: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <input name="geographicMarket" type='text' className='customFormControl' value={infoCont.geographicMarket} placeholder='Geographic Market'
                                                                                onChange={(e) => setInfoCont({
                                                                                    ...infoCont,
                                                                                    geographicMarket: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='col-md-12 mt-3'>
                                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                                <p
                                                                                    onClick={(e) => handleSubmit(e, "2", "save&finish")}
                                                                                    className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                                <input
                                                                                    className='primaryBtn d-inline-block'
                                                                                    type="submit"
                                                                                    value="Continue"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>,
                                                            pagethree:
                                                                <div className='row mt-4'>
                                                                    <h3 className='headTxtBlue mb-4'><b>Business Information (final)</b></h3>
                                                                    <form onSubmit={(e) => handleSubmit(e, "3")} className='registrationForm row mt-3'>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            {/* <input name="companyName" type='text' className='customFormControl' value='' placeholder='Industry ' /> */}
                                                                            <select className="cateSelectbox" required="" value={industryGroupId} onChange={(e) => { handleChangeIndustry(e.target.value, "industry") }}
                                                                            >
                                                                                <option value="" >Select Industry Groups</option>
                                                                                {industryGroup?.length > 0 ?
                                                                                    <>
                                                                                        {industryGroup.map((data) => {
                                                                                            return <option value={data.id}>{data.name}</option>
                                                                                        })}

                                                                                    </>
                                                                                    : ""}
                                                                            </select>
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            {/* <input name="companyName" type='text' className='customFormControl' value='' placeholder='' /> */}
                                                                            <select className="cateSelectbox" required="" value={industrySubGroupId}
                                                                                onChange={(e) => { handleChangeIndustry(e.target.value, "subindustry"); }}
                                                                            // onChange={(e) => { subIndustry(e.target.value) }}

                                                                            >
                                                                                <option value="">Select Industry Sub Groups</option>
                                                                                {subIndustryGroup?.length > 0 ?
                                                                                    <>
                                                                                        {subIndustryGroup.map((data) => {
                                                                                            return <option value={data.id}>{data.name}</option>
                                                                                        })}
                                                                                    </>
                                                                                    : ""}
                                                                            </select>
                                                                        </div>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <textarea name="products" type='text' value={infoFinal.products} className='customFormControl' placeholder='Products and Services'
                                                                                onChange={(e) => setInfoFinal({
                                                                                    ...infoFinal,
                                                                                    products: e.target.value
                                                                                })} ></textarea>
                                                                        </div>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <textarea name="coreSkills" type='text' value={infoFinal.coreSkills} className='customFormControl' placeholder='Core Competencies and Skills'
                                                                                onChange={(e) => setInfoFinal({
                                                                                    ...infoFinal,
                                                                                    coreSkills: e.target.value
                                                                                })}
                                                                            ></textarea>
                                                                        </div>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <textarea name="businessHistory" type='text' value={infoFinal.businessHistory} className='customFormControl' placeholder='Business History'
                                                                                onChange={(e) => setInfoFinal({
                                                                                    ...infoFinal,
                                                                                    businessHistory: e.target.value
                                                                                })}
                                                                            ></textarea>
                                                                        </div>
                                                                        <div className='col-md-12 mt-3'>
                                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                                <p
                                                                                    onClick={(e) => handleSubmit(e, "3", "save&finish")}
                                                                                    className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                                <input
                                                                                    className='primaryBtn d-inline-block'
                                                                                    type="submit"
                                                                                    value="Continue"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>,
                                                            pagefour:
                                                                <div className='row mt-4'>
                                                                    <h3 className='headTxtBlue mb-4'><b>Business Diversity Information</b></h3>
                                                                    <form onSubmit={(e) => handleSubmit(e, "4")} className='registrationForm row mt-3'>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            {/* <input name="businessStatus" type='number' className='customFormControl' value={diversityInfo.businessStatus} placeholder='Diverse Business Status '
                                                                onChange={(e) => setDiversityInfo({
                                                                    ...diversityInfo,
                                                                    businessStatus: e.target.value
                                                                })}
                                                            /> */}
                                                                            {companyDescription &&
                                                                                <Multiselect
                                                                                    options={companyDescription} // Options to display in the dropdown
                                                                                    selectedValues={selected} // Preselected value to persist in dropdown
                                                                                    onSelect={handleChanges} // Function will trigger on select event
                                                                                    onRemove={handleChanges} // Function will trigger on remove event
                                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                                    placeholder="Diverse Business Status " // Plac
                                                                                />
                                                                            }
                                                                        </div>
                                                                        {driversSupplierDescriptionId?.includes(16) ?
                                                                            <div className='form-group col-md-12 mb-3'>
                                                                                <input name="companyDescription" type='text' className='customFormControl' value={companyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCompanyOtherDescription(e.target.value)} />
                                                                            </div> : ""

                                                                        }
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            {/* <textarea name="companyservicesdescription" type='text' className='customFormControl' placeholder=''  ></textarea> */}
                                                                            {/* existing certification */}
                                                                            {getExistingCertifications &&
                                                                                <Multiselect
                                                                                    options={getExistingCertifications} // Options to display in the dropdown
                                                                                    selectedValues={selectedCertification} // Preselected value to persist in dropdown
                                                                                    onSelect={handleCertificationsChanges} // Function will trigger on select event
                                                                                    onRemove={handleCertificationsChanges} // Function will trigger on remove event
                                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                                    placeholder="Existing Certification "
                                                                                    className='selectIndex'
                                                                                />
                                                                            }
                                                                        </div>
                                                                        {existingCertificationsId?.includes(11) ?
                                                                            <div className='form-group col-md-12 mb-3'>
                                                                                <input name="companyDescription" type='text' className='customFormControl' value={certificationOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCertificationOtherDescription(e.target.value)} />
                                                                            </div> : ""

                                                                        }
                                                                        <div className='form-group col-md-12 mb-3'>

                                                                            {/* <input name="businessStatus" type='number' className='customFormControl' value={diversityInfo.formerCertification} placeholder='Former Certifications'
                                                                onChange={(e) => setDiversityInfo({
                                                                    ...diversityInfo,
                                                                    formerCertification: e.target.value
                                                                })}
                                                            /> */}
                                                                            {/* */}
                                                                            {getExistingCertifications &&
                                                                                <Multiselect
                                                                                    options={getExistingCertifications} // Options to display in the dropdown
                                                                                    selectedValues={selectedFormerCertification} // Preselected value to persist in dropdown
                                                                                    onSelect={handleCertificationsFormerChanges} // Function will trigger on select event
                                                                                    onRemove={handleCertificationsFormerChanges} // Function will trigger on remove event
                                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                                    placeholder="Former Certification "
                                                                                    className='selectIndex'
                                                                                />
                                                                            }
                                                                        </div>
                                                                        {formerCertificationId?.includes(11) ?
                                                                            <div className='form-group col-md-12 mb-3'>
                                                                                <input name="companyDescription" type='text' className='customFormControl' value={formerOtherDescription} placeholder='Other Description' disabled="" onChange={e => setFormerOtherDescription(e.target.value)} />
                                                                            </div> : ""

                                                                        }
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            {/* trade membership */}
                                                                            {getTradeMemberships &&
                                                                                <Multiselect
                                                                                    options={getTradeMemberships} // Options to display in the dropdown
                                                                                    selectedValues={selectedTradeMembership} // Preselected value to persist in dropdown
                                                                                    onSelect={handleTradeMembership} // Function will trigger on select event
                                                                                    onRemove={handleTradeMembership} // Function will trigger on remove event
                                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                                    placeholder="Trade Membership"
                                                                                    className='selectIndex'
                                                                                />
                                                                            }
                                                                            {/* <input name="businessStatus" type='number' className='customFormControl' value={diversityInfo.tradeCertification} placeholder='Trade Certifications'
                                                                onChange={(e) => setDiversityInfo({
                                                                    ...diversityInfo,
                                                                    tradeCertification: e.target.value
                                                                })}
                                                            /> */}
                                                                        </div>
                                                                        {tradeCertificationId?.includes(11) ?
                                                                            <div className='form-group col-md-12 mb-3'>
                                                                                <input name="companyDescription" type='text' className='customFormControl' value={tradeOtherDescription} placeholder='Other Description' disabled="" onChange={e => setTradeOtherDescription(e.target.value)} />
                                                                            </div> : ""

                                                                        }
                                                                        <div className='col-md-12 mt-3'>
                                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                                <p
                                                                                    onClick={(e) => handleSubmit(e, "4", "save&finish")}
                                                                                    className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                                <input
                                                                                    className='primaryBtn d-inline-block'
                                                                                    type="submit"
                                                                                    value="Continue"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>,
                                                            pagefive:
                                                                <div className='row mt-4'>
                                                                    <h3 className='headTxtBlue mb-4'><b>Business Diversity Information (cont.)</b></h3>
                                                                    <form onSubmit={(e) => handleSubmit(e, "5")} className='registrationForm row mt-3'>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <input name="companyName" type='text' className='customFormControl' value={diversityInfoCont.ownerName} placeholder='Business Owner Full Name'
                                                                                onChange={(e) => setDiversityInfoCont({
                                                                                    ...diversityInfoCont,
                                                                                    ownerName: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <input name="ownershipPercentage" min="1" max="100" type='number' className='customFormControl' value={diversityInfoCont.ownershipPercentage} placeholder='Percentage of Ownership'
                                                                                onChange={(e) => setDiversityInfoCont({
                                                                                    ...diversityInfoCont,
                                                                                    ownershipPercentage: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <div className='customTypeDate position-relative'>
                                                                                {/* <input name="ownershipDate" type='text' className='customFormControl' value={diversityInfoCont.ownershipDate} placeholder='Date Ownership Aquired'
                                                                onChange={(e) => setDiversityInfoCont({
                                                                    ...diversityInfoCont,
                                                                    ownershipDate: e.target.value
                                                                })}
                                                            /> */}
                                                                                <DatePicker
                                                                                    selected={diversityInfoCont?.ownershipDate}
                                                                                    // selected={new Date(diversityInfoCont.ownershipDate) ??  new Date()}
                                                                                    onChange={handleDateAccquired}
                                                                                    showYearDropdown
                                                                                    scrollableYearDropdown
                                                                                    yearDropdownItemNumber={100}
                                                                                    placeholderText="Date Ownership Aquired" // Placeholder text for the input
                                                                                    className='customFormControl w-100'
                                                                                />
                                                                                <img src={Images.calendaratach} alt='calendar' className='img-fluid inputIcon' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <input name="ownershipDate" type='email' className='customFormControl' value={diversityInfoCont.ownerEmail} placeholder='Enter email'
                                                                                onChange={(e) => setDiversityInfoCont({
                                                                                    ...diversityInfoCont,
                                                                                    ownerEmail: e.target.value
                                                                                })}
                                                                            />
                                                                            {/* <div className='customTypeDate position-relative'>
                                                                                <DatePicker
                                                                                    selected={diversityInfoCont?.ownerDob}
                                                                                    onChange={handleDateChange}
                                                                                    showYearDropdown
                                                                                    scrollableYearDropdown
                                                                                    yearDropdownItemNumber={100}
                                                                                    placeholderText="Click to select date" // Placeholder text for the input
                                                                                    className='customFormControl w-100'
                                                                                />
                                                                                <img src={Images.calendaratach} alt='calendar' className='img-fluid inputIcon' />
                                                                            </div> */}
                                                                        </div>
                                                                        <div className='form-group col-md-6 mb-3'>
                                                                            <select value={diversityInfoCont.ownerGender} className='customFormControl bg-transparent' onChange={(e) => setDiversityInfoCont({
                                                                                ...diversityInfoCont,
                                                                                ownerGender: e.target.value
                                                                            })}>
                                                                                <option value="">Gender</option>
                                                                                <option value="1">Male</option>
                                                                                <option value="2">Female</option>
                                                                                <option value="3">Transgender</option>
                                                                                <option value="4">Non-Binary</option>
                                                                                <option value="5">Other</option>
                                                                                <option value="6">Prefer Not To Respond</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            {/* <select value={diversityInfoCont.ownerEthnicity} className='customFormControl bg-transparent' onChange={(e) => setDiversityInfoCont({
                                                                                ...diversityInfoCont,
                                                                                ownerEthnicity: e.target.value
                                                                            })}>
                                                                                <option value="">Ethnicity</option>
                                                                                <option value="1">Alaskan Native</option>
                                                                                <option value="2">Asian</option>
                                                                                <option value="3">Black(not of Hispanic Origin)</option>
                                                                                <option value="4">Hispanic (including persons of Mexican, Puerto Rican, Cuban and Central or South American Region)</option>
                                                                                <option value="5">Indigenous America</option>
                                                                                <option value="6">Native Hawaiian/Pacific Islander</option>
                                                                                <option value="7">White</option>
                                                                                <option value="8">Other</option>
                                                                            </select> */}

                                                                            {
                                                                                ethnicityType && <Multiselect
                                                                                    options={ethnicityType} // Options to display in the dropdown
                                                                                    selectedValues={selectedEthnicity} // Preselected value to persist in dropdown
                                                                                    onSelect={handleEthnicityChanges} // Function will trigger on select event
                                                                                    onRemove={handleEthnicityChanges} // Function will trigger on remove event
                                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                                    placeholder="Ethnicity"
                                                                                    className='selectIndex'
                                                                                />
                                                                            }

                                                                            {selectedEthnicityId?.includes(8) ?
                                                                                <div className='form-group col-md-12 mb-3'>
                                                                                    <input name="companyDescription" type='text' className='customFormControl' value={ethnictyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setEthnicityOtherDescription(e.target.value)} />
                                                                                </div> : ""

                                                                            }



                                                                        </div>
                                                                        <div className='form-group col-md-12 mb-3'>
                                                                            <input name="ownerLicense" type='text' className='customFormControl' value={diversityInfoCont.ownerLicense} placeholder='Licenses and Accreditations'
                                                                                onChange={(e) => setDiversityInfoCont({
                                                                                    ...diversityInfoCont,
                                                                                    ownerLicense: e.target.value
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <div className='col-md-12 mt-3'>
                                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                                <p
                                                                                    onClick={(e) => handleSubmit(e, "5", "save&finish")}
                                                                                    className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                                <input
                                                                                    className='primaryBtn d-inline-block'
                                                                                    type="submit"
                                                                                    value="Continue"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>,
                                                            pagesix:
                                                                <div className='row mt-4'>
                                                                    <h3 className='headTxtBlue mb-4'><b>Business Diversity Information (cont.)</b></h3>
                                                                    <form onSubmit={(e) => handleSubmit(e, "6")} className='registrationForm  mt-3'>
                                                                        {additionalOwners.map((owner, index) => (
                                                                            <>
                                                                                {additionalOwners.length > 1 ? <span className='plusFields' onClick={() => handleRemoveOwner(index)}><i class="fas fa-minus" ></i></span> : ""}


                                                                                <div key={index} className='row'>
                                                                                    <div className='form-group col-md-9 mb-3'>
                                                                                        <input
                                                                                            type='text'
                                                                                            className='customFormControl'
                                                                                            value={owner.name}
                                                                                            placeholder='Additional Company Owner Full Name'
                                                                                            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='form-group col-md-3 mb-3'>
                                                                                        <input
                                                                                            type='number'
                                                                                            className='customFormControl'
                                                                                            min="1"
                                                                                            max="100"
                                                                                            value={owner.ownership}
                                                                                            placeholder='Ownership %'
                                                                                            onChange={(e) => handleInputChange(index, 'ownership', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='form-group col-md-6 mb-2'>
                                                                                        <input
                                                                                            type='email'
                                                                                            className='customFormControl'
                                                                                            value={owner.email}
                                                                                            placeholder='Email'
                                                                                            onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='form-group col-md-6 mb-2'>
                                                                                        <input
                                                                                            type='text'
                                                                                            className='customFormControl'
                                                                                            value={owner.title}
                                                                                            placeholder='Title'
                                                                                            onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                                                                                        />

                                                                                    </div>
                                                                                </div>

                                                                            </>

                                                                        ))}
                                                                        <span className='plusFields' onClick={handleAddOwner}><i class="fas fa-plus" ></i></span>

                                                                        {companyOfficer.map((officer, index) => (
                                                                            <>
                                                                                {companyOfficer.length > 1 ? <span className='plusFields' onClick={() => handleRemoveOfficer(index)}><i class="fas fa-minus" ></i></span> : ""}
                                                                                <div key={index} className='row'>
                                                                                    <div className='form-group col-md-12 mb-3'>
                                                                                        <input type='text' className='customFormControl' value={officer.name} placeholder='Company Officer Full Name'
                                                                                            onChange={(e) => handleInputChangeCompanyOfficer(index, 'name', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='form-group col-md-6 mb-2'>
                                                                                        <input type='email' className='customFormControl' value={officer.email} placeholder='Email'
                                                                                            onChange={(e) => handleInputChangeCompanyOfficer(index, 'email', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='form-group col-md-6 mb-2'>
                                                                                        <input type='text' className='customFormControl' value={officer.title} placeholder='Title'
                                                                                            onChange={(e) => handleInputChangeCompanyOfficer(index, 'title', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </>
                                                                        ))}

                                                                        <span className='plusFields' onClick={handleAddCompanyOfficer}><i class="fas fa-plus"></i></span>

                                                                        {boardMember.map((member, index) => (
                                                                            <>
                                                                                {boardMember.length > 1 ? <span className='plusFields' onClick={() => handleRemoveBoardMember(index)}><i class="fas fa-minus" ></i></span> : ""}
                                                                                <div key={index} className='row'>
                                                                                    <div className='form-group col-md-12 mb-3'>
                                                                                        <input type='text' className='customFormControl' value={member.name} placeholder='Board Member Full Name'
                                                                                            onChange={(e) => handleInputChangeBoardMember(index, 'name', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='form-group col-md-6 mb-2'>
                                                                                        <input type='email' className='customFormControl' value={member.email} placeholder='Email'
                                                                                            onChange={(e) => handleInputChangeBoardMember(index, 'email', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='form-group col-md-6 mb-2'>
                                                                                        <input type='text' className='customFormControl' value={member.title} placeholder='Title'
                                                                                            onChange={(e) => handleInputChangeBoardMember(index, 'title', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ))}
                                                                        <span className='plusFields' onClick={handleAddBoardMember}><i class="fas fa-plus"></i></span>

                                                                        <div className='col-md-12 mt-3'>
                                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                                <p
                                                                                    onClick={(e) => handleSubmit(e, "6", "save&finish")}
                                                                                    className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p>
                                                                                <input
                                                                                    className='primaryBtn d-inline-block'
                                                                                    type="submit"
                                                                                    value="Continue"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </form >
                                                                </div>,
                                                            pageseven:
                                                                <div className='row mt-4'>
                                                                    <form onSubmit={(e) => handleSubmit(e, "7")} className='registrationForm row mt-3'>
                                                                        <h3 className='headTxtBlue mb-4'><b>Verification Documents </b></h3>

                                                                        <div className='col-md-12 mb-3'>
                                                                            <div className="uploadImgebox" >
                                                                                <div {...getRootProps({ className: "dropzone" })} >
                                                                                    <input className='d-none' {...getInputProps()} />
                                                                                    <p className='uploadbtnn'>Upload</p>
                                                                                    <p className='uploadText mt-2'>DOC, PDF, PNG, JPG file upload</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            myDocumentList?.length > 0 ?
                                                                                <>
                                                                                    <div className='col-md-12'>
                                                                                        <div className='row'>
                                                                                            {
                                                                                                myDocumentList.map((file, index) => (
                                                                                                    <>
                                                                                                        <div className='col-lg-2 col-md-3 col-3 mb-3'>
                                                                                                            <div className='docUpload'>
                                                                                                                <img
                                                                                                                    className='document'
                                                                                                                    src={
                                                                                                                        file.document_format === "application/pdf" ? Images.iconPdf
                                                                                                                            : file.document_format === "image/jpeg" ? Images.iconJpeg
                                                                                                                                : file.document_format === "image/png" ? Images.iconPng
                                                                                                                                    : file.document_format === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.document_format === "application/msword" ? Images.iconDoc : Images.iconJpeg
                                                                                                                    }
                                                                                                                />
                                                                                                                <button className='btn' onClick={(e) => documentDelete(file?.id, e)}>Delete</button>
                                                                                                                {file.document_format == "application/pdf" || file.document_format == "image/jpeg" || file.document_format == "image/jpg" || file.document_format === "image/png" || file.document_format === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?

                                                                                                                    <button onClick={(e) => openPdf(e, file.document)} className="viewBtn mt-2">
                                                                                                                        View
                                                                                                                    </button>


                                                                                                                    : null
                                                                                                                }
                                                                                                                {/* <button className='viewBtn mt-2'>View</button> */}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </>

                                                                                :
                                                                                ""
                                                                        }


                                                                        <div className='col-md-12 mt-3'>
                                                                            <div className='d-flex justify-content-end align-items-center'>
                                                                                {/* <p
                                                                    onClick={(e) => handleSubmit(e, "7", "save&finish")} 
                                                                    className='innerTxt me-3'>Save & <span className='linkTxt'><b>Finish Later</b></span></p> */}
                                                                                <input
                                                                                    className='primaryBtn d-inline-block'
                                                                                    type="submit"
                                                                                    value="Continue"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>,
                                                        }[page]
                                                    }
                                                </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-5' id='rightArea'>

                            {pageNumber == 1 ?
                                <div className='rightContentArea stepOne'>
                                    <p className='mb-5'>
                                        “It isn’t sufficient just to want – you’ve got to ask yourself what you are going to do to get the things you want.”
                                    </p>
                                    <b>Richard D. Rosen</b>
                                </div> :
                                pageNumber == 2 ?

                                    <div className='rightContentArea stepTwo'>
                                        <p className='mb-5'>
                                            “Setting a goal is not the main thing.  It’s deciding how you will go about achieving it and staying with that plan.”
                                        </p>
                                        <b>Tom Landry</b>
                                    </div>
                                    : pageNumber == 3 ?
                                        <div className='rightContentArea stepThree'>
                                            <p className='mb-5'>
                                                “Do not judge me by my successes, judge me by how many times I fell down and got back up again.”
                                            </p>
                                            <b>Nelson Mandela</b>
                                        </div>

                                        : pageNumber == 4 ?
                                            <div className='rightContentArea stepFour'>
                                                <p>
                                                    “Everything you want is out there waiting for you. But you have to take action to get it.”
                                                </p>
                                                <b className='mb-4'>Jules Renard</b>
                                            </div>
                                            : pageNumber == 5 ?
                                                <div className='rightContentArea stepFive'>
                                                    <p>
                                                        “If you want to achieve greatness, stop asking for permission.”
                                                    </p>
                                                    <b>Anonymous</b>
                                                </div>

                                                : pageNumber == 6 ?
                                                    <div className='rightContentArea stepSix'>
                                                        <p>
                                                            "If you don't like the road you're walking, start paving another one."
                                                        </p>
                                                        <b>Dolly Parton</b>
                                                    </div>
                                                    : pageNumber == 7 ?
                                                        <div className='rightContentArea stepSeven'>
                                                            <p>
                                                                "The best way to predict the future is to create it."
                                                            </p>
                                                            <p className='mb-0'>Peter Drucker</p>
                                                            <p className='txt14 mb-4'>Management Consultant and Author.</p>
                                                        </div>
                                                        : null

                            }

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CertificateStep