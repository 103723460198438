import React, { useCallback, useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { getCompanyProfileInfo, userCompanyProfileUpdate, userExistingCertificationsUpdate, userTradeMembershipsUpdate } from '../../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import { useAuthSelector } from '../../../redux/selector/auth';

const EditExistingCertifications = (props) => {
    const dispatch = useDispatch();
    const authSelector = useAuthSelector()

    // these state taken for store Certifications and Memberships API data
    const [getExistingCertifications, setGetExistingCertifications] = useState("");
    const [getTradeMemberships, setGetTradeMemberships] = useState("");

    // these state taken for get updated API data
    const [existingCertifications, setExistingCertifications] = useState([]);
    const [tradeMemberships, setTradeMemberships] = useState([]);
    const [getCompanyWebsite, setGetCompanyWebsite] = useState("");

    // these state taken for store selected data after change
    const [selectedExistingCertificationsId, setSelectedExistingCertificationsId] = useState("");
    const [selectedMembershipId, setSelectedMembershipId] = useState("");


    // these state taken for store all updated API data
    const [companyDetails, setCompanyDetails] = useState();

    // these state taken for store other option data in Certifications and Memberships
    const [otherExistingCertifications, setOtherExistingCertifications] = useState("")
    const [otherTradeMemberships, setOtherTradeMemberships] = useState("")

    // for find selected existing Certifications details
    const selectedCertificationsValue = existingCertifications?.map((data) => { return data?.existing_certificates_details });

    // for find selected Trade Memberships details
    const selectedMembershipsValue = tradeMemberships?.map((data) => { return data?.trade_membership_details });


    // change Existing Certifications...............
    const handleCertificationsChanges = (data) => {
        // setSelectedDiverseStatus(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setSelectedExistingCertificationsId(arr)
    }

    // change Trade Memberships...............
    const handleMembershipsChanges = (data) => {
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setSelectedMembershipId(arr)
    }

    //for update Existing Certifications........
    const UpdateExistingCertifications = (id) => {
        dispatch(userExistingCertificationsUpdate({
            cb(res) {
                if (res.status) {
                    setGetExistingCertifications(res?.data?.payload)
                }
            }
        }))
    }

    //for update trade Memberships.......
    const UpdateTradeMemberships = (id) => {
        dispatch(userTradeMembershipsUpdate({
            cb(res) {
                if (res.status) {
                    setGetTradeMemberships(res?.data?.payload)
                }
            }
        }))
    }

    // company edit profile
    const handleSubmit = (e) => {
        e.preventDefault()
        if (selectedExistingCertificationsId?.includes(11) && !otherExistingCertifications) {
            toast.error("please enter existing certifications other option");
            return;
        }

        else if (selectedMembershipId?.includes(11) && !otherTradeMemberships) {
            toast.error("please enter trade memberships other option");
            return;
        }

        let params = {
            id: companyDetails?.id,
            company_existing_certificates_id: selectedExistingCertificationsId,
            company_trade_membership_id: selectedMembershipId,
            company_website: getCompanyWebsite
        };

        if (selectedMembershipId?.includes(11) && otherTradeMemberships) {
            params = {
                ...params,
                company_trade_membership_other: otherTradeMemberships,
            }
        }
  
         if (selectedExistingCertificationsId?.includes(11) && otherExistingCertifications) {
            params = {
                ...params,
                company_existing_certificates_other: otherExistingCertifications,
            }
        }

        dispatch(userCompanyProfileUpdate({
            ...params, cb(res) {
                if (res.status) {
                    props.refreshCompanyDetail();
                    props.close();
                }
            }
        }))
    };


    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(getCompanyProfileInfo({
            cb(res) {
                // if (res.status) {
                //     setCompanyDetails(res?.data?.payload?.userDetails?.user_company_information)
                //     setExistingCertifications(res?.data?.payload?.userDetails?.user_company_information?.company_existing_certificate_details)
                //     setTradeMemberships(res?.data?.payload?.userDetails?.user_company_information?.company_trade_membership_details)
                //     setGetCompanyWebsite(res?.data?.payload?.userDetails?.user_company_information?.company_website)
                    
                //     const selectedValuesCertifications = res?.data?.payload?.userDetails?.user_company_information?.company_existing_certificate_details?.map((data) => { return data?.company_existing_certificates_id })
                //     setSelectedExistingCertificationsId(selectedValuesCertifications)

                //     const selectedValuesMemberships = res?.data?.payload?.userDetails?.user_company_information?.company_trade_membership_details?.map((data) => { return data?.company_trade_membership_id })
                //     setSelectedMembershipId(selectedValuesMemberships)
                // }
                // else {

                // }



                if (res.status) {
                    if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                        setCompanyDetails(res?.data?.payload?.userDetails?.user_company_information)
                        setExistingCertifications(res?.data?.payload?.userDetails?.user_company_information?.company_existing_certificate_details)
                        setTradeMemberships(res?.data?.payload?.userDetails?.user_company_information?.company_trade_membership_details)
                        setGetCompanyWebsite(res?.data?.payload?.userDetails?.user_company_information?.company_website)
                        
                        const selectedValuesCertifications = res?.data?.payload?.userDetails?.user_company_information?.company_existing_certificate_details?.map((data) => { return data?.company_existing_certificates_id })
                        setSelectedExistingCertificationsId(selectedValuesCertifications)
    
                        const selectedValuesMemberships = res?.data?.payload?.userDetails?.user_company_information?.company_trade_membership_details?.map((data) => { return data?.company_trade_membership_id })
                        setSelectedMembershipId(selectedValuesMemberships)
                    }
                    else {
                        setCompanyDetails(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
                        setExistingCertifications(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_existing_certificate_details)
                        setTradeMemberships(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_trade_membership_details)
                        setGetCompanyWebsite(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_website)
                        
                        const selectedValuesCertifications = res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_existing_certificate_details?.map((data) => { return data?.company_existing_certificates_id })
                        setSelectedExistingCertificationsId(selectedValuesCertifications)
    
                        const selectedValuesMemberships = res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_trade_membership_details?.map((data) => { return data?.company_trade_membership_id })
                        setSelectedMembershipId(selectedValuesMemberships)
                    }

                }
                else {

                }
            }
        }))
    }

    useEffect(() => {
        UpdateExistingCertifications();
        UpdateTradeMemberships();
        companyProfileInfo();
    }, []); 
 
    return (
        <>

            {authSelector.userDeatil ?
                <>
                    <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                </>
                :
                <div className='row'>
                    <form onSubmit={(e) => handleSubmit(e)}
                        className='signupForm row justify-content-center mt-3'>
                        <div className='form-group col-md-12 mb-3'>
                            <label className="headingSmall_ mb-2" htmlFor="">Existing Certifications</label>
                            {getExistingCertifications &&
                                <Multiselect
                                    options={getExistingCertifications} // Options to display in the dropdown
                                    selectedValues={selectedCertificationsValue} // Preselected value to persist in dropdown
                                    onSelect={handleCertificationsChanges} // Function will trigger on select event
                                    onRemove={handleCertificationsChanges} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                />
                            }
                            {selectedExistingCertificationsId?.includes(11) ?
                                <div className='form-group col-md-12 mt-3'>
                                    <label className="headingSmall_ mb-2" htmlFor="">Existing Certifications other</label>
                                    <input name="companyDescription" type='text' className='customFormControl' value={otherExistingCertifications} placeholder='Other Existing Certifications' disabled="" onChange={e => setOtherExistingCertifications(e.target.value)} />
                                </div> : ""
                            }
                        </div>

                        <div className='form-group col-md-12 mb-3'>
                            <label className="headingSmall_ mb-2" htmlFor="">Trade Memberships</label>
                            {getTradeMemberships &&
                                <Multiselect
                                    options={getTradeMemberships} // Options to display in the dropdown
                                    selectedValues={selectedMembershipsValue} // Preselected value to persist in dropdown
                                    onSelect={handleMembershipsChanges} // Function will trigger on select event
                                    onRemove={handleMembershipsChanges} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                    className='selectIndex'
                                />
                            }
                            {selectedMembershipId?.includes(11) ?
                                <div className='form-group col-md-12 mb-3'>
                                    <label className="headingSmall_ mt-3 mb-2" htmlFor="">Trade Memberships Other</label>
                                    <input name="companyDescription" type='text' className='customFormControl' value={otherTradeMemberships} placeholder='Other Trade Memberships' disabled="" onChange={e => setOtherTradeMemberships(e.target.value)} />
                                </div> : ""
                            }
                        </div>
                        <div className='button_box text-center  my-2'>
                            <button className='primaryBtn mx-2' type="submit">
                                Save
                            </button>
                            <button className='subsmallBtnblue_ mx-2' onClick={() => props.close()}  >Cancel</button>
                        </div>
                    </form>
                </div>
            }

        </>
    )
}

export default EditExistingCertifications