import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useAuthSelector } from '../../../redux/selector/auth';
import { updateSettings } from '../../../redux/slices/auth';

const PrivacySettings = () => {

    const dispatch = useDispatch();
    const authSelector = useAuthSelector();
    const settingData = authSelector?.userInfo?.user?.user_settings

    const handleSettingChange = (key,value) => {
        const params = {
            [key]:value
        }
        dispatch(updateSettings({
            ...params, cb(res) {
                if (res.status) {
                    toast.dismiss()
                    toast.success('Settings updated successfully')
                }
            }
        }))
    }


    return (
        <div className='PrivacySettings privacySetting'>
            <h3 className='headTxtBlueSmall mb-4'>Privacy Settings and Tools</h3> 
            <div className='listingMenusContainer'>
                <div className='listingMenusArea'>
                    <h5 className='innerSubtext'><b>Your Activity</b></h5>
                    <ul className='listingMenus'>
                        <li className='listingItems'>
                            <p className='headingTitleLight_'>Who can see the people, Pages and lists you follow?</p>
                            <div className="dropdown">
                                <button className="subsmallBtnblack_ p-0" type="button" id="dropdownMenuButtons" data-bs-toggle="dropdown" aria-expanded="false">
                                {settingData?.who_can_see == 1 ? 'All Members' : 'Connected Members Only'}
                                
                                <i class="fas fa-chevron-down"></i></button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtons">
                                    <li><Link className="dropdown-item" onClick={()=> {handleSettingChange('who_can_see',1)}}>All Members</Link></li>
                                    <li><Link className="dropdown-item" onClick={()=> {handleSettingChange('who_can_see',2)}}>Connected Members Only</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <hr className='topLightboder'/>
                <div className='listingMenusArea'>
                    <h5 className='innerSubtext'><b>Public</b></h5>
                    <ul className='listingMenus'>
                        <li className='listingItems'>
                            <p className='headingTitleLight_'>Who can see your community feed activity?</p>
                            <div className="dropdown">
                                <button className="subsmallBtnblack_ p-0" type="button" id="dropdownMenuButtons" data-bs-toggle="dropdown" aria-expanded="false"> {settingData?.who_can_see_community_feed == 1 ? 'All Members' : 'Connected Members Only'} <i class="fas fa-chevron-down"></i></button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtons">
                                    <li><Link className="dropdown-item" onClick={()=> {handleSettingChange('who_can_see_community_feed',1)}}>All Members</Link></li>
                                    <li><Link className="dropdown-item" onClick={()=> {handleSettingChange('who_can_see_community_feed',2)}}>Connected Members Only</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <hr className='topLightboder'/>
                <div className='listingMenusArea'>
                    <h5 className='innerSubtext'><b>Notifications</b></h5>
                    <ul className='listingMenus'>
                        <li className='listingItems'>
                            <p className='headingTitleLight_ mb-0'>Send notice to email for notifications</p>
                            <div className="dropdown">
                                <button className="subsmallBtnblack_ p-0" type="button" id="dropdownMenuButtons" data-bs-toggle="dropdown" aria-expanded="false">{settingData?.email_notifications == 1 ? 'Yes' : 'No'} <i class="fas fa-chevron-down"></i></button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtons">
                                    <li><Link className="dropdown-item" onClick={()=> {handleSettingChange('email_notifications',1)}}>Yes</Link></li>
                                    <li><Link className="dropdown-item" onClick={()=> {handleSettingChange('email_notifications',2)}}>No</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='listingMenusArea '>
                    <h5 className='innerSubtext'><b>Communication</b></h5>
                    <ul className='listingMenus'>
                        <li className='listingItems'>
                            <p className='headingTitleLight_'>Preferences</p>
                            <div className="dropdown">
                                <button className="subsmallBtnblack_ p-0" type="button" id="dropdownMenuButtons" data-bs-toggle="dropdown" aria-expanded="false">{settingData?.communication == 1 ? 'Opting in promotional emails and newsletters.' : 'Opting out of promotional emails and newsletters.'} <i class="fas fa-chevron-down"></i></button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtons">
                                    <li><Link className="dropdown-item" onClick={()=> {handleSettingChange('communication',1)}}>Opting in promotional emails and newsletters.</Link></li>
                                    <li><Link className="dropdown-item" onClick={()=> {handleSettingChange('communication',2)}}>Opting out of promotional emails and newsletters.</Link></li>
                                </ul>
                            </div>
                    
                        </li>
                    </ul>
                </div>
                <div className='listingMenusArea'>
                    <h5 className='innerSubtext'><b>Account</b></h5>
                    <ul className='listingMenus'>
                        <li className='listingItems'>
                            <p className='headingTitleLight_'>Deactivation and Deletion:</p>
                            <div className='deactivateDeleteBtn'>
                                <button className='secondaryBtnOuter mb-2'>Deactivate</button><br/>
                                <button className='subsmallBtngreen_'>Delete</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PrivacySettings