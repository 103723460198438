import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import * as Containers from "../app/containers";
import * as Layouts from "../app/layouts"
import PrivateCheck from "../app/layouts/PrivateCheck";
import RunOneSignal from "../utilities/oneSignal";


const Router = () => {
    return (
        <>
            <Routes>

                <Route element={<Layouts.MainLayout />}>
                    <Route path="/" element={<Containers.Home />} />
                    <Route path="/about-us" element={<Containers.AboutUs />} />
                    <Route path="/membershipchooseperrole" element={<Containers.MemberShipChoosePerRole />} />
                    <Route path="/small-business" element={<Containers.Membership />} />
                    <Route path="/enterprise-business" element={<Containers.EnterpriseBusiness />} />
                    <Route path="/terms-condition" element={<Containers.TermsCondition />} />
                    <Route path="/privacy-policy" element={<Containers.PrivacyPolicy />} />
                    <Route path="/contact-us" element={<Containers.ContactUs />} />
                    <Route path="/frequent-question" element={<Containers.FrequentQuestion />} />
                    <Route path="/resource" element={<Containers.Resource />} />
                    <Route path="/certificate" element={<Containers.Certificate />} />
                </Route>

                <Route element={<Layouts.AuthLayout />}>

                    <Route element={<PrivateCheck auth={false} />}>
                        <Route path="/login" element={<Containers.Login />} />
                        <Route path="/signup" element={<Containers.Signup />} />
                        <Route path="/forget-password" element={<Containers.ForgetPassword />} />
                        <Route path="/forget-password-verify" element={<Containers.ForgetPasswordVerification />} />
                        <Route path="/reset-password" element={<Containers.ResetPassword />} />
                        <Route path="/chooserolesfor=signup" element={<Containers.ChooseRoles />} />
                        {/* <Route path="/chooserolesfor=signin" element={<Containers.ChooseRoles />} /> */}
                        <Route path="/verification" element={<Containers.Verification />} />
                        <Route path="/businessneed" element={<Containers.BusinessNeed />} />
                        <Route path="/supplier-business-need" element={<Containers.supplierBusinessNeed />} />
                    </Route>

                    <Route element={<PrivateCheck auth={true} />}>
                        <Route path="/dashboard" element={<Containers.AccountDashboard />} />
                        <Route path="/dashboard/post/:id" element={<Containers.AccountDashboard />} />
                        <Route path="/registration" element={<Containers.Registration />} />
                        <Route path="/setting" element={<Containers.Setting />} />
                        <Route path="/notification" element={<Containers.Notifications />} />
                        <Route path="/business" element={<Containers.Business />} />
                        <Route path="/view-profile" element={<Containers.ViewProfile />} />
                        <Route path="/resources" element={<Containers.Resources />} />
                        <Route path="/:membercompanyname/:id" element={<Containers.CompanyfrontProfile />} />
                        <Route path="/:mycompanyname" element={<Containers.MyCompanyProfile />} />
                        <Route path="/all-connections" element={<Containers.AllConnections />} />
                        <Route path="/opportunities" element={<Containers.Opportunities />} />
                        <Route path="/document-view" element={<Containers.DocumentView />} />
                        <Route path="/post-opportunity" element={<Containers.PostOpportunity />} />
                        <Route path="/account-message" element={<Containers.AccountMessage />} />
                        <Route path="/edit-profile" element={<Containers.Editprofile />} />
                        <Route path="/certificate-application" element={<Containers.CertificateApplication />} />
                        <Route path="/certificate-step" element={<Containers.CertificateStep />} />
                    </Route>
                    <Route path="/payment-cancelled" element={<Containers.PaymentFailurePage />} />
                </Route>
            </Routes>
        </>
    );
}

export default Router;