import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import Faq from "react-faq-component";


const Membership = () => {
    const navigate = useNavigate();

    const handleSubmit = (plan) => {
        localStorage.setItem('choosenPlan',plan)
        navigate('/chooserolesfor=signup',{ state: { from: 'membershipPage', membershipID : 2 } });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Member-Ship";
    }, []);

    const [rows, setRowsOption] = useState(null);
    const data = {
        title: "For Buyers",
        rows: [
            {
                title: "What is GetOpp$ and how can it benefit me as a buyer?               ",
                content: `GetOpp$ is a dynamic social media networking site designed for buyers and suppliers. It provides a platform to connect and engage with a diverse community of members, discover new products and services, and foster valuable business relationships. By joining GetOpp$, you gain access to a wide range of suppliers and buyers, streamline your procurement process, and unlock new growth opportunities.                `,
            },
            {
                title: "How can I find relevant suppliers on GetOpp$?",
                content:
                    "GetOpp$ offers targeted search functionalities that allow you to find suppliers based on specific criteria such as location, industry, certifications, and more. You can utilize advanced search filters to narrow down your options and connect with suppliers who best align with your requirements.",
            },
            {
                title: "Can I review supplier profiles and ratings on GetOpp$?",
                content: `Absolutely! GetOpp$ provides comprehensive supplier profiles where you can find detailed information about their offerings, certifications, experience, and more. Additionally, you can benefit from ratings and reviews provided by other buyers who have engaged with these suppliers, helping you make informed decisions and evaluate their reputation.`,
            },
        ],
 
    };
    const supplierdata = {
        title: "For Suppliers",
        rows: [
            {
                title: "How can GetOpp$ benefit me as a supplier?",
                content: `GetOpp$ offers immense benefits to suppliers by providing a platform to connect and engage with a wide network of buyers. It enables you to showcase your products or services, expand your reach, and establish meaningful relationships with potential buyers. GetOpp$ helps you gain visibility, increase your chances of securing contracts, and grow your business.                `,
            },
            {
                title: "Can I directly message buyers on GetOpp$?",
                content: `Absolutely! GetOpp$ facilitates direct messaging between suppliers and buyers, allowing you to communicate and engage with potential customers in a convenient and efficient manner. You can leverage this feature to discuss requirements, provide additional information, and build relationships with buyers who are interested in your offerings.`,
            },
            {
                title: "How can I enhance my profile on GetOpp$ to attract buyers?",
                content: `To attract buyers on GetOpp$, it's important to create a compelling and informative profile. Highlight your key offerings, showcase your unique value proposition, and include relevant certifications or qualifications. Regularly update your profile with fresh content, such as case studies, testimonials, or industry insights, to demonstrate your expertise and credibility.`,
            },
        ],

    };

    const styles = {
        // bgColor: 'white',
        titleTextColor: "dark gray",
        rowTitleColor: "dark gray",
        // rowContentColor: 'grey',
        // arrowColor: "red",
    };

    const config = {
        animate: true,
        arrowIcon: "V",
        openOnload: 0,
        expandIcon: "+",
        collapseIcon: "x",
    };
    return (
        <>
            <section className='membershipBanner mt-5 mb-4'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='memeberHead_ col-md-10 '>
                            <h3 className='memberHead_'>Access all the <strong>benefits</strong> to <strong>grow your business.</strong></h3>
                            <h5 className='discription_'><strong>Small Business Members</strong><br></br>  {`${"( <$5M GROSS ANNUAL REVENUE )"}`}</h5>
                        </div>
                    </div>
                    <div className='memberHomeImage_ lg-mt-5 mt-3 mb-4'>
                        <div className='memberPlansbox_'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 lg-mb-0 mb-4'>
                                    <div className='palnmemberInner'>
                                        <img className='memberImage' src={Images.member1} />
                                        <div className='memberMiddle'>
                                            <div className="toppalnHead_">
                                                <h3 className='memberHead_'><b>Platinum</b> Membership</h3>
                                            </div>
                                            <div className='featuresList_'>
                                                <h4 className='growblueHead_'>Features</h4>
                                                <ul className='featureListbox_'>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>GetOpp$ Company Profile</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Direct Messaging with ALL Members</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Full Access to Community Feed</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Real Time Notifications</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'> Unlimited Searches</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Unlimited Post or Reply Contract Opportunities</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Unlimited Post or Reply Contract Opportunities</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>750 MB Document Sharing and Storage</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Access to GetOPP$ Suggestion Box</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Unlimited Member Endorsements</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'><strong>Full Access to GetOPP$ Business Resources</strong></span></li>
                                                    {/* <li><img className='check_' src={Images.check_} /> <span className='listHead_'><strong>Access to GetOpp$ Suggestion Box </strong></span></li> */}
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'><strong>First Priority Listing in Target Searches</strong></span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'><strong>Sponsor and Featured Host for GetOPP$ Video</strong></span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'><strong>Education and Podcast series each month</strong></span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'><strong>Featured Spotlight Call to Action Advertising Space</strong></span></li>
                                                </ul>
                                            </div>
                                            <div className='licenceUser_'>
                                                <h4 className='licenceHead_'>User License: 4</h4>
                                                <div className='costbox_'>
                                                    <div className='costLeft_'>
                                                        <h5 className='costHead_ mb-2'>Year 1 Introductory</h5>
                                                        <h4 className='costboldHead'>Annual Cost</h4>
                                                    </div>
                                                    <h3 className='pricehead_'>$7,500</h3>
                                                </div>
                                                <div className='yearCost_ mt-3'>
                                                    <h4>Year 2 Cost</h4>
                                                    <h3>$15,000</h3>
                                                </div>
                                                <button onClick={() => handleSubmit('Platinum')} className='subsmallBtngreen_ mt-3'>Get Started</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 lg-mb-0 mb-4'>
                                    <div className='palnmemberInner'>
                                        <img className='memberImage' src={Images.member2} />
                                        <div className='memberMiddle'>
                                            <div className="toppalnHead_">
                                                <h3 className='memberHead_'><b>Gold</b> Membership</h3>

                                            </div>
                                            <div className='featuresList_'>
                                                <h4 className='growblueHead_'>Features</h4>
                                                <ul className='featureListbox_'>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>GetOpp$ Company Profile</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Direct Messaging with ALL Members</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Full Access to Community Feed</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Real Time Notifications</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>8 Searches Per Month Per User</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>16 Post or Reply Contract Opportunities Per Month Per User</span></li>
                                                    {/* <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Per User</span></li> */}
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>250 MB Document Sharing and Storage</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Access to GetOPP$ Suggestion Box</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Unlimited Member Endorsements</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'><strong>Full Access to GetOPP$ Business Resources</strong></span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'><strong>Priority Listing in Target Searches</strong></span></li>
                                                    {/* <li><img className='check_' src={Images.check_} /> <span className='listHead_'><strong>Priority Listing in Target Searches</strong></span></li> */}
                                                </ul>

                                            </div>
                                            <div className='licenceUser_'>
                                                <h4 className='licenceHead_'>User License: 2</h4>
                                                <div className='costbox_'>
                                                    <div className='costLeft_'>
                                                        <h5 className='costHead_ mb-2'>Year 1 Introductory</h5>
                                                        <h4 className='costboldHead'>Annual Cost</h4>
                                                    </div>
                                                    <h3 className='pricehead_'>$5,000</h3>
                                                </div>
                                                <div className='yearCost_ mt-3'>
                                                    <h4>Year 2 Cost</h4>
                                                    <h3>$10,000</h3>
                                                </div>
                                                <button onClick={() => handleSubmit('Gold')} className='subsmallBtngreen_ mt-3'>Get Started</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 lg-mb-0 mb-4'>
                                    <div className='palnmemberInner'>
                                        <img className='memberImage' src={Images.member3} />
                                        <div className='memberMiddle'>
                                            <div className="toppalnHead_">
                                                <h3 className='memberHead_'><b>Bronze</b> Membership</h3>

                                            </div>
                                            <div className='featuresList_'>
                                                <h4 className='growblueHead_'>Features</h4>
                                                <ul className='featureListbox_'>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>GetOpp$ Company Profile</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Direct Messaging with Connected Members</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>View Community Feed</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>Real Time Notifications</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>4 Searches Per Month Per User</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>8 Post or Reply Contract Opportunities Per Month Per User</span></li>
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'>10 MB Document Sharing and Storage</span></li>
                                                    {/* <li><img className='check_' src={Images.check_} /> <span className='listHead_'>4 Contract Opportunities Per Month Per User</span></li> */}
                                                    {/* <li><img className='check_' src={Images.check_} /> <span className='listHead_'>10 MB of Document Sharing and Storage</span></li> */}
                                                    <li><img className='check_' src={Images.check_} /> <span className='listHead_'><strong>Access to GetOPP$ Suggestion Box</strong></span></li>

                                                </ul>
                                            </div>
                                            <div className='licenceUser_'>
                                                <h4 className='licenceHead_'>User License: 1</h4>
                                                <div className='costbox_'>
                                                    <div className='costLeft_'>
                                                        <h5 className='costHead_ mb-2'>Year 1 Introductory</h5>
                                                        <h4 className='costboldHead'>Annual Cost</h4>
                                                    </div>
                                                    <h3 className='pricehead_'>$2,500</h3>
                                                </div>
                                                <div className='yearCost_ mt-3'>
                                                    <h4>Year 2 Cost</h4>
                                                    <h3>$5,000</h3>
                                                </div>
                                                <button onClick={() =>  handleSubmit('Bronze')} className='subsmallBtngreen_ mt-3'>Get Started</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='frequentlyQuestion mb-5'>
                <div className='container'>
                    <div className='innerfawBox_'>
                        <h3 className='otherHeading_ lg-mb-5 mb-0 text-center'><strong>Frequently Asked Questions</strong></h3>
                        {/* <div className='row pt-4'>
                            <div className='col-md-6 col-lg-6 mb-2 pe-4 ps-4'>
                                <div className='faqQuestionBox_'>
                                    <h4 className='innerSubtext'>What happens at the end of my free trial?</h4>
                                    <p className='paragraph_'>At the end of your free trial, your membership will automatically renew and you will be charged for a subscription on a monthly or annual basis (depending on the billing option you choose) unless you cancel. You can cancel at any time prior to the renewal at linkedin.com/settings.</p>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-6 mb-2 pe-4 ps-4'>
                                <div className='faqQuestionBox_'>
                                    <h4 className='innerSubtext'>What happens at the end of my free trial?</h4>
                                    <p className='paragraph_'>At the end of your free trial, your membership will automatically renew and you will be charged for a subscription on a monthly or annual basis (depending on the billing option you choose) unless you cancel. You can cancel at any time prior to the renewal at settings.</p>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-6 mb-2'>
                                <div className='faqQuestionBox_'>
                                    <h4 className='innerSubtext'>Can I change or cancel my plan later on?</h4>
                                    <p className='paragraph_'> Yes. You can upgrade or cancel anytime from your settings page. When you cancel, you will lose your Premium features at the end of your billing cycle. Be sure you use all your InMail messages before the cancellation goes into effect.</p>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-6 mb-2'>
                                <div className='faqQuestionBox_'>
                                    <h4 className='innerSubtext'>Can I change or cancel my plan later on?</h4>
                                    <p className='paragraph_'>Yes. You can upgrade or cancel anytime from your settings page. When you cancel, you will lose your Premium features at the end of your billing cycle. Be sure you use all your InMail messages before the cancellation goes into effect.</p>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-6 mb-2'>
                                <div className='faqQuestionBox_'>
                                    <h4 className='innerSubtext'>How will you bill me?</h4>
                                    <p className='paragraph_'>We offer monthly billing and in some cases annual billing. Depending on what option you choose, your membership will be renewed at the end of each month or at the end of each year. If you choose to pay annually, you could save up to 33% compared to paying monthly.</p>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-6 mb-2'>
                                <div className='faqQuestionBox_'>
                                    <h4 className='innerSubtext'>How will you bill me?</h4>
                                    <p className='paragraph_'>We offer monthly billing and in some cases annual billing. Depending on what option you choose, your membership will be renewed at the end of each month or at the end of each year. If you choose to pay annually, you could save up to 33% compared to paying monthly.</p>
                                </div>
                            </div>
                        </div> */}
                        <div className='row'>
                            <div className="memberHomeImage_ mt-5 mb-4">
                                <div className='row'>
                                    <div className='col-md-12 col-lg-12'>
                                        <div className='faqcontentBox_'>
                                            <Faq
                                                getRowOptions={setRowsOption}
                                                data={data}

                                                styles={styles}
                                                config={config}
                                            />
                                        </div>

                                        <div className='faqcontentBox_'>
                                            <Faq
                                                getRowOptions={setRowsOption}
                                                data={supplierdata}

                                                styles={styles}
                                                config={config}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='col-md-4 col-lg-4'>
                                <div className='faqImgbox'>
                                    <img className='faqImg' src={Images.faqImg} />
                                </div>
                            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Membership