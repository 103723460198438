import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
// Worker saga will be fired on USER_FETCH_REQUESTED actions
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { onErrorStopLoad, setLabels, setAddLabels, setChatHead, setChatInfo, setAllCompanyInfo, setCreateDraft, setGetChatDraft, setChatDelete, setGetTrashChat, setMessageDelete, setTrashChatUpdate } from '../../slices/messages';


function* getLabel(action) {
  try {

    const resp = yield call(ApiClient.get, action.url = ApiPath.MessagePath.GET_LABELS, action.payload = action.payload);
    if (resp.status) {

      yield put(setLabels(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* addLabel(action) {
  try {

    const resp = yield call(ApiClient.post, action.url = ApiPath.MessagePath.ADD_LABELS, action.payload = action.payload);
    if (resp.status) {

      yield put(setAddLabels(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setAddLabels({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data?.msg);
  }
}

function* ChatHead(action) {
  try {
    var dataToSend = { ...action.payload }
    delete dataToSend.cb
    const resp = yield call(ApiClient.get, action.url = ApiPath.MessagePath.GET_CHAT_HEAD, action.params = { params: dataToSend });
    if (resp.status) {
      yield put(setChatHead(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setChatHead({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data?.msg);
  }
}

function* ChatInfo(action) {
  try {

    const resp = yield call(ApiClient.get, action.url = `${ApiPath.MessagePath.GET_CONVERSATION}${action.payload.chatId}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setChatInfo(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setChatInfo({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data.msg);
  }
}

function* GetAllCompanyInfo(action) {
  try {

    const resp = yield call(ApiClient.get, action.url = `${ApiPath.MessagePath.GET_ALL_COMPANY_INFORMATION}all?search=${action.payload.search}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setAllCompanyInfo(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setAllCompanyInfo({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* createDrafts(action) {
  try {

    const resp = yield call(ApiClient.post, action.url = ApiPath.MessagePath.CREATE_DRAFT, action.payload = action.payload);
    if (resp.status) {

      yield put(setCreateDraft(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setCreateDraft({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getChatDrafts(action) {
  try {

    const resp = yield call(ApiClient.get, action.url = ApiPath.MessagePath.GET_CHAT_DRAFT, action.payload = action.payload);
    if (resp.status) {

      yield put(setGetChatDraft(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setGetChatDraft({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* messageDeleted(action) {
  try {

    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.MessagePath.DELETE_MESSAGE}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setMessageDelete(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setMessageDelete({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* chatDeleted(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.MessagePath.DELETE_CHAT}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setChatDelete(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setChatDelete({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getTrashChatS(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.MessagePath.GET_TRASH_CHAT, action.payload = action.payload);
    if (resp.status) {

      yield put(setGetTrashChat(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setGetTrashChat({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* chatTrashUpdate(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.MessagePath.CHAT_TRASH_UPDATE}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {

      yield put(setTrashChatUpdate(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    // yield put(setTrashChatUpdate({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* messageSaga() {
  yield all([
    takeLatest('messages/getLabels', getLabel),
    takeLatest('messages/addLabels', addLabel),
    takeLatest('messages/getChatHead', ChatHead),
    takeLatest('messages/getChatInfo', ChatInfo),
    takeLatest('messages/getAllCompanyInfo', GetAllCompanyInfo),
    takeLatest('messages/createDraft', createDrafts),
    takeLatest('messages/getChatDraft', getChatDrafts),
    takeLatest('messages/messageDelete', messageDeleted),
    takeLatest('messages/chatDelete', chatDeleted),
    takeLatest('messages/getTrashChat', getTrashChatS),
    takeLatest('messages/trashChatUpdate', chatTrashUpdate)
  ])
}

export default messageSaga;

