import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useAuthSelector } from "../../../redux/selector/auth";
import * as Images from "../../../utilities/images";
import io from 'socket.io-client';
import { useSocketSelector } from "../../../redux/selector/socket";
import { useDispatch } from "react-redux";
import { setSocket } from "../../../redux/slices/socket";

import { socket } from "../socket";
//import { socketInstance } from "../socketNew";
import { allUserWithSearch } from "../../../redux/slices/dashboard";
import { getCompanyProfileInfo, setSubscriptionID } from "../../../redux/slices/auth";
import OneSignal from "react-onesignal";
import CustomModal from "./shared/CustomModal";
import Payment from "../../containers/subscription/payment";
import Plans from "../../containers/subscription/plans";
import UpdatePayment from "./updatePayment";


const AuthNav = () => {

    const navigate = useNavigate();
    const authSelector = useAuthSelector();

    const dispatch = useDispatch()
    const socketSelector = useSocketSelector()
    const userId = authSelector?.userInfo?.user?.id;

    const [userDeatil, setUserDeatil] = useState();
    const [searchInput, setSearchInput] = useState("");
    const [allUser, setAllUser] = useState("");
    const [addClass, setAddClass] = useState("");

    const [socketInstance, setSocketInstance] = useState(null);
    const [oneSignalKey, setOneSignalKey] = useState(Math.random());


    // var socketInstance;
    // if ( (authSelector?.userStep?.step == 6 || authSelector?.userInfo?.user?.step == 6)) {
    //     console.log("Socket emitted",socketInstance);
    //     socketInstance = socket();
    //     // hasSocketEmitted = true;
    // }

    useEffect(() => {
        // Check conditions and create the socket instance
        if (authSelector?.userStep?.step === 6 || authSelector?.userInfo?.user?.step === 6) {
            const newSocketInstance = socket();
            setSocketInstance(newSocketInstance);
        }
    }, [socketSelector?.connected === false]);

    const step = authSelector?.userInfo?.user?.step <= 6 && authSelector?.userStep?.step == 6 || authSelector?.userInfo?.user?.step == 6;
    const socialStep = authSelector?.userSignUp?.user?.step <= 6 && authSelector?.userStep?.step == 6 || authSelector?.userInfo?.user?.step == 6;
    const location = useLocation();
    const token = localStorage.getItem("authToken")
    const [activeTab, setActiveTab] = useState("");
    const path = location.pathname;
    const userInfo = authSelector?.userInfo?.user;
    console.log(userInfo, "userInfo");


    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random())

    };

    useEffect(() => {
        if (userInfo && userInfo.membership_status == 2 && path != '/setting') {

            setModalDetail({
                show: true,
                title: "paymentUpdate",
                flag: "paymentUpdate"
            });
            setKey(Math.random())

        }
    }, [userInfo, path])

    useEffect(() => {

        if (userInfo && socketInstance && dispatch) {
            console.log("Socket friend");
            dispatch(setSocket(socketInstance))

            socketInstance.on('connection', (response) => {
                console.log('Connected', response);
            });

            joinRoom({
                company_id: userInfo?.user_company_information ? userInfo?.user_company_information?.id : userInfo?.user_selected_company_information?.id,
                sender_id: userInfo.id
            })

            // Other socket event listeners and operations
            return () => {
                socketInstance.disconnect();
                console.log("disconnect")
            };
        }

    }, [userInfo && socketInstance && dispatch]);

    console.log(OneSignal, "OneSignaliddddddddddddddddddddd");

    useEffect(() => {

        if (OneSignal && userInfo) {

            setTimeout(() => {
                // if (OneSignal.User.PushSubscription.id === undefined) {

                //     localStorage.removeItem('onesignal-notification-prompt')
                //     OneSignal.Slidedown.promptPush();
                // }
                if (userInfo.one_signal_subscription_id == undefined || !userInfo.one_signal_subscription_id) {
                    localStorage.removeItem('onesignal-notification-prompt')
                    OneSignal.Slidedown.promptPush();
                }
                // else {
                //     if (OneSignal.User.PushSubscription.id !== userInfo.one_signal_subscription_id) {
                //         updateSubscriptionID(OneSignal.User.PushSubscription.id)
                //     }
                // }
                else if (userInfo.one_signal_subscription_id) {
                    updateSubscriptionID(OneSignal.User.PushSubscription.id)
                }

                console.log(OneSignal.User.PushSubscription.id, 'in')

            }, 1000);
        }
    }, [OneSignal, oneSignalKey]);
// }, [OneSignal, userInfo, oneSignalKey]);

    if (OneSignal) {
        OneSignal.Slidedown.addEventListener('change', (e) => {
          console.log(e, 'event');
      
          if (e.to.optedIn === true && e.to.id) {
            if (e.to.id !== userInfo.one_signal_subscription_id) {
              updateSubscriptionID(e.to.id);
            }
          }
        });
      }
    // console.log(OneSignal.User.PushSubscription.id, 'One-signal-id')
    // console.log(userInfo, 'User-Info')
    // console.log(OneSignal, 'OneSignal-Info')

    const updateSubscriptionID = (id) => {

        let params = {
            one_signal_subscription_id: id,
            one_signal_external_id: `${userInfo.id}getOpps-notification-id`
        }

        console.log(params, 'paramssss')

        dispatch(setSubscriptionID({
            ...params, cb(res) {
                console.log(res, `response`)
            }
        }))

    }


    const joinRoom = (params) => {
        socketInstance.emit('join_room', params)
    }

    if (socketSelector && typeof socketSelector.emit === 'function') {
        socketSelector?.on('join_room', (response) => {
            console.log('socket', response)
        })
    }

    // handle  view profile 
    const handleViewProfile = () => {
        if (userDeatil?.user_company_information?.company_name) {
            navigate(`/${userDeatil?.user_company_information?.company_name}`)
        }
    }

    //search input
    const onChangeSearchInput = (searchValue) => {
        setSearchInput(searchValue);

    }

    //reset input
    const onChangeReset = () => {
        setSearchInput("")
        getAllUserWithSearch("clear");
    }

    //get all user with search
    const getAllUserWithSearch = (flag) => {
        let params = {
            search: searchInput ? searchInput : ""
        }
        if (flag == "clear") {
            params = { serach: "" }
        }
        dispatch(allUserWithSearch({
            ...params, cb(res) {
                if (res.status) {
                    setAllUser(res.data.payload.data)
                }
            }
        }))
    }

    // handle  page change 
    const handlePageRedirect = (id, name) => {
        setAddClass("searchboxactive")
        if (id) {
            // navigate(`/companyfrontProfile/${id}`)
            navigate(`/${name}/${id}`)
            onChangeReset();
            setAllUser("")
        }
    }


    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(getCompanyProfileInfo({
            cb(res) {

                if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                    setUserDeatil(res?.data?.payload?.userDetails?.user_company_information)
                }
                else {
                    setUserDeatil(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
                }
            }
        }))
    }

    useEffect(() => {
        // companyProfileInfo();
        if (!userId) {

        }
        else {
            getAllUserWithSearch();
        }

    }, [searchInput])

    useEffect(() => {
        if (token) {
            companyProfileInfo();
        }
    }, [])


    return (
        <>
            <div className="authNavBar">
                <nav className="navbarContain navInnerhaed">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-2 text-center">
                                <Nav.Link href="/"
                                    className="navbar-brand">
                                    <img src={Images.logo} alt='Logo' className="img-fluid" />
                                </Nav.Link>
                            </div>

                            <div className="col-md-12 col-lg-10 text-end">
                                {token && step || socialStep ?
                                    <Navbar expand="lg">
                                        <div className={`rightNav_ ${path == '/resources' ? '' : 'searchNav'}`}>
                                            {path == "/resources" ? "" :
                                                <div className="">
                                                    <div className="searchBox">
                                                        <form className='inputSearchForm'>
                                                            <div className='input-group me-2'>
                                                                <input type="text" className="form-control rounded" onChange={(e) => onChangeSearchInput(e.target.value)}
                                                                    placeholder="Profile Search" value={searchInput} aria-label="Search" aria-describedby="search-addon" />
                                                                <button
                                                                    className={`inputClose ${searchInput ? 'd-block' : ''}`}

                                                                    onClick={() => {
                                                                        onChangeReset()
                                                                    }} type="reset"> <i className="las la-times"></i> </button>
                                                            </div>
                                                            <button className='searchBtn' type="button" onClick={() => { return searchInput ? getAllUserWithSearch() : '' }}>
                                                                <i className="las la-search"></i>
                                                            </button>
                                                        </form>
                                                    </div>
                                                    {allUser?.length > 0 ?
                                                        <div

                                                            className={`searchBoxUsers ${searchInput ? "active" : ""}`}
                                                        >
                                                            <>
                                                                {allUser?.map((user, index) => {
                                                                    return (
                                                                        <>
                                                                            <div onClick={() => handlePageRedirect(user?.user_company_information?.id, user?.user_company_information?.company_name)} className={`searchBoxuser ${addClass == "searchboxactive" ? "searchboxactive" : ""}`}  >
                                                                                <figure>
                                                                                    <img src={user?.user_company_information?.company_logo ? user?.user_company_information?.company_logo : Images.companyprofile} alt="Bell" className="searchCompany" />
                                                                                </figure>
                                                                                <artical className="">
                                                                                    <h6 className="m-0">{user?.user_profile?.first_name}</h6>
                                                                                    <p className="lightgreyHead_">{user?.user_company_information?.company_name}</p>
                                                                                </artical>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </>
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            }
                                            <div className="flexBox">
                                                <div className="navheadInner">
                                                    <Navbar.Collapse id="basic-navbar-nav">
                                                        <Nav className="">
                                                            <Link to="/dashboard"
                                                                className={`nav-link ${path == '/dashboard' ? 'active' : ''}`}
                                                            // onClick={() => setActiveTab('dashboard')}
                                                            >Dashboard</Link>
                                                            <Link to="/business"
                                                                className={`nav-link ${path == '/business' ? 'active' : ''}`}
                                                            // onClick={() => setActiveTab('search')}
                                                            >Search</Link>
                                                            <Link to="/all-connections"
                                                                className={`nav-link ${path == '/all-connections' ? 'active' : ''}`}
                                                            // onClick={() => setActiveTab('connections')}
                                                            >Connections</Link>
                                                            <Link to="/resources"
                                                                className={`nav-link ${path == '/resources' ? 'active' : ''}`}
                                                            // onClick={() => setActiveTab('resources')}
                                                            >Resources</Link>
                                                            <Link to="/opportunities"
                                                                className={`nav-link ${path == '/opportunities' ? 'active' : ''}`}
                                                            // onClick={() => setActiveTab('opportunities')}
                                                            >Opportunities</Link>
                                                        </Nav>
                                                    </Navbar.Collapse>
                                                </div>
                                                <ul className="authMenus d-flex align-items-center ms-3">
                                                    <li className="authItems">
                                                        <Link to="/account-message"
                                                            className={`authLinks ${path == '/account-message' ? 'active' : ''}`}
                                                        // onClick={() => setActiveTab('accountMessage')}
                                                        >
                                                            <img src={Images.envelope} alt="Envelope" className="authIcons" />

                                                        </Link>
                                                    </li>
                                                    <li className="authItems">
                                                        <Link to="/notification"
                                                            className={`authLinks  notification ${path == '/notification' ? 'active' : ''}`}
                                                        // onClick={() => setActiveTab('notification')}
                                                        >
                                                            <img src={Images.bell} alt="Bell" className="authIcons" />
                                                        </Link>
                                                    </li>
                                                    <li className="authItems userDetails">
                                                        <Link to={`/${userDeatil?.company_name}`} className="d-flex align-items-center" >
                                                            <img src={authSelector?.userInfo?.user?.user_profile?.profile_photo ? authSelector?.userInfo?.user?.user_profile?.profile_photo : Images.companyprofile} alt="User" className="userImg me-lg-2 me-0" />
                                                            <span className="userName me-3 d-none d-md-inline">{authSelector?.userInfo?.user?.user_profile?.first_name} {authSelector?.userInfo?.user?.user_profile?.last_name ? authSelector?.userInfo?.user?.user_profile?.last_name : ""}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                    <li className="authItems">
                                                        <Link to='/setting'
                                                            className={`authLinks ${path == '/setting' ? 'active' : ''}`}
                                                        // onClick={() => setActiveTab('setting')}
                                                        >
                                                            <img src={Images.setting} alt="Setting" className="authIcons" />
                                                        </Link>
                                                    </li>
                                                </ul>
                                                <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0">
                                                    <i className="las la-bars barIcon"></i>
                                                </Navbar.Toggle>
                                            </div>
                                        </div>
                                    </Navbar> : ""
                                }
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag ? "commonWidth customContent" : ''}
                ids={modalDetail.flag}
                child={
                    modalDetail.flag === "paymentUpdate" ? (
                        <UpdatePayment
                            close={() => handleOnCloseModal()} />
                    )
                        :
                        null
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )

}

export default AuthNav;