import React, { useState, useEffect, useRef } from 'react'
import * as Images from "../../../utilities/images";
import { activeTab, addConnection, setActiveTab } from "../../../redux/slices/connection";
import { getAdvanceSearch, diverseSupplierDescription, diverseSupplierCertification, existingCertification, tradeMemberShip, saveSearch } from "../../../redux/slices/advanceSearch";
import { businessOwnershipType, getAllIndustryGroups, getAllIndustrySubGroups } from "../../../redux/slices/auth";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAdvanceSearchSelector } from "../../../redux/selector/advanceSearch";
import ReactPaginate from "react-paginate";
import { AdvReadMoreMore } from "read-more-more";
import Multiselect from 'multiselect-react-dropdown';
import CustomModal from "../../components/common/shared/CustomModal";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import SavedSearches from './SavedSearches';
import SaveSearch from './SaveSearch';
import { toast } from 'react-toastify';
import { useConnectionSelector } from '../../../redux/selector/connection';

const Business = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);
  const selectedFilteredValue = queryParams.get('selectedFilters');

  const connectionData = useConnectionSelector()
  const activeTabSearch = location?.state?.activeTabSearch;
  const certificate_tooltip = " Say goodbye to tedious paperwork and complex requirements. GetOPP$ simplifies and streamlines the entire certification process, empowering you to focus on growing your business. With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier. Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity. "
  const maxLength = 60;
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(Math.random());
  const [currentPage, setCurrentPage] = useState(0);
  const messagesEndRef = useRef(null);
  const advanceSearchSelector = useAdvanceSearchSelector();
  const [businessName, setBusinessName] = useState(1);
  const [businessNameRoles, setBusinessNameRoles] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [advanceSearchinfo, setAdvanceSearchinfo] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [driversSupplierDesc, setDriversSupplierDesc] = useState([]);
  const [driversSupplierDescriptionIds, setDriversSupplierDescriptionId] = useState();
  const [industryGroupsList, setindustryGroupsList] = useState();
  const [industrySubGroupsList, setindustrySubGroupsList] = useState();
  const [industryGroupId, setIndustryGroupId] = useState("");
  const [industrySubGroupId, setIndustrySubGroupId] = useState("")
  const [selected, setSelected] = useState([]);
  const [selecteTrade, setSelectedTrade] = useState("");
  const [selecteCertificate, setSelectedCertificate] = useState("")
  const [selectedSort, setSelectedSort] = useState("");
  const [changePage, setChangePage] = useState("10");
  const [businessType, setBusinessType] = useState("");
  const [businessTypeRole, setBusinessTypeRole] = useState(2);
  const [ownershipType, setOwnershipType] = useState("")
  const [ownershipTypeId, setOwnershipTypeId] = useState("")
  const [localServiceArea, setLocalServiceArea] = useState("");
  const [internationalServiceAreas, setInternationalServiceAreas] = useState("");
  const [existingCertificationData, setExistingCertificationData] = useState([]);
  const [existingCertificationId, setExistingCertificationId] = useState("");
  const [tradeMemberShipData, setTradeMemberShipData] = useState("");
  const [tradeMemberShipId, setTradeMemberShipId] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [checkboxValuesJV, setCheckboxValuesJV] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const [scrollTop, setScrollTop] = useState(false)
  const [badgeInfo, setBadgeInfo] = useState("")
  const selectedFilter = location?.state?.selectedFilter

  //closeModal
  const handleOnCloseModal = () => {
    setScrollTop(true)
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //Company Status
  const handleCheckboxChange = (event) => {

    const { value, checked } = event.target;
    if (checked) {
      // If checkbox is checked, add the value to the array
      setCheckboxValues([...checkboxValues, value]);
    } else {
      // If checkbox is unchecked, remove the value from the array
      setCheckboxValues(checkboxValues.filter((item) => item !== value));
    }
  };

  //Open to JV supplier opportunities
  const handleCheckboxChangeJV = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // If checkbox is checked, add the value to the array
      setCheckboxValuesJV([...checkboxValuesJV, value]);
    } else {
      // If checkbox is unchecked, remove the value from the array
      setCheckboxValuesJV(checkboxValuesJV.filter((item) => item !== value));
    }
  };

  // function for user veiw profile.........
  const handleSubmited = (id) => {
    navigate('/view-profile', { state: { id: id } });
  }

  // function for user veiw profile buyer & supplier.........
  const handleSubmitedViewProfile = (id, name) => {
    navigate(`/${name}/${id}`)
  }

  // for scroll to Bottom in supplier ,buyer ,opp. listing after pagination
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
  };

  // if (activeTabSearch == 1) {
  //   // function for business Name.........
  //   businessNames(1, "opportunity")
  // }



  // function for business Name.........
  const businessNames = (flag, opp) => {
    setCurrentPage(0)
    setBusinessType(flag)
    setBusinessTypeRole("")
    AdvanceSearchList(1, 10, flag, opp);
    setSearchInput("")
    setOwnershipTypeId("")
    setDriversSupplierDescriptionId("")
    setSelected("")
    setIndustryGroupId("")
    setIndustrySubGroupId("")
    setCheckboxValues("")
    setLocalServiceArea("");
    setExistingCertificationData([])
    setExistingCertificationId("")
    setTimeout(() => {
      // Code to execute after 1 second
      ExistingCertification();
    }, 50);
    setSelectedCertificate("")
  }


  // function for business Name Role.........
  const businessNameRole = (flag) => {
    setCurrentPage(0)
    setBusinessTypeRole(flag)
    setBusinessType("")
    AdvanceSearchList(1, 10, flag);
    setSearchInput("")
    setOwnershipTypeId("")
    setDriversSupplierDescriptionId("")
    setDriversSupplierDesc([])
    setLocalServiceArea("")
    setCheckboxValuesJV("")
    setInternationalServiceAreas("")
    // Set a timeout for 1 second
    setTimeout(() => {
      // Code to execute after 1 second
      SupplierDescription();
    }, 50);

    setExistingCertificationId("")
    setExistingCertificationData([])
    // Set a timeout for 1 second
    setTimeout(() => {
      // Code to execute after 1 second
      ExistingCertification();
    }, 50);

    setTradeMemberShipData("")
    setTradeMemberShipId("")


    // Set a timeout for 1 second
    setTimeout(() => {
      // Code to execute after 1 second
      TradeMembership();
    }, 50);

    setSelected("")
    setSelectedTrade("")
    setSelectedCertificate("")

    setIndustryGroupId("")
    setIndustrySubGroupId("")
    setCheckboxValues("")

  }

  // if(activeTabSearch === 1){

  //   businessNames(1, "opportunity");
  // }

  //for add connection.........
  const handleSubmit = (id, userid) => {
    let params = {
      receiver_id: userid,
      company_id: id,
      message: "hello"
    }
    dispatch(addConnection({
      ...params, cb(res) {
        if (res.status) {
          AdvanceSearchList();
        }
      }
    }))
  }

  //for add drivers Supplier Description........
  const SupplierDescription = (id) => {
    setDriversSupplierDescriptionId(id)
    dispatch(diverseSupplierDescription({
      cb(res) {
        if (res.status) {
          setDriversSupplierDesc(res?.data?.payload);
        }
      }
    }))
  }

  //for add industry Groups .......
  const industryGroups = (id) => {
    dispatch(getAllIndustryGroups({
      cb(res) {
        if (res.status) {
          setindustryGroupsList(res?.data?.payload)
        }
      }
    }))
  }

  //for add industry Sub Groups .......
  const industrySubGroups = (id) => {
    if (id) {
      let params = {
        id: id,
      }
      dispatch(getAllIndustrySubGroups({
        ...params, cb(res) {
          if (res.status) {
            setindustrySubGroupsList(res?.data?.payload)
          }
        }
      }))
    }
  }

  // onchange input 
  const handleChanged = (id, flag) => {
    if (flag == "industry") {
      setIndustryGroupId(id)
      industrySubGroups(id)
    }
    else {
      setIndustrySubGroupId(id)
    }
  }

  //on change page pagination
  const handlePageClick = (e) => {

    if (businessTypeRole === 2) {
      AdvanceSearchList(e.selected + 1, 10, 2, "")
    }
    else if (businessTypeRole === 1) {
      AdvanceSearchList(e.selected + 1, 10, 1, "")
    }
    else if (!businessTypeRole) {
      AdvanceSearchList(e.selected + 1, 10, "", "opportunity")
    }
    setTimeout(() => {
      scrollToBottom();
    }, 10)
  };
  // role, opp, Sorting
  // driver supplier description
  const handleChanges = (data) => {
    setSelected(data)
    let arr = [];
    for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
    setDriversSupplierDescriptionId(arr)
  }

  const handleChangesCertifications = (data) => {

    setSelectedCertificate(data)

    let arr = [];
    for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }

    setExistingCertificationId(arr)
  }

  const handleChangesTradeMembership = (data) => {

    setSelectedTrade(data)

    let arr = [];
    for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }

    setTradeMemberShipId(arr)
  }

  // sort listing data 
  const handleSort = (Sorting) => {
    setSelectedSort(Sorting)
    if (Sorting) {
      AdvanceSearchList(1, 10, 1, "", Sorting)
    }
  }

  //change page size
  const hangelPage = (flag) => {
    setChangePage(flag)
    if (flag == 10) {
      AdvanceSearchList(1, 10, 1)
    }
    else if (flag == 20) {
      AdvanceSearchList(1, 20, 1)
    }
    else if (flag == 30) {
      AdvanceSearchList(1, 30, 1)
    }
  }
  //  reset all filter after delete the saved filtes
  const ResetFilter = (data) => {
    if (data === "empty") {
      ResetAllFilter()
    }
  }

  // reset all filters
  const ResetAllFilter = (data) => {
    setInternationalServiceAreas("")
    setLocalServiceArea("")
    setSearchInput("")
    setOwnershipTypeId("")
    setDriversSupplierDescriptionId('')
    // SupplierDescription();
    setSelected("")
    setExistingCertificationId("")
    setSelectedCertificate("")
    setTradeMemberShipId("")
    setSelectedTrade("")
    setIndustryGroupId("")
    setIndustrySubGroupId("")
    setCheckboxValues("")
    setExistingCertificationId("")
    setTradeMemberShipId("")
    setBusinessTypeRole(2)
    setBusinessType("")
    AdvanceSearchList(1, 10)
    setCheckboxValuesJV("")
    // window.location.reload();
  }


  // handle change address Address
  const handleSelectLocalServiceArea = async value => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setLocalServiceArea(value)
  }

  // handle change address Internation Service Area
  const handleSelectInternationalServiceAreas = async value => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setInternationalServiceAreas(value)
  }

  // business ownership
  const businessOwnership = (id) => {
    setOwnershipTypeId(id)
    dispatch(businessOwnershipType({
      cb(res) {
        if (res.status) {
          setOwnershipType(res?.data?.payload)
        }
        else {
        }
      }
    }))
  }

  // Existing certification
  const ExistingCertification = (id) => {
    setExistingCertificationId(id)
    dispatch(existingCertification({
      cb(res) {
        if (res.status) {
          setExistingCertificationData(res.data.payload)
        }
        else {

        }
      }
    }))
  }

  // Trade Membership 
  const TradeMembership = () => {
    dispatch(tradeMemberShip({
      cb(res) {
        if (res.status) {
          setTradeMemberShipData(res.data.payload)
        }
        else {

        }
      }
    }))
  }

  // handle change certification
  const handleChangeCertification = (id) => {
    setExistingCertificationId(id)
  }

  // handle change certification
  const handleChangeTradeMemberShip = (id) => {
    setTradeMemberShipId(id)
  }


  const handleSaveSearch = (name) => {

    const searchParams = {
      business_ownership_type: ownershipTypeId,
      diverse_supplier_description: driversSupplierDescriptionIds ? driversSupplierDescriptionIds.map(num => parseInt(num)).join(',') : "",
      industry_groups: industryGroupId,
      industry_sub_groups: industrySubGroupId,
      company_status: checkboxValues ? checkboxValues?.map(num => parseInt(num)).join(',') : "",
      trade_membership_id: tradeMemberShipId ? tradeMemberShipId?.map(num => parseInt(num)).join(',') : "",
      existing_certifications_id: existingCertificationId ? existingCertificationId?.map(num => parseInt(num)).join(',') : "",
      domestic_service_areas: localServiceArea,
      global_service_areas: internationalServiceAreas,
      open_to_jv_supplier_opportunities: checkboxValuesJV ? checkboxValuesJV?.map(num => parseInt(num)).join(',') : "",
      user_role: businessType ? "" : (businessTypeRole ? businessTypeRole : 2),
      business_name: searchInput.trim(),
      type: businessType ? businessType : "",
    }

    const params = {
      name: name,
      data: searchParams
    }
    // if (businessTypeRole != "") {
    //   params = {
    //     type: businessType ? businessType : 2,
    //   }
    // }

    dispatch(saveSearch({
      ...params, cb(res) {
        if (res.status == 201) {
          toast.success('Search filters saved successfully')
        }
      }
    }))

  }


  const industrySubGroupsPlay = (id, subCatId) => {
    if (id) {
      let params = {
        id: id,
      }
      dispatch(getAllIndustrySubGroups({
        ...params, cb(res) {
          if (res.status) {

            setindustrySubGroupsList(res?.data?.payload)
            setIndustrySubGroupId(subCatId)

          }
        }
      }))
    }
  }

  const handlePlaySearch = (data) => {
    let newData = JSON.parse(data)

    setOwnershipTypeId(newData.business_ownership_type)

    const idsToFilterOut = newData.diverse_supplier_description.split(',').map((val) => {
      return parseInt(val)
    });
    const filteredArray = driversSupplierDesc.filter(item => idsToFilterOut.includes(item.id));
    setSelected(filteredArray)
    setDriversSupplierDescriptionId(idsToFilterOut)

    setIndustryGroupId(newData.industry_groups)

    industrySubGroupsPlay(newData.industry_groups, newData.industry_sub_groups)

    setCheckboxValues(newData.company_status.split(','))

    const TradeMemberShipIdToFilterOut = newData.trade_membership_id.split(',').map((val) => {
      return parseInt(val)
    });
    const filteredMemberShipArray = tradeMemberShipData.filter(item => TradeMemberShipIdToFilterOut.includes(item.id));
    setSelectedTrade(filteredMemberShipArray)
    setTradeMemberShipId(TradeMemberShipIdToFilterOut)

    const CertificationIdToFilterOut = newData.existing_certifications_id.split(',').map((val) => {
      return parseInt(val)
    });
    const filteredbbCertificateArray = existingCertificationData.filter(item => CertificationIdToFilterOut.includes(item.id));
    setSelectedCertificate(filteredbbCertificateArray)
    setExistingCertificationId(CertificationIdToFilterOut)

    setLocalServiceArea(newData.domestic_service_areas)

    setInternationalServiceAreas(newData.global_service_areas)

    setCheckboxValuesJV(newData.open_to_jv_supplier_opportunities.split(','))

    setBusinessType(newData.type)

    setBusinessTypeRole(newData.user_role)

    setSearchInput(newData.business_name)

    updateFilterData(1, 10, newData)

  }


  //for add connection.........
  const handleActiveTab = (id) => {


    let params = {

    }
    if (id === 1) {
      params.id = "buyers"
    }
    else if (id === 2) {
      params.id = "suppliers"
    }
    else if (id === 3) {
      params.id = "opportunity"
    }
    dispatch(activeTab({
      ...params, cb(res) {
      }
    }))
  }


  //handle filter function
  const updateFilterData = (page = 1, limit = 10, newParams = null) => {
    //for add Advance search list.........
    let params = {
      page: page,
      limit: limit,
      business_ownership_type: ownershipTypeId,
      // diverse_supplier_description: driversSupplierDescriptionIds ? driversSupplierDescriptionIds.map(num => parseInt(num)).join(',') : "",
      // industry_sub_groups: industrySubGroupId,

      // existing_certifications_id: existingCertificationId ? existingCertificationId?.map(num => parseInt(num)).join(',') : "",
      // domestic_service_areas: localServiceArea,

      business_name: searchInput.trim()
    }
    if (businessTypeRole != "") {
      params = {
        ...params,
        type: businessType ? businessType : 2,
        industry_groups: industryGroupId,
        industry_sub_groups: industrySubGroupId,
        diverse_supplier_description: driversSupplierDescriptionIds ? driversSupplierDescriptionIds.map(num => parseInt(num)).join(',') : "",
        company_status: checkboxValues ? checkboxValues?.map(num => parseInt(num)).join(',') : "",
        trade_membership_id: tradeMemberShipId ? tradeMemberShipId?.map(num => parseInt(num)).join(',') : "",
        global_service_areas: internationalServiceAreas,
        existing_certifications_id: existingCertificationId ? existingCertificationId?.map(num => parseInt(num)).join(',') : "",
        open_to_jv_supplier_opportunities: checkboxValuesJV ? checkboxValuesJV?.map(num => parseInt(num)).join(',') : "",
        user_role: businessTypeRole ? businessTypeRole : 2,
        domestic_service_areas: localServiceArea,
      }
    }
    if (businessType === 1) {
      params = {
        ...params,
        page: page,
        limit: limit,
        type: 1,
        industry_group: industryGroupId,
        industry_sub_group: industrySubGroupId,
        diversity_status: driversSupplierDescriptionIds ? driversSupplierDescriptionIds.map(num => parseInt(num)).join(',') : "",
        existing_certificates: existingCertificationId ? existingCertificationId?.map(num => parseInt(num)).join(',') : "",
        hq_location: localServiceArea,

        // user_role: 1
      }
    }

    if (newParams) {
      params = {
        page: page,
        limit: limit,
        ...newParams
      }
    }

    dispatch(getAdvanceSearch({
      ...params, cb(res) {
        if (res.status) {
          setAdvanceSearchinfo(res?.data?.payload?.data)
          setPageCount(res?.data?.payload?.total_pages)
        }
      }
    }))
  }

  //for add Advance search list........
  const AdvanceSearchList = (page = 1, limit = 10, role, opp, Sorting) => {
    let params = {
      page: page,
      limit: limit,
      user_role: role ? role : 2,
      sort: Sorting ? Sorting : "",
    }
    if (businessTypeRole != "") {
      params = {
        ...params,
        type: businessType ? businessType : 2,
        sort: Sorting ? Sorting : "",
        business_name: searchInput.trim(),
      }
    }
    if (opp === "opportunity") {
      params = {
        type: 1,
        page: page,
        limit: limit,
        sort: Sorting ? Sorting : "",
        posting_title: searchInput.trim(),
      }
    }
    dispatch(getAdvanceSearch({
      ...params, cb(res) {
        if (res.status) {
          setAdvanceSearchinfo(res?.data?.payload?.data)
          setPageCount(res?.data?.payload?.total_pages)
          // setCurrentPage(res?.data?.payload?.current_page)
        }
      }
    }))
  }

  // Reset Input Search
  const ResetInputSearch = (page = 1, limit = 10,) => {
    let params = {
      page: page,
      limit: limit,
      user_role: businessTypeRole
    }
    if (businessTypeRole != "") {
      params = {
        ...params,
        type: businessType ? businessType : 2,
      }
    }
    dispatch(getAdvanceSearch({
      ...params, cb(res) {
        if (res.status) {
          setAdvanceSearchinfo(res?.data?.payload?.data)
          setPageCount(res?.data?.payload?.total_pages)
        }
      }
    }))
  }

  const emptyActiveTab = () => {
    let params = {}
    dispatch(setActiveTab({
      ...params, cb(res) {

      }
    }))
  }

  useEffect(() => {
    SupplierDescription();
    businessOwnership();
    TradeMembership();
    ExistingCertification();
    industryGroups();
    AdvanceSearchList();
    document.title = "Business";
  }, []);


  useEffect(() => {
    if (selectedFilter && selectedFilter === 'opportunity') {

      setTimeout(() => {
        businessNames(1, "opportunity")
      }, 50)

    }
  }, [selectedFilter])

  useEffect(() => {
    if (connectionData?.activeTab?.id === 'buyers') {
      setTimeout(() => {
        businessNameRole(1)
      }, 50)

      // businessNameRole(1)

    }
    else if (connectionData?.activeTab?.id === 'suppliers') {
      setTimeout(() => {
        businessNameRole(2)
      }, 50)
      // businessNameRole(2)

    }
    else if (connectionData?.activeTab?.id === 'opportunity') {
      setTimeout(() => {
        businessNames(1, "opportunity")
      }, 50)
      // businessNames(1, "opportunity")
    }
  }, [connectionData?.activeTab])

  useEffect(() => {

    if (selectedFilteredValue === 'opportunity') {

      setTimeout(() => {
        businessNames(1, "opportunity")

        queryParams.delete('selectedFilters');
        const updatedQueryString = queryParams.toString();
        const newURL = `${window.location.pathname}${updatedQueryString ? `?${updatedQueryString}` : ''}`;

        navigate(newURL);

      }, 50)
    }
  }, [selectedFilteredValue])

  useEffect(() => {
    if (searchInput != "") {
      AdvanceSearchList(1, 10, businessTypeRole ? businessTypeRole : 2);
    }
    if (businessType == 1) {
      let params = {
        type: 1,
        page: 1,
        limit: 10,
        posting_title: searchInput.trim(),
      }
      dispatch(getAdvanceSearch({
        ...params, cb(res) {
          if (res.status) {
            setAdvanceSearchinfo(res?.data?.payload?.data)
            setPageCount(res?.data?.payload?.total_pages)
          }
        }
      }))
    }
  }, [searchInput]);

  useEffect(() => {
    if (scrollTop) {
      window.scrollTo(0, 0)
    }

  }, [scrollTop]);


  return (
    <>
      <div className='businessSearch'>
        <div className='row mb-2'>
          <div className='col-md-12 col-lg-6 '>
            {/* <h3 className='headTextmain_'>
              <span className='blueHead_ '>Suppliers</span> Business Search</h3>
              <span className='blueHead_ '>Filters</span></h3> */}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-5'>
            <div className='mb-3'>
              {/* {businessType == 1 ? "" : businessTypeRole == 1 ? <span className='mainHeading me-1'>Buyer</span>
            : <span className='mainHeading coloredHeading me-1'> Supplier </span>} {businessType == 1 ?
             <span className='mainHeading me-1'>Opportunity</span> : "Business Search"} */}
              {businessTypeRole == 1 ?
                <>
                  <span className='mainHeading coloredHeading me-1'>Buyer</span>
                  <span> Busines Search </span>
                </>
                :
                businessTypeRole == 2 ?
                  <>
                    <span className='mainHeading coloredHeading me-1'>Supplier</span>
                    <span> Busines Search </span>
                  </>
                  :
                  businessType == 1 ?
                    <>
                      <span className='mainHeading coloredHeading me-1'>Opportunity</span>
                      <span> Busines Search </span>
                    </> : ""}

            </div>
          </div>
          <div className='col-lg-7'>
            <div className='selectListbox mb-3 text-end'>
              <div className="checkmark_ radioCheck_" ref={messagesEndRef} >
                <button className={`secondaryBtnGrey ${businessTypeRole == '2' ? 'subsmallBtnblue_' : ''}`} onClick={() => {
                  emptyActiveTab()
                  businessNameRole(2)
                }} type='button'>
                  Suppliers
                </button>
              </div>
              <div className="checkmark_ radioCheck_" >

                <button onClick={() => {
                  emptyActiveTab()
                  businessNameRole(1)
                }} type='button' className={`secondaryBtnGrey ${businessTypeRole == '1' ? 'subsmallBtnblue_' : ''}`} >
                  Buyers
                </button>
              </div>
              <div className="checkmark_ radioCheck_" >
                <button className={`secondaryBtnGrey ${businessType == '1' ? 'subsmallBtnblue_' : ''}`} onClick={() => {
                  emptyActiveTab()
                  businessNames(1, "opportunity")
                }} type='button' >
                  Opportunities
                </button>
              </div>
            </div>
          </div>
          <div className='col-md-12 col-lg-4'>
            <div className='containerBox innerSidebar'>
              {!businessNameRoles ?
                <>
                  <label className="postopportunity_label mb-2" htmlFor="">Keyword Search</label>
                  <div className="business_search">
                    <form className='inputSearchForm'>
                      <div className="input-group flexBox">
                        <input className="custoInput" value={searchInput} onChange={(e) => { setSearchInput(e.target.value); setPagination(Math.random()) }} type="text" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                        {searchInput &&
                          <img src={Images.crossIcon} alt='crossIcon' onClick={() => { setSearchInput(""); ResetInputSearch(1, 10) }} className='img-fluid crossIconSearch' />
                        }
                      </div>
                    </form>
                  </div>

                  <label className="postopportunity_label mt-3" htmlFor="">Business Ownership Type</label>
                  <div className='form-group col-md-12 mb-3'>
                    <select className="cateSelectbox" required="" value={ownershipTypeId} onChange={(e) => { businessOwnership(e.target.value) }}>
                      <option value="0">Select Option</option>
                      {ownershipType?.length > 0 ?
                        <>
                          {ownershipType?.map((data) => {

                            return <option value={data?.id}>{data?.name}</option>
                          })}
                        </>
                        : ""}
                    </select>
                  </div>

                  <div className="tagsType mt-3 mb-3 col-md-12">
                    <label className="postopportunity_label mb-2" htmlFor="">Diverse Status</label>


                    {driversSupplierDesc &&
                      <>
                        <Multiselect
                          options={driversSupplierDesc} // Options to display in the dropdown
                          selectedValues={selected} // Preselected value to persist in dropdown
                          onSelect={handleChanges} // Function will trigger on select event
                          onRemove={handleChanges} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                        <i class="las la-plus inputIcon"></i>
                      </>
                    }
                  </div>

                  <div className='location_ mt-3 mb-2 '>
                    <h5 className='postopportunity_label mb-2'>Industry Group</h5>
                    <select className="cateSelectbox" required="" value={industryGroupId} onChange={(e) => { handleChanged(e.target.value, "industry") }} >
                      <option value="0">Select Option</option>
                      {industryGroupsList?.length > 0 ?
                        <>
                          {industryGroupsList.map((option, id) => (
                            <option value={option?.id}>{option?.name}</option>
                          ))}
                        </>
                        : ""
                      }
                    </select>
                  </div>
                  <div className='location_ mt-3 mb-2 '>
                    <select className="cateSelectbox" required="" value={industrySubGroupId} onChange={(e) => { handleChanged(e.target.value, "subindustry"); }} >
                      <option value="">Select Sub Industry</option>
                      {industrySubGroupsList?.length > 0 ?
                        <>
                          {industrySubGroupsList.map((option, id) => (
                            <option value={option?.id}>{option?.name}</option>
                          ))}
                        </>
                        : ""
                      }
                    </select>
                  </div>
                  {selectedFilter === "opportunity" || selectedFilteredValue === "opportunity" || businessType === 1 ? "" :
                    <div className='form-group col-md-12 mt-3 mb-3'>
                      <label className='postopportunity_label'>Company Status</label>
                      <div className="form-group col-md-12 mb-3">
                        <div className='d-flex mb-3'>
                          <input type="checkbox" id="Public" className='checkBox me-2' value="1"
                            checked={checkboxValues.includes('1')}
                            onChange={handleCheckboxChange} />
                          <label htmlFor="Public" className='checkBoxTxt align-items-start align-items-md-center'>
                            <span>
                              Public
                            </span>
                          </label>
                        </div>
                        <div className='d-flex mb-3'>
                          <input type="checkbox" id="Private" className='checkBox me-2' value="2"
                            checked={checkboxValues.includes('2')}
                            onChange={handleCheckboxChange} />
                          <label htmlFor="Private" className='checkBoxTxt align-items-start align-items-md-center'>
                            <span>
                              Private
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  }
                  {selectedFilter === "opportunity" || selectedFilteredValue === "opportunity" || businessType === 1 ? "" :
                    <div className='form-group col-md-12 mb-3'>
                      <label className='postopportunity_label'>Open to JV supplier opportunities	</label>
                      <div className="form-group col-md-12 mb-3">
                        <div className='d-flex mb-3'>
                          <input type="checkbox" id="Yes" value="1" checked={checkboxValuesJV.includes('1')}
                            onChange={handleCheckboxChangeJV}
                            className='checkBox me-2' />
                          <label htmlFor="Yes" className='checkBoxTxt align-items-start align-items-md-center'>
                            <span>
                              Yes
                            </span>
                          </label>
                        </div>
                        <div className='d-flex'>
                          <input type="checkbox" id="No" value="0" checked={checkboxValuesJV.includes('0')} onChange={handleCheckboxChangeJV} className='checkBox me-2' />
                          <label htmlFor="No" className='checkBoxTxt align-items-start align-items-md-center'>
                            <span>
                              No
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  }
                  <div className='location_ mt-3 mb-2 '>
                    <h5 className='postopportunity_label mb-2'>Existing Certification</h5>
                    {/*   */}
                    {existingCertificationData &&
                      <>
                        <div className='position-relative'>
                          <Multiselect
                            options={existingCertificationData} // Options to display in the dropdown
                            selectedValues={selecteCertificate} // Preselected value to persist in dropdown
                            onSelect={handleChangesCertifications} // Function will trigger on select event
                            onRemove={handleChangesCertifications} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                          />
                          <i class="las la-plus inputIcon"></i>
                        </div>
                      </>
                    }
                  </div>
                  {selectedFilter === "opportunity" || selectedFilteredValue === "opportunity" || businessType === 1 ? "" :
                    <div className='location_ mt-3 mb-2 '>
                      <h5 className='postopportunity_label mb-2'>Trade Membership</h5>
                      {tradeMemberShipData &&
                        <>
                          <div className='position-relative'>
                            <Multiselect
                              options={tradeMemberShipData} // Options to display in the dropdown
                              selectedValues={selecteTrade} // Preselected value to persist in dropdown
                              onSelect={handleChangesTradeMembership} // Function will trigger on select event
                              onRemove={handleChangesTradeMembership} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                              className='selectIndex'
                            />
                            <i class="las la-plus inputIcon"></i>
                          </div>
                        </>
                      }

                      {/* <select className="cateSelectbox" required="" value={tradeMemberShipId} onChange={(e) => { handleChangeTradeMemberShip(e.target.value) }} >
                      <option value="">Select Option</option>
                      {tradeMemberShipData?.length > 0 ?
                        <>
                          {tradeMemberShipData.map((option, id) => (
                            <option value={option?.id}>{option?.name}</option>
                          ))}
                        </>
                        : ""
                      }
                    </select> */}
                    </div>
                  }

                  <div className='location_ mt-3 mb-2 '>
                    <h5 className='postopportunity_label mb-2'>Local Service Area</h5>
                    <PlacesAutocomplete
                      value={localServiceArea}
                      onChange={setLocalServiceArea}
                      onSelect={handleSelectLocalServiceArea}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'Search Places ...',
                              className: ' customFormControl location-search-input form-control textArea',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>

                  {selectedFilter === "opportunity" || selectedFilteredValue === "opportunity" || businessType === 1 ? "" :
                    <div className='location_ mt-3 mb-2 '>
                      <h5 className='postopportunity_label mb-2'>International Service Areas	</h5>
                      <PlacesAutocomplete
                        value={internationalServiceAreas}
                        onChange={setInternationalServiceAreas}
                        onSelect={handleSelectInternationalServiceAreas}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: ' customFormControl location-search-input form-control textArea',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  }
                </>
                : <div className="business_search">
                  <form className='inputSearchForm'>
                    <div className="input-group">
                      <input className="custoInput" value={searchInput} onChange={(e) => { setSearchInput(e.target.value); setPagination(Math.random()) }} type="search" placeholder="Company Search" aria-label="Search" aria-describedby="search-addon" />
                      {searchInput && <button className='inputClose' type="reset" onClick={() => { setSearchInput("") }}> </button>}
                    </div>
                  </form>
                </div>
              }
              {/* <div className='Business_details'>
                {businessNameRoles ?
                  <> */}
              {/* <div className='businessType_ mt-3 mb-2 pb-3'>
                      <h5 className='headingSmall_ mb-2'>Types of Business</h5>
                      <div className='businessDetail_ mt-2'>
                        {selectedTypeOfBusiness?.length > 0 ?
                          <>
                            {selectedTypeOfBusiness?.map((items, index) => {
                              return (
                                <div className="checkmark_ " key={items.id}>

                                  <input
                                    type="checkbox"
                                    name="languages"
                                    id={items.id}
                                    onChange={(e) => handleSelect(e)}
                                  />
                                  <label htmlFor={items.id} className="labelTxt_">
                                    <span className='innerSubtextSmall'>{items.name}</span>
                                  </label>
                                  <span className='value_'>{items?.totalCount}</span>
                                </div>

                              );
                            })}
                          </>
                          : ""}

                      </div>
                    </div> */}

              {/* <div className="tagsType mt-3 mb-3 col-md-12">
                      <label className="headingSmall_ mb-2" htmlFor="">Diverse Status</label>
                      {driversSupplierDesc &&
                        <Multiselect
                          options={driversSupplierDesc} // Options to display in the dropdown
                          selectedValues={selected} // Preselected value to persist in dropdown
                          onSelect={handleChanges} // Function will trigger on select event
                          onRemove={handleChanges} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      }
                    </div> */}

              {/* <div className='location_ mt-3 mb-2 '>
                      <h5 className='headingSmall_ mb-2'>Industry Groups</h5>
                      <select className="cateSelectbox" required="" onChange={(e) => { handleChanged(e.target.value, "industry") }} >
                        <option value="">Select Industry Groups</option>
                        {industryGroupsList?.length > 0 ?
                          <>
                            {industryGroupsList.map((option, id) => (
                              <option value={option?.id}>{option?.name}</option>
                            ))}
                          </>
                          : ""
                        }
                      </select>
                    </div>
                    <div className='location_ mt-3 mb-2 '>
                      <h5 className='headingSmall_ mb-2'>Industry Sub Groups</h5>
                      <select className="cateSelectbox" required="" onChange={(e) => { handleChanged(e.target.value, "subindustry"); }} >
                        <option value="">Select Industry Sub Groups</option>
                        {industrySubGroupsList?.length > 0 ?
                          <>
                            {industrySubGroupsList.map((option, id) => (
                              <option value={option?.id}>{option?.name}</option>
                            ))}
                          </>
                          : ""
                        }
                      </select>
                    </div> */}

              {/* <div className='form-group col-md-12 mb-3'>
                      <label className='postopportunity_label'>Company Status</label>
                      <select className="cateSelectbox" value={selectedId} onChange={handleSelectChange}>
                        <option value="" >Select Company Status</option>
                        {options.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>

                  </>
                  : ""
                }

              </div> */}

              <div className='businessBtn mt-4'>
                <button onClick={() => ResetAllFilter()} className="secondaryBtnGrey me-2">Reset</button>
                <button onClick={() => updateFilterData()} className="primaryBtnSmall">Update Search</button>
              </div>
              <hr className='bottomBorder_'></hr>
              <div className='businessBtn mt-4'>
                <button className="secondaryBtnOuter me-2"
                  onClick={() => {
                    setModalDetail({ show: true, flag: "SaveSearch" });
                    setKey(Math.random());
                  }}>
                  Save Search</button>
                <button className="secondaryBtnGrey"
                  onClick={() => {
                    setModalDetail({ show: true, flag: "SavedSearches" });
                    setKey(Math.random());
                    setScrollTop(false)
                  }}>
                  View Search
                </button>
              </div>
            </div>
            {/* <div className='lookSuplier mt-4'>
              <img className="suplierImg_" src={Images.suplierImg} alt="suplierImg" />
              <div className='supText_'>
                <h5>Looking for Suppliers? <strong>Click here</strong></h5>
              </div>
            </div> */}
            {/* <div className='lookSuplier mt-4'>
              <img className="suplierImg_" src={Images.businessImg_} alt="suplierImg" />
              <div className='businessText_'>
                <h5>Business Resources +</h5>
              </div>
            </div> */}
            <div className='cardContainer mt-4'>
              <div className='cardImg'>
                <img src={Images.certifyUser} className='img-fluid' alt='CardImg' />
              </div>
              <div className='cardBodyArea'>
                <h5>Get Certified</h5>
                <div className="infoiconText">
                  <span className="showinfoText">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                </div>
                <p className='my-4'>GetOPP$ Diversity Certifications for inclusive business futures. </p>
                <div className="infoiconText m-0">
                  <Link to="/certificate">
                    <button className='primaryBtn'>Learn More</button>
                  </Link>
                  <span className="showinfoText">
                    {/* {data?.posting_title.length > 13.5 ? data?.posting_title.substring(0, 13.5) + "...." : data?.posting_title} */}

                    {certificate_tooltip.substring(0, maxLength)}...

                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12 col-lg-8'>
            <div className='containerBox'>
              {businessName == 1 ?
                <h3 className='mainHeading'>
                  Search Results
                </h3> : ""
              }
              <div className='topPagefilter'>

                <div className='col-12 text-end'>
                  <select value={selectedSort} onChange={(e) => handleSort(e.target.value)} className="cateSelectbox" required="">
                    <option value="">Select Sort type</option>
                    <option value="1" >Recently Added</option>
                    <option value="2" >Top - Bottom</option>
                  </select>
                  <select value={changePage} onChange={(e) => hangelPage(e.target.value)} className="cateSelectbox" required="">
                    {/* <option value="">Select per page </option> */}
                    <option value="10">10 Per Page</option>
                    <option value="20" >20 Per Page</option>
                    <option value="30" >30 Per Page</option>
                  </select>
                </div>
              </div>
              {advanceSearchSelector.loading ?
                <>
                  <div className="row">
                    <div className="col-sm-12 text-center pt-5 pb-5">
                      <div className="spinner-grow text-success" role="status"></div>
                    </div>
                  </div>
                </>
                :
                <>
                  {advanceSearchinfo?.length ?
                    <>
                      {advanceSearchinfo.map((data, index) => {
                        let logo;
                        if (data?.company_logo) {
                          logo = data.company_logo;
                        }
                        else if (data?.opportunity_media) {
                          logo = data.opportunity_media;
                        }
                        else {
                          logo = Images.companyprofile;
                        }

                        return (
                          <>

                            <div key={index} className='businessDesincontent_ mt-4'>
                              <div className='row' >
                                <div className='col-lg-3 col-md-4'>
                                  <div className='designImg_'>
                                    <img className='imgproduct_' src={logo} />
                                  </div>
                                </div> 
                                <div className='col-lg-9 col-md-8'>
                                  <div className='designContent_'>
                                    <div className='designcontentTop_ mb-2'>
                                      <div className='designLeft_'>
                                      {/* <p className='bussinessSector mb-0'>hello</p> */}
                                        <h4 className='headingTitleSmall_'>{data?.company_name ? data?.company_name : data?.posting_title}{data?.user_details?.business_type === 1 ? <img className="badgeImg" src={Images.badgeImg} />  :""}</h4>
                                        <h5 className='weeksTxt'>{data?.hq_location}</h5>                                     
                                        </div>
                                      <div className='designbtnRight'>
                                        {businessType === 1 ?
                                          <button className='subsmallBtnblue_ me-2' onClick={() => {
                                            handleActiveTab(3)
                                            handleSubmited(businessName ? data?.id : data?.id)
                                          }}>
                                            View
                                          </button>
                                          :
                                          <button className='subsmallBtnblue_ me-2' onClick={() => {
                                            handleActiveTab(businessTypeRole)
                                            handleSubmitedViewProfile(businessName ? data?.id : data?.id, data?.company_name)
                                          }}>
                                            View Profile
                                          </button>}

                                        {businessName != "" ? ""
                                          :
                                          !data.company_connection ?
                                            <button className='subsmallBtngreen_' onClick={() => { handleSubmit(data?.id, data?.user_id,) }}>Connect</button>
                                            : data.company_connection?.status == 1 ?
                                              <button className='subsmallBtngreen_' disabled="true">Pending</button>
                                              : data.company_connection?.status == 2 ?
                                                "" :
                                                data.company_connection?.status == 3 ?
                                                  <button className='subsmallBtngreen_' onClick={() => { handleSubmit(data?.id, data?.user_id) }}>Connect</button>
                                                  : ""
                                        }

                                      </div>
                                    </div>
                                    <div className='advanceDetails'>
                                      <AdvReadMoreMore text={data?.company_service_description ? data?.company_service_description : data?.description} linesToShow={3}
                                        checkFor={100}
                                        transDuration={2} />
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="bottomBorder_"></hr>

                            {index == 0 && businessNameRoles != null ?
                              <div className='cardContainer_ mt-4'>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <div className='cardBodyArea'>
                                      <span className='certified_ mb-4'>Certified</span>
                                      <h5>Bridge the Gap</h5>
                                      <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore e dolore.</p>
                                      <button className='btnNobg_ mt-4'>Learn More</button>
                                    </div>
                                  </div>
                                  <div className='col-md-6'>
                                    <div className='bridgeImg '>
                                      <img src={Images.bridgeImg} className='img-fluid' alt='CardImg' />
                                    </div>
                                  </div>
                                </div>
                              </div> : null
                            }
                          </>
                        )
                      })
                      }
                    </>
                    : <b>No Data Found</b>
                  }
                </>
              }
              <div className="col-12 ">
                <div className="customPagination mt-4">
                  <div className="pagination">
                    {advanceSearchinfo?.length > 0 ? (
                      <ReactPaginate
                        forcePage={currentPage}
                        // initialPage={currentPage}
                        // key={pagination}
                        // breakLabel=""
                        // nextLabel=">"
                        nextLabel={<i className='fas fa-caret-right'></i>}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={pageCount}
                        marginPagesDisplayed={0}
                        pageCount={pageCount}
                        previousLabel={<i className='fas fa-caret-left'></i>}
                        // previousLabel="<"
                        renderOnZeroPageCount={null}
                      />
                    ) : ""}
                  </div>
                </div>
              </div>
              {/* <hr className="bottomBorder_"></hr> */}
            </div>
          </div>
        </div>
      </div >


      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "SavedSearches" ? "SavedSearches" : ""}
        ids={
          modalDetail.flag === "SavedSearches" ?
            "SavedSearches" :
            modalDetail.flag === "SaveSearch" ?
              "SaveSearch" :
              null}
        child={
          modalDetail.flag === "SavedSearches" ? (
            <SavedSearches
              close={() => handleOnCloseModal()}
              playSearch={(data) => handlePlaySearch(data)}
              setScrollTop={setScrollTop}
              ResetFilter={(data) => ResetFilter(data)}

            />
          ) :
            modalDetail.flag === "SaveSearch" ?
              (
                <SaveSearch
                  close={() => handleOnCloseModal()}
                  saveSearch={(name) => handleSaveSearch(name)}
                />
              )
              :
              null
        }
        header={
          <div className='modalHeader_'>
            <div className="common_">
              {modalDetail.flag === "SavedSearches" ?
                <h2 className="addLabels_heading">
                  Saved Searches
                </h2>
                : modalDetail.flag === "SaveSearch" ?
                  <h2 className="addLabels_heading">
                    Add Name
                  </h2>
                  : ""
              }
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default Business