import React from "react"
import { Link, useNavigate } from "react-router-dom";
import * as images from "../../../utilities/images";
import { Nav } from "react-bootstrap";

const AuthFooter = () => {
    return (
        <>
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-6 col-md-6">
                            <h4 className="heading_">Company</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    <Link className="footerLinks" to="/about-us#press"
                                    >Press & News</Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='/privacy-policy' className="footerLinks">Privacy Policy</Link>
                                </li>
                                <li className="footerItems">
                                    <Link  className="footerLinks">Refund Policy</Link>
                                </li>

                                <li className="footerItems">
                                    <Link to='/terms-condition' className="footerLinks">Terms of Service</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-6 col-md-6">
                            <h4 className="heading_">Community</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    <Link to='/chooserolesfor=signup' className="footerLinks">Get Started</Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='/membershipchooseperrole' className="footerLinks">Membership</Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='/resource' className="footerLinks">Resources</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-6 col-md-6">
                            <h4 className="heading_">Support</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    <Link to='/frequent-question' className="footerLinks">FAQ's</Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='/about-us' className="footerLinks">About Us</Link>
                                </li>
                                <li className="footerItems" >
                                    <Link to='/contact-us' className="footerLinks">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-6 col-md-6 text-end">
                            <img src={images.mobileLogo} className='img-fluid footerImg' alt='footerImg' />
                        </div>
                    </div>
                    <section className="footerBottom">
                        <div className="row align-items-center">
                            <div className="col-6 col-md-4">
                                <ul className="socialMenus">
                                    <li className="socialItems">
                                        <Link to='' className="socialLinks">
                                            <img src={images.twitterIcon} alt="Twitter" />
                                        </Link>
                                    </li>
                                    <li className="socialItems">
                                        <Link to='' className="socialLinks">
                                            <img src={images.facebookIcon} alt="Facebook" />
                                        </Link>
                                    </li>
                                    <li className="socialItems">
                                        <Link to='' className="socialLinks">
                                            <img src={images.instaIcon} alt="Insta" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 col-md-8 text-end">
                                <p className="subtextInner">
                                    © 2023 GetOpps
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )

}

export default AuthFooter;