import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuthSelector } from '../../redux/selector/auth';
import AuthFooter from '../components/common/authFooter';
import AuthNav from '../components/common/authNav';

const AuthLayout = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const { membercompanyname, mycompanyname, id } = useParams();

  var companyRouteNames = [`/${mycompanyname}`, `/${membercompanyname}/${id}`]

  const authRoutes = ['/resources', '/companyfrontProfile', '/mycompanyProfile'];

  useEffect(() => {
    if (window)
      window.scrollTo(0, 0);

  }, [pathName, window]);

  return (
    <>
      <AuthNav />
      {authRoutes.includes(pathName) ?
        <>
          <Outlet />
        </>
        :
        <>
          {companyRouteNames.includes(decodeURIComponent(pathName)) ?
            <main className='main'>
              <div>
                <Outlet />
              </div>
            </main>
            :
            <main className='main' id='main'>
              <div className='container'>
                <Outlet />
              </div>
            </main>
          }
        </>
      }
      <AuthFooter />
    </>
  );
};

export default AuthLayout;