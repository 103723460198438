import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import auth, {
  getCompanyProfileInfo,
  stepThreeCompanyLogoUplaod,
  companyBannerUplaod,
  companyUpdateLogoBanner,
} from "../../../redux/slices/auth";
import { Link, useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../../redux/selector/auth";
import {
  postFeature,
  userPostComment,
  userPostLike,
} from "../../../redux/slices/dashboard";
import AllPostLikeUser from "../dashboard/allPostLikeUser";
import CustomModal from "../../components/common/shared/CustomModal";
import CommmentBox from "../dashboard/comment";

const MyCompanyProfile = () => {
  const authSelector = useAuthSelector();
  const [hideEditBtn, setHideEditBtn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDeatil, setUserDeatil] = useState();
  const [featurePost, setFeaturePost] = useState();
  const [leaveEndorsementsDate, setLeaveEndorsementsDate] = useState(
    new Date()
  );
  const [userCurrentOpportunity, setUserCurrentOpportunity] = useState();
  const [leaveEndorsementsDescription, setLeaveEndorsementsDescription] =
    useState("");
  const [userEndorsementGiven, setUserEndorsementGiven] = useState();
  const [userEndorsementReceived, setUserEndorsementReceived] = useState();
  const [leaveEndorsementsCompanyName, setleaveEndorsementsCompanyName] =
    useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyBanner, setCompanyBanner] = useState("");
  const [comment, setComments] = useState([]);
  const [postComments, setPostComments] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const userSignupSelectedCompanyInformation = authSelector?.userStep;
  const userLoginSelectedCompanyInformation = authSelector?.userStep?.user;
  const userLoginOwnCompanyInformation =
    authSelector?.userStep?.user?.user_company_information;
  const userSigninOwnCompanyInformation =
    authSelector?.userStep?.user_company_information;

  //onchange input
  const handleChange = (e, id) => {
    const value = e.target.value;
    setComments({
      ...comment,
      [id]: value,
    });
  };

  // // onchange date set date in the state
  // const onChangeDateLeaveEndorsement = (date) => {
  //   setLeaveEndorsementsDate(date);
  // };

  // // cancel endrosment
  // const CancleEndrosement = () => {
  //   setleaveEndorsementsCompanyName("");
  //   setLeaveEndorsementsDate("");
  //   setLeaveEndorsementsDescription("");
  // };

  //for COMPANY PROFILE INFORMATION.......
  const companyProfileInfo = () => {
    dispatch(
      getCompanyProfileInfo({
        cb(res) {
          if (res.status) {
            if (res?.data?.payload?.userDetails?.user_company_information !== null) {
              setUserDeatil(res?.data?.payload?.userDetails?.user_company_information)
              // setUserData(res?.data?.payload?.userDetails)
            }
            else {
              // setUserData(res?.data?.payload?.userDetails)
              setUserDeatil(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
            }
            setFeaturePost(res?.data?.payload?.feature_post);
            // setUserDeatil(res?.data?.payload?.userDetails);
            setUserCurrentOpportunity(res?.data?.payload?.companyPostOpportunity?.data);
            setUserEndorsementGiven(res?.data?.payload?.endorsement_given);
            setUserEndorsementReceived(
              res?.data?.payload?.endorsement_received
            );


          } else {
          }
        },
      })
    );
  };


  // apply API for uplaod company Logo

  const onChangePicture = (e, flag) => {
    const imageFile = e.target.files[0];
    e.currentTarget.value = null;
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error("Please select a valid image.");
      return false;
    }
    let params = {
      photo: imageFile,
    };
    if (flag == 1) {
      dispatch(
        stepThreeCompanyLogoUplaod({
          ...params,
          cb(res) {
            if (res?.data) {
              setCompanyLogo(res?.data?.payload);
              saveProfileChnage(res?.data?.payload, 1);
            }
          },
        })
      );
    }
    if (flag == 2) {
      dispatch(
        companyBannerUplaod({
          ...params,
          cb(res) {
            if (res?.data) {
              setCompanyBanner(res?.data?.payload);
              saveProfileChnage(res?.data?.payload, 2);
            }
          },
        })
      );
    }
  };

  // apply API for update Logo and Banner

  const saveProfileChnage = (data, flag) => {
    let params;
    if (flag == 1) {
      params = {
        id:
          userSignupSelectedCompanyInformation
            ?.user_selected_company_information?.user_id ||
          userLoginSelectedCompanyInformation?.user_selected_company_information
            ?.user_id ||
          userLoginOwnCompanyInformation?.id ||
          userSigninOwnCompanyInformation?.id,
        company_logo: data?.url,
        company_logo_size: parseInt(data?.size),
        company_logo_name: data?.name,
      };
    } else if (flag == 2) {
      params = {
        id:
          userSignupSelectedCompanyInformation
            ?.user_selected_company_information?.user_id ||
          userLoginSelectedCompanyInformation?.user_selected_company_information
            ?.user_id ||
          userLoginOwnCompanyInformation?.id ||
          userSigninOwnCompanyInformation?.id,
        banner: data?.url,
        banner_name: data?.name,
        banner_size: parseInt(data?.size),
        banner_format: data?.format,
      };
    }

    dispatch(
      companyUpdateLogoBanner({
        ...params,
        cb(res) {
          if (res.status) {
            companyProfileInfo();
          }
        },
      })
    );
  };

  //handle play button icon
  const handleVideoPlay = (post, index) => {
    setFeaturePost((prevData) => {
      let data = [...prevData];

      // If you want to update the like status
      const updatedPost = {
        ...data[index],
        isvideo: true,
      };
      data[index] = updatedPost;

      return data;
    });
  };

  // // API for post Feature ..............
  const postFetures = (id, flag, index) => {
    let params = {
      post_id: id,
      feature_status: flag,
    };
    dispatch(
      postFeature({
        ...params,
        cb(res) {
          if (res.status) {
            companyProfileInfo();
          }
        },
      })
    );
  };

  //for post like...............
  const postLike = (id, flag, index) => {
    const params = {
      post_id: id,
      like_status: flag,
    };
    dispatch(
      userPostLike({
        ...params,
        cb(res) {
          if (res.status) {
            companyProfileInfo();
          } else {
          }
        },
      })
    );
  };

  // total user like post
  const totalUserLikeList = (id) => {
    setModalDetail({ show: true, flag: "allPostLikeUser", id: id });
    setKey(Math.random());
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
    companyProfileInfo();
  };

  //for post comment...............
  const postComment = (id, index) => {
    if (!comment[id]?.length) {
      toast.error("Please enter comment");
      return;
    }
    const params = {
      post_id: id,
      comment: comment[id],
    };
    dispatch(
      userPostComment({
        ...params,
        cb(res) {
          if (res.status) {
            setPostComments(res?.data?.payload);
            delete comment[id];
            setComments(comment);
            setFeaturePost((prevData) => {
              let data = [...prevData];
              if (data[index]) {
                // If you want to update the like status
                const updatedPost = {
                  ...data[index],
                  post_comments: res.data.payload.post_comments,
                  total_comments: res?.data?.payload?.total_comments,
                };
                data[index] = updatedPost;
              }
              return data;
            });
          } else {
          }
        },
      })
    );
  };
  const handleChangePage = () => {
    navigate("/resources");
  };

  // handle  page change 
  const handlePageRedirect = (name, id) => {
    if (id) {
      navigate(`/${name}/${id}`)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    companyProfileInfo();
    document.title = "Profile";
  }, []);

  //   useEffect(() => {
  //     if (authSelector?.userInfo?.user?.user_company_information?.company_name !== null) {
  //         setHideEditBtn(false)
  //     }
  //     else if (authSelector?.userInfo?.user?.user_company_information?.company_name === null) {
  //         setHideEditBtn(true)
  //     }
  // }, [])

  useEffect(() => {
    if (authSelector?.userStep?.user?.user_company_information !== null) {
      setHideEditBtn(false)
    }
    else if (authSelector?.userStep?.user?.user_company_information === null) {
      setHideEditBtn(true)
    }
  }, [])


  return (
    <>
      <section className="companyProfile_">
        <div className="topprofileBanner">
          <div className="container-fluid noPadding">
            <div className="row">
              <img
                className="profile-img companyprofilebanner"
                src={
                  userDeatil?.banner
                    ? userDeatil?.banner
                    : Images.companyProfile
                }
              />
              {
                hideEditBtn === true ? "" :
                  <>
                    <input
                      type="file"
                      id="fileAttCompany"
                      accept="image/jpeg, image/jpg, image/png"
                      className="form-control d-none"
                      required=""
                      onChange={(e) => {
                        onChangePicture(e, 2);
                      }}
                    />
                    <label className="fileupimg" htmlFor="fileAttCompany">
                      <figure className="chnageProfile">
                        <img
                          src={Images.attach}
                          alt="Attach"
                          className="uploadIcon_"
                        />
                      </figure>
                    </label>
                  </>

              }

            </div>
          </div>
        </div>
        <div className="companyDetailspro_ mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="companyDetailsLeft">
                  <div className="containerBox">
                    <div className="row">
                      <div className="col-lg-2 col-md-3 p-0">
                        <div className="designImg_">
                          {
                            authSelector?.loading ?
                              <>
                                <div className="row">
                                  <div className="col-sm-12 text-center pt-5 pb-5">
                                    <div className="spinner-grow text-success" role="status"></div>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                                <img
                                  className="imgproduct_"
                                  src={
                                    userDeatil?.company_logo
                                      ? userDeatil?.company_logo
                                      : Images.companyprofile
                                  }
                                />
                                {
                                  hideEditBtn === true ? "" : <>

                                    <input
                                      type="file"
                                      id="fileAttach"
                                      accept="image/jpeg, image/jpg, image/png"
                                      className="form-control d-none"
                                      required=""
                                      onChange={(e) => {
                                        onChangePicture(e, 1);
                                      }}
                                    />
                                    <label className="fileupimg" htmlFor="fileAttach">
                                      <figure className="chnageProfile">
                                        <img
                                          src={Images.attach}
                                          alt="Attach"
                                          className="uploadIcon_"
                                        />
                                      </figure>
                                    </label>
                                  </>
                                }
                                :
                              </>

                          }
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-9">
                        <div className="designContent_">
                          <div className="designcontentTop_">
                            <div className="designLeft_">
                              <h4 className="boldmainHeading_">
                                {
                                  userDeatil?.company_name
                                }
                              </h4>
                              <h5 className="abouText_ mt-2">
                                {
                                  userDeatil?.industries_group_details?.name

                                }
                              </h5>
                              <p className="motionLoc mt-3 mb-3">
                                <img
                                  src={Images.location_}
                                  alt="Location"
                                  className="me-1"
                                />
                                <span className="textInnerOuter">
                                  {
                                    userDeatil?.domestic_service_areas
                                  }
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="designbtnRight">

                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="bottomBorder_"></hr>
                    <div className="aboutprofile_ mt-4">
                      <h5 className="headingTitle_">
                        <b>
                          About
                        </b>
                      </h5>
                      <p className="innerSubtextSmall companyInfo mt-2">
                        {
                          userDeatil?.company_service_description
                        }
                      </p>
                    </div>
                  </div>

                  <div className="containerBox mt-4">
                    <h4 className="headingTitle_ mb-3">
                      <b>Featured Posts</b>
                    </h4>
                    {
                      authSelector?.loading ?
                        <>
                          <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                              <div className="spinner-grow text-success" role="status"></div>
                            </div>
                          </div>
                        </>
                        :
                        <>
                          {featurePost?.length ? (
                            <>
                              {featurePost?.map((post, index) => {
                                return (
                                  <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                      <div class="postContainerArea">
                                        <div class="posts">
                                          <img
                                            src={
                                              post?.post_details?.user_details
                                                ?.user_profile?.profile_photo
                                                ? post?.post_details?.user_details
                                                  ?.user_profile?.profile_photo
                                                : Images.companyprofile
                                            }
                                            class="postUser me-3"
                                            alt="UserImage"
                                          />
                                          <div class="postsDetails">
                                            <p class="innerSubtext m-0">
                                              <strong>
                                                {
                                                  post?.post_details?.user_details
                                                    ?.user_company_information
                                                    ?.company_name
                                                }{" "}
                                                Company{" "}
                                              </strong>
                                            </p>
                                            <h5 class="headingTitleSmall_ d-flex align-items-center justify-content-between mb-2">
                                              <span class="memberName">
                                                {
                                                  post?.post_details?.user_details
                                                    ?.user_profile?.first_name
                                                }{" "}
                                                {
                                                  post?.post_details?.user_details
                                                    ?.user_profile?.last_name
                                                }
                                              </span>
                                            </h5>
                                            {post?.post_details?.post?.startsWith(
                                              "http"
                                            ) ||
                                              post?.post_details?.post?.startsWith(
                                                "https"
                                              ) ? (
                                              <a
                                                className="linkIndex_"
                                                href={post?.post_details?.post}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                {post?.post_details?.post}
                                              </a>
                                            ) : (
                                              <p className="innerSubtextSmall mb-2">
                                                {post?.post_details?.post}
                                              </p>
                                            )}
                                            <span class="weeksTxt">
                                              {moment(post?.created_at).fromNow()}
                                            </span>
                                          </div>
                                        </div>
                                        {post?.post_details?.attachment_type == 3 ? (
                                          <>
                                            {post?.isvideo == true ? (
                                              <video controls>
                                                <source
                                                  src={post?.post_details?.attachment}
                                                  type="video/mp4"
                                                  className="soundbiteImg"
                                                />
                                              </video>
                                            ) : (
                                              <div className="postBox">
                                                <img
                                                  className="imagepost_"
                                                  src={
                                                    post?.post_details?.thumbnail_url
                                                  }
                                                />
                                                <img
                                                  onClick={() =>
                                                    handleVideoPlay(
                                                      post?.post_details,
                                                      index
                                                    )
                                                  }
                                                  className="videoIcon"
                                                  src={Images.VideoIcon}
                                                />
                                              </div>
                                            )}
                                          </>
                                        ) : post?.post_details?.attachment == null ? (
                                          ""
                                        ) : (
                                          <div className="postImage_ mt-3 mb-3">
                                            <img
                                              className="imagepost_"
                                              src={post?.post_details?.attachment}
                                            />
                                          </div>
                                        )}
                                        <div className="bottomPostSec d-flex align-items-center justify-content-end mt-1">
                                          <p className="postrate_">
                                            <span
                                              onClick={() =>
                                                totalUserLikeList(
                                                  post?.post_details?.id
                                                )
                                              }
                                            >
                                              {post?.likeCount}
                                            </span>
                                            {post?.post_details?.is_like &&
                                              post?.post_details?.is_like
                                                ?.like_status === 1 ? (
                                              <img
                                                className="likepost_ me-2"
                                                src={Images.thumbsup}
                                                onClick={(e) => {
                                                  postLike(
                                                    post?.post_details?.id,
                                                    2,
                                                    index
                                                  );
                                                }}
                                              />
                                            ) : (
                                              <img
                                                className="likepost_ me-2"
                                                src={Images.greyLike}
                                                onClick={(e) => {
                                                  postLike(
                                                    post?.post_details?.id,
                                                    1,
                                                    index
                                                  );
                                                }}
                                              />
                                            )}
                                          </p>
                                          <p className="postrate_">
                                            <span>{post?.total_comments}</span>
                                            <img
                                              onClick={() => {
                                                setModalDetail({
                                                  show: true,
                                                  flag: "CommentBox",
                                                  id: post?.post_details?.id,
                                                });
                                                setKey(Math.random());
                                              }}
                                              src={Images.message}
                                              className="messageiconn me-2"
                                              alt="Message"
                                            />
                                          </p>
                                          <p className="postrate_">
                                            <img
                                              src={Images.star}
                                              onClick={(e) => {
                                                postFetures(
                                                  post?.post_details?.id,
                                                  2,
                                                  index
                                                );
                                              }}
                                              className="messageiconn"
                                              alt="Message"
                                            />

                                          </p>
                                        </div>
                                        <div className="commentBox_ mt-3">
                                          <div className="form-group mb-3 col-md-12">
                                            <textarea
                                              type="text"
                                              className="customFormControl"
                                              placeholder="What do you have to tell people."
                                              id="inputName"
                                              name="post"
                                              value={
                                                comment[post?.post_details?.id]
                                                  ? comment[post?.post_details?.id]
                                                  : ""
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  post?.post_details?.id
                                                )
                                              }
                                            />
                                            <img
                                              className="sendIcon_"
                                              src={Images.sendIcon_}
                                              onClick={() => {
                                                postComment(
                                                  post?.post_details?.id,
                                                  index
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            "No Post Found"
                          )}
                        </>
                    }

                  </div>

                  <div className="containerBox mt-4 pb-5">
                    <div className="currentOpportunity_">
                      <div className="currentTop">
                        <h4 className="headingTitle_">
                          <b>Current Opportunities</b>
                        </h4>
                        <Link to="/opportunities">
                          <button className="subsmallBtnblue_ me-2">
                            View All
                          </button>
                        </Link>
                      </div>
                      {
                        authSelector?.loading ?
                          <>
                            <div className="row">
                              <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                              </div>
                            </div>
                          </>
                          :
                          <>
                            <div className="row mt-5 ctive">
                              {userCurrentOpportunity?.length > 0 ? (
                                <>
                                  {userCurrentOpportunity.slice(0, 3).map((data, index) => {
                                    return (
                                      <>
                                        <div className="col-lg-4">
                                          <div className={`opportunityComtent_ ${index == 0 ? 'noOpportunity' : ''}`}>
                                            <img
                                              className="todoList_"
                                              src={
                                                data?.opportunity_media
                                                  ? data?.opportunity_media
                                                  : Images.todoList
                                              }
                                            />
                                            <h5 className="opportunityHead_ mt-3">
                                              {data?.posting_title}
                                            </h5>
                                            <h6 className="headingTitleSmall_ mb-2">
                                              Fixed Term Contract
                                            </h6>
                                            <p className="lightgreyHead_">
                                              Additional Contract Details
                                            </p>
                                            <p className="postHours_ mb-3">
                                              U.S. Veterans
                                            </p>
                                            <p className="innerSubtextSmall">
                                              {data?.description}
                                            </p>
                                            <button className="subsmallBtngreen_ mt-3">
                                              Request Application
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                "No Current Opportunity Found"
                              )}
                            </div>
                          </>
                      }


                    </div>
                  </div>

                  <div className="containerBox mt-4">
                    <div className="newsBox_ endorsement_ ">
                      <Tabs defaultIndex={1}>
                        <div className="endorTabs">
                          <h4 className="headingTitle_">
                            <b>Endorsements</b>
                          </h4>
                          <TabList>
                            <Tab>Given</Tab>
                            <Tab>Received</Tab>
                          </TabList>
                        </div>

                        {
                          authSelector?.loading ?
                            <>
                              <div className="row">
                                <div className="col-sm-12 text-center pt-5 pb-5">
                                  <div className="spinner-grow text-success" role="status"></div>
                                </div>
                              </div>
                            </>
                            :
                            <div className="row mt-4">
                              <TabPanel>
                                {userEndorsementGiven?.length > 0 ? (
                                  <>
                                    {userEndorsementGiven?.map((data, index) => {
                                      return (
                                        <>
                                          <div className="messageDetail d-flex   mt-4">
                                            <div className="imageDiv">
                                              <img
                                                onClick={() => handlePageRedirect(data?.receiver_details?.user_company_information?.company_name, data?.receiver_details?.user_company_information?.id)}
                                                src={data?.receiver_details?.user_company_information?.company_logo ? data?.receiver_details?.user_company_information?.company_logo : Images.companyprofile}
                                                className="userProfile me-2"
                                                alt="UserImage"
                                              />
                                            </div>
                                            <div className="postsDetails">
                                              <h5 className="innerSubtextSmall d-flex align-items-center mb-2">
                                                <span
                                                  onClick={() => handlePageRedirect(data?.receiver_details?.user_company_information?.company_name, data?.receiver_details?.user_company_information?.id)}
                                                >
                                                  <strong>
                                                    {data?.receiver_details?.user_company_information?.company_name}{" "}
                                                  </strong>
                                                </span>
                                              </h5>
                                              <p className="discription_">
                                                {data?.endorsement}
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <p>No Endorsements Given</p>
                                )}
                              </TabPanel>

                              <TabPanel>
                                {userEndorsementReceived?.length > 0 ? (
                                  <>
                                    {userEndorsementReceived?.map((data, index) => {

                                      return (
                                        <>
                                          <div className="messageDetail d-flex   mt-4">
                                            <div className="imageDiv">
                                              <img
                                                onClick={() => handlePageRedirect(data?.sender_details?.user_company_information?.company_name, data?.sender_details?.user_company_information?.id)}
                                                src={data?.sender_details?.user_company_information?.company_logo ? data?.sender_details?.user_company_information?.company_logo : Images.companyprofile}
                                                //src="/static/media/accholder1.80a726e142a9ba09e13e.png"
                                                className="userProfile me-2"
                                                alt="UserImage"
                                              />
                                            </div>
                                            <div className="postsDetails">
                                              <h5 className="innerSubtextSmall d-flex align-items-center mb-2">
                                                <span
                                                  onClick={() => handlePageRedirect(data?.sender_details?.user_company_information?.company_name, data?.sender_details?.user_company_information?.id)}
                                                >
                                                  <strong>
                                                    {data?.sender_details?.user_company_information?.company_name}{" "}
                                                  </strong>
                                                </span>
                                              </h5>
                                              <p className="discription_">
                                                {data?.endorsement}
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <p>No Endorsements Received</p>
                                )}
                              </TabPanel>
                            </div>
                        }

                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="containerBoxright mt-5">
                  <div className="companyDetailsRight">
                    <h5 className="headingTitle_ mb-2">
                      <b>Company Details</b>
                    </h5>
                    {
                      authSelector?.loading ?
                        <>
                          <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                              <div className="spinner-grow text-success" role="status"></div>
                            </div>
                          </div>
                        </>
                        :
                        <ul className="companyDetailsList">
                          <li>
                            <span className="blueinnerSubtext">Website</span>
                            <span className="listHead_">
                              {
                                userDeatil?.company_website
                              }
                            </span>
                          </li>
                          <li>
                            <span className="blueinnerSubtext">Industry</span>
                            <span className="listHead_">
                              {
                                userDeatil?.industries_group_details?.name
                              }
                            </span>
                          </li>
                          <li>
                            <span className="blueinnerSubtext">Company Size</span>

                            <span className="listHead_">
                              {userDeatil?.number_of_employees == 0
                                ? userDeatil?.number_of_employees
                                : userDeatil?.number_of_employees + `+ employees`}
                            </span>
                          </li>
                          <li>
                            <span className="blueinnerSubtext">Locations</span>
                            <span className="listHead_">
                              {
                                userDeatil?.main_company_country_location
                              }
                            </span>
                          </li>
                        </ul>
                    }

                  </div>
                </div>
                <div className="containerBoxright mt-4">
                  <div className="deviserCompany_">
                    <div className="currentTop">
                      <h4 className="headingTitle_"><b>Diverse Supplier</b></h4>
                      <button
                        onClick={() => handleChangePage()}
                        className="subsmallBtnblue_ me-2"
                      >
                        Learn More
                      </button>
                    </div>
                    <p>
                      A diverse supplier refers to a business or entity that is
                      owned, operated, and controlled by individuals who belong
                      to traditionally underrepresented or marginalized groups.
                      These groups can include, but are not limited to, ethnic
                      minorities, women, LGBTQ+ individuals, veterans, and
                      people with disabilities. Diverse suppliers play a crucial
                      role in promoting inclusivity and equity in business
                      practices and procurement processes, as they contribute to
                      a broader range of perspectives, economic empowerment, and
                      increased opportunities within various industries.
                    </p>
                  </div>
                </div>

                <div className="lookSuplier mt-4">
                  <div className="cardBox">
                    <div className="textBox">
                      <h6>
                        Business <b>Resources</b>
                      </h6>
                      <p>
                        Building, sustaining and growing a business is a
                        never-ending journey that involves continuos infusions
                        of knowledge and wisdom.
                      </p>
                      <button
                        onClick={() => navigate("/resources")}
                        className="primaryBtnSmall"
                      >
                        Start Learning
                      </button>
                    </div>
                  </div>
                </div>
                <div className="containerBoxright mt-3">
                  <div className="deviserCompany_">
                    <div className="currentTop">
                      <h4 className="headingTitle_">
                        <b>Certifications</b>
                      </h4>
                      <button className="subsmallBtnblue_ me-2">
                        Learn More
                      </button>
                    </div>

                    <div className="certificationContainer_">
                      {userDeatil?.existing_certifications ? (
                        <div className="posts justify-content-between mt-3">
                          <img
                            src={Images.certifactionBadge}
                            className="certifyBadge me-3"
                            alt="UserImage"
                          />
                          <div className="postsDetails">
                            <h5 className="headingTitleSmall_ d-flex     justify-content-between mb-1">
                              <span className="listHead_">
                                {
                                  userDeatil?.existing_certifications
                                }
                                <span className="docText">
                                  (
                                  {userDeatil?.existing_certifications
                                    .split(" ")
                                    .map((word) => word.charAt(0))
                                    .join("")}
                                  )
                                </span>

                              </span>
                            </h5>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "CommentBox"
            ? "commentBox"
            : modalDetail.flag === "CommentBox"
              ? "commonWidth customContent"
              : ""
        }
        ids={modalDetail.flag === "addTagToPost" ? "addTagToPost" : ""}
        child={
          modalDetail.flag === "CommentBox" ? (
            <CommmentBox
              id={modalDetail.id}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag == "allPostLikeUser" ? (
            <AllPostLikeUser
              id={modalDetail.id}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "CommentBox" ? (
                <h2 className="headingTitle_">Comment Box</h2>
              ) : modalDetail.flag == "allPostLikeUser" ? (
                <h2 className="headingTitle_">Post Like List</h2>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default MyCompanyProfile;
