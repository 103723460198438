import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as images from "../../../utilities/images";

const ChooseRoles = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("");
    const [id, setId] = useState("");

    const from  = location?.state?.from;
    const membershipID =  location?.state?.membershipID;

    //tab click
    const tabClick = (id) => {
        setId(id)
    }

    //create Account by select role
    const handleSubmit = (e, flag) => {
        e.preventDefault();
        if (!id) {
            toast.error("Please Select atleast one role.")
        }
        else if (flag === "login") {
            navigate('/login', { state: { id: id } });
        }
        else if (flag === "signup") {

            if(from && membershipID && from == "membershipPage"){
                navigate('/signup', { state: { id: membershipID, roleId: id } });
            }
            else{
                navigate('/businessneed', { state: { id: id } });
            }

        }
        else {

            if(from && membershipID && from == "membershipPage"){
                navigate('/signup', { state: { id: membershipID, roleId: id } });
            }
            else{
                navigate('/businessneed', { state: { id: id } });
            }

        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Choose-role";
    }, []);

    return (
        <div className="row justify-content-center">
            <div className="col-md-10 text-center">
                <div className="detailsContainer">
                    <h3 className="headTxt mt-3 mb-4">{location.pathname == "/chooserolesfor=signin" ? "Sign in" : "Sign up"} as a <span className="highLightTxt">Buyer</span> or <span className="highLightTxt">Supplier</span>

                        {/* or  */}
                        {/* <span className='highLightTxt'>both</span> */}
                    </h3>
                    <form onSubmit={(e) => handleSubmit(e)} >
                        <div className='row justify-content-center'>
                            <div onClick={() => tabClick(1)} className='col-md-6 col-lg-4 mb-3'>
                                <div className={activeTab == 'Buyer' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("Buyer")}>
                                    <img src={images.buyer} alt='Buyer' className='img-fluid' />
                                    <h4 className="heading_ mt-3 mb-3">Buyer</h4>
                                    <p className='textInner'>I'm a buyer, looking to connect with suppliers.</p>
                                </div>
                            </div>
                            <div onClick={() => tabClick(2)} className='col-md-6 col-lg-4 mb-3'>
                                <div className={activeTab == 'Supplier' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("Supplier")}>
                                    <img src={images.supplier} alt='Supplier' className='img-fluid' />
                                    <h4 className="heading_ mt-3 mb-3">Supplier</h4>
                                    <p className='textInner'>I’m a supplier, looking to connect with buyers.</p>
                                </div>
                            </div>
                            {/* <div onClick={() => tabClick(3)} className='col-md-6 col-lg-4 mb-3'>
                                <div className={activeTab == 'Both' ? "rolesContainer active" : "rolesContainer"} onClick={() => setActiveTab("Both")}>
                                    <img src={images.boths} alt='Both' className='img-fluid' />
                                    <h4 className="heading_ mt-3 mb-3">Both</h4>
                                    <p className='textInner'>I’m a supplier & buyer, looking to connect with businesses.</p>
                                </div>
                            </div> */}
                            <div className='col-12 col-md-12 col-lg-7 mt-3 mb-3'>
                                {location.pathname == "/login" ?
                                    <>
                                        <button onClick={(e) => handleSubmit(e, "login")} className='primaryBtn w-100 mt-2' type="submit">
                                            &nbsp;&nbsp;
                                            <span>Sign in account</span>
                                        </button>
                                        <div className='accountRelate'>
                                            <p className='innerTxt mt-4 w-100'>Don't have an account? <button onClick={(e) => handleSubmit(e, "signup")} className='linkTxt'><b>Sign up</b></button></p>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <button onClick={(e) => handleSubmit(e, "signup")} className='primaryBtn w-100 mt-2' type="submit">
                                            &nbsp;&nbsp;
                                            <span>Create account</span>
                                        </button>
                                        <div className='accountRelate'>
                                            <p className='innerTxt mt-4 w-100'>Already have an account? <button onClick={(e) => handleSubmit(e, "login")} type="submit" className='linkTxt'><b>Log In</b></button></p>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChooseRoles