import React, { useState } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image';
import { useNavigate } from 'react-router-dom';
import * as Images from "../../../utilities/images";


const UpdatePayment = (props) => {

 const navigate = useNavigate()
    
  const handleRedirect = () =>{
    navigate('/setting',{state:{activeTab:'subscription'}})
    props.close()
  }

  return (
    <>
    <form class="registrationForm row mt-3 justify-content-center">
        <div class="membershipRegistration_">
            <div className='heading text-center'>
                <h3 className="headingSmall">Update Payment</h3> 
                <p>
                    Your current membership plan has been expired please update your next billing to proceed further.
                </p>                      
            </div>
        </div>
        <div class="row justify-content-center my-3">
            <div class="col-md-6">
                <button class="submitopportunity w-75" onClick={()=>{handleRedirect()}}>My Plan</button>
            </div>
        </div>
    </form>
    </>
  )
}


export default UpdatePayment