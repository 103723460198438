import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { diverseSupplierDescription } from '../../../redux/slices/advanceSearch';
import { businessOwnershipType, getAllIndustryGroups, getAllIndustrySubGroups, getCompanyProfileInfo, userCompanyProfileUpdate } from '../../../redux/slices/auth';
import { useAuthSelector } from '../../../redux/selector/auth';

const EditCompanyInformationDetails = (props) => {
    const dispatch = useDispatch();
    const authSelector = useAuthSelector()
    const [companyStatus, setCompanyStatus] = useState()
    const [companyJVOpportunities, setCompanyJVOpportunities] = useState("")
    const [ownershipType, setOwnershipType] = useState("")
    const [ownershipTypeId, setOwnershipTypeId] = useState("")
    const [totalNoEmployee, setTotalNoEmployee] = useState("")
    const [industryGroupId, setIndustryGroupId] = useState("")
    const [subIndustryGroupId, setSubIndustryGroupId] = useState("")
    const [industryGroup, setIndustryGroup] = useState([])
    const [industrySubGroup, setindustrySubGroup] = useState([]);
    const [address, setAddress] = useState("");
    const [addresHqLocation, setAddresHqLocation] = useState("");
    const [companyDetails, setCompanyDetails] = useState("");
    const [cordinates, setCordinates] = useState({
        lat: null,
        lng: null
    });
    const [cordinatesHqLocation, setCordinatesHqLocation] = useState({
        lat: null,
        lng: null
    });



    const options = [
        { id: 1, name: 'Enterprise business' },
        { id: 2, name: 'Small business' },
    ];

    const [selectedId, setSelectedId] = useState(1); // Default selected ID

    // Find the selected option based on selectedId
    const selectedOption = options.find(option => option.id === selectedId);

    const handleSelectChange = (event) => {
        const newSelectedId = parseInt(event.target.value, 10); // Parse the selected value as an integer
        setSelectedId(newSelectedId);
    };

    // handle change address
    const handleSelect = async value => {
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        setAddress(value)
        setCordinates(ll)
    }

    // handle change hq location
    const handleSelectHqLocation = async value => {
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        setAddresHqLocation(value)
        setCordinatesHqLocation(ll)
    }
    const businessOwnership = (id) => {
        setOwnershipTypeId(id)
        dispatch(businessOwnershipType({
            cb(res) {
                if (res.status) {
                    setOwnershipType(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    const handleChange = (e, flag) => {
        const { value } = e.target;
        if (flag == "employees") {
            setTotalNoEmployee(value);
        }
    }

    const IndustryGroups = (id) => {
        setIndustryGroupId(id)
        dispatch(getAllIndustryGroups({
            cb(res) {
                if (res.status) {
                    setIndustryGroup(res?.data?.payload)
                    if (id) {
                        industrySubGroups(id);
                        setSubIndustryGroupId("")
                    }

                }
            }
        }))
    }

    //for add industry Sub Groups .......
    const industrySubGroups = (id) => {
        if (id) {
            let params = {
                id: id,
            }
            dispatch(getAllIndustrySubGroups({
                ...params, cb(res) {
                    if (res.status) {
                        setindustrySubGroup(res?.data?.payload)
                    }
                }
            }))
        }
    }
    // onchange input 
    const handleChangeIndustrySubGroup = (id) => {
        setSubIndustryGroupId(id)
    }


    // company edit profile
    const handleSubmit = (e) => {
        e.preventDefault()

        let params = {
            id: companyDetails?.id,
            business_ownership_type: ownershipTypeId ? ownershipTypeId : 0,
            company_status: parseInt(companyStatus) ? parseInt(companyStatus) : null,
            number_of_employees: totalNoEmployee ? totalNoEmployee : 0,
            domestic_service_areas: address ? address : "",
            global_service_areas: addresHqLocation ? addresHqLocation : "",
            industry_groups: industryGroupId ? industryGroupId : null,
            industry_sub_groups: subIndustryGroupId ? subIndustryGroupId : null,
            company_website: companyDetails?.company_website ? companyDetails?.company_website : "",
            open_to_jv_supplier_opportunities: parseInt(companyJVOpportunities) ? parseInt(companyJVOpportunities) : null

        };

        dispatch(userCompanyProfileUpdate({
            ...params, cb(res) {
                if (res.status) {
                    props.refreshCompanyDetail();
                    props.close();
                }
            }
        }))
    };

    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(getCompanyProfileInfo({
            cb(res) {
                // if (res.status) {
                //     if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                //     setCompanyJVOpportunities(res?.data?.payload?.userDetails?.user_company_information?.open_to_jv_supplier_opportunities)
                //     setCompanyStatus(res?.data?.payload?.userDetails?.user_company_information?.company_status)
                //     setCompanyDetails(res?.data?.payload?.userDetails?.user_company_information)
                //     setIndustryGroupId(res?.data?.payload?.userDetails?.user_company_information?.industry_groups)
                //     setSubIndustryGroupId(res?.data?.payload?.userDetails?.user_company_information?.industry_sub_groups)
                //     setAddresHqLocation(res?.data?.payload?.userDetails?.user_company_information?.global_service_areas)
                //     setAddress(res?.data?.payload?.userDetails?.user_company_information?.domestic_service_areas)
                //     setTotalNoEmployee(res?.data?.payload?.userDetails?.user_company_information?.number_of_employees)
                //     setOwnershipTypeId(res?.data?.payload?.userDetails?.user_company_information?.business_ownership_type)
                //     }

                // }
                // else {

                // }

                if (res.status) {
                    if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                        setCompanyJVOpportunities(res?.data?.payload?.userDetails?.user_company_information?.open_to_jv_supplier_opportunities)
                        setCompanyStatus(res?.data?.payload?.userDetails?.user_company_information?.company_status)
                        setCompanyDetails(res?.data?.payload?.userDetails?.user_company_information)
                        setIndustryGroupId(res?.data?.payload?.userDetails?.user_company_information?.industry_groups)
                        setSubIndustryGroupId(res?.data?.payload?.userDetails?.user_company_information?.industry_sub_groups)
                        setAddresHqLocation(res?.data?.payload?.userDetails?.user_company_information?.global_service_areas)
                        setAddress(res?.data?.payload?.userDetails?.user_company_information?.domestic_service_areas)
                        setTotalNoEmployee(res?.data?.payload?.userDetails?.user_company_information?.number_of_employees)
                        setOwnershipTypeId(res?.data?.payload?.userDetails?.user_company_information?.business_ownership_type)
                    }
                    else {
                        setCompanyJVOpportunities(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.open_to_jv_supplier_opportunities)
                        setCompanyStatus(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.company_status)
                        setCompanyDetails(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
                        setIndustryGroupId(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.industry_groups)
                        setSubIndustryGroupId(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.industry_sub_groups)
                        setAddresHqLocation(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.global_service_areas)
                        setAddress(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.domestic_service_areas)
                        setTotalNoEmployee(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.number_of_employees)
                        setOwnershipTypeId(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information?.business_ownership_type)
                    }

                }
                else {

                }
            }
        }))
    }


    useEffect(() => {
        companyProfileInfo()
        // SupplierDescription();
        businessOwnership();
        IndustryGroups();
        // industrySubGroups(id)
    }, []);


    useEffect(() => {
        if (industryGroupId) {
            industrySubGroups(industryGroupId)
        }
    }, [industryGroupId])

    return (
        <>
            {authSelector.userDeatil ?
                <>
                    <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                </>
                :

                <form onSubmit={(e) => handleSubmit(e)} className='signupForm row justify-content-center mt-5'>
                    <div className='row'>
                        {/* <div className='form-group col-md-12 mb-3'>
                            <label className="headingSmall_ mb-2" htmlFor="">Diverse Status</label>
                            {companyDescription &&
                                <Multiselect
                                    options={companyDescription} // Options to display in the dropdown
                                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                                    onSelect={handleChanges} // Function will trigger on select event
                                    onRemove={handleChanges} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                />
                            }
                        </div>
                        {driversSupplierDescriptionId.includes(18) ?
                            <div className='form-group col-md-12 mb-3'>
                                <input name="companyDescription" type='text' className='customFormControl' value={companyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCompanyOtherDescription(e.target.value)} />
                            </div> : ""

                        } */}
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Company Status</label>
                            <select className="cateSelectbox" value={companyStatus} onChange={(e) => setCompanyStatus(e.target.value)}>
                                <option value="" >Select Company Status</option>
                                <option value="1">Private</option>
                                <option value="2">Public</option>
                                {/* {options.map(option => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))} */}
                            </select>
                        </div>

                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Business Ownership type</label>
                            <select className="cateSelectbox" required="" value={ownershipTypeId} onChange={(e) => { businessOwnership(e.target.value) }}>
                                <option value="">Business Ownership type</option>
                                {ownershipType?.length > 0 ?
                                    <>
                                        {ownershipType?.map((data) => {
                                            return <option value={data?.id}>{data.name}</option>
                                        })}

                                    </>
                                    : ""}
                            </select>
                        </div>

                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Open to JV Opportunities</label>
                            <select className="cateSelectbox" value={companyJVOpportunities} onChange={(e) => setCompanyJVOpportunities(e.target.value)}>
                                <option value="" >Select JV Opportunities</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                                {/* {options.map(option => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))} */}
                            </select>
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Employees</label>
                            <input type="Number" value={totalNoEmployee} onChange={(e) => handleChange(e, "employees")} className='customFormControl' placeholder='' />
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Local Service Area</label>
                            <PlacesAutocomplete
                                value={address}
                                onChange={setAddress}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Search Places ...',
                                                className: ' customFormControl location-search-input form-control textArea',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions?.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>International service Area</label>
                            <PlacesAutocomplete
                                value={addresHqLocation}
                                onChange={setAddresHqLocation}
                                onSelect={handleSelectHqLocation}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Search Places ...',
                                                className: ' customFormControl location-search-input form-control textArea',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions?.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Industry</label>
                            <select className="cateSelectbox" required="" value={industryGroupId} onChange={(e) => { IndustryGroups(e.target.value) }}
                            >
                                <option value="" >Select Industry Groups</option>
                                {industryGroup?.length > 0 ?
                                    <>
                                        {industryGroup?.map((data) => {
                                            return <option value={data.id}>{data.name}</option>
                                        })}

                                    </>
                                    : ""}
                            </select>
                        </div>
                        <div className='form-group col-md-6 mb-3'>
                            <label className='postopportunity_label'>Sub Industry</label>
                            <select className="cateSelectbox" required="" value={subIndustryGroupId} onChange={(e) => { handleChangeIndustrySubGroup(e.target.value) }}
                            >
                                <option value="" >Select Sub Industry Groups</option>
                                {industrySubGroup?.length > 0 ?
                                    <>
                                        {industrySubGroup?.map((data) => {
                                            return <option value={data.id}>{data.name}</option>
                                        })}

                                    </>
                                    : ""}
                            </select>
                        </div>

                        <div className='button_box text-center  my-2'>
                            <button className='primaryBtn mx-2' type="submit">
                                Save
                            </button>
                            <button className='subsmallBtnblue_ mx-2' onClick={() => props.close()}  >Cancel</button>
                        </div>

                    </div>
                </form>
            }
        </>
    )
}

export default EditCompanyInformationDetails