import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { logout } from '../../../redux/slices/auth';
import * as Images from "../../../utilities/images";
import EditProfile from '../editProfile';
import Notifications from '../notification';
import PrivacySettings from '../privacySettings';
import Subscription from '../subscription';
import EditUserDetail from '../editUserDetail';
import Support from '../support';
import Editprofile from '../editProfile/editprofile';
import ManageUser from '../manageUser/manageUser';
import { setDiverseStatusEmpty } from '../../../redux/slices/certification';
import { useAuthSelector } from '../../../redux/selector/auth';

const Setting = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const authSelector = useAuthSelector();

    const queryParams = new URLSearchParams(window.location.search);
    const tab = location?.state?.activeTab;

    const active = queryParams.get('active')

    const [activeTab, setActiveTab] = useState(tab ? tab : (active ? active : 'privacy'));
    const [hideManageUser, setHideManageUser] = useState(false)

    const emptyDiverseCertificates = () => {
        let params = {}
        dispatch(setDiverseStatusEmpty({
            ...params, cb(res) {

            }
        }))
    }

    //user logout
    const userLogout = () => {
        dispatch(logout({
            cb(res) {
                if (res.status) {
                    navigate("/")
                    emptyDiverseCertificates()
                }
                else {
                }
            }
        }))
    }


    // handle tab chnage
    const handleTabChange = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    // const userSigninInfo =  ? authSelector?.userInfo?.user?.user_company_information : authSelector?.userInfo?.user?.user_selected_company_information?.user_company_information;


    useEffect(() => {
        if (authSelector?.userStep?.user?.user_company_information !== null) {
            setHideManageUser(false)
        }
        else if(authSelector?.userStep?.user?.user_company_information === null){
            setHideManageUser(true)
        }
    }, [])
    

    return (
        <div className='settings membershipDetails'>
            <div className='row'>
                <div className='col-lg-4 col-md-12'>
                    <div className='containerBox'>
                        <h3 className='headTxtBlueSmall mb-4'><b>Settings</b></h3>
                        <ul className='settingMenus'>
                            <li
                                onClick={() => handleTabChange("privacy")}
                                className='settingItems'>
                                <Link to='' className={`${activeTab === 'privacy' ? 'settingLinks active' : ''}settingLinks`}>
                                    <img src={Images.privacy} alt='Privacy' />
                                    <span>Settings & Privacy</span>
                                </Link>
                            </li>
                            <hr className='topBorder' />
                            <li
                                onClick={() => handleTabChange("account")}
                                className='settingItems'>
                                <Link to='' className={`${activeTab === 'account' ? 'settingLinks active' : ''}settingLinks`}>
                                    <img src={Images.accountUser} alt='Account' />
                                    <span>Account Details </span>
                                </Link>
                            </li>
                            {
                                hideManageUser === true ? " "
                                    :
                                    <>
                                        <hr className='topBorder' />
                                        <li
                                            onClick={() => handleTabChange("subscription")}
                                            className='settingItems'>
                                            <Link to='' className={`${activeTab === 'subscription' ? 'settingLinks active' : ''}settingLinks`}>
                                                <img src={Images.subscription} alt='Subscription' />
                                                <span>Manage Subscription</span>
                                            </Link>
                                        </li>
                                    </>
                            }
                            {
                                hideManageUser === true ? " "
                                    :
                                    <>
                                        <hr className='topBorder' />
                                        <li
                                            onClick={() => handleTabChange("manageUser")}
                                            className='settingItems'>
                                            <Link to='' className={`${activeTab === 'manageUser' ? 'settingLinks active' : ''}settingLinks`}>
                                                <img src={Images.subscription} alt='Subscription' />
                                                <span>Manage Users</span>
                                            </Link>
                                        </li>
                                    </>

                            }
                            <hr className='topBorder' />
                            <li
                                onClick={() => handleTabChange("support")}
                                className='settingItems'>
                                <Link to='' className={`${activeTab === 'support' ? 'settingLinks active' : ''}settingLinks`}>
                                    <img src={Images.support} alt='Support' />
                                    <span>Support</span>
                                </Link>
                            </li>
                            <hr className='topBorder' />
                            <li onClick={() => { userLogout() }}
                                className='settingItems'>
                                <Link to='' className={`${activeTab === 'logout' ? 'settingLinks active' : ''}settingLinks`}>
                                    <img src={Images.logout} alt='Logout' />
                                    <span>Log Out</span>
                                </Link>
                            </li>
                            <hr className='topBorder' />
                        </ul>
                        <div className='advData mb-5'>
                            <h4 className='headingSmall_ mb-2'>Advertising Data</h4>
                            <p className='headingTitleSmall_'>Advertising data is a dataset that is made up of one the largest range of information as far as data is concerned. In the world of business, advertising forms the basis upon which businesses are built and transformed for growth</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-8 col-md-12'>
                    {activeTab == 'privacy' ?
                        <PrivacySettings />
                        : activeTab == "notifications" ?
                            <Notifications />
                            : activeTab == "subscription" ?
                                <Subscription />
                                : activeTab == "editProfile" ?
                                    <Editprofile />
                                    : activeTab == "account" ?
                                        <Editprofile />
                                        : activeTab == "manageUser" ?
                                            <ManageUser />
                                            : activeTab == "support" ?
                                                <Support /> : ""
                    }
                </div>
            </div>
        </div>
    )
}

export default Setting