import { all, call, put, takeLatest } from 'redux-saga/effects'
import { io } from 'socket.io-client';
import { setSocket } from '../../slices/socket';
import BaseUrl from "../../../constants/baseUrl";

function* initializeSocketSaga(action) {

  try {

    let socket = io(BaseUrl.SOCKET_URL,{
        path: "/api/v1/connect",
        query: action.payload.id
    });   

    const socketSet = {...socket}
    
    console.log(socketSet,'resp-socket');

    yield put(setSocket(socket));

  } catch (e) {
    console.log(e,'error_while_socket_connection')
  }

}

function* socketSaga() {
  yield all([
    // takeLatest('socket/initialize',initializeSocketSaga )
  ])
}

export default socketSaga;