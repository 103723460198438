import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { onErrorStopLoad, setConnectionListing, setAddConnection, setUpdateRequestConnection, setpendingConnectionListing, setConnectionFollow, setConnectionUnfollow, setConnectionRequsetCancel, setConnectionRequsetUnfriend, setActiveTab } from '../../slices/connection';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';


// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* addAllConnection(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.ConnectionApiPath.ADD_CONNECTION, action.payload = action.payload);
    if (resp.status) {
      yield put(setAddConnection(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAddConnection({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* activeTab(action) {
  yield put(setActiveTab(action.payload));
}

function* updateAllConnection(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.ConnectionApiPath.UPDATE_CONNECTION}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setUpdateRequestConnection(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUpdateRequestConnection({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* pendingConnection(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.ConnectionApiPath.PENDING_CONNECTION, action.payload = action.payload);
    if (resp.status) {
      yield put(setpendingConnectionListing(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setpendingConnectionListing({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getAllConnection(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.ConnectionApiPath.GET_CONNECTION_LIST, action.payload = action.payload);
    if (resp.status) {
      yield put(setConnectionListing(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setConnectionListing({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* followConnections(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.ConnectionApiPath.FOLLOW_CONNECTION, action.payload = action.payload);
    if (resp.status) {
      yield put(setConnectionFollow(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setConnectionFollow({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* unfollowConnections(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.ConnectionApiPath.UNFOLLOW_CONNECTION}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setConnectionUnfollow(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setConnectionUnfollow({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* cancelConnectionsRequest(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.ConnectionApiPath.CANCEL_CONNECTION_REQUEST}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setConnectionRequsetCancel(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setConnectionRequsetCancel({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* unfriendConnectionsRequest(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.ConnectionApiPath.UNFRIEND_CONNECTION_REQUEST}${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setConnectionRequsetUnfriend(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setConnectionRequsetUnfriend({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* userConnection() {
  yield all([
    takeLatest('connection/addConnection', addAllConnection),
    takeLatest('connection/connectionListing', getAllConnection),
    takeLatest('connection/pendingConnectionListing', pendingConnection),
    takeLatest('connection/updateRequestConnection', updateAllConnection),
    takeLatest('connection/connectionFollow', followConnections),
    takeLatest('connection/connectionUnfollow', unfollowConnections),
    takeLatest('connection/connectionRequsetCancel', cancelConnectionsRequest),
    takeLatest('connection/connectionRequsetUnfriend', unfriendConnectionsRequest),
    takeLatest('connection/activeTab', activeTab),
  ])
}

export default userConnection;
