import React, { useState, useEffect } from "react";
import Select from 'react-select';
import * as Images from "../../../utilities/images";
import { getOpportunityViewDetail } from '../../../redux/slices/opportunity';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ApplyNow from "./applyNow";
import ComposeMessage from "../accountMessage/ComposeMessages";
import CustomModal from "../../components/common/shared/CustomModal";
import { setActiveTab } from "../../../redux/slices/connection";

const ViewProfile = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const id = location?.state?.id;
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [information, setInformation] = useState("");
  const [activeTab, setActiveTabs] = useState("active");
  const certificate_tooltip = " Say goodbye to tedious paperwork and complex requirements. GetOPP$ simplifies and streamlines the entire certification process, empowering you to focus on growing your business. With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier. Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity. "
  const maxLength = 60;
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const ViewDetails = () => {
    let params = {
      id: id,
    }
    dispatch(getOpportunityViewDetail({
      
      ...params, cb(res) {
        if (res.status) {
          setInformation(res?.data?.payload)
        }
      }
    }))
  }

  // function for user veiw profile.........
  const followCompany = (name, id) => {
    navigate(`/${name}/${id}`)
  }

  //reply now 
  const ReplyNow = () => {
    setActiveTabs("applynow")
  }
  const BackListing = () => {
    navigate('/business?selectedFilters=opportunity', { state: { activeTabSearch: 1 } });
    emptyActiveTab()
  }


  const emptyActiveTab = () => {
    let params = {}
    dispatch(setActiveTab({
      ...params, cb(res) {

      }
    }))
  }
  // for close Modal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  useEffect(() => {
    ViewDetails()
    document.title = "viewProfile";
  }, []);


  return (
    <div className='viewProfile_'>
      <div className='row mb-2'>
        <div className='col-md-6 col-lg-6 '>
          <h3 className='headTextmain_'>
            <span className='blueHead_ '>Opportunity</span> Search</h3>
        </div>
      </div>
      <div className='row'>
        {/* <div className='col-md-12 col-lg-4'>
          <div className='containerBox containerCustom'>
            <h3 className="headingTitle_ mb-3">Business Name</h3>
            <div className="business_search">
              <img className="searchIcon_" src={Images.search_} alt="Search" />
              <input type="text" className="custoInput" placeholder="Search" aria-label="discount code" aria-describedby="basic-addon2" />
            </div>
            <div className='businessType_ mt-3 mb-2 pb-3'>
              <h5 className='headingSmall_ mb-2'>Types of Business</h5>
              <div className='businessDetail_ mt-2'>
                <div className="checkmark_">
                  <input type="checkbox" id="business1" name="" className="businessType_" />
                  <label for="business1" className="labelTxt_">
                    <span className='innerSubtextSmall'>Automative</span>
                  </label>
                  <span className='value_'>[32]</span>
                </div>
                <div className="checkmark_">
                  <input type="checkbox" id="business2" name="" className="businessType_" checked />
                  <label for="business2" className="labelTxt_">
                    <span className='innerSubtextSmall'>Marketing & Design</span>
                  </label>
                  <span className='value_'>[64]</span>
                </div>
                <div className="checkmark_">
                  <input type="checkbox" id="business3" name="" className="businessType_" />
                  <label for="business3" className="labelTxt_">
                    <span className='innerSubtextSmall'>Public Relations</span>
                  </label>
                  <span className='value_'>[32]</span>
                </div>
                <div className="checkmark_">
                  <input type="checkbox" id="business4" name="" className="businessType_" />
                  <label for="business4" className="labelTxt_">
                    <span className='innerSubtextSmall'>Security</span>
                  </label>
                  <span className='value_'>[13]</span>
                </div>
                <div className="checkmark_">
                  <input type="checkbox" id="business5" name="" className="businessType_" />
                  <label for="business5" className="labelTxt_">
                    <span className='innerSubtextSmall'>Technology</span>
                  </label>
                  <span className='value_'>[8]</span>
                </div>
              </div>
            </div>
            <div className='location_ mt-3 mb-2 '>
              <h5 className='headingSmall_ mb-2'>Location</h5>
              <select className="cateSelectbox" required="">
                <option value="">City / State</option>
                <option>Category1</option>
                <option>Category2</option>
              </select>
            </div>
            <div className='location_ mt-3 mb-2'>
              <h5 className='headingSmall_ mb-2'>Trade Memberships</h5>
              <select className="cateSelectbox" required="">
                <option value="">Disability Inclusion Network</option>
                <option>Category1</option>
                <option>Category2</option>
              </select>
            </div>
            <div className="tagsType mt-3 mb-3 col-md-12">
              <label className="headingSmall_ mb-2" htmlFor="">Diversity Status</label>
              <Select
                isMulti
                name="tags"
                options={options}
                value={selectedTags}
                onChange={setSelectedTags}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className='businessBtn mt-4'>
              <button className="secondaryBtnGrey me-2">Reset</button>
              <button className="primaryBtnSmall">Update Search</button>
            </div>
          </div>
          <div className='cardContainer mt-4'>
            <div className='cardImg'>
              <img src={Images.certifyUser} className='img-fluid' alt='CardImg' />
            </div>
            <div className='cardBodyArea'>
              <h5>Get Certified</h5>
              <div className="infoiconText">
                <span className="showinfoText">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
              </div>
              <p className='my-4'>GetOPP$ Diversity Certifications for inclusive business futures. </p>
              <div className="infoiconText m-0">
                <Link to="/certificate">
                  <button className='primaryBtn'>Learn More</button>
                </Link>
                <span className="showinfoText">
               
                  {certificate_tooltip.substring(0, maxLength)}...
                </span>
              </div>
            </div>
          </div>
        </div> */}
 
        {activeTab == "active" ?
          <div className='col-md-12 col-lg-12 mb-4'>
            <div className='containerBox'>
              <div className='businessDesincontent_ '>
                <div className='row'>
                  <div className='col-lg-12 '>
                    <div className='col-md-12 text-end'>
                      <button className='smallgreyBtn_' onClick={() => { BackListing() }}>Back to Listing</button>
                    </div>
                    <div className='businessServer_'>
                      <div className='designcontentTop_'>
                        <div className='designLeft_'>
                          <img className='todoList_' src={Images.todoList} />
                          <h4 className='boldmainHeading_'>{information?.user_details?.user_company_information?.company_name}</h4>
                        </div>
                        <div className='designbtnRight'>
                          <p className='postHours_'><strong>POSTED </strong>{moment(information?.created_at).fromNow()}</p>
                        </div>
                      </div>
                      <h5 className='textInnerOuter mt-2'>
                        <img className="locationImg_" src={Images.location_} alt="locationImg" />
                        {information?.hq_location}</h5>
                    </div>
                  </div>
                </div>
                <div className="viewProfileDetails mt-3">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Response Deadline</h4>
                      <p className="discription_ mb-0">{moment(information?.response_deadline_date).fromNow()}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Award Date</h4>
                      <p className="discription_ mb-0">{moment(information?.award_date).fromNow()}</p>
                    </div>
                    <div className="col-lg-12  mb-3">
                      <h5 className='headingSmall_'>Contract Details</h5>
                      <p className='discription_ mt-2'>{information?.description}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Currency</h4>
                      <p className="discription_ mb-0">{information?.currency}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Contract Amount</h4>
                      <p className="discription_ mb-0">{information?.opportunity_amount}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Contract Length</h4>
                      <p className="discription_ mb-0">{information?.contract_length}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Industry Group</h4>
                      <p className="discription_ mb-0">{information?.industries_group_details?.name}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Sub Industry Group</h4>
                      <p className="discription_ mb-0">{information?.industries_sub_group_details?.name}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Specific Service Location</h4>
                      <p className="discription_ mb-0">{information?.specific_service_locations_required == true ? "Yes" : "No"}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Service Location</h4>
                      <p className="discription_ mb-0">{information?.hq_location}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Diverse Status</h4>
                      {information?.post_opportunity_diversity_details?.length > 0 ?
                        <>
                          {information?.post_opportunity_diversity_details?.map((data, index) => {
                            return (
                              <>
                                <p className='discription_ mb-0'>{data?.diversity_status_details?.name}</p>
                              </>
                            )
                          })
                          }
                        </> : <p>No Diverse Status found</p>
                      }
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Existing Certifications</h4>
                      {information?.opportunity_existing_certificate_details?.length > 0 ?
                        <>
                          {information?.opportunity_existing_certificate_details?.map((data, index) => {
                            return (
                              <>
                                <p className='discription_ mb-0'>{data?.existing_certificates_details?.name}</p>
                              </>
                            )
                          })
                          }
                        </> : <p>No Existing Certifications found</p>
                      }
                    </div>
                    <div className="col-md-4 mb-3">
                      <h4 className="memberblueHead_ mb-1">Service Licenses Requires?</h4>
                      <p className="discription_ mb-0">{information?.service_licenses_required == true ? "Yes" : "No"}</p>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <h4 className="memberblueHead_ mb-3">Opportunity Media</h4>
                          <img src={information?.opportunity_media} alt="media" className="img-fluid viewProfileImg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='contractDetails_ mt-5'>
                <div className='designbtnRight mt-3'>
                  {information?.is_applied == null ?
                    <button className='subsmallBtnblue_ me-2' onClick={() => ReplyNow()
                    } >Reply Now</button>
                    : ""
                  }
                  {
                    information?.is_applied == null ?
                      <button onClick={() => {
                        // setModalDetail({ show: true, flag: "ComposeMessage" });
                        // setKey(Math.random());
                      }} className='secondaryBtnGrey' disabled >Send Message</button>
                      :
                      <button onClick={() => {
                        setModalDetail({ show: true, flag: "ComposeMessage" });
                        setKey(Math.random());
                      }} className='subsmallBtngreen_'>Send Message</button>
                  }
                  {/* <button onClick={() => {
                    setModalDetail({ show: true, flag: "ComposeMessage" });
                    setKey(Math.random());
                  }} className='subsmallBtngreen_'>Send Message</button> */}
                </div>
                </div>
                <hr className="bottomBorder_"></hr>
                <div className='businessDesincontent_  aboutCopany_ mt-3'>
                  <div className='row mb-2'>
                    <div className='col-md-6 col-lg-6 col-6'>
                      <h3 className='headingSmall_'>
                        About the company</h3>
                    </div>
                    <div className='col-md-6 col-lg-6 col-6 text-end'>
                      <button className='subsmallBtnblue_' onClick={() => { followCompany(information?.user_details?.user_company_information?.company_name, information?.user_details?.user_company_information?.id) }}>Follow Company</button>
                    </div>
                  </div>
                  <div className='row align-items-center'>
                    <div className='col-lg-2 col-md-2'>
                      <div className='designImg_'>
                        <img className='imgproduct_' src={information?.user_details?.user_company_information?.company_logo ? information?.user_details?.user_company_information?.company_logo : Images.atlanticsmall} />
                      </div>
                    </div>
                    <div className='col-lg-10 col-md-10'>
                      <div className='designContent_'>
                        <div className='designcontentTop_'>
                          <div className='designLeft_'>
                            <h4 className='headingTitleSmall_'>{information?.user_details?.user_company_information?.company_name}</h4>
                          </div>
                        </div>
                        <p className='innerSubtextSmall mt-2'>{information?.user_details?.user_company_information?.company_service_description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <>
              <ApplyNow id={id}
                setActiveTabs={() => setActiveTabs("active")}
              />
            </>
        }

            <CustomModal
              key={key}
              show={modalDetail.show}
              backdrop="static"
              showCloseBtn={false}
              isRightSideModal={false}
              mediumWidth={false}
              className={modalDetail.flag === "Addlabels" ? "commonWidth customContent" : ""}
              ids={modalDetail.flag === "Addlabels" ? "Addlabels" : "ComposeMessage"}
              child={modalDetail.flag === "ComposeMessage" ? (
                <ComposeMessage
                  opportunityCompanyName={information?.user_details?.user_company_information?.company_name}
                  opportunityCompanyId={information?.user_details?.user_company_information?.user_id}
                  close={() => handleOnCloseModal()}
                /> 
              )
                : ""}
              header={
                <div className='modalHeader_'>
                  <div className="common_">
                    <h4 className="headingSmall_">Saved Searches</h4>
                  </div>
                </div>
              }
              onCloseModal={() => handleOnCloseModal()}
            />
          </div>
    </div>
      )
}

      export default ViewProfile