import React, { useCallback, useEffect, useState } from 'react';
import * as Images from "../../../utilities/images";
import { toast } from 'react-toastify';
import moment from 'moment';
import { useAuthSelector } from "../../../redux/selector/auth";
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { stepThreeCompanyLogoUplaod } from '../../../redux/slices/auth';
import { applyOpportunity, getOpportunityViewDetail } from '../../../redux/slices/opportunity';
import { useNavigate } from 'react-router-dom';

const ApplyNow = (props) => {
  const id = props.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authSelector = useAuthSelector();
  const userLoginInfo = authSelector?.userStep?.user;
  const userSignupInfo = authSelector?.userStep;
  const userSelectedSignupCompanyInfo = authSelector?.userStep?.user_selected_company_information?.user_company_information?.company_name;
  const userSelectedLoginCompanyInfo = authSelector?.userStep?.user?.user_selected_company_information?.user_company_information?.company_name;

  const [photo, setPhotoUpload] = useState("");
  const [photoInfo, setPhotoInfo] = useState();
  const [introduction, setIntroduction] = useState("");
  const [description, setDescription] = useState();
  const [information, setInformation] = useState("");


  //remove file
  const removeFile = file => () => {
    const newFiles = [photo]
    newFiles.splice(newFiles.indexOf(file), 1)
    setPhotoUpload(newFiles)
  }

  //onDrop
  const onDrop = useCallback(acceptedFiles => {
    const imageFile = acceptedFiles[0];
    // if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
    //   toast.error("Please select a valid image.");
    //   return false;
    // }
    let params = {
      photo: imageFile,
    }
    dispatch(stepThreeCompanyLogoUplaod({
      ...params, cb(res) {
        if (res.status) {
          setPhotoUpload(res?.data?.payload?.url);
          setPhotoInfo(res?.data?.payload)
        }
        else {
        }
      }
    }))

  }, [])

  const { getRootProps, getInputProps } =

    useDropzone({
      onDrop,
      accept: {
        'image/jpeg': [],
        'image/jpg': [],
        'image/png': [],
        'application/pdf': [],
        'application/msword': [], // .doc files
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] //docx file
      }
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    const regex = /[a-z|A-Z]+(?: [a-z|A-Z]+)*$/
    if (!introduction) {
      toast.error("Please enter Introduction");
      return;
    }
    else if (introduction.length <= 2) {
      toast.error("Introduction Served should be maximum 3 character")
      return;
    }
    // else if (!introduction.match(regex)) {
    //   toast.error("Please enter only character and single space in Introduction");
    //   return;
    // }
    else if (!description) {
      toast.error("Please enter Description");
      return;
    }
    else if (description.length <= 2) {
      toast.error("Description should be maximum 3 character")
      return;
    }
    // else if (!description.match(regex)) {
    //   toast.error("Please enter only character and single space in Description");
    //   return;
    // }
    // else if (!photo) {
    //   toast.error("Please select Image");
    //   return;
    // }


    let params = {
      company_id: 1,
      opportunity_id: id,
      name: userLoginInfo?.user_profile?.first_name ? `${userLoginInfo?.user_profile?.first_name} ${userLoginInfo?.user_profile?.last_name}` : `${userSignupInfo?.user_profile?.first_name} ${userSignupInfo?.user_profile?.last_name}`,
      location: "mohali",
      phone_no: "9857431",
      email_address: userLoginInfo?.email ? userLoginInfo?.email : userSignupInfo?.email,
      introduction: introduction,
      description: description,
      opportunity_media: photo
    }
    dispatch(applyOpportunity({
      ...params, cb(res) {
        if (res.status) {
          navigate('/business')

        }
        else {
        }
      }
    }))
  }

  const ViewDetails = () => {
    let params = {
      id: id,
    }
    dispatch(getOpportunityViewDetail({
      ...params, cb(res) {
        if (res.status) {
          setInformation(res?.data?.payload)
        }
      }
    }))
  }
  // function for user veiw profile.........
  const followCompany = (name, id) => {
    navigate(`/${name}/${id}`)
  }
  const BackListing = () => {
    props.setActiveTab("active")

  }

  useEffect(() => {
    ViewDetails()
  }, []);

  return (
    <>
      <div className='col-md-12 col-lg-12 mb-4'>
        <div className='containerBox'>
          <div className='col-md-12 text-end'>
            <button className='smallgreyBtn_' onClick={() => { BackListing() }}>Back to Listing</button>
          </div>
          <h4 className='boldmainHeading_'>Proposal Submission</h4>
          <div className=''>
            <p className='company_heading my-4'> Company :
              <span className='company_type'>{userLoginInfo?.user_company_information?.company_name || userSignupInfo.user_company_information?.company_name || userSelectedSignupCompanyInfo || userSelectedLoginCompanyInfo}</span>
              <span className='company_details'>(Edit Profile)</span>
            </p>
            <div className='row'>
              <div className='col-lg-6'>
                <p className='company_heading'> Name :
                  <span className='company_details'>{userLoginInfo?.user_profile?.first_name ? userLoginInfo?.user_profile?.first_name : userSignupInfo?.user_profile?.first_name} {userLoginInfo?.user_profile?.last_name ? userLoginInfo?.user_profile?.last_name : userSignupInfo?.user_profile?.last_name}</span>
                </p>
              </div>
              <div className='col-lg-6'>
                <p className='company_heading'> Location :
                  <span className='company_details'>{userLoginInfo?.user_company_information?.domestic_service_areas}</span>
                </p>
              </div>
              <div className='col-lg-6'>
                <p className='company_heading'>  Phone Number :
                  <span className='company_details'>805-343-9691 </span>
                </p>
              </div>
              <div className='col-lg-6'>
                <p className='company_heading'> Email Address :
                  <span className='company_details'>{userLoginInfo?.email ? userLoginInfo?.email : userSignupInfo?.email} </span>
                </p>
              </div>
            </div>
          </div>
          <div className='form-group col-md-12 mb-3'>
            <label className='postopportunity_label'>Introduction</label>

            <textarea name="email" type='text' rows='4' className='customFormControl' placeholder='Type here...' value={introduction} onChange={e => setIntroduction(e.target.value)} />
          </div>
          <div className='form-group col-md-12 mb-3'>
            <label className='postopportunity_label'>Description</label>

            <textarea name="email" type='text' rows='7' className='customFormControl' placeholder='Type here...' value={description} onChange={e => setDescription(e.target.value)} />
          </div>
          <div className='form-group col-md-12 mb-3'>
            {photo?.length > 0 ?
              "" :
              <div className="uploadImgebox">

                <div {...getRootProps({ className: "dropzone" })}>
                  <input type="file" accept="image/png, image/jpeg" {...getInputProps()} />
                  <p className='uploadbtnn'>Upload</p>
                  <p className='uploadText mt-2' >JPEG, JPG, PNG, PDF, DOC or DOCX file upload</p>
                </div>
              </div>
            }
          </div>
          {photo?.length > 0 ?
            <aside >
              <h4 className='headingSmall_'> Uploaded Files</h4>
              <ul className='uploadProfile_'>

                {photoInfo.format == "application/pdf" ?
                  <img className="w3-round" src={Images.PDFImage} />
                  : photoInfo.format == "application/msword" || photoInfo.format == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                    <img className="w3-round" src={Images.DocImage} />
                    : <img className="w3-round" src={photo} />
                }
                {/* <img className="w3-round" src={photo} ></img> */}
                <div className='profileDetail_'>
                  <h5 className='profileboldHead'>{photoInfo.name}</h5>

                  <p className='profileboldgraph'>
                    {(photoInfo?.size / (1024 * 1024)).toFixed(2)} MB
                  </p>
                </div>
                <i className="fa fa-trash text-red" onClick={removeFile(photo)}></i>
              </ul>
            </aside>
            : ""
          }

          <div className='submitProposal text-end submit-proposal'>
            <button className="primaryBtnSmall" onClick={(e) => handleSubmit(e)}>Submit Proposal</button>
          </div>
          <div className='serverMigration my-3'>
            <figure className='server-list' >
              <img className='imgserver_' src={Images.todoList} />
              <h4 className='boldmainHeading_'>{information?.posting_title}</h4>
            </figure>
            <figure className='server-list'>
              <img className='imgserver_' src={Images.location_} />
              <span className='company_details'>{information?.hq_location}</span>
            </figure>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <p className='company_heading'> Type of Business :
                <span className='company_details'>{information?.opportunity_business_ownership_details?.name}</span>
              </p>
            </div>
            <div className='col-lg-6'>
              <p className='company_heading'> Response Deadline :
                <span className='company_details'>{moment(information?.response_deadline_date).fromNow()}</span>
              </p>
            </div>
          </div>
          <div className='server'>
            <h3 className=' headingSmall_ mb-3'>Contract Details</h3>
            <p className='innerSubtextSmall mb-3'> {information?.description}</p>
          </div>
          <hr className="bottomBorder_"></hr>
          <div className='businessDesincontent_  aboutCopany_ mt-3'>
            <div className='row mb-2'>
              <div className='col-md-6 col-lg-6 col-6'>
                <h3 className='headingSmall_'>
                  About the company</h3>
              </div>
              <div className='col-md-6 col-lg-6 col-6 text-end'>
                <button className='subsmallBtnblue_' onClick={() => { followCompany(information?.user_details?.user_company_information?.company_name, information?.user_details?.user_company_information?.id) }} >Follow Company</button>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-lg-2 col-md-2'>
                <div className='designImg_'>
                  <img className='imgproduct_' src={information?.user_details?.user_company_information?.company_logo ? information?.user_details?.user_company_information?.company_logo : Images.atlanticsmall} />
                </div>
              </div>
              <div className='col-lg-10 col-md-10'>
                <div className='designContent_'>
                  <div className='designcontentTop_'>
                    <div className='designLeft_'>
                      <h4 className='headingTitleSmall_'>{information?.user_details?.user_company_information?.company_name}</h4>
                    </div>
                  </div>
                  <p className='innerSubtextSmall mt-2'>{information?.user_details?.user_company_information?.company_service_description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ApplyNow