import React, { useCallback, useEffect, useState } from 'react'
import { useAuthSelector } from "../../../redux/selector/auth";
import * as Images from "../../../utilities/images";
const CompanyLogo = () => {
    const authSelector = useAuthSelector();
    const userStepInfo = authSelector?.userStep;
    
    return (
   
        <img src={userStepInfo?.user?.user_company_information?.company_logo ??  Images.companyprofile} className='img-fluid motionModalImg' alt='motionImg' />

    )
}

export default CompanyLogo;