import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Images from "../../../utilities/images";
import CustomModal from '../../components/common/shared/CustomModal';
import SuggestionBox from './suggestionBox';
import { useAuthSelector } from '../../../redux/selector/auth';

const Resource = () => {
    const authSelector = useAuthSelector();
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };


    // go to particular section
    const SectionToScroll = (flag) => {
        var section = document.getElementById(flag);
        if (section.id == "Knowledgeexchange") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Mainstage") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Paneldiscussion") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        else if (section.id == "Virtualtrade") {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Resources";
    }, []);
    return (
        <>
            <section className='commonContentBox bussinessResource'>
                {/* Start HomePage Section */}
                <div className='homeBanner'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-7'>
                                <div className='aboutContainer'>
                                    <div className='mb-3 mb-lg-4'>
                                        <h3 className='bannerHead mb-5'>
                                            Business <b>Resources</b>
                                        </h3>
                                        <p className='bannerTxt'>
                                            Building, sustaining and growing a business is a never-ending journey that involves continuous infusions of knowledge and wisdom.

                                            {/* Building, sustaining and growing a business is a never-ending journey that involves continuous infusions of knowledge and wisdom. the most successful companies are those run by people who are committed to being lifelong learners. */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <video controls className='w-100 videoBox'>
                                    <source src=" https://api.getopps.itechnolabs.tech:4000/uploads/videos/business_resources.mp4" type="video/mp4" className='soundbiteImg' />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End HomePage Section */}
                {/* Start AboutMenus Section */}
                {/* <section className='aboutMenus resourceMenu p-0'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='d-flex justify-content-space-between '>
                                    <ul className='aboutMenusContainer'>
                                        <li className='aboutItem'>
                                            <Link className='aboutLinks'>Soundbytes</Link>
                                        </li>
                                        <li className='aboutItem'>
                                            <Link className='aboutLinks'>Spotlight</Link>
                                        </li>
                                        <li className='aboutItem'>
                                            <Link className='aboutLinks'>Sunday Funday</Link>
                                        </li>
                                        <div className="dropdown">
                                            <li className='aboutItem dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <Link className='aboutLinks'>Browse Resources</Link>
                                            </li>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Knowledgeexchange")} >Knowledge Exchange</button></li>
                                                <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Mainstage")} >Mainstage</button></li>
                                                <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Paneldiscussion")} >Panel Discussion</button></li>
                                                <li className="dropdownBox"><button className="dropdown-item" onClick={() => SectionToScroll("Virtualtrade")} >Virtual Trade</button></li>
                                           
                                            </ul>
                                        </div>
                                    </ul>
                                    <div className='suggestionBox m-0'>
                                        <button className='primaryBtn' onClick={() => {
                                            setModalDetail({ show: true, flag: "suggestionbox", });
                                            setKey(Math.random());
                                        }} >Suggestion Box
                                            <img src={Images.Chat} alt="Icon" className="img-fluid suggestionIcon" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* Start AboutMenus Section */}
                {/* Start AboutFounder Section */}
                {/* <section className='aboutFounder sucessCompny_'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-6 order-last'>
                                <div className='liveStreame'>
                                    <img className='streamImg' src={Images.livestreamImg} />
                                    <img className='streamImgchat' src={Images.streamchat} />
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-6'>
                                <div className='mb-4'>
                                    <h5 className='anotherHeading'>
                                        The <strong>most successful companies</strong> are those run by people who are committed to being <strong>lifelong learners.</strong>
                                       
                                    </h5>
                                </div>
                                <p className='bannerTxt mb-4'>
                                    GetOpp$ supports the evolutionary process of a business and of learning by offering educational resources, best practices and case studies that range from video learning to articles to livestreams.
                                </p>
                                <p className='bannerTxt mb-5'>
                                    Topics are offered in a wide range of expertise: how to enhance contracting opportunities, how to secure funding, selecting the right corporate structure, navigating company certification processes, and more.
                                </p>

                                <p className='bannerTxt Boldtxt'><strong>GetOpp$ is a Bridge for learning and for opportunity!</strong></p>

                            </div>

                        </div>
                    </div>
                </section> */}
                {/* End AboutFounder Section */}
                {/* Start AboutFounder Section */}
                {/* <section className='aboutFounder relevenceMain_'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-6 '>
                                <div className='liveStreame'>
                                    <img className='streamImg' src={Images.relavenceImg} />
                                    <img className='streamImgchat' src={Images.streamchat} />
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-6 '>
                                <div className='ralevanceRight_'>
                                    <div className='mb-3'>
                                        <h5 className='anotherHeading'>Why Supplier <strong>Diversity Matters</strong>

                                        </h5>
                                    </div>
                                    <p className='bannerTxt mb-4'>
                                        One of the goals of GetOpp$ is to put diverse businesses on global procurement radar screens. Access to international B2B markets enhances their ability to sell their goods and services.
                                    </p>
                                    <p className='bannerTxt'>
                                        Choosing Diverse Suppliers contributes to both Buyers and Suppliers success because:
                                    </p>
                                    <ul className='diversityList_ mb-5'>
                                        <li className='bannerTxt'>
                                            Small and Minority-Owned businesses are significant drivers of economic growth
                                            and job creation
                                          
                                        </li>
                                        <li className='bannerTxt'>
                                  
                                            Diverse Suppliers often serve niche markets that may be untapped by traditional
                                            suppliers
                                        </li>
                                        <li className='bannerTxt'>
                                      
                                            Diverse Suppliers may operate in different locations or have alternative sourcing strategies reducing the risks of supply chain disruptions
                                        </li>
                                        <li className='bannerTxt'>
                                            Choosing Diverse Suppliers supports the growth of underrepresented businesses

                                        </li>
                                        <li className='bannerTxt'>
                                            Choosing Diverse Suppliers demonstrates a commitment to social responsibility
                                            and inclusion
                                        </li>
                                        <li className='bannerTxt'>
                                            Choosing Diverse Suppliers increases brand loyalty and positive public
                                            perception
                                        </li>
                                    </ul>

                                    <p className='bannerTxt'><strong className='fontNineteen'>That is the GetOpp$ Win-Win!!</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* End AboutFounder Section */}
                {/* Start Premium SoundBites Section  */}
                {/* <section className='premiumSoundbite_'>
                    <div className='container'>
                        <div className='row'>
                            <div className='premiumbite_'>
                                <div className='row'>
                                    <div className='col-mg-5 col-lg-6 noPadding order-last'>
                                        <div className='bitesLeftImg'>
                                            <img className='biteImg' src={Images.diversityCertificate} />
                                        </div>
                                    </div>
                                    <div className='col-md-12 col-lg-6'>
                                        <div className='bitesRight_'>

                                            <div className='workDealing mt-5'>
                                                <h4 className='headingBluemain_ mb-4'><strong>GetOPP$ Diversity Certifications</strong> <br />
                                                    for inclusive business futures
                                                </h4>
                                                <p className='termspara_'>Say goodbye to tedious paperwork and complex requirements. GetOPP$ simplifies and streamlines the entire certification process, empowering you to focus on growing your business. </p>
                                                <p className='termspara_'>
                                                    With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier.
                                                </p>
                                                <p className='termspara_'>
                                                    Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity.
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* End Premium SoundBites Section  */}
                {/* Start SoundBites Section  */}
                {/* <section className='mainSoundbites_'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Soundbytes</b></h3>
                                    <p>Soundbytes are short sessions on a variety of topics curated by the GetOpp$ team that subscribers can watch/listen to on demand.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                 
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/CertificationProcess1.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>GO$ Certification Process</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to assist you in making an informed decision as to whether you should venture into the world of Certifications.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                             
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/Marilyn.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>GO$ Marketing </b></h6>
                                        <p className='listHead_'>Check out this SoundByte for insights and guidance on how to maximize marketing results on any budget.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                             
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/Walter.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>GO$ Avoid Being Vicitimized By Phishing Scam</b></h6>
                                        <p className='listHead_'>Check out this SoundByte to learn some of the things that you can do to avoid becoming the next victim of a Phishing Scam. </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                               
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/CertificationProcess4.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>GO$ How NOT to go bankrupt</b></h6>
                                        <p className='listHead_'>Check out this SoundByte for tips to assist in guiding you through the land mines of running a successful and profitable business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* End SoundBites Section  */}
                {/* Start Knowledge Section  */}
                {/* <section className='mainSoundbites_'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Sunday Funday</b></h3>
                                    <p>Get to know others on the GetOpp$ platform in a fun, virtual, interactive monthly game session where companies compete against each other for a prize sponsored by GetOpp$.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                              
                                    <video controls>
                                        <source src="https://api.getopps.itechnolabs.tech:4000/uploads/videos/sunfun1.mp4" type="video/mp4" className='soundbiteImg' />
                                    </video>
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Sunday Funday</b></h6>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg sudayFunday' src={Images.sudayFunday1} />
                                  
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Sunday Funday</b></h6>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg sudayFunday' src={Images.sudayFunday2} />
                                  
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Sunday Funday</b></h6>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg sudayFunday' src={Images.sudayFunday3} />
                                  
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Sunday Funday</b></h6>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* End Knowledge Section  */}


                {/* <section className='mainSoundbites_ mainstagebox_ '>
                    <div id="Knowledgeexchange" className='container '>
                        <div className='row '>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Knowledge Exchange</b></h3>
                                    <p>Knowledge Exchange are 45-minute sessions on a variety of topics curated by the GetOpp$ team that subscribers can watch/listen to on demand
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4 sectionDisable'>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the web</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mainstagebtn'>
                        <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
                    </div>
                </section> */}
                {/* Start Mainstage Learning Section  */}
                {/* <section className='mainSoundbites_ mainstagebox_ '>
                    <div id="Mainstage" className='container '>
                        <div className='row '>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Mainstage Learning</b></h3>
                                    <p>Attend 60 minute virtual learning sessions hosted by Platinum members on a topic of their choosing that will be both live and pre-recorded, with subsequent on-demand viewing.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4 sectionDisable'>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the web</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mainstagebtn'>
                        <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
                    </div>
                </section> */}
                {/* End Mainstage Learning Section  */}
                {/* <section className='mainSoundbites_ mainstagebox_ '>
                    <div id="Paneldiscussion" className='container '>
                        <div className='row '>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Panel Discussions
                                    </b></h3>
                                    <p>Increase your knowledge via panel discussions of experts on a variety of topics that can help companies enhance their business performance and their chances of success in the procurement process.


                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4 sectionDisable'>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the web</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mainstagebtn'>
                        <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
                    </div>
                </section> */}
                {/* <section className='mainSoundbites_ mainstagebox_ '>
                    <div id='Virtualtrade' className='container '>
                        <div className='row '>
                            <div className='col-lg-10'>
                                <div className='topheadingBites'>
                                    <h3 className='otherHeading_ '><b>Virtual Trade
                                    </b></h3>
                                    <p>Save precious time and money by foregoing in-person conferences and attending virtual trade fairs on the GetOpp$ platform that can help “match-make”  Buyers and Suppliers.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4 sectionDisable'>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbiteImg} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Recover lost emails</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite2} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Navigating the web</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite3} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Creating a safe work area</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                                <div className='bitesCrad'>
                                    <img className='soundbiteImg' src={Images.soundbite4} />
                                    <div className='soundbiteContent'>
                                        <h6 className='headingSmall_mb-2'> <b>Team building exercises</b></h6>
                                        <p className='listHead_'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mainstagebtn'>
                        <button className='primaryBtn w-100 mt-2'>Premium Membership Required</button>
                    </div>
                </section> */}


            </section>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "suggestionbox" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "suggestionbox" ? "suggestionbox" : "ComposeMessage"}
                child={
                    modalDetail.flag === "suggestionbox" ? (
                        <SuggestionBox
                            close={() => handleOnCloseModal()}
                        />
                    ) :
                        ""
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "suggestionbox" ?
                                <>
                                    <h2 className="addLabels_heading">
                                        Have a Suggestion?
                                    </h2>
                                    <p>We are always looking for input please provide below</p>
                                </>
                                : ""
                            }
                        </div>
                    </div>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default Resource