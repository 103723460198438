import React, { useState, useEffect, useRef } from "react";
import { useConnectionSelector } from "../../../redux/selector/connection";
import { Link, useNavigate } from 'react-router-dom';
import * as Images from "../../../utilities/images";
import { updateRequestConnection, connectionListing, pendingConnectionListing } from "../../../redux/slices/connection";
import { useDispatch } from 'react-redux';
import { useAuthSelector } from "../../../redux/selector/auth";
import moment from 'moment';
import { getCompanyProfileInfo, logout } from "../../../redux/slices/auth";
import CustomModal from "../../components/common/shared/CustomModal";
import ComposeMessage from "../accountMessage/ComposeMessages";
import EndorsementsText from "../dashboard/modals/endorsementsText";
const AllConnections = () => {
  const certificate_tooltip = " Say goodbye to tedious paperwork and complex requirements. GetOPP$ simplifies and streamlines the entire certification process, empowering you to focus on growing your business. With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier. Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity. "
  const endorsements_tooltp = "   Make your mark on the vibrant GetOpp$ buyer and supplier social networking platform by endorsing fellow members and igniting a powerful network of support. Share your positive experiences, highlight exceptional skills, and build credibility within the community. Endorsements strengthen professional relationships, boost visibility, and open doors to exciting collaboration opportunities. Let your voice be heard, cultivate a thriving network, and be part of a community that values recognition and growth. "
  const maxLength = 60;
  const navigate = useNavigate();
  const authSelector = useAuthSelector()
  const connectionSelector = useConnectionSelector();
  const useridlogin = authSelector?.userInfo?.user?.id;
  const dispatch = useDispatch();
  const [getConnection, setgetConnection] = useState([])
  const [getTotalConnection, setGetTotalConnection] = useState([])
  const [pendingConnection, setpendingConnection] = useState([])
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [totalpendingConnection, settotalPendingConnection] = useState([])
  const [userDeatil, setUserDeatil] = useState();
  const [key, setKey] = useState(Math.random());
  const [userData, setUserData] = useState()
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const userSigninInfo = authSelector?.userInfo?.user?.user_company_information !== null ? authSelector?.userInfo?.user?.user_company_information : authSelector?.userInfo?.user?.user_selected_company_information?.user_company_information;
  const userStepInfo = authSelector?.userStep?.user_company_information;


  let ComapnyLogo;
  if (userDeatil?.company_logo) {
    ComapnyLogo = userDeatil?.company_logo;
  }
  else {
    ComapnyLogo = Images.companyprofile;
  }


  const handleEndorsements = () => {
    setModalDetail({ show: true, flag: "Endorsements" });
    setKey(Math.random());
  }
  //for COMPANY PROFILE INFORMATION.......
  const companyProfileInfo = () => {
    dispatch(getCompanyProfileInfo({
      cb(res) {
        if (res?.data?.payload?.userDetails?.user_company_information !== null) {
          setUserDeatil(res?.data?.payload?.userDetails?.user_company_information)
          setUserData(res?.data?.payload?.userDetails)
        }
        else {
          setUserData(res?.data?.payload?.userDetails)
          setUserDeatil(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
        }
      }
    }))
  }


  // for get all connection list ............
  const getConnectionList = () => {
    dispatch(connectionListing({
      cb(res) {
        if (res.status) {
          setgetConnection(res?.data?.payload?.connections)
          setGetTotalConnection(res?.data?.payload?.total)
        }
      }
    }))
  }

  // for all pending connection list .............
  const pendingConnectionList = () => {
    dispatch(pendingConnectionListing({
      cb(res) {
        if (res.status) {
          setpendingConnection(res?.data?.payload?.connections)
          settotalPendingConnection(res?.data?.payload?.total)
        }
        else {
        }

      }
    }))
  }

  // for accept @ reject connection ............
  const AcceptRejectConnection = (id, flag) => {
    updateRequest(id, flag);
  }


  //update request
  const updateRequest = (id, flag) => {
    let params = {
      id: id,
      status: flag
    }
    dispatch(updateRequestConnection({
      ...params, cb(res) {
        if (res.status) {
          getConnectionList();
          pendingConnectionList();
        }
        else {
        }
      }
    }))
  }

  // handle  page change 
  const handlePageRedirect = (val) => {
    if (val) {
      navigate(`/${val?.company_name}/${val?.id}`)
    }
  }

  // handle page redirect
  const handlePageRedirectPage = (val) => {
    if (val.receiver_id === useridlogin) {
      navigate(`/${val?.sender_details?.user_company_information?.company_name}/${val?.sender_details?.user_company_information?.id}`)
    }
    else if (val.sender_id === useridlogin) {
      navigate(`/${val?.receiver_details?.user_company_information?.company_name}/${val?.receiver_details?.user_company_information?.id}`)
    }
  }

  // handle change page
  const handleChangePage = (flag) => {
    if (flag == "edit") {
      navigate('/setting', { state: { activeTab: "editProfile" } })
    }
    else if (flag == "plan") {
      navigate('/setting', { state: { activeTab: "subscription" } })
    }
    else if (flag == "allOpportunities") {
      navigate('/opportunities', { state: { activeTab: "activeOpportunity" } })
    }
    else {
      navigate('/opportunities', { state: { activeTab: "activeOpportunity" } })
    }
  }

  // for close Modal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //user logout
  const userLogout = () => {
    dispatch(logout({
      cb(res) {
        if (res.status) {
          navigate("/")
        }
        else {
        }
      }
    }))
  }

  const handleUser = (val) => {

    setModalDetail({ show: true, flag: "ComposeMessage" });
    setKey(Math.random());
    setSelectedCompanyName(val)
  }

  useEffect(() => {
    document.title = "Connection";
    getConnectionList();
    pendingConnectionList();
    companyProfileInfo();
  }, []);

  return (
    <>
      <div className='accountDashboard'>
        <div className="allConnection_">
          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-3'>
              <div className='companyContainerArea'>
                {/* <h3 className='headingTitle_ mb-3 invisible'>PostFiled</h3> */}
                <h3 className='mainHeading px-3'>Connections</h3>

                <div className='companyProfile'>
                  <div className='motionSec ps-4 pe-4 text-center'>
                    {!userDeatil ?
                      <>
                        <div className="row">
                          <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                          </div>
                        </div>
                      </>
                      :

                      <img src={ComapnyLogo} className='img-fluid motionImg' alt='motionImg' />
                    }
                    {/* <img src={userDeatil?.user_company_information?.company_logo ? userDeatil?.user_company_information?.company_logo : Images.companyprofile} className='img-fluid motionImg' alt='motionImg' /> */}
                    {/* <img src={Images.motionImg} className='img-fluid motionImg' alt='motionImg' /> */}
                    <div className='motionSecData mt-4 text-center'>
                      <h5 className='headingSmall_'><b>{userSigninInfo?.company_name ? userSigninInfo?.company_name : userStepInfo?.company_name}</b></h5>
                      <p className='motionLoc'>
                        {userDeatil?.domestic_service_areas ?
                          <>
                            <img src={Images.locationpin} alt='Location' className='me-1' />
                            <span className='textInnerOuter'>{userDeatil?.domestic_service_areas}
                              {/* {userSigninInfo?.main_company_country_location ? userSigninInfo?.main_company_country_location : userStepInfo?.main_company_country_location} */}
                            </span>
                          </>
                          : ""
                        }
                      </p>
                      {
                        userData?.user_company_information !== null ?
                          <div className="connectionbtn_">
                            <button className='secondaryBtnOuter mt-2' onClick={() => { handleChangePage("plan") }}>{userData?.membership_type == 1 || userData?.membership_type == 4 ? "Platinum" : userData?.membership_type == 2 ? "Gold" || userData?.membership_type == 5 : userData?.membership_type == 3 || userData?.membership_type == 6 ? "Bronze" : ""} Plan
                            </button>
                          </div>
                          : ""
                      }

                    </div>
                  </div>
                  <div className='companyDetails mt-3'>
                    <ul className='companyDetailsMenus'>
                      <li className='companyDetailsItems'>
                        <button onClick={() => { handleChangePage("edit") }} className='companyDetailsLinks'>Edit Profile</button>
                      </li>
                      <li className='companyDetailsItems'>
                        <Link to="/setting" className='companyDetailsLinks'>Settings & Privacy</Link>
                      </li>
                      <li className='companyDetailsItems'>
                        <button onClick={() => handleChangePage("plan")} className='companyDetailsLinks'>Manage Membership</button>
                      </li>
                      <li className='companyDetailsItems'>
                        <Link to="/all-connections" className='companyDetailsLinks'>View Connections</Link>
                      </li>
                      <li className='companyDetailsItems'>
                        <Link to="/account-message" className='companyDetailsLinks'>View Messages</Link>
                      </li>
                      <li className='companyDetailsItems'>
                        <Link to="/opportunities" className='companyDetailsLinks'>Manage Opportunities</Link>
                      </li>
                      <li className='companyDetailsItems'>
                        <Link to="/business" className='companyDetailsLinks'>Targeted   Search</Link>
                      </li>
                      <li className='companyDetailsItems'>
                        <Link to="/resources" className='companyDetailsLinks'>Resources</Link>
                      </li>
                      <li className='companyDetailsItems'>
                        <Link to="/document-view" className='companyDetailsLinks'>Documents</Link>
                      </li>
                      <li className='companyDetailsItems' onClick={() => { userLogout() }}>
                        <Link to="/resources" className='companyDetailsLinks'>Logout</Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className='companyDetails mt-3'>
                    <ul className='companyDetailsMenus'>
                      <li className='companyDetailsItems'>
                        <Link className='companyDetailsLinks'>Connections</Link>
                        <span className="smallheadingBlue_">{getTotalConnection}</span>
                      </li>
                      <li className='companyDetailsItems'>
                        <Link className='companyDetailsLinks'>Pending</Link>
                        <span className="smallheadingBlue_">{totalpendingConnection}</span>
                      </li>
                      <li className='companyDetailsItems'>
                        <Link className='companyDetailsLinks'>Messages</Link>
                        <span className="smallheadingBlue_">14</span>
                      </li>
                      <li className='companyDetailsItems'>
                        <Link className='companyDetailsLinks'>Privacy Settings</Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className='cardContainer mt-4'>
                  <div className='cardImg'>
                    <img src={Images.certifyUser} className='img-fluid' alt='CardImg' />
                  </div>
                  <div className='cardBodyArea'>
                    <h5>Get Certified</h5>
                    <div className="infoiconText">
                      <span className="showinfoText">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                    </div>
                    <p className='my-4'>GetOPP$ Diversity Certifications for inclusive business futures. </p>
                    <div className="infoiconText m-0">

                      <Link to="/certificate">
                        <button className='primaryBtn'>Learn More</button>
                      </Link>

                      <span className="showinfoText">
                        {/* {data?.posting_title.length > 13.5 ? data?.posting_title.substring(0, 13.5) + "...." : data?.posting_title} */}

                        {certificate_tooltip.substring(0, maxLength)}...

                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className='cardContainer mt-4'>
                  <div className='cardImg'>
                    <img src={Images.connectionImg} className='img-fluid' alt='CardImg' />
                  </div>
                  <div className='cardBodyArea'>
                    <h5>Get Certified</h5>
                    <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
                    <button className='primaryBtn'>Learn More</button>
                  </div>
                </div> */}
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-5'>
              <h3 className='mainHeading'>Search</h3>
              <div className='postSec'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className="business_search my-4">
                      <img className="searchIcon_" src={Images.search_} alt="Search" />
                      <input type="text" className="custoInput" placeholder="Search connections" aria-label="discount code" aria-describedby="basic-addon2" />
                    </div>
                    <div className='postContainer'>
                      {connectionSelector?.loading ?
                        <>
                          <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                              <div className="spinner-grow text-success" role="status"></div>
                            </div>
                          </div>
                        </>
                        :
                        <>
                          {getConnection?.length > 0 ?
                            <>
                              {getConnection?.map((val, index) => {
                                let sender = val?.sender_details;
                                let receiver = val?.receiver_details;
                                return (
                                  <div className='postContainerArea' key={index} >
                                    <div className='posts align-items-center'>
                                      {val?.receiver_id == useridlogin ? sender?.user_profile?.profile_photo != null ?
                                        <img onClick={() => handlePageRedirectPage(val)} className='postUser me-2' alt='UserImage' src={sender?.user_profile?.profile_photo} />
                                        : <img onClick={() => handlePageRedirectPage(val)} className='postUser me-2' alt='UserImage' src={Images.companyprofile} /> :
                                        val?.sender_id == useridlogin ? receiver?.user_profile?.profile_photo != null ?
                                          <img onClick={() => handlePageRedirectPage(val)} className='postUser me-2' alt='UserImage' src={receiver?.user_profile?.profile_photo} />
                                          : <img onClick={() => handlePageRedirectPage(val)} className='postUser me-2' alt='UserImage' src={Images.companyprofile} /> :
                                          <img onClick={() => handlePageRedirectPage(val)} className='postUser me-2' alt='UserImage' src={Images.companyprofile} />
                                      }
                                      <div className='postsDetails'>
                                        <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                                          <span><strong onClick={() => handlePageRedirectPage(val)}  >
                                            {val?.receiver_id == useridlogin ?
                                              sender?.user_profile?.first_name + " " + sender?.user_profile?.last_name :
                                              val?.sender_id == useridlogin ?
                                                receiver?.user_profile?.first_name + receiver?.user_profile?.last_name : ""}
                                          </strong > at {val?.receiver_id == useridlogin ?
                                            sender?.user_company_information?.company_name :
                                            val?.sender_id == useridlogin ?
                                              receiver?.user_company_information?.company_name : ""}</span>
                                        </h5>
                                        {/* <p className='weeksTxt'>{moment(val?.created_at).fromNow()}</p> */}
                                        <div className="messagebtn">
                                          <button onClick={() => {
                                            setModalDetail({ show: true, flag: "ComposeMessage" });
                                            setKey(Math.random());setSelectedCompanyName(val?.receiver_id == useridlogin ?
                                              val?.sender_details :
                                              val?.sender_id == useridlogin ?
                                                val?.receiver_details : "" )
                                          }} className="subsmallBtnblue_ me-2  ">Message</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </>
                            : <b className="py-4">No Connection Found</b>
                          }
                        </>
                      }
                      {/* <div className='postContainerArea' >
                        <div className='posts align-items-center'>
                          <img src={Images.accholder1} className='postUser me-2' alt='UserImage' />
                          <div className='postsDetails'>
                            <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                            <span><strong>Carlos Gieth</strong> Business Analyst at Blucor Systems Pvt Ltd</span>
                            </h5>
                            <p className='weeksTxt'>5 Minutes ago</p>
                            <div className="messagebtn">
                              <button className="subsmallBtnblue_ me-2 ">Message</button>
                            </div>
                          </div>
                        </div>

                      </div> */}
                      {/* <div className='postContainerArea' >
                        <div className='posts align-items-center'>
                          <img src={Images.accholder2} className='postUser me-2' alt='UserImage' />
                          <div className='postsDetails'>
                            <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                              <span><strong>Carlos Gieth</strong> Posted new business article.</span>
                            </h5>
                            <p className='weeksTxt'>5 Minutes ago</p>
                            <div className="messagebtn">
                              <button className="subsmallBtnblue_ me-2 ">Message</button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className='postContainerArea' >
                        <div className='posts align-items-center'>
                          <img src={Images.accholder2} className='postUser me-2' alt='UserImage' />
                          <div className='postsDetails'>
                            <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                            <span><strong>Carlos Gieth</strong> Business Analyst at Blucor Systems Pvt Ltd</span>
                            </h5>
                            <p className='weeksTxt'>5 Minutes ago</p>
                            <div className="messagebtn">
                              <button className="subsmallBtnblue_ me-2 ">Message</button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className='postContainerArea' >
                        <div className='posts align-items-center'>
                          <img src={Images.accholder2} className='postUser me-2' alt='UserImage' />
                          <div className='postsDetails'>
                            <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                            <span><strong>Carlos Gieth</strong> Business Analyst at Blucor Systems Pvt Ltd</span>
                            </h5>
                            <p className='weeksTxt'>5 Minutes ago</p>
                            <div className="messagebtn">
                              <button className="subsmallBtnblue_ me-2 ">Message</button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className='postContainerArea' >
                        <div className='posts align-items-center'>
                          <img src={Images.accholder2} className='postUser me-2' alt='UserImage' />
                          <div className='postsDetails'>
                            <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                            <span><strong>Carlos Gieth</strong> Business Analyst at Blucor Systems Pvt Ltd</span>
                            </h5>
                            <p className='weeksTxt'>5 Minutes ago</p>
                            <div className="messagebtn">
                              <button className="subsmallBtnblue_ me-2 ">Message</button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className='postContainerArea' >
                        <div className='posts align-items-center'>
                          <img src={Images.accholder2} className='postUser me-2' alt='UserImage' />
                          <div className='postsDetails'>
                            <h5 className='innerSubtextSmall d-flex align-items-center justify-content-between mb-2'>
                              <span><strong>Carlos Gieth</strong> Business Analyst at Blucor Systems Pvt Ltd</span>
                            </h5>
                            <p className='weeksTxt'>5 Minutes ago</p>
                            <div className="messagebtn">
                              <button className="subsmallBtnblue_ me-2 ">Message</button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <h3 className='mainHeading'>Pending</h3>
              <aside className='dashboardRightArea '>
                <div className='messageContainer py-4'>
                  {connectionSelector.loading ?
                    <>
                      <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                          <div className="spinner-grow text-success" role="status"></div>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      {pendingConnection?.length > 0 ?
                        <>
                          {pendingConnection.map((val, index) => {
                            return (
                              <div className='postContainerArea' key={index}>
                                <div className='posts align-items-center d-flex justify-content-between'>
                                  <div className='d-flex align-items-center'>
                                    <img onClick={() => handlePageRedirect(val?.sender_details?.user_company_information)} src={val?.sender_details?.user_profile?.profile_photo ? val?.sender_details?.user_profile?.profile_photo : Images.companyprofile} className='postUser me-3' alt='UserImage' />
                                    <div className='postsDetails'>
                                      <h5 onClick={() => handlePageRedirect(val?.sender_details?.user_company_information)} className='headingTitleSmall_ d-flex align-items-center justify-content-between mb-2'>
                                        <span >{val?.sender_details?.user_profile?.first_name} {val?.sender_details?.user_profile?.last_name}</span>
                                      </h5>
                                      <p className='innerSubtextSmall'>{val?.sender_details?.user_company_information?.company_name}</p>
                                    </div>
                                  </div>
                                  <div className="messageButton_">
                                    <button className="acceptBtn_" onClick={() => { AcceptRejectConnection(val?.id, 2) }}>Connect</button>
                                    {/* <button className="acceptBtn_" onClick={() => { AcceptRejectConnection(val?.id, 3) }} >Dis-connect</button> */}
                                    <img className="crossBtn_" src={Images.crossIconn} onClick={() => { AcceptRejectConnection(val?.id, 3) }} />
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                        : <b>No Pending Connection Found</b>
                      }
                    </>
                  }
                </div>
                {/* <div className='CardContainer mt-4 d-flex'>
                  <div className='cardImg d-flex'>
                    <img src={Images.endrosement} className='img-fluid' alt='CardImg' />
                  </div>
                  <div className='cardBodyArea'>
                    <h5>Endorsements</h5>
                    <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt.</p>
                    <button className='primaryBtn'>Learn More</button>
                  </div>
                </div> */}
                {/* <div className='cardContainer mt-4 d-flex'>
                  <div className='cardImg d-flex'>
                    <img src={Images.tradeshow} className='img-fluid' alt='CardImg' />
                  </div>
                  <div className='cardBodyArea'>
                    <h5>Tradeshows</h5>
                    <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt.</p>
                    <button className='primaryBtn'>Learn More</button>
                  </div>
                </div> */}
                <div className='cardContainer mt-4 d-flex'>
                  <div className='cardImg d-flex'>
                    <img src={Images.bannerendsor} className='img-fluid' alt='CardImg' />
                    <div className='cardBodyArea'>
                      <h5>Endorsements</h5>
                      <p className='my-5'>Elevate Connections and Inspire Growth with GetOPP$ Endorsements. </p>
                      <div className="infoiconText m-0">
                        <button onClick={() => { handleEndorsements() }} className='primaryBtn' >Learn More</button>
                        <span className="showinfoText">
                          {endorsements_tooltp.substring(0, maxLength)}...
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "Addlabels" ? "commonWidth customContent" : ""}
        ids={modalDetail.flag === "Addlabels" ? "Addlabels" : "ComposeMessage"}
        child={modalDetail.flag === "ComposeMessage" ? (
          <ComposeMessage
            chatConnectionCompanyName={selectedCompanyName}
            close={() => handleOnCloseModal()}
          />
        )
          : modalDetail.flag == "Endorsements" ?
            <EndorsementsText
              close={() => handleOnCloseModal()} />
            : ""

        }
        header={
          <div className='modalHeader_'>
            <div className="common_">
              <h4 className="headingSmall_">Saved Searches</h4>
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default AllConnections