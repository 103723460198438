import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { setGetCompanyInformation, onErrorStopLoad, setCreateEndrosement } from '../../slices/companyInformation';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';


function* CompanyInformation(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.CompanyInformationApiPath.COMPANY_INFORMATION}${action.payload.id}`);
    if (resp.status) {
      yield put(setGetCompanyInformation(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetCompanyInformation({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* CreateEndrosements(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.CompanyInformationApiPath.CREATE_ENDROSEMENT, action.payload = action.payload);
    if (resp.status) {
      yield put(setCreateEndrosement(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setCreateEndrosement({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}



function* userOpportunity() {
  yield all([
    takeLatest('companyInformation/getCompanyInformation', CompanyInformation),
    takeLatest('companyInformation/createEndrosement', CreateEndrosements),
    
  ])
}

export default userOpportunity;