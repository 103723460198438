import React, { useState, useEffect } from 'react'

const SoundbytesThumbnail = (props) => {
  return (
    <>

      <div className=''>
        <div className='bitesCrad'>
          <video controls>
            <source src={props.videoThumbnail} type="video/mp4" className='soundbiteImg' />
          </video>
        </div>
      </div>
    </>
  )
}

export default SoundbytesThumbnail