import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { myDocuments, deleteDocument } from '../../../redux/slices/documents';
import { updateArchiveDocuments } from '../../../redux/slices/documents/archiveDocuments';
import { useDispatch } from 'react-redux';
import { useDocumentsSelector } from '../../../redux/selector/documents';
import ReactPaginate from "react-paginate";
import swal from 'sweetalert';

const AllDocuments = () => {
    const dispatch = useDispatch();
    const documentSelector = useDocumentsSelector();
    const [myDocumentList, setMyDocumentList] = useState('');
    const [pageCount, setPageCount] = useState(1);

    // API for get my document ..............
    const getMyDocuments = (page = 1, limit = 10) => {
        let params = {
            page: page,
            limit: limit,
        }
        dispatch(myDocuments({
            ...params, cb(res) {
                if (res.status) {
                    setMyDocumentList(res?.data?.payload?.data)
                    setPageCount(res?.data?.payload?.total_pages)
                }
            }
        }))
    }

    // API for delete document ..............
    const documentDelete = (id) => {
        swal({
            // title: "Are you sure?",
            text: `Are you sure want to remove the selected file?`,
            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
        .then(result => {
            if (result) {
                let params = {
                    id: id,
                }
                dispatch(deleteDocument({
                    ...params, cb(res) {
                        if (res.status) {
                            getMyDocuments()
                        }
                    }
                }))
            }
        });
    }

    // API for document   Archive ..............
    const archiveDocuments = (id, flag) => {
        let params = {
            id: id,
            is_archive: flag
        }
        dispatch(updateArchiveDocuments({
            ...params, cb(res) {
                if (res.status) {
                    getMyDocuments()
                }
            }
        }))
    }
    //on change page pagination
    const handlePageClick = (e) => {
        getMyDocuments(e.selected + 1);
    };

    useEffect(() => {
        getMyDocuments()
        document.title = "document";
    }, []);
    return (
        <>

            <div className='allDocuments'>
                <h3 className="headingTitle_ mb-4">All Documents</h3>
                <div className='row'>
                    {documentSelector.loading ?
                        <>
                            <div className="row">
                                <div className="col-sm-12 text-center pt-5 pb-5">
                                    <div className="spinner-grow text-success" role="status"></div>
                                </div>
                            </div>
                        </> :
                        <>
                            {myDocumentList?.length > 0 ?
                                <>
                                    {
                                        myDocumentList.map((val, index) => {
                                            return (
                                                <div className='col-md-3' key={index}>
                                                    <div className='documentCrad mb-3'>
                                                        <img className='document' src={val?.document_format == "application/pdf" ? Images.PDFImage :  ((val?.document_format == "application/msword" || val?.document_format == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ? Images.DocImage  : val?.document )} />
                                                        <h5 className='reportHead_ mt-2 mb-3'>{val?.document_name.length > 25 ? val?.document_name.substring(0,25) + "...." : val?.document_name}</h5>
                                                        <div className='documentButton_'>
                                                            { val?.document_format == "application/msword" || val?.document_format == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? 
                                                                null
                                                            :
                                                                <a href={val.document} target="_blank" className=' subsmallBtnblue_ me-3 text-center'>
                                                                    <button className="viewBtn ">
                                                                        View
                                                                    </button>
                                                                </a>
                                                            }
                                                            <div className="dropdown">
                                                                <button className="subsmallBtnblack_ " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    More
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><a className="dropdown-item" onClick={() => archiveDocuments(val?.id, 1)}>Archive</a></li>
                                                                    <li><a className="dropdown-item" onClick={() => documentDelete(val?.id)}>Delete Document</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </>
                                : "No Documents Found"
                            }
                        </>
                    }
                </div>
                <div className="col-12 ">
                    <div className="customPagination mt-4">
                        <div className="pagination">
                            {myDocumentList?.length > 0 ? (
                                <ReactPaginate

                                    breakLabel=""
                                    // nextLabel=">"
                                    nextLabel={<i className='fas fa-caret-right'></i>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={pageCount}
                                    marginPagesDisplayed={0}
                                    pageCount={pageCount}
                                    previousLabel={<i className='fas fa-caret-left'></i>}
                                    // previousLabel="<"
                                    renderOnZeroPageCount={null}
                                />
                            ) : ""}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AllDocuments