import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
// import Select from 'react-select';
import * as Images from "../../../utilities/images";
import { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import "react-datepicker/dist/react-datepicker.css";
import { businessOwnershipType, getAllIndustryGroups, getAllIndustrySubGroups, stepThreeCompanyLogoUplaod } from '../../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOpportunitySelector } from "../../../redux/selector/opportunity";
import { addOpportunity, addPostOpportunityUploadPhotoOrPdf, getAllCurriencies } from "../../../redux/slices/opportunity";
import Multiselect from 'multiselect-react-dropdown';
import { diverseSupplierDescription, existingCertification } from '../../../redux/slices/advanceSearch';
import PlacesAutocomplete, {
} from 'react-places-autocomplete';

const AddPostOpportunity = (props) => {

    const opportunitySelector = useOpportunitySelector();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [postingtitle, setPostingtitle] = useState("");
    const [description, setDescription] = useState("");

    const [currency, setCurrency] = useState('USD');
    const [allCurriencies, setAllCurriencies] = useState([]);

    const [contractAmount, setContractAmount] = useState("");
    const [contractLength, setContractLength] = useState("");

    const [industryGroup, setIndustryGroup] = useState([])
    const [industryGroupId, setIndustryGroupId] = useState("")
    const [industrySubGroupId, setIndustrySubGroupId] = useState("")
    const [subIndustryGroup, setSubIndustryGroup] = useState([])
    const [awardDate, setAwardDate] = useState(new Date());
    const [responseDeadline, setResponseDeadline] = useState(new Date());
    const [serviceLocation, setServiceLocation] = useState("");
    const [ownershipTypeId, setOwnershipTypeId] = useState("")
    const [ownershipType, setOwnershipType] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    // const [companyDescriptionId, setCompanyDescriptionId] = useState("")
    // const [companyDescriptionOtherId, setCompanyDescriptionOtherId] = useState("")
    const [companyOtherDescription, setCompanyOtherDescription] = useState("")
    const [driversSupplierDescriptionId, setDriversSupplierDescriptionId] = useState();
    const [specificServiceLocationRequired, setSpecificServiceLocationRequired] = useState(true);
    const [existingCertificationData, setExistingCertificationData] = useState("")
    const [existingCertificationsId, setExistingCertificationsId] = useState();
    const [selected, setSelected] = useState([]);
    const [selectedCertifications, setSelectedCertifications] = useState([]);
    const [serviceLicensesRequires, setServiceLicensesRequires] = useState(true);

    const [photo, setPhotoUpload] = useState("");
    const [photoInfo, setPhotoInfo] = useState();

    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    //for add drivers Supplier Description........
    const SupplierDescription = (id) => {
        // if (id == 16) {
        //     setCompanyDescriptionOtherId(id)
        //     setCompanyDescriptionId("")
        //     setCompanyOtherDescription("")
        // }
        // else {
        //     setCompanyDescriptionId(id)
        //     setCompanyDescriptionOtherId("")
        //     setCompanyOtherDescription("")
        // }

        dispatch(diverseSupplierDescription({
            cb(res) {
                if (res.status) {
                    setCompanyDescription(res?.data?.payload)
                }
            }
        }))
    }


    // Existing certification
    const ExistingCertification = () => {
        dispatch(existingCertification({
            cb(res) {
                if (res.status) {
                    setExistingCertificationData(res.data.payload)
                }
                else {

                }
            }
        }))
    }


    const getAllCurrienciesAction = () => {
        dispatch(getAllCurriencies({
            cb(res) {
                if (res.status == 200) {
                    setAllCurriencies(res.data.payload)
                }
            }
        }))
    }


    //remove file
    const removeFile = file => () => {
        const newFiles = [photo]
        newFiles.splice(newFiles.indexOf(file), 1)
        setPhotoUpload(newFiles)
        setPhotoInfo("")
    }

    const industry = (e) => {
        dispatch(getAllIndustryGroups({
            cb(res) {
                if (res.status) {
                    setIndustryGroup(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    // onchange input 
    const handleChange = (id, flag) => {
        if (flag == "industry") {
            setIndustryGroupId(id)
            subIndustry(id)
        }
        else {
            setIndustrySubGroupId(id)
        }
    }

    const subIndustry = (id) => {
        if (id) {
            setIndustryGroupId(id)
            let params = {
                id: id,
            }
            dispatch(getAllIndustrySubGroups({
                ...params, cb(res) {
                    if (res.status) {
                        setSubIndustryGroup(res?.data?.payload)
                        setIndustrySubGroupId("")
                    }
                    else {

                    }
                }
            }))

        }

    }

    // handle change address
    const handleSelect = async value => {
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        setServiceLocation(value)
        // setCordinates(ll)
    }

    const businessOwnership = (id) => {
        setOwnershipTypeId(id)
        dispatch(businessOwnershipType({
            cb(res) {
                if (res.status) {
                    setOwnershipType(res?.data?.payload)
                }
                else {

                }
            }
        }))
    }

    // onchange date set date in the state
    const onChangeDateAward = (date) => {
        setAwardDate(date)
    }

    // onchange date set date in the state
    const onChangeDateResponse = (date) => {
        setResponseDeadline(date)
    }

    // driver supplier description
    const handleChanges = (data) => {
        setSelected(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setDriversSupplierDescriptionId(arr)
    }

    // Existing Certifications
    const handleChangesCertifications = (data) => {
        setSelectedCertifications(data)
        let arr = [];
        for (let i = 0; i < data.length; i++) { arr.push(data[i].id) }
        setExistingCertificationsId(arr)
    }

    //for add Opportunity
    const handleSubmit = (e) => {
        e.preventDefault();
        const numbers = /^[0-9]+$/;
        //  const numbers = /^[0-9]+$/;
        const regex = /[a-z|A-Z]+(?: [a-z|A-Z]+)*$/

        if (!postingtitle) {
            toast.error("Please enter posting title");
            return;
        }
        // else if (postingtitle.length <= 2) {
        //     toast.error("Posting title should be maximum 3 character")
        //     return;
        // }
        // else if (!postingtitle.match(regex)) {
        //     toast.error("Please enter only character and single space in posting title");
        //     return;
        // }
        else if (!responseDeadline) {
            toast.error("Please select response deadline");
            return;
        }
        // else if (!awardDate) {
        //     toast.error("Please select award date");
        //     return;
        // }
        else if (!description) {
            toast.error("Please enter description of contract opportunity");
            return;
        }
        else if (description.trim() === "") {
            toast.error("Description can't be empty");
            return;
        }
        else if (description.length <= 2) {
            toast.error("description should be maximum 3 character")
            return;
        }
        // else if (!description.match(regex)) {
        //     toast.error("Please enter only character and single space in Description");
        //     return;
        // }
        // else if (!contractAmount) {
        //     toast.error("Please select contract amount");
        //     return;
        // }
        // else if (!contractLength) {
        //     toast.error("Please select contract length");
        //     return;
        // }
        // // else if (!contractLength.match(numbers)){
        // //     toast.error("Please enter only numbers");
        // //     return;
        // // }
        // else if (!photo || !photoInfo) {
        //     toast.error("Please select opportunity media");
        //     return;
        // }
        // else if (!preferencesSupplier) {
        //     toast.error("Please select preferences for supplier");
        //     return; 
        // }
        // else if (!serviceCategories) {
        //     toast.error("Please select product and service categories");
        //     return;
        // }
        // else if (!address) {
        //     toast.error("Please select service Location");
        //     return;
        // }
        // else if (!annualRevenue) {
        //     toast.error("Please select annual revenue");
        //     return;
        // }

        // else if (!numberOfEmployees) {
        //     toast.error("Please select number of Employees");
        //     return;
        // }
        // else if (!yearsInBusiness) {
        //     toast.error("Please select years in Business");
        //     return;
        // }
        // else if (!industriesServed) {
        //     toast.error("Please enter industries Served");
        //     return;
        // }
        // else if (industriesServed.length <= 2) {
        //     toast.error("Industries Served should be maximum 3 character")
        //     return;
        // }
        // else if (!industriesServed.match(regex)) {
        //     toast.error("Please enter only character and single space in Industries Served");
        //     return;
        // }
        // else if (!businessTypesServed) {
        //     toast.error("Please enter business types Served");
        //     return;
        // }
        // else if (businessTypesServed.length <= 2) {
        //     toast.error("Business Types Served should be maximum 3 character")
        //     return;
        // }
        // else if (!businessTypesServed.match(regex)) {
        //     toast.error("Please enter only character and single space in business Types Served");
        //     return;
        // }
        // else if (!certifications) {
        //     toast.error("Please enter certifications");
        //     return;
        // }
        // else if (certifications.length <= 2) {
        //     toast.error("Certifications should be maximum 3 character")
        //     return;
        // }
        // else if (!certifications.match(regex)) {
        //     toast.error("Please enter only character and single space in Certifications");
        //     return;
        // }
        let params = {
            posting_title: postingtitle.trim(),
            response_deadline_date: responseDeadline,
            award_date: awardDate ? awardDate : "",
            description: description.trim(),
            currency: currency ?? 'USD',
            opportunity_amount: contractAmount ? contractAmount : 0,
            contract_length: contractLength ? contractLength : "",
            industry_group: industryGroupId ? industryGroupId : 0,
            industry_sub_group: industrySubGroupId ? industrySubGroupId : 0,
            specific_service_locations_required: specificServiceLocationRequired,
            hq_location: serviceLocation,
            business_ownership_type: ownershipTypeId,
            diversity_status: driversSupplierDescriptionId,
            existing_certificates: existingCertificationsId,
            service_licenses_required: serviceLicensesRequires,
            opportunity_media: photoInfo?.url ? photoInfo?.url : "",
            image_name: photoInfo?.name ? photoInfo?.name : "",
            image_size: photoInfo?.size ? photoInfo?.size : 0,
            image_mime_type: photoInfo?.format ? photoInfo?.format : "",
        }

        if (companyOtherDescription) {
            params = {
                ...params,
                diverse_supplier_description_other: companyOtherDescription,
            }
        }
        dispatch(addOpportunity({
            ...params, cb(res) {
                if (res.status) {
                    // navigate('/business')
                    props.setActiveTab("activeOpportunity");
                }
                else {
                }
            }
        }))
    }


    //onDrop
    const onDrop = useCallback(acceptedFiles => {
        const imageFile = acceptedFiles[0];
        // if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
        //     toast.error("Please select a valid image.");
        //     return false;
        // }
        // if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg|pdf)$/i)) {
        //     toast.error("Please select a valid image or PDF file.");
        //     return false;
        // }
        let params = {
            photo: imageFile,
        }

        dispatch(addPostOpportunityUploadPhotoOrPdf({
            ...params, cb(res) {
                if (res.status) {
                    setPhotoUpload(res?.data?.payload?.url);
                    setPhotoInfo(res?.data?.payload)
                }
                else {
                }
            }
        }))

    }, [])

    const { getRootProps, getInputProps } =

        useDropzone({
            onDrop,
            accept: {
                'image/jpeg': [],
                'image/jpg': [],
                'image/png': [],
                'application/pdf': [],
                'application/msword': [], // .doc files
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] //docx file
            }
        });


    useEffect(() => {
        industry();
        businessOwnership();
        SupplierDescription();
        ExistingCertification();
        getAllCurrienciesAction();

    }, []);

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)} className='editprofileForm_ row'>
                <h6 className='postHeading'><b>Post Opportunity</b> </h6>
                <div className='row'>
                    <div className='form-group col-lg-6 col-md-12 mb-4'>
                        <label className='postopportunity_label'>Contract Opportunity Title<span className='ColoredText'>*</span></label>
                        <input type='text' name="postingTitle" className='customFormControl' placeholder='Enter Title' value={postingtitle} onChange={e => setPostingtitle(e.target.value)} />
                    </div>
                    <div className='form-group col-lg-3  col-md-6 mb-4'>
                        <label className='postopportunity_label'>Response Deadline <span className='ColoredText'>*</span></label>
                        <div className='position-relative'>
                            <DatePicker
                                className='customFormControl'
                                selected={responseDeadline}
                                onChange={onChangeDateResponse}
                                // maxDate={moment().toDate()}
                                dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                placeholderText="Month Day, Year" // Example placeholder text
                            />
                            <img src={Images.calanderIcon} alt='calendar' className='img-fluid inputIcon newCalander' />
                        </div>
                    </div>
                    <div className='form-group col-lg-3 col-md-6 mb-4'>
                        <label className='postopportunity_label'>Award Date</label>
                        <div className='position-relative'>
                            <DatePicker
                                className='customFormControl'
                                selected={awardDate}
                                onChange={onChangeDateAward}
                                // minDate={moment().toDate()}
                                // maxDate={moment().toDate()}
                                dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                placeholderText="Month Day, Year" // Example placeholder text
                            />
                            <img src={Images.calanderIcon} alt='calendar' className='img-fluid inputIcon newCalander' />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-md-12 mb-3'>
                        <label className='postopportunity_label'>Description of Contract opportunity <span className='ColoredText'>*</span></label>
                        <textarea value={description} onChange={e => setDescription(e.target.value)} name="description" type='text' className='customFormControl' placeholder='Type here...' rows='7'></textarea>
                    </div>
                </div>
                <div className='row'>

                    <div className='form-group col-md-2 mb-5'>
                        <label className='postopportunity_label'>Currency</label>
                        <select className="cateSelectbox" required="" value={currency} onChange={(e) => { setCurrency(e.target.value) }} >
                            {allCurriencies.length > 0 ?
                                <>
                                    {
                                        allCurriencies.map((data) => {
                                            return <option value={data.code}>{data.code}</option>
                                        })
                                    }
                                </>
                                :
                                null
                            }
                        </select>
                    </div>
                    <div className='form-group col-lg-4 col-md-6 mb-5'>
                        <label className='postopportunity_label'>Contract Amount</label>
                        <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} value={contractAmount} onChange={e => setContractAmount(e.target.value)} type="number" className='customFormControl' pattern="[0-9]+" placeholder='Enter' />
                    </div>
                    <div className='form-group col-lg-4 col-md-6 mb-5'>
                        <label className='postopportunity_label'>Contract Length</label>
                        <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} value={contractLength} onChange={e => {
                            if (e.target.value.length > 20) {
                                toast.warning('Not more than 20 characters allowed in Contract Length')
                            }
                            setContractLength(e.target.value)
                        }
                        } type="text" className='customFormControl' placeholder='Enter' />
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-md-5 mb-5'>
                        <select className="cateSelectbox" required="" value={industryGroupId} onChange={(e) => { handleChange(e.target.value, "industry") }}
                        >
                            <option value="" >Select Industry Groups</option>
                            {industryGroup?.length > 0 ?
                                <>
                                    {industryGroup.map((data) => {
                                        return <option value={data.id}>{data.name}</option>
                                    })}
                                </>
                                : ""}
                        </select>
                    </div>
                    <div className='form-group col-md-5 mb-5'>
                        <select className="cateSelectbox" required="" value={industrySubGroupId}
                            onChange={(e) => { handleChange(e.target.value, "subindustry"); }}
                        >
                            <option value="">Select Industry Sub Groups</option>
                            {subIndustryGroup?.length > 0 ?
                                <>
                                    {subIndustryGroup.map((data) => {
                                        return <option value={data.id}>{data.name}</option>
                                    })}
                                </>
                                : ""}
                        </select>
                    </div>

                </div>
                <div className='row'>
                    <div className='form-group col-md-5 mb-5'>
                        <label className='postopportunity_label'>Specific Service Location Required?</label>
                        <div className="form-group col-md-12 mt-3 d-flex">
                            <div className="checkmark_ radioCheck_" >
                                <input type="radio" id="Location" className="businessType_" defaultChecked="{true}" value="true" name="radio-group" onChange={() => { setSpecificServiceLocationRequired(true) }} />
                                <label className="labelTxt_" for="Location">
                                    <span className='innerSubtextSmall'>Yes</span>
                                </label>
                            </div>
                            <div className="checkmark_ radioCheck_" >
                                <input type="radio" id="useriD" className="businessType_" value="false" name="radio-group" onChange={() => { setSpecificServiceLocationRequired(false) }} />
                                <label className="labelTxt_" for="useriD">
                                    <span className='innerSubtextSmall'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='form-group col-md-5 mb-5'>
                        <label className='postopportunity_label'>Service Location</label>
                        <PlacesAutocomplete
                            value={serviceLocation}
                            onChange={setServiceLocation}
                            onSelect={handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: ' customFormControl location-search-input form-control textArea',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-md-5 mb-5'>
                        <label className="headingSmall_ mb-2" htmlFor="">Business Ownership type</label>
                        <select className="cateSelectbox" required="" value={ownershipTypeId} onChange={(e) => { businessOwnership(e.target.value) }}>
                            <option value="">Business Ownership type</option>
                            {ownershipType?.length > 0 ?
                                <>
                                    {ownershipType?.map((data) => {
                                        return <option value={data?.id}>{data.name}</option>
                                    })}

                                </>
                                : ""}
                        </select>
                    </div>
                    <div className='form-group col-md-5 mb-5'>
                        <label className="headingSmall_ mb-2" htmlFor="">Diverse Status</label>
                        {companyDescription &&
                            <Multiselect
                                options={companyDescription} // Options to display in the dropdown
                                selectedValues={selected} // Preselected value to persist in dropdown
                                onSelect={handleChanges} // Function will trigger on select event
                                onRemove={handleChanges} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                            />
                        }
                    </div>
                    {driversSupplierDescriptionId?.includes(16) ?

                        <div className='form-group col-md-5 mb-5'>
                            <label className="headingSmall_ mb-2" htmlFor="">Diverse Status Other</label>
                            <input name="companyDescription" type='text' className='customFormControl' value={companyOtherDescription} placeholder='Other Description' disabled="" onChange={e => setCompanyOtherDescription(e.target.value)} />
                        </div> : ""

                    }
                </div>
                <div className='row'>
                    <div className='form-group col-md-5 mb-3'>
                        <label className='postopportunity_label'>Existing Certifications</label>
                        {existingCertificationData &&
                            <Multiselect
                                options={existingCertificationData} // Options to display in the dropdown
                                selectedValues={selectedCertifications} // Preselected value to persist in dropdown
                                onSelect={handleChangesCertifications} // Function will trigger on select event
                                onRemove={handleChangesCertifications} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                            />
                        }
                    </div>
                    <div className='form-group col-md-4 mb-3'>
                        <label className='postopportunity_label'>Service Licenses Requires?</label>
                        <div className="form-group col-md-12 mt-3 d-flex">
                            <div className="checkmark_ radioCheck_" >
                                <input type="radio" id="Service" className="businessType_" defaultChecked="{true}" value="true" name="radio-group2" onChange={() => { setServiceLicensesRequires(true) }} />
                                <label className="labelTxt_" for="Service">
                                    <span className='innerSubtextSmall'>Yes</span>
                                </label>
                            </div>
                            <div className="checkmark_ radioCheck_" >
                                <input type="radio" id="ServiceLicenses" className="businessType_" value="false" name="radio-group2" onChange={() => { setServiceLicensesRequires(false) }} />
                                <label className="labelTxt_" for="ServiceLicenses">
                                    <span className='innerSubtextSmall'>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <h6 class="postHeading"><b>Opportunity Media</b> </h6>
                    <div className='form-group col-md-12 mb-3'>
                        {photo?.length > 0 ?
                            "" :
                            <div className="uploadImgeboxNew">

                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    <img src={Images.uploadIcon} alt="uploadIcon" className='img-fluid uploadfileIcon hjgfhjfhj' />
                                    <p className='uploadbtn'>Drag & Drop or <span className='ColoredText'>browse</span></p>
                                    <p className='uploadText mt-2'>Supports: JPEG, JPG, PNG, PDF, DOC or DOCX</p>
                                </div>
                            </div>
                        }
                        {photo?.length > 0 ?
                            <aside >
                                <h4 className='headingSmall_'> Uploaded Files</h4>
                                <ul className='uploadProfile_'>
                                    {photoInfo.format == "application/pdf" ?
                                        <img className="w3-round" src={Images.PDFImage} />
                                        : photoInfo.format == "application/msword" || photoInfo.format == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                            <img className="w3-round" src={Images.DocImage} />
                                            : <img className="w3-round" src={photo} />
                                    }
                                    <div className='profileDetail_'>
                                        <h5 className='profileboldHead'>{photoInfo.name}</h5>
                                        <p className='profileboldgraph'> {(photoInfo?.size / (1024 * 1024)).toFixed(2)} MB</p>
                                    </div>
                                    <i className="fa fa-trash text-red" onClick={removeFile(photo)}></i>
                                </ul>
                            </aside>
                            : ""
                        }
                    </div>
                </div>
                <button className='submitopportunity opportunityButton mt-2'>Submit Opportunity</button>
            </form>
        </>
    )
}
export default AddPostOpportunity

