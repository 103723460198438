import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../../utilities/api';
import { setRecentlyDeleteOpportunity,onErrorStopLoad, setPermanentlyDelete, setUndeleteAction } from '../../../slices/opportunity/recentlyDeleteOpportunity';
import ApiPath from '../../../../constants/apiPath';
import { toast } from 'react-toastify';


function* RecentlyDeleteOpportunities(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.PostOpportunityApiPath.RecentlyDeleteApiPath.RECENTLY_DELETE_OPPORTUNITY}?page=${action.payload.page}&limit=${action.payload.limit}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setRecentlyDeleteOpportunity(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setRecentlyDeleteOpportunity({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* PermanentlyDelete(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.PostOpportunityApiPath.RecentlyDeleteApiPath.PERMANENTLY_DELETE}/${action.payload.id}`, action.params = {});
    if (resp.status) {
      yield put(setPermanentlyDelete());
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setPermanentlyDelete());
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* UndeleteAction(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.PostOpportunityApiPath.RecentlyDeleteApiPath.UNDELETE_ACTION}/${action.payload.id}`, action.params = {});
    if (resp.status) {
      yield put(setUndeleteAction());
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUndeleteAction());
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}








function* userRecentlyDelete() {
  yield all([
    takeLatest("recentlyDeleteOpportunities/recentlyDeleteOpportunity", RecentlyDeleteOpportunities),
    takeLatest("recentlyDeleteOpportunities/permanentlyDelete", PermanentlyDelete),
    takeLatest("recentlyDeleteOpportunities/undeleteAction", UndeleteAction)
  ])
}

export default userRecentlyDelete;