import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'


const SaveSearch = (props) => {

    const [name, setName] = useState('')   

    const handleSave = (name) => {
          
        if(name < 3)
        {
            toast.dismiss()
            toast.warning("Name must be atleast of 3 characters")
            return false
        }
    
        props.saveSearch(name)
        props.close()
        
    }

    const handleNameChange = (e) => {
        
        // if(e.target.value.length > 20)
        // {
        //     toast.dismiss()
        //     toast.warning("Name must be less than 20 characters")
        //     return false
        // }
        setName(e.target.value)
    }


    return (
        <>  
         <div className='saveSearchBox'>
            <div className='form-group'>
                <label className='postopportunity_label'>Give a name to save search</label>
                <input value={name} onChange={(e) => {handleNameChange(e)}} type="text" className='customFormControl' placeholder='Add Name' />
            </div>
            <button  className="primaryBtnSmall mt-3" onClick={()=>handleSave(name)}> Save Search</button>
        </div>
        </>
    )
}
export default SaveSearch