import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { uploadAllDocuments } from "../../../redux/slices/documents";
import { useNavigate } from 'react-router-dom';


const UploadDocuments = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [documentsInfo, setDocumentsInfo] = useState();
    const [documents, setDocuments] = useState("");


    //onDrop
    const onDrop = useCallback(acceptedFiles => {
        const imageFile = acceptedFiles[0];
        // if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
        //     toast.error("Please select a valid image.");
        //     return false;
        // }
        let params = {
            document: imageFile,
        }
        dispatch(uploadAllDocuments({
            ...params, cb(res) {
                if (res.status) {
                    setDocuments(res?.data?.payload?.document);
                    setDocumentsInfo(res?.data?.payload)
                    props.setActiveTab("allDocuments");
                }
                else {
                }
            }
        }))

    }, [])


    const { getRootProps, getInputProps } =

        useDropzone({
            onDrop,
            accept: {
                'application/pdf': [],
                'image/jpeg': [],
                'image/png': [],
                'application/msword':[], // .doc files
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':[] //docx file
            }
        });

    //remove file
    const removeFile = file => () => {
        const newFiles = [documents]
        newFiles.splice(newFiles.indexOf(file), 1)
        setDocuments(newFiles)
    }

    //for add Opportunity
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    // }
    return (
        <>
            <form className='editprofileForm_ row'>

                <div className='note_box'>
                    <h6 className='postHeading mt-3'>Document Upload</h6>
                    <span className="noteHeading mt-3">pdf file not more than 5mb</span>
                </div>
                <div className='form-group col-md-12 mb-3'>
                    {documents?.length > 0 ?
                        "" :
                        <div className="uploadImgebox">

                            <div {...getRootProps({ className: "dropzone" })}>
                                <input  {...getInputProps()} />
                                <p className='uploadbtnn'>Upload</p>
                                <p className='uploadText mt-2'>PNG, JPG, PDF, DOC, DOCX file upload</p>
                            </div>
                        </div>
                    }
                </div>
                {documents?.length > 0 ?
                    <aside >
                        <h4 className='headingSmall_'> Uploaded Files</h4>
                        <ul className='uploadProfile_'>
                            <img className="w3-round" src={documents} ></img>
                            <div className='profileDetail_'>
                                <h5 className='profileboldHead'>{documentsInfo.name}</h5>
                                <p className='profileboldgraph'>
                                    {(documentsInfo?.size / (1024 * 1024)).toFixed(2)} MB
                                </p>
                            </div>
                            <i className="fa fa-trash text-red" onClick={removeFile(documents)}></i>
                        </ul>
                    </aside>
                    : ""
                }

                {/* <button className='submitopportunity  mt-2 '>Submit</button> */}
            </form>
        </>
    )
}
export default UploadDocuments;