import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Images from "../../../utilities/images";
import Faq from "react-faq-component";
import { useCertificationSelector } from '../../../redux/selector/certification';


const Certificate = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [rows, setRowsOption] = useState(null);
  const data = {

    rows: [
      {
        title:
          "What are diversity certifications??               ",
        content: `Diversity certifications are official designations bestowed upon businesses owned by individuals from historically underrepresented groups, such as minorities, women, veterans, people with disabilities or people of the LGBTQIA+ community. Based upon information collected from businesses seeking these certifications and thereafter reviewed by the certifying body, receipt of a diverse certification verifies that a particular business is diverse owned.                `,
      },
      {
        title: "Why are diversity certifications important?",
        content:
          "Diverse certifications are important because they can help those businesses with the designations access opportunities, contracts, and partnerships that are designed to promote diversity and inclusion. Many organizations and government agencies have Supplier Diversity Programs as a part of their overall procurement process.  Via these programs, they prioritize working with diverse businesses granting them access to contracts and partnerships they might otherwise not have access to.  As such, these organizations and governments actively seek out diverse owned businesses to ensure diversity in their supplier and vendor networks thereby fostering a more inclusive business environment.",
      },
      {
        title: "Is there a market advantage to being certified as a diverse owned business?",
        content: `Being a certified diverse business can differentiate a company from its competitors and attract customers and clients who value diversity and social responsibility.`,
      },
      {
        title: "Which certifications does GetOpp$ offer?",
        content: `GetOpp$ offers certification for the following diverse owned businesses:
        <ul class="listDotted">
          <li>Disability Owned </li>
          <li>LGBTQIA+ Owned</li>
          <li>Minority Owned</li>
          <li>Veteran Owned</li>
          <li>Woman Owned</li>
        </ul>
        `,
      },
      {
        title: "What is the process and timeframe to receive a diverse certification from GetOpp$?",
        content: `GetOpp$ offers certification for the following diverse owned businesses:
        <ul class="listDotted">
          <li>Application Requirements </li>
        </ul>
          <ul class="listDottedSecond">
            <li>Member of GetOPP$</li>
            <li>Submit all required documents and information</li>
          </ul>
          <ul class="listDotted">
            <li>The review and reward process timeframe is typically 7-14 days</li>
            <li>Members are required to renew their certification status every 2 years from the date of original certification and 2 years from each renewal date thereafter.</li>
          </ul>
        `,
      },
    ],
  };

  const certificationData = useCertificationSelector();
  const flag = certificationData?.diversityCertification?.step;

  const supplierdata = {
    rows: [
      {
        title: "ID Verification Documents Required:",
        content: `<ul class="listDotted2">
                    <li>Current passport with signature</li>
                    <li>Green card</li>
                    <li>Real ID Driver’s license</li>
                  </ul> `,
      },
      {
        title: "Diversity  Verification Documents Required:",
        content: `<ul class="listDotted2">
                    <li>Birth certificate</li>
                    <li>Naturalization certificate</li>
                    <li>Blood Degree Certificate/Tribal Registry Letter</li>
                  </ul>`,
      },

      {
        title: "Business Ownership Verification Documents Required:",
        content: `<ul class="listDotted2">
                  <li>Professional Business/Operating License</li>
                  <li>Affidavid for sole proprietorship - provide form</li>
                  <li>Sole Proprietorships: Assumed Name Docs</li>
                  <li>Articles of Incorporation/ Organization Formation</li>
                  <li>Bank name, account manager, address, approved credit line, type of bank account</li>
                  <li>Certification of Incorporation/ Organization</li>
                  <li>Foreign Entity Certificate</li>
                  <li>LP Certificate of Existence issued by the State</li>
                </ul>`,
      },
      {
        title: "Business in Good Standing Verification Documents Required:",
        content: `<ul class="listDotted2">
                    <li>Ulility Bills 1-3 months</li>
                    <li>Proof of capital and/or equity investments by owner(s). Documents indicating the method by which the owner(s) obtained ownership interests (e.g., canceled checks, opening bank statements, proof of stock purchase, written statement by owner, list of contributed equipment and/or expertise, etc.)</li>
                    <li>Schedule of advances made to business by shareholders or others for the preceding 3 years</li>
                    <li>A Certificate of Good Standing, Certificate of Legal Existence, or Certificate of Status</li>
                    <li>IRS Letter of Good Standing</li>
                    <li>Lease Agreements</li>
                  </ul>`,
      },
      {
        title: "Additional Company Information Required:",
        content: `<ul class="listDotted2">
                    <li>Resumes
                   </li>
                    <li> Board of Directors and Officers information</li>
                    <li> Products, Services, Core Competencies and Skills</li>
                    <li> Partnership Agreement</li>
                  </ul>`,
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "dark gray",
    rowTitleColor: "dark gray",
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
  };
  const SectionToScroll = (flag) => {
    var section = document.getElementById(flag);
    if (section.id == "diversityCertificate") {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    else if (section.id == "whyItMatter") {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    else if (section.id == "faq") {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    else if (section.id == "requirement") {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleCertification = (e) => {
    e.preventDefault();
    if (flag >= 1) {  
      navigate("/certificate-step")
    } else {
      navigate("/certificate-application")
    }
  }

  return (
    <>
      {/* Start Certificate Banner Section */}
      <section className="certificateBanner" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="leftSec">
                <h3 className="heading58 mb-4">GetOPP$ <span className="headingGreen58"> Diversity Certifications </span> <br /> for inclusive business futures.</h3>
                <p className='txt26 mb-5 fw-500'>Open doors to new contracts, forge connections, and showcase your <br /> commitment to diversity.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Certificate Banner Section */}
      {/* Start Certificate Banner Section */}
      <section className="overviewLinks">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="overviewCon">
                <ul className='overviewList'>
                  <li><Link href="#" onClick={() => SectionToScroll("diversityCertificate")}>Diversity Certifications </Link></li>
                  <li><Link href="#" onClick={() => SectionToScroll("whyItMatter")}>Why it Matters </Link></li>
                  <li><Link href="#" onClick={() => SectionToScroll("faq")}>FAQs</Link></li>
                  <li><Link href="#" onClick={() => SectionToScroll("requirement")}>Requirements</Link></li>
                </ul>
                <div className='getCertifiedBtn'>
                  <Link to="/certificate-application">
                    <button className='getBtn'>Get Certified <img src={Images.certificateImg} alt="img" /></button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Certificate Section */}
      {/* Start Certificate Section */}
      <section className="diveersitySection" id="diversityCertificate">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="diveersityLeft">
                <h1 className='headingGreen30 mb-3'>GetOPP$ Diversity Certifications <span className='heading30'><br /> for  inclusive business futures</span></h1>
                <p className='txt19 mb-3'>Say goodbye to tedious paperwork and complex requirements. GetOPP$ simplifies and streamlines the entire certification process, empowering you to focus on growing your business. </p>

                <p className='txt19 mb-3'>With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier.</p>

                <p className='txt19 mb-3'>Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity. </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="diveersityRight">
                <img src={Images.divercityLeftImg} className='img-fluid' alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Certificate Section */}
      {/* Start Supplier Section */}
      <section className="supplierSection" id="whyItMatter">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-3 lg-mb-0">
              <div className="supplierLeft">
                <img src={Images.supplierLeft} className='img-fluid' alt="img" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="supplierRight">
                <h1 className='heading30 mb-3'>Why Supplier  <span className='headingGreen30'>Diversity Matters</span></h1>
                <p className='txt18 mb-3'>Diverse Suppliers contribute to Buyers and Suppliers success because they:</p>
                <ul className='supplierList mb-4'>
                  <li><p className='txt14'>Serve untapped niche markets</p></li>
                  <li><p className='txt14'>Provide untapped location demographics </p></li>
                  <li><p className='txt14'>Provide alternative sourcing strategies </p></li>
                  <li><p className='txt14'>Reduce the risks of supply chain disruptions</p></li>
                  <li><p className='txt14'>Supports the growth of underrepresented businesses</p></li>
                  <li><p className='txt14'>Demonstrates a commitment to social responsibility</p></li>
                  <li><p className='txt14'>Demonstrates a commitment to inclusion</p></li>
                  <li><p className='txt14'> Increases brand loyalty</p></li>
                  <li><p className='txt14'> Increases positive public perception</p></li>
                </ul>
                <p className='txt18 mb-4'>One of the goals of GetOpp$ is to put diverse businesses on global procurement radar screens.</p>
                <p className='txt18 mb-0 fw-800'>That is the <span className='txtGreen18 fw-800'> GetOpp$ </span> Win-Win!!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Certificate Banner Section */}
      {/* Start FAQ Section  */}
      <section className="certificateFaq" id="requirement">
        <div className="container">
          <div className="containerBox">
            <div className="row">
              <div className="col-md-12 ">
                <h3 className="headingGreen36 fw-800 text-center">
                  GetOPP$ <span className='heading36 fw-800 '> Diversity Certifications</span>
                </h3>
                <p className='txt23 text-center mb-5'>Document Requirements</p>
              </div>
            </div>
            <div className="row">
              <div className="memberHomeImage_">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="faqcontentBox_">
                      <Faq
                        getRowOptions={setRowsOption}
                        data={supplierdata}
                        styles={styles}
                        config={config}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End FAQ Section */}
      {/* Start Diversity Section */}
      <section className="diversitySection">
        <div className="container">
          <div className="row align-items-center">
            <div className='diversityCon'>
              <div className="col-lg-6">
                <div className="diversityLeft">
                  <h1 className='headingGreen30 mb-3 fw-800'>GetOPP$  <span className='headingBlue30'>Diversity <br /><span className='headingBlue30 fw-800'>Certifications</span>  Pricing</span></h1>
                  <p className='txt24'>Diversity Certifications Pricing <br />for Application and Renewal</p>
                  <div className='diversityRenewal'>
                    <img src={Images.diversityRenewlImg} alt="icon" />
                    <div className='diversityRenLeft'>
                      <h1 className='txtBlue15'>First 2 Years</h1>
                      <p className='txt20'>$1800-3600</p>
                    </div>
                    <div className='diversityRenRight'>
                      <h1 className='txtBlue15'>RENEWAL</h1>
                      <p className='txt20'>$1800-3600</p>
                    </div>
                  </div>
                  <Link>
                    <button className='getStaredBtn' onClick={(e) => handleCertification(e)}>Get Certified</button>
                  </Link>
                </div>
              </div>
              <img src={Images.diversityRightImg} alt='image' className='diversityRightImg img-fluid' />
            </div>
          </div>
        </div>
      </section>
      {/* End Certificate Banner Section */}
      {/* Start FAQ Section  */}
      <section className="certificateFaq whiteDark" id="faq">
        <div className="container">
          <div className="containerBox p-0">
            <div className="row">
              <div className="col-md-12 ">
                <h3 className="headingGreen36  fw-800 text-center">
                  GetOPP$ <span className='heading36 fw-800'>Certifications</span>
                </h3>
                <p className='txt23 text-center mb-5'>Frequently Asked Questions</p>
              </div>
            </div>
            <div className="row">
              <div className="memberHomeImage_">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="faqcontentBox_">
                      <Faq
                        getRowOptions={setRowsOption}
                        data={data}
                        styles={styles}
                        config={config}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End FAQ Section */}



    </>
  )
}

export default Certificate