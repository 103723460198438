import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import * as Images from "../../../utilities/images";
const FrequentQuestion = () => {
  const [rows, setRowsOption] = useState(null);
  const data = {
    title: "For Buyers",
    rows: [
      {
        title:
          "What is GetOpp$ and how can it benefit me as a buyer?               ",
        content: `GetOpp$ is a dynamic social media networking site designed for buyers and suppliers. It provides a platform to connect and engage with a diverse community of members, discover new products and services, and foster valuable business relationships. By joining GetOpp$, you gain access to a wide range of suppliers and buyers, streamline your procurement process, and unlock new growth opportunities.                `,
      },
      {
        title: "How can I find relevant suppliers on GetOpp$?",
        content:
          "GetOpp$ offers targeted search functionalities that allow you to find suppliers based on specific criteria such as location, industry, certifications, and more. You can utilize advanced search filters to narrow down your options and connect with suppliers who best align with your requirements.",
      },
      {
        title: "Can I review supplier profiles and ratings on GetOpp$?",
        content: `Absolutely! GetOpp$ provides comprehensive supplier profiles where you can find detailed information about their offerings, certifications, experience, and more. Additionally, you can benefit from ratings and reviews provided by other buyers who have engaged with these suppliers, helping you make informed decisions and evaluate their reputation.`,
      },
    ],
  };

  const supplierdata = {
    title: "For Suppliers",
    rows: [
      {
        title: "How can GetOpp$ benefit me as a supplier?",
        content: `GetOpp$ offers immense benefits to suppliers by providing a platform to connect and engage with a wide network of buyers. It enables you to showcase your products or services, expand your reach, and establish meaningful relationships with potential buyers. GetOpp$ helps you gain visibility, increase your chances of securing contracts, and grow your business.                `,
      },
      {
        title: "Can I directly message buyers on GetOpp$?",
        content: `Absolutely! GetOpp$ facilitates direct messaging between suppliers and buyers, allowing you to communicate and engage with potential customers in a convenient and efficient manner. You can leverage this feature to discuss requirements, provide additional information, and build relationships with buyers who are interested in your offerings.`,
      },

      {
        title: "How can I enhance my profile on GetOpp$ to attract buyers?",
        content: `To attract buyers on GetOpp$, it's important to create a compelling and informative profile. Highlight your key offerings, showcase your unique value proposition, and include relevant certifications or qualifications. Regularly update your profile with fresh content, such as case studies, testimonials, or industry insights, to demonstrate your expertise and credibility.`,
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "dark gray",
    rowTitleColor: "dark gray",
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "x",
  };

  // useEffect(() => {
  //     if (rows) {

  //             rows[0].expand();
  //             rows[1].expand();
  //             rows[2].expand();
  //             rows[4].expand();
  //             rows[5].expand();

  //         // setTimeout(() => {
  //         //     rows[0].close();
  //         // }, 5000);

  //             // rows[0].scrollIntoView();
  //             // rows[0].scrollIntoView(true);

  //     }
  // }, [rows]);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "frequent-Question";
}, []);


  return (
    <section className="frequentyQuestion_ mt-5 mb-4">
      <div className="container">
        <div className="containerBox">
          <div className="row justify-content-center">
            <div className="memeberHead_ col-md-10 ">
              <h3 className="memberHead_">
                <strong>Frequently Asked Questions</strong>
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="memberHomeImage_ mt-5 mb-4">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="faqcontentBox_">
                    <Faq
                      getRowOptions={setRowsOption}
                      data={data}
                      styles={styles}
                      config={config}
                    />
                  </div>
                  <div className="faqcontentBox_">
                    <Faq
                      getRowOptions={setRowsOption}
                      data={supplierdata}
                      styles={styles}
                      config={config}
                    />
                  </div>
                </div>
                {/* <div className='col-md-4 col-lg-4'>
                                <div className='faqImgbox'>
                                    <img className='faqImg' src={Images.faqImg} />
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrequentQuestion;
