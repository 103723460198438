import React, { useEffect, useState } from "react";
import OneSignal from 'react-onesignal';

// export default async function runOneSignal() {
//   // await OneSignal.init({ appId: '4f4a4dd1-1d37-47b0-8550-28414e2a497e', allowLocalhostAsSecureOrigin: true});
//   // OneSignal.Slidedown.promptPush();
//   await OneSignal.init({ appId: '418e62b6-670b-4914-8ffe-b939336a1622', allowLocalhostAsSecureOrigin: true});


//   // OneSignal.Slidedown.promptPush();
// }
const RunOneSignal = ({ children }) => {

  useEffect(() => {
    try {
      OneSignal.init({
        appId: '4f4a4dd1-1d37-47b0-8550-28414e2a497e',
        allowLocalhostAsSecureOrigin: true,
      });
      // OneSignal.Slidedown.promptPush();
    } catch (error) {
      console.error('Error initializing OneSignal:', error);
    }

  }, []);
  return <>{children}</>;
}

export default RunOneSignal;