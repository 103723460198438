import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import * as Images from "../../../utilities/images";
import { addSuggestion} from '../../../redux/slices/resource';

const SuggestionBox = (props) => {
  const dispatch = useDispatch();
  const [suggestionName, setSuggestionName] = useState("")
  const [suggestionCompany, setSuggestionCompany] = useState("")
  const [suggestionEmail, setSuggestionEmail] = useState("")
  const [suggestionMessage, setSuggestionMessage] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!suggestionName) {
      toast.error("Please enter name");
      return;
    }
    else if (!suggestionCompany) {
      toast.error("Please enter company");
      return;
    }
    else if (!suggestionEmail) {
      toast.error("Please enter email");
      return;
    }
    else if (suggestionEmail && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(suggestionEmail)) {
      toast.error("Please enter valid email address");
      return;
    }
    else if (!suggestionMessage) {
      toast.error("Please enter message");
      return;
    }


    let params = {
      name: suggestionName,
      company: suggestionCompany,
      email: suggestionEmail,
      message: suggestionMessage
    };
    dispatch(addSuggestion({
      ...params, cb(res) {
        if (res?.data) {
          props.close()
        }
      }
    }))
  }

  useEffect(() => {


  }, []);
  return (
    <>

      <div className=''>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="row">
            <div className="form-group col-md-6 mb-3">
              <label className="headingSmall_ mb-2" for="">Name</label>
              <input type="text" className="customFormControl" placeholder='Company Name' value={suggestionName} onChange={e => setSuggestionName(e.target.value)} />
            </div>
            <div className="form-group col-md-6 mb-3">
              <label className="headingSmall_ mb-2" for="">Company</label>
              <input type="text" className="customFormControl" placeholder='Company Name' value={suggestionCompany} onChange={e => setSuggestionCompany(e.target.value)} />
            </div>
            <div className="form-group col-md-6 mb-3">
              <label className="headingSmall_ mb-2" for="">Email Address</label>
              <input type="text" className="customFormControl" placeholder='Company Name' value={suggestionEmail} onChange={e => setSuggestionEmail(e.target.value)} />
            </div>
            <div className="form-group mb-3 col-md-12">
              <label className="headingSmall_ mb-2" for="">Message</label>
              <textarea type="text" className="customFormControl" placeholder="What do you have to tell people." id="inputName" name="post" value={suggestionMessage} onChange={e => setSuggestionMessage(e.target.value)} cols="4" />
            </div>
            <div className="form-group suggestionModal">
              <button className="primaryBtn">Submit Suggestion</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default SuggestionBox