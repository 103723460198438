import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  chatHead:[],
  messageList:[],
  messageConversation:[],
  chatInfo:[],
  getAllCompanyInformation:[],
  getlabels:[],
  addlabels:[],
  createDraft:[],
  getChatDraft:[],
  chatDelete:[],
  messageDelete:[],
  getTrashChat:[],
  trashChatUpdate:[],
  loading:false
}

export const messageSlice = createSlice({

  name: 'messages',
  initialState,
  reducers: {
    getChatHead: (state) => {
      state.loading = true
    },
    setChatHead: (state, action) => {
      state.loading = false
      state.chatHead = action.payload
    },
    chatDelete: (state) => {
      state.loading = true
    },
    setChatDelete: (state, action) => {
      state.loading = false
      state.chatDelete = action.payload
    },
    messageDelete: (state) => {
      state.loading = true
    },
    setMessageDelete: (state, action) => {
      state.loading = false
      state.messageDelete = action.payload
    },
    getTrashChat: (state) => {
      state.loading = true
    },
    setGetTrashChat: (state, action) => {
      state.loading = false
      state.getTrashChat = action.payload
    },
    trashChatUpdate: (state) => {
      state.loading = true
    },
    setTrashChatUpdate: (state, action) => {
      state.loading = false
      state.trashChatUpdate = action.payload
    },
    getChatInfo: (state) => {
      state.loading = true
    },
    setChatInfo: (state, action) => {
      state.loading = false
      state.chatInfo = action.payload
    },
    createDraft: (state) => {
      state.loading = true
    },
    setCreateDraft: (state, action) => {
      state.loading = false
      state.createDraft = action.payload
    },
    getChatDraft: (state) => {
      state.loading = true
    },
    setGetChatDraft: (state, action) => {
      state.loading = false
      state.getChatDraft = action.payload
    },
    getAllCompanyInfo: (state) => {
      state.loading = true
    },
    setAllCompanyInfo: (state, action) => {
      state.loading = false
      state.getAllCompanyInformation = action.payload
    },
    getLabels: (state) => {
      state.loading = true
    },
    setLabels: (state, action) => {
      state.loading = false
      state.getlabels = action.payload
    },
    addLabels: (state) => {
      state.loading = true
    },
    setAddLabels: (state, action) => {
      state.loading = false
      state.addlabels = action.payload
    },
    getMessageList: (state) => {
      state.loading = true
    },
    setMessageList: (state, action) => {
      state.loading = false
      state.messageList = action.payload
    },
    getMessageConversation: (state) => {
      state.loading = true
    },
    setMessageConversation: (state, action) => {
      state.loading = false
      state.messageConversation = action.payload
    },
    addLabel: (state) => {
      state.loading = true
    },
    onErrorStopLoad: (state) => {
        state.loading = false
    }
  }
})

// Action creators are generated for each case reducer function
export const { getLabels, setLabels, getMessageList, setMessageList, getMessageConversation, setMessageConversation, addLabel,getChatHead,setChatHead,getChatInfo,setChatInfo,getAllCompanyInfo,setAllCompanyInfo,addLabels,setAddLabels,createDraft,setCreateDraft,getChatDraft,setGetChatDraft, messageDelete,setMessageDelete,chatDelete,setChatDelete,getTrashChat,setGetTrashChat,trashChatUpdate,setTrashChatUpdate,onErrorStopLoad} = messageSlice.actions

export default messageSlice.reducer