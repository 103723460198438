import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomModal from "../../components/common/shared/CustomModal";
import { useAuthSelector } from "../../../redux/selector/auth";
import * as Images from "../../../utilities/images";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { allDashboardData, allPostList, deletePost, disableEnableComment, editPost, getDashboardOpportunity, getPendingEndorsement, getPostSingleDetails, postFeature, postImageUplaod, postUserLikeList, setSavedReduxData, updateEndorsementsRequest, updateUserProfileStatus } from "../../../redux/slices/dashboard";
import { useDashboardSelector } from "../../../redux/selector/dashboard";
import { userPost, userPostLike } from "../../../redux/slices/dashboard";
import CommmentBox from "./comment";
import CompanyLogo from "./companyLogo";
import InfiniteScroll from 'react-infinite-scroll-component';
import { activeInactiveOppertunity, myPostOpportunity } from "../../../redux/slices/opportunity";
import { getCompanyProfileInfo } from "../../../redux/slices/auth";
import { logout } from '../../../redux/slices/auth';
import AllPostLikeUser from "./allPostLikeUser";
import AddTagToPost from "./modals/addTagToPost";
import EndorsementsText from "./modals/endorsementsText";
import swal from "sweetalert";
import { socketInstance } from "../../components/socketNew";
import OneSignal from "react-onesignal";

const AccountDashboard = () => {
    const [hideEditBtn, setHideEditBtn] = useState(false);
    const authSelector = useAuthSelector();
    const [loader, setLoader] = useState(false)
    const [uploadloader, setUploadLoader] = useState(false)

    // let BusinessType = authSelector?.userStep?.user_membership_data && authSelector?.userStep?.user_membership_data[0]?.membership_details?.business_type;

    // let current_membership_plan = authSelector?.userStep?.user_membership_data && authSelector?.userStep?.user_membership_data[0]?.membership_details?.name;

    const certificate_tooltip = " Say goodbye to tedious paperwork and complex requirements. GetOPP$ simplifies and streamlines the entire certification process, empowering you to focus on growing your business. With a user-friendly interface and automated verification processes, completing your application accurately and promptly has never been easier. Gain access to procurement opportunities, strategic partnerships, and invaluable resources. Open doors to new contracts, forge connections, and showcase your commitment to diversity. "
    const endorsements_tooltp = "   Make your mark on the vibrant GetOpp$ buyer and supplier social networking platform by endorsing fellow members and igniting a powerful network of support. Share your positive experiences, highlight exceptional skills, and build credibility within the community. Endorsements strengthen professional relationships, boost visibility, and open doors to exciting collaboration opportunities. Let your voice be heard, cultivate a thriving network, and be part of a community that values recognition and growth. "
    const maxLength = 60;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dashboardSelector = useDashboardSelector();
    const [userDeatil, setUserDeatil] = useState();
    const [newPost, setNewPost] = useState(false)
    const { id } = useParams()


    const like_Status = dashboardSelector?.allPost;
    const [allPost, setAllPostList] = useState([]);
    const [allDashboard, setAllDashboard] = useState("");
    const [pendingEndorsements, setPendingEndorsements] = useState("");
    const [post, setPost] = useState("");
    const [userData, setUserData] = useState()

    const [photoInfo, setPhotoInfo] = useState();
    const [page, setPages] = useState(1);
    const [videoUrlOriginal, setVideoUrlOriginal] = useState();
    const [totalPages, setTotalPages] = useState();

    const [photo, setPhotoUpload] = useState({
        uploadPhoto: "",
        flag: ""
    });
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const [mainTags, setMainTags] = useState({
        companies: [],
        users: [],
    })
    const [selectedIds, setSelectedIds] = useState({
        companies: [],
        users: [],
    })

    const [myOpportunities, setMyOpportunities] = useState('');

    const userSigninInfo = authSelector?.userInfo?.user?.user_company_information !== null ? authSelector?.userInfo?.user?.user_company_information : authSelector?.userInfo?.user?.user_selected_company_information?.user_company_information;

    const userStepInfo = authSelector?.userStep?.user_company_information;

    const [hasMore, setHasMore] = useState(true);
    const observerRef = useRef();

    const [editFlag, setEditFlag] = useState(false);
    const [editPostID, setEditPostID] = useState(false);


    const [showNewBackground, setShowNewBackground] = useState(false);

    if (socketInstance) {
        socketInstance.on('new_post', (data) => {
            if (data.user_id !== userSigninInfo?.user_id) {
                setNewPost(true)
            }
        })
    }


    // const [result, loading, error] = useScrapper({
    //     url:'https://www.ebay.com',
    // })

    const [url, setUrl] = useState([])




    useEffect(() => {
        //Set showNewBackground to true after 1 second
        const timeoutId = setTimeout(() => {
            setShowNewBackground(true);
        }, 3000);

        //Clean up the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);
    }, []);

    const updateTagData = (tagData, idData) => {
        setMainTags(tagData)
        setSelectedIds(idData)
    }

    const removeTags = (itemId, name, type) => {

        const data = { ...mainTags };
        const IdData = { ...selectedIds };

        if (type === 'company') {

            if (IdData.companies.includes(itemId)) {
                // Remove the company from the selected list and data
                const index = IdData.companies.indexOf(itemId);
                if (index > -1) {
                    IdData.companies.splice(index, 1);
                    data.companies = data.companies.filter(company => company.id !== itemId);
                }
            }
        } else {

            if (IdData.users.includes(itemId)) {
                // Remove the user from the selected list and data
                const index = IdData.users.indexOf(itemId);
                if (index > -1) {
                    IdData.users.splice(index, 1);
                    data.users = data.users.filter(user => user.id !== itemId);
                }
            }
        }

        setMainTags(data)
        setSelectedIds(IdData)
    };


    //user logout
    const userLogout = () => {
        dispatch(logout({
            cb(res) {
                if (res.status) {
                    navigate("/")
                }
                else {
                }
            }
        }))
    }

    //for COMPANY PROFILE INFORMATION.......
    const companyProfileInfo = () => {
        dispatch(getCompanyProfileInfo({
            cb(res) {
                if (res.status) {
                    if (res?.data?.payload?.userDetails?.user_company_information !== null) {
                        setUserDeatil(res?.data?.payload?.userDetails?.user_company_information)
                        setUserData(res?.data?.payload?.userDetails)
                    }
                    else {
                        setUserData(res?.data?.payload?.userDetails)
                        setUserDeatil(res?.data?.payload?.userDetails?.user_selected_company_information?.user_company_information)
                    }

                }
                else {

                }
            }
        }))
    }

    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
        fetchData();
    };

    // total user like post
    const totalUserLikeList = (id) => {
        setModalDetail({ show: true, flag: "allPostLikeUser", id: id });
        setKey(Math.random());
    }

    //handle play button icon
    const handleVideoPlay = (post, index) => {
        setAllPostList((prevData) => {
            let data = [...prevData]

            // If you want to update the like status
            const updatedPost = {
                ...data[index], isvideo: true,
            };
            data[index] = updatedPost;

            return data;
        })
    }

    //for post like...............
    const postLike = (id, flag, index) => {
        const params = {
            post_id: id,
            like_status: flag,
        }
        dispatch(userPostLike({
            ...params, cb(res) {
                if (res.status) {
                    setAllPostList((prevData) => {
                        let data = [...prevData]
                        if (flag === 1) {
                            // If you want to update the like status
                            const updatedPost = {
                                ...data[index], is_like: res?.data?.payload?.is_like, likeCount: res?.data?.payload?.likeCount
                            };
                            data[index] = updatedPost;
                        } else {
                            // If you want to reset the like status to null
                            const updatedPost = { ...data[index], is_like: res?.data?.payload?.is_like, likeCount: res?.data?.payload?.likeCount };
                            data[index] = updatedPost;
                        }
                        return data;
                    })
                }

                else {
                }
            }
        }))
    }


    //remove file
    const removeFile = file => () => {
        const newFiles = [photoInfo]
        newFiles.splice(newFiles.indexOf(file), 1)

        setPhotoInfo(newFiles)

        setPhotoInfo('')
        setPhotoUpload('')
        setVideoUrlOriginal('')

    }

    //onChange input
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "post") {
            setPost(value)
        }
    };

    //onChange image
    const onChangePicture = (e, flag) => {
        const imageFile = e.target.files[0];
        e.currentTarget.value = null
        // if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        //     toast.error("Please select a valid image.");
        //     return false;
        // }

        if (imageFile) {
            const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/heic', 'image/heif', 'video/mp4', 'image/gif', 'image/png'];
            if (acceptedTypes.includes(imageFile.type)) {
                // File type is supported, you can proceed with processing the file here
            } else {
                alert('Unsupported file type. Please choose a different file.');
            }
        }
        let params = {
            photo: imageFile,
            type: "post"
        }
        setUploadLoader(true)
        dispatch(postImageUplaod({
            ...params, cb(res) {
                if (res.status) {
                    setUploadLoader(false)
                    if (res?.data?.payload?.originalUrl?.format == "video/mp4") {
                        setPhotoUpload({ ...photo, uploadPhoto: res?.data?.payload?.thumbnailUrl, flag: 3 });
                        setVideoUrlOriginal(res?.data?.payload?.originalUrl.url)
                    }
                    else {
                        setPhotoUpload({ ...photo, uploadPhoto: res?.data?.payload?.thumbnailUrl, flag: flag })
                        setVideoUrlOriginal(res?.data?.payload?.originalUrl.url)
                    }
                    setPhotoInfo([res?.data?.payload?.thumbnailUrl.url])
                }
                else {
                }
            }
        }))
    }

    //all dashboard data
    const getAllDashboardData = () => {
        dispatch(allDashboardData({
            cb(res) {
                if (res.status) {
                    setAllDashboard(res.data.payload)


                }
            }
        }))
    }

    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!post.trim()) {
            toast.error("Please enter Post text");
            return;
        }
        else if (!post) {
            toast.error("Please enter Post without trim");
            return;
        }

        else if (post.length <= 2) {
            toast.error("Name should be maximum 3 character")
            return;
        }

        // else if (!photo.uploadPhoto && !photo.flag) {
        //     toast.error('Please select image')
        //     return
        // }
        let params = {};

        if (post !== "") {
            params.post = post.trim();
        }

        if (photoInfo) {
            params.attachment = videoUrlOriginal;
            params.attachment_type = photo.flag;
            params.thumbnail_url = photo.uploadPhoto?.url;
            params.attachment_name = photo.uploadPhoto?.name;
            params.attachment_size = photo.uploadPhoto?.size;
        }

        if (selectedIds.companies.length > 0) {
            params.company_tags = selectedIds.companies;
        }

        if (selectedIds.users.length > 0) {
            params.user_tags = selectedIds.users;
        }

        if (editFlag) {
            params.id = editPostID
            dispatch(editPost({
                ...params, cb(res) {

                    if (res.status == 200) {

                        setEditFlag(false)
                        setEditPostID(null)
                        setVideoUrlOriginal("")
                        setPhotoInfo("")
                        setPost("")
                        setPhotoUpload({ ...photo, uploadPhoto: "", flag: "" });
                        setMainTags({
                            companies: [],
                            users: [],
                        })
                        setSelectedIds({
                            companies: [],
                            users: [],
                        })

                        setAllPostList((prevData) => {
                            const data = prevData.filter((item, i) => res.data.payload.id != item.id);
                            const newData = [res.data.payload, ...data]
                            return newData;
                        })
                        toast.success("Post Updated Successfully")
                    }
                    else {

                    }

                }
            }))

        }

        else {
            setLoader(true)
            dispatch(userPost({
                ...params, cb(res) {
                    if (res.status) {
                        setLoader(false)

                        setVideoUrlOriginal("")
                        setPhotoInfo("")
                        setPost("")
                        setPhotoUpload({ ...photo, uploadPhoto: "", flag: "" });
                        setMainTags({
                            companies: [],
                            users: [],
                        })
                        setSelectedIds({
                            companies: [],
                            users: [],
                        })
                        setAllPostList([res.data.payload, ...allPost]);
                    }
                }
            }))
        }

    }

    console.log(loader, "loader value");

    const fetchData = (scrollpage) => {
        let params = {
            page: scrollpage ? scrollpage : page,
            limit: 10,
        };

        dispatch(allPostList({
            ...params, cb(res) {
                if (res?.data) {
                    setTotalPages(res?.data?.payload?.total_pages)

                    if (scrollpage == 1) {
                        setAllPostList([...res?.data?.payload?.posts])
                    } else {
                        setAllPostList((prevData) => {
                            const data = [...prevData]
                            const newData = data.concat(res?.data?.payload?.posts)
                            return newData;
                        })
                        //setAllPostList([...res?.data?.payload?.posts])
                    }
                    setTimeout(() => {
                        if (id) {
                            getSelectedPostDetails(id)
                        }
                    }, 1000)
                }
            }
        }))
    };


    const fetchMoreData = () => {
        if (totalPages > page) {
            setPages(page + 1);
            fetchData(page + 1);
        }
    };

    // handle change page
    const handleChangePage = (flag) => {
        if (flag == "edit") {
            navigate('/setting', { state: { activeTab: "editProfile" } })
        }
        else if (flag == "plan") {
            navigate('/setting', { state: { activeTab: "subscription" } })
            localStorage.setItem("activeTabSetting", "subscription")
        }
        else if (flag == "allOpportunities") {
            navigate('/opportunities', { state: { activeTab: "activeOpportunity" } })
            localStorage.setItem("activeTabSetting", "activeOpportunity")
        }
        else if (flag == "setting") {
            navigate('/setting', { state: { activeTab: "privacy" } })
            localStorage.setItem("activeTabSetting", "setting")
        }
        else {
            navigate('/opportunities', { state: { activeTab: "activeOpportunity" } })
            localStorage.setItem("activeTabSetting", "activeOpportunity")
        }
    }

    // handle  page change 
    const handlePageRedirect = (name, id) => {
        if (id) {
            navigate(`/${name}/${id}`)
        }
    }

    // handle  page change 
    const handlePageRedirectMyProfile = (name) => {
        navigate(`/${name}`)
    }

    // handle  view profile 
    const handleViewProfile = () => {
        navigate(`/${userDeatil?.company_name}`)
    }

    const handleEndorsements = () => {
        setModalDetail({ show: true, flag: "Endorsements" });
        setKey(Math.random());
    }

    // For set signup and signin Comapny Logo
    let ComapnyLogo;
    if (userDeatil?.company_logo) {
        ComapnyLogo = userDeatil?.company_logo;
    }
    else {
        ComapnyLogo = Images.companyprofile;
    }

    // API for get MyOpportunities ............
    const myOpportunity = (page = 1, limit = 10) => {
        let params = {
            page: page,
            limit: limit,
        }
        dispatch(getDashboardOpportunity({
            ...params, cb(res) {
                if (res.status) {
                    setMyOpportunities(res?.data?.payload?.data)
                }
            }
        }))
    }

    // API for get pendingEndorsement ............
    const pendingEndorsement = () => {
        let params = {
        }
        dispatch(getPendingEndorsement({
            ...params, cb(res) {
                if (res.status) {
                    setPendingEndorsements(res.data.payload)
                }
            }
        }))
    }

    // // API for inactive Update ..............
    const inActiveOpportunities = (id, flag) => {
        let params = {
            id: id,
            is_active: flag,
        }
        dispatch(activeInactiveOppertunity({
            ...params, cb(res) {
                if (res.status) {
                    myOpportunity()
                }
            }
        }))
    }

    // // API for post Feature ..............
    const postFetures = (id, flag, index) => {
        let params = {
            post_id: id,
            feature_status: flag,
        }
        dispatch(postFeature({
            ...params, cb(res) {
                if (res.status) {
                    setAllPostList((prevData) => {
                        let data = [...prevData]
                        if (flag === 2) {
                            // If you want to update the like status
                            const updatedPost = {
                                ...data[index], feature_post: res?.data?.payload?.feature_post
                            };
                            data[index] = updatedPost;
                        } else {
                            // If you want to reset the like status to null
                            const updatedPost = { ...data[index], feature_post: res?.data?.payload?.feature_post, };
                            data[index] = updatedPost;
                        }
                        return data;
                    })
                }
            }
        }))
    }

    // // API for post delete ..............
    const deletePostAction = (id, index) => {
        swal({
            text: `Are you sure want to remove this post?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: id,
                    }
                    dispatch(deletePost({
                        ...params, cb(res) {
                            if (res.status) {

                                setAllPostList((prevData) => {
                                    const data = prevData.filter((item, i) => i !== index);
                                    return data;
                                })

                                toast.success("Post deleted successfully")

                            }
                        }
                    }))
                }
            });

    }

    const editPostAction = (id) => {

        let params = {
            id: id,
        }
        dispatch(getPostSingleDetails({
            ...params, cb(res) {
                if (res.status) {

                    setEditFlag(true)
                    setEditPostID(res.data.payload?.id)
                    setPost(res.data.payload?.post ?? '')

                    const companyTagData = res.data.payload?.tag_company_details?.map((item, i) => {
                        return {
                            id: item.company_id,
                            name: item?.company_information?.company_name
                        }
                    })

                    const userTagData = res.data.payload?.tag_user_details?.map((item, i) => {
                        return {
                            id: item.user_id,
                            name: item?.user_details?.user_profile?.first_name + " " + item?.user_details?.user_profile?.last_name
                        }
                    })

                    setMainTags({
                        companies: companyTagData,
                        users: userTagData
                    })

                    setSelectedIds({
                        companies: companyTagData.map((item) => item.id),
                        users: userTagData.map((item) => item.id)
                    })

                    if (res?.data?.payload?.attachment) {

                        setPhotoUpload({
                            uploadPhoto: {

                                name: res?.data?.payload?.attachment_name ?? 'File',
                                size: res?.data?.payload?.attachment_size ?? 100000,
                                url: res?.data?.payload?.thumbnail_url

                            }, flag: res?.data?.payload?.attachment_type
                        });

                        setVideoUrlOriginal(res?.data?.payload?.attachment)

                        setPhotoInfo([res?.data?.payload?.thumbnail_url])

                    }

                    if (window)
                        window.scrollTo(0, 0);

                }
            }
        }))
    }

    const copyLinkToClipboard = (postId) => {


        const currentBaseUrl = window.location.origin;
        const dynamicUrl = `${currentBaseUrl}/dashboard/post/${postId}`;

        if (postId) {
            navigator.clipboard.writeText(dynamicUrl).then(() => {
                toast.dismiss()
                toast.success('Link copied to clipboard')
            });
        }
        else {
            toast.error('Somenthing went wrong')
        }

    }

    // // API for post delete ..............
    const disableEnableCommentAction = (id, flag, index) => {

        swal({
            text: `Are you sure want to ${flag == 1 ? 'disable' : 'enable'} comments on this post?`,
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: id,
                        disable_comment: flag
                    }
                    dispatch(disableEnableComment({
                        ...params, cb(res) {
                            if (res.status) {

                                setAllPostList((prevData) => {

                                    let data = [...prevData]
                                    data[index] = { ...data[index], disable_comment: flag }
                                    return data;

                                })

                                toast.success(res?.data?.msg)

                            }
                        }
                    }))
                }
            });

    }

    const getSelectedPostDetails = (id) => {
        let params = {
            id: id,
        }
        dispatch(getPostSingleDetails({
            ...params, cb(res) {
                if (res.status) {

                    if (res.data.payload.id) {
                        setAllPostList((prevData) => {

                            const data = prevData.filter((item, i) => res.data.payload.id != item.id);
                            const newData = [res.data.payload, ...data]

                            return newData;
                        })
                    }
                }
            }
        }))
    }

    //update Endorsements
    const updateEndorsements = (id, status) => {
        let params = {
            id: id,
            status: status
        }
        dispatch(updateEndorsementsRequest({
            ...params, cb(res) {
                if (res.status) {
                    pendingEndorsement();
                }
                else {
                }
            }
        }))
    }

    //update Endorsements
    const userProfileStatus = (flag) => {
        if (flag == 1) {
            dispatch(updateUserProfileStatus({
                cb(res) {
                    if (res.status) {
                        navigate('/setting', { state: { activeTab: "editProfile" } })
                    }
                }
            }))
        }
        else if (flag == 2) {
            dispatch(updateUserProfileStatus({
                cb(res) {
                    if (res.status) {
                        companyProfileInfo()
                    }
                }
            }))
        }

    }

    const handleObserver = (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMore) {
            // Perform additional actions if needed when the observer element is visible
            // In this example, we disable the observer to prevent further loading.
            observerRef.current = null;
        }
    };


    const handleNavigationActiveInactive = (value) => {
        // active
        if (value === 1) {
            navigate('/opportunities', {
                state: { activeTab: 'activeOpportunity' }
            });
        }
        else if (value === 0) {
            // in-active
            navigate('/opportunities', {
                state: { activeTab: 'inactiveOpportunity' }
            });
        }
    }


    useEffect(() => {
        // Create a new Intersection Observer instance
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        });

        // Attach the observer to the reference element
        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            // Clean up the observer on unmount
            observer.disconnect();
        };
    }, [hasMore]);

    useEffect(() => {
        companyProfileInfo();
        getAllDashboardData();
        fetchData()
        myOpportunity()
        pendingEndorsement()
        document.title = "Dashboard";
    }, []);


    useEffect(() => {
        if (authSelector?.userStep?.user?.user_company_information !== null) {
            setHideEditBtn(false)
        }
        else if (authSelector?.userStep?.user?.user_company_information === null) {
            setHideEditBtn(true)
        }
    }, [])

    return (
        <>
            {/* <h1><RunOneSignal/></h1> */}
            {newPost && modalDetail.show == false &&
                <div>
                    <button className="postBtn" onClick={() => {
                        fetchData(1)
                        setNewPost(false)
                        if (window)
                            window.scrollTo(0, 0);
                    }}>New Posts</button>
                </div>}
            <div className='accountDashboard'>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-3'>
                        <h3 className="mainHeading">
                            Company Profile
                        </h3>
                        <div className='companyContainerArea'>
                            <div className='motionSec ps-4 pe-4 '>

                                <div className="companylogo text-center" onClick={() => {
                                    setModalDetail({ show: true, flag: "CompanyLogo" });
                                    setKey(Math.random());
                                }}>
                                    {!userDeatil ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12 text-center pt-5 pb-5">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </>
                                        :

                                        <img src={ComapnyLogo} className='img-fluid motionImg' alt='motionImg' />
                                    }


                                    {/* <img src={userDeatil?.company_logo ? userDeatil?.company_logo : Images.companyprofile} className='img-fluid motionImg' alt='motionImg' /> */}

                                </div>
                                <div className='motionSecData'>
                                    <h5 className='headingSmall_'><b>{userSigninInfo?.company_name ? userSigninInfo?.company_name : userStepInfo?.company_name}</b></h5>
                                    <p className='motionLoc'>
                                        {userDeatil?.domestic_service_areas !== null ?
                                            <>
                                                <img src={Images.locationpin} alt='Location' className='me-1' />
                                                <span className='textInnerOuter'>{userDeatil?.domestic_service_areas}
                                                    {/* {userSigninInfo?.main_company_country_location ? userSigninInfo?.main_company_country_location : userStepInfo?.main_company_country_location} */}
                                                </span>
                                            </>
                                            : ""}
                                    </p>
                                    {/* <Link to='/setting'> */}

                                    <div className="dashboardButton">
                                        <button className='primaryBtnSmall mx-2' onClick={() => { handleViewProfile() }}>View Profile</button>
                                        {
                                            userData?.user_company_information !== null ?
                                                <button className='secondaryBtnOuter mt-2' onClick={() => { handleChangePage("plan") }}>{userData?.membership_type == 1 || userData?.membership_type == 4 ? "Platinum" : userData?.membership_type == 2 ? "Gold" || userData?.membership_type == 5 : userData?.membership_type == 3 || userData?.membership_type == 6 ? "Bronze" : ""} Plan
                                                </button>
                                                : ""
                                        }
                                    </div>

                                    {/* </Link> */}
                                </div>
                            </div>
                            <div className='companyProfile'>
                                <div className='companyDetails mt-3'>
                                    <ul className='companyDetailsMenus'>
                                        <li className='companyDetailsItems'>
                                            <button onClick={() => { handleChangePage("edit") }} className='companyDetailsLinks'>Edit Profile</button>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            {/* <Link to="/setting" className='companyDetailsLinks'></Link> */}
                                            <button onClick={() => { handleChangePage("setting") }} className='companyDetailsLinks'>Settings & Privacy</button>

                                        </li>
                                        {
                                            hideEditBtn === true ? " " :
                                                <li className='companyDetailsItems'>
                                                    <button onClick={() => handleChangePage("plan")} className='companyDetailsLinks'>Manage Membership</button>
                                                </li>
                                        }

                                        <li className='companyDetailsItems'>
                                            <Link to="/all-connections" className='companyDetailsLinks'>View Connections</Link>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/account-message" className='companyDetailsLinks'>View Messages</Link>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/opportunities" className='companyDetailsLinks'>Manage Opportunities</Link>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/business" className='companyDetailsLinks'>Targeted   Search</Link>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/resources" className='companyDetailsLinks'>Resources</Link>
                                        </li>
                                        <li className='companyDetailsItems'>
                                            <Link to="/document-view" className='companyDetailsLinks'>Documents</Link>
                                        </li>
                                        <li className='companyDetailsItems' onClick={() => { userLogout() }}>
                                            <Link to="/resources" className='companyDetailsLinks'>Logout</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className='cardContainer mt-4'>
                                <div className='cardImg'>
                                    <img src={Images.certifyUser} className='img-fluid' alt='CardImg' />
                                </div>
                                <div className='cardBodyArea'>
                                    <h5>Get Certified</h5>
                                    <div className="infoiconText">
                                        <span className="showinfoText">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                                    </div>
                                    <p className='my-4'>GetOPP$ Diversity Certifications for inclusive business futures. </p>
                                    <div className="infoiconText m-0">
                                        <Link to="/certificate">
                                            <button className='primaryBtn'>Learn More</button>
                                        </Link>
                                        <span className="showinfoText">
                                            {/* {data?.posting_title.length > 13.5 ? data?.posting_title.substring(0, 13.5) + "...." : data?.posting_title} */}

                                            {certificate_tooltip.substring(0, maxLength)}...

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-12 col-md-6 col-lg-5'>
                        <h3 className="mainHeading">Community Feed</h3>

                        {userDeatil?.is_completed == false ?
                            <div className="updateBox">
                                <figure className="m-0">
                                    <img src={Images.Cancel} className="updateIcon" onClick={() => { userProfileStatus("2") }} />
                                </figure>
                                <artical>
                                    <h5 className="updateHeading">Heads up!</h5>
                                    <p className="updateSubheading">Your company profile is not complete yet.</p>
                                </artical>
                                <button className='primaryBtnSmall' onClick={() => { userProfileStatus("1") }}>Update now</button>
                            </div>
                            : ""
                        }
                        {pendingEndorsements?.length > 0 ?
                            <>
                                {pendingEndorsements.map((endorsements, index) => {
                                    return (
                                        index == 0 ?
                                            <div className="approveEndorsement mb-5">
                                                <div className="cardBodyArea w-100">
                                                    <div className='d-flex'>
                                                        <div className='cardImg'>
                                                            <img src={endorsements?.receiver_details?.company_logo ? endorsements?.receiver_details?.company_logo : Images.companyprofile} className='img-fluid ' alt='CardImg' />
                                                        </div>
                                                        <div className='w-100'>
                                                            {/* <h6 className="text-end">{moment(endorsements?.created_at).fromNow()}</h6> */}
                                                            <h5>You recived  an endorsement
                                                                {/* {endorsements?.company_name} */}
                                                            </h5>
                                                            {/* <p>3 Motion is dedicated to their mission.</p> */}
                                                            <p className='communityMaxHeigh'>{endorsements?.endorsement}</p>
                                                            <div className="approveEndorsementButton">
                                                                <button onClick={() => { updateEndorsements(endorsements?.id, 1) }} className='primaryBtn'>Approve</button>
                                                                <button onClick={() => { updateEndorsements(endorsements?.id, 2) }} className='secondaryBtnOuter'>Reject</button>
                                                                {/* <img onClick={() => { updateEndorsements(endorsements?.id, 2) }} className="crossBtn_" src={Images.Cancel} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    )
                                })}
                            </>
                            : ""
                        }
                        <div className='postSec'>
                            {
                                loader ? <div className="row">
                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                        <div className="spinner-grow text-success" role="status"></div>
                                    </div>
                                </div>
                                    :

                                    <form className='postForm row align-items-center' onSubmit={(e) => { handleSubmit(e) }}>
                                        <div className="form-group mb-3 col-md-12">
                                            {/* <textarea name="description" type='text' value={description} onChange={e => setDescription(e.target.value)} className='customFormControl' placeholder='Type here...' rows='4'></textarea> */}
                                            <textarea type="text" className="customFormControl" placeholder="Share your business insights, opinions, and achievements" id="inputName" name="post" value={post} onChange={e => { onChangeInput(e, 'post') }} cols="4" />
                                            <div>
                                            </div>

                                        </div>

                                        <div className="form-group mb-3 col-md-12">
                                            <div className="row postTags">
                                                {mainTags.companies.map((val, index) => {
                                                    return (
                                                        <div className="tag col-md-3">
                                                            <div>
                                                                <span>{val.name}</span>
                                                            </div>
                                                            <div className="close" onClick={() => { removeTags(val.id, val.name, 'company') }}>
                                                                <i className="fa fa-times-circle" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {mainTags.users.map((val, index) => {
                                                    return (
                                                        <div className="tag col-md-3">
                                                            <div>
                                                                <span>{val.name}</span>
                                                            </div>
                                                            <div className="close" onClick={() => { removeTags(val.id, val.name, 'users') }} >
                                                                <i className="fa fa-times-circle" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    )
                                                })}


                                                <div className="add-tag col-md-3" onClick={() => {
                                                    if (post == null || post == '') {
                                                        toast.dismiss()
                                                        toast.warning('Post text must be added before adding tags')
                                                        return false;
                                                    }
                                                    setModalDetail({ show: true, flag: "addTagToPost", header: "Add tags to post" });
                                                    setKey(Math.random());
                                                }}>
                                                    <p>
                                                        + Add Tag
                                                    </p>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='col-md-6 col-sm-6 mt-2'>
                                            <input type="file" id="fileAttach"
                                                // accept="image/jpeg, image/png"
                                                accept=".jpeg, .jpg, .heic, .mp4, .gif, .png"
                                                className="form-control d-none" required="" onChange={(e) => { onChangePicture(e, 1) }} />
                                            <label className="fileupimg" htmlFor="fileAttach">
                                                <img src={Images.attach} alt='Attach' />
                                            </label>
                                        </div>
                                        <div className='col-md-6 col-sm-6 text-end mt-2'>
                                            {editFlag ?
                                                <button className='primaryBtnSmall'>Update Thread</button>
                                                :
                                                <button className='primaryBtnSmall'>Post Thread</button>
                                            }
                                        </div>

                                        {
                                            uploadloader ? <div className="row">
                                                <div className="col-sm-12 text-center pt-5 pb-5">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                                :
                                                <div className="uploadFile_ col-md-12">

                                                    {photoInfo ?
                                                        <>
                                                            {photo?.uploadPhoto != "" && photo?.uploadPhoto?.format == "video/mp4" ?
                                                                <>
                                                                    <div className="uploadProfile_">
                                                                        <video controls className="videoBox_ me-3">
                                                                            <source src={photoInfo} type="video/mp4" className='soundbiteImg' />
                                                                        </video>


                                                                        <img className="w3-round" src={photoInfo}></img>

                                                                        <h5 className='profileboldHead me-2'>{photo?.uploadPhoto?.name.length > 40.5 ? photo?.uploadPhoto?.name.substring(0, 40.5) + "...." : photo?.uploadPhoto?.name}</h5>
                                                                        <p className='profileboldgraph'>
                                                                            {/* {photo.uploadPhoto?.size} */}
                                                                            {(photo.uploadPhoto?.size / (1024 * 1024)).toFixed(2)} MB
                                                                        </p>
                                                                        <i className="fa fa-times crossfile text-red"
                                                                            onClick={removeFile(photoInfo)}
                                                                        ></i>
                                                                    </div>
                                                                </>
                                                                : photo?.uploadPhoto ?
                                                                    <>

                                                                        <ul className='uploadProfile_'>
                                                                            <img className="w3-round" src={photoInfo}></img>
                                                                            <div className='profileDetail_'>
                                                                                <h5 className='profileboldHead'>{photo?.uploadPhoto?.name > 40.5 ? photo?.uploadPhoto?.name.substring(0, 40.5) + "...." : photo?.uploadPhoto?.name}</h5>
                                                                                <p className='profileboldgraph'>
                                                                                    {/* {photo.uploadPhoto?.size} */}
                                                                                    {(photo.uploadPhoto?.size / (1024 * 1024)).toFixed(2)} MB
                                                                                </p>
                                                                            </div>
                                                                            <i className="fa fa-times crossfile text-red"
                                                                                onClick={removeFile(photoInfo)}
                                                                            ></i>
                                                                        </ul>

                                                                    </>

                                                                    : ""
                                                            }
                                                        </>
                                                        : ""
                                                    }

                                                </div>}
                                    </form>
                            }
                            <hr></hr>
                            <div className='row'>
                                <div className='col-md-12 tabindex_'>
                                    <div className='postContainer set-scroll'

                                    >
                                        {authSelector?.loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <InfiniteScroll
                                                    dataLength={allPost?.length} // Total length of the data
                                                    next={() => fetchMoreData()} // Callback function for loading more data
                                                    hasMore={true} // Set to false when there is no more data
                                                    // loader={<h4>Loading...</h4>} // Loading indicator
                                                    // endMessage={<p>No more data to load.</p>}
                                                    className="overflow-hidden"
                                                >

                                                    {allPost?.length > 0 ?
                                                        <>
                                                            {allPost?.map((post, index) => {
                                                                console.log(post, "dsfsdf");
                                                                return (
                                                                    <div className={`postContainerArea ${id && id == post.id ? `background-selected ${showNewBackground ? 'background-new' : ''}` : " "}`} key={index}  >
                                                                        <div className='posts justify-content-between'>
                                                                            <div className="posts">
                                                                                {userDeatil?.id == post?.user_id ?
                                                                                    <img onClick={() => handlePageRedirectMyProfile(post?.user_details?.company_name)} src={post?.user_details?.user_profile?.profile_photo ? post?.user_details?.user_profile?.profile_photo : Images.companyprofile} className='postUser me-3' alt='UserImage' />
                                                                                    :
                                                                                    <img onClick={() => handlePageRedirect(post?.user_details?.company_name, post?.user_details?.id)} src={post?.user_details?.user_profile?.profile_photo ? post?.user_details?.user_profile?.profile_photo : Images.companyprofile} className='postUser me-3' alt='UserImage' />
                                                                                }
                                                                                <div className='postsDetails'>
                                                                                    <p className='innerSubtext m-0'><strong>{post?.user_details?.company_name}</strong></p>
                                                                                    {userDeatil?.id == post?.user_id ?
                                                                                        <h5 onClick={() => handlePageRedirectMyProfile(post?.user_details?.company_name)} className='headingTitleSmall_ d-flex align-items-center justify-content-between mb-2'>
                                                                                            <span className="memberName">{post?.user_details?.user_profile?.first_name} {post?.user_details?.user_profile?.last_name}</span>
                                                                                        </h5> :
                                                                                        <h5 onClick={() => handlePageRedirect(post?.user_details?.company_name, post?.user_details?.id)} className='headingTitleSmall_ d-flex align-items-center justify-content-between mb-2'>
                                                                                            <span className="memberName">{post?.user_details?.user_profile?.first_name} {post?.user_details?.user_profile?.last_name}</span>
                                                                                        </h5>
                                                                                    }
                                                                                    {post?.post?.startsWith('http') || post?.post?.startsWith('https') ?
                                                                                        <a className="linkIndex_" href={post.post} target="_blank" rel="noopener noreferrer" key={key}>
                                                                                            {post.post}
                                                                                        </a>
                                                                                        : <p className='innerSubtextSmall mb-2 text-break text-start'>{post?.post}</p>
                                                                                    }
                                                                                    <span className='weeksTxt'>{moment(post?.created_at).fromNow()}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="postOptionBox">
                                                                                <div className="dropdown">
                                                                                    <p className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="las la-ellipsis-h moreOptionIcon"></i>
                                                                                    </p>

                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                                                                        {userDeatil?.id == post?.user_id &&
                                                                                            <li onClick={(e) => { postFetures(post?.id, (post?.feature_post.length > 0 ? 2 : 1), index) }} ><a className="dropdown-item">
                                                                                                {
                                                                                                    post && post?.feature_post.length > 0 ?
                                                                                                        "Remove from Featured"
                                                                                                        : "Featured on profile"
                                                                                                }
                                                                                            </a></li>
                                                                                        }

                                                                                        <li onClick={() => copyLinkToClipboard(post?.id)}><a className="dropdown-item">Copy link to post</a></li>

                                                                                        {userDeatil?.id == post?.user_id &&
                                                                                            <>
                                                                                                <li onClick={() => editPostAction(post?.id)}><a className="dropdown-item">Edit post</a></li>

                                                                                                <li onClick={(e) => { deletePostAction(post?.id, index) }} >
                                                                                                    <a className="dropdown-item">Delete post</a>
                                                                                                </li>

                                                                                                <li onClick={(e) => { disableEnableCommentAction(post?.id, post?.disable_comment == 1 ? 2 : 1, index) }} >
                                                                                                    <a className="dropdown-item">
                                                                                                        {post?.disable_comment == 1 ?

                                                                                                            "Enable comments on this post"
                                                                                                            :

                                                                                                            "Disable comments on this post"
                                                                                                        }
                                                                                                    </a>
                                                                                                </li>

                                                                                                <li><Link to="/setting" className="dropdown-item">who can see this  post ?</Link></li>
                                                                                            </>
                                                                                        }
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row postTagsList">
                                                                            {post?.tag_company_details?.map((val, index) => {
                                                                                return (
                                                                                    <div className="tag col-md-3">
                                                                                        <div>
                                                                                            <span>{val?.company_information?.company_name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                            {post?.tag_user_details?.map((val, index) => {
                                                                                return (
                                                                                    <div className="tag col-md-3">
                                                                                        <div>
                                                                                            <span>{val?.user_details?.user_profile?.first_name + " " + val?.user_details?.user_profile?.last_name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        {post?.attachment_type == 3 ?
                                                                            <>
                                                                                {post?.isvideo == true ?
                                                                                    <video controls autoPlay>
                                                                                        <source src={post?.attachment} type="video/mp4" className='soundbiteImg' />
                                                                                    </video>
                                                                                    :
                                                                                    <div className="postBox">
                                                                                        <img className="imagepost_" src={post?.thumbnail_url} />
                                                                                        <img onClick={() => handleVideoPlay(post, index)} className="videoIcon" src={Images.VideoIcon} />
                                                                                    </div>
                                                                                }
                                                                            </>


                                                                            : post?.attachment == null ?
                                                                                ""
                                                                                :
                                                                                <div className="postImage_ mt-3 mb-3" >
                                                                                    <img className="imagepost_" src={post?.attachment} />
                                                                                </div>
                                                                        }

                                                                        <div className='bottomPostSec d-flex align-items-center justify-content-end mt-1'>
                                                                            <p className="postrate_">
                                                                                <span onClick={() => totalUserLikeList(post.id,)} >{post?.likeCount}</span>
                                                                                {post?.is_like?.like_status && post?.is_like?.like_status == 1 ? <img className='likepost_ me-2' src={Images.thumbsup} onClick={(e) => { postLike(post.id, 2, index) }} />
                                                                                    : <img className='likepost_ me-2' src={Images.greyLike} onClick={(e) => { postLike(post.id, 1, index) }} />
                                                                                }
                                                                            </p>
                                                                            {/* <img src={Images.arrow} className='me-2' alt='Arrow' /> */}

                                                                            {post?.disable_comment == 2 &&
                                                                                <p className="postrate_"><span>{post?.total_comments}</span>
                                                                                    <img onClick={() => {
                                                                                        setModalDetail({ show: true, flag: "CommentBox", id: post.id });
                                                                                        setKey(Math.random());
                                                                                    }}
                                                                                        src={Images.message} className='messageiconn me-2' alt='Message' />
                                                                                </p>
                                                                            }

                                                                            {userDeatil?.id == post?.user_id &&
                                                                                <p className="postrate_"  >
                                                                                    {post && post?.feature_post.length > 0 ?
                                                                                        <img src={Images.star} onClick={(e) => { postFetures(post?.id, 2, index) }} className='messageiconn' alt='Message' />
                                                                                        : <img src={Images.StartUnfilled} onClick={(e) => { postFetures(post?.id, 1, index) }} className='messageiconn' alt='Message' />
                                                                                    }
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </>
                                                        : "No Post Found"
                                                    }
                                                    {/* </>
                                        } */}
                                                </InfiniteScroll>
                                            </>
                                        }
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <aside className='dashboardRightArea'>
                            <h3 className="mainHeading">
                                Resources
                            </h3>
                            <div className='lookSuplier'>
                                <div className='cardBox'>
                                    <div className="textBox">
                                        <h6>Business<b> Resources</b></h6>
                                        <p>Building, sustaining and growing a business is a never-ending journey that involves continuos infusions of knowledge and wisdom.</p>
                                        <button onClick={() => navigate("/resources")} className='primaryBtnSmall' >Start Learning</button>
                                    </div>
                                </div>
                            </div>
                            <div className='currentTop mt-5'>
                                <h4 className='headingTitle_'>Opportunity</h4>
                                <button className="primaryBtnSmall me-2" onClick={() => { handleChangePage("allOpportunities") }}>See all opportunities</button>
                            </div>

                            <div className='containerBoxright userOppertunities mt-3'>
                                {authSelector?.loading ?
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12 text-center pt-5 pb-5">
                                                <div className="spinner-grow text-success" role="status"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {myOpportunities?.length > 0 ?
                                            <>
                                                {myOpportunities?.map((val, index) => {

                                                    return (
                                                        <div className='deviserCompany_'>
                                                            <div className='certificationContainerBox_'>
                                                                <div className="d-flex justify-content-between w-100">
                                                                    <img className="opportunitiesBox" src={val?.opportunity_media ? val?.opportunity_media : Images.pepcicoImg} alt="suplierImg" onClick={() => handleNavigationActiveInactive(val?.is_active)} />
                                                                    <div className="opportunitiesHead w-100">
                                                                        <h5 className="postopportunity_label" onClick={() => handleNavigationActiveInactive(val?.is_active)}>{val?.posting_title}</h5>
                                                                        <p className="innerSubtext mt-2 mb-2">{val?.description}</p>
                                                                        <span className="uploadText">{moment(val?.created_at).fromNow()}</span>
                                                                    </div>
                                                                    <div>
                                                                        {val?.is_active == 1 ? <button className="secondaryBtnOuter" onClick={() => inActiveOpportunities(val?.id, 0)}>Active</button>
                                                                            : <button className="subsmallBtnblack_" onClick={() => inActiveOpportunities(val?.id, 1)}>Inactive</button>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )

                                                })
                                                }
                                            </>
                                            : "No Opportunity Found"
                                        }
                                    </>

                                }
                            </div>
                            <div className='cardContainer mt-4 d-flex'>
                                <div className='cardImg commonCartImg'>
                                    <img src={Images.bannerendsor} className='img-fluid' alt='CardImg' />
                                    <div className='cardBodyArea'>
                                        <h5>Endorsements</h5>
                                        <p className='my-5'>Elevate Connections and Inspire Growth with GetOPP$ Endorsements. </p>
                                        <div className="infoiconText m-0">
                                            <button onClick={() => { handleEndorsements() }} className='primaryBtn' >Learn More</button>
                                            <span className="showinfoText">
                                                {endorsements_tooltp.substring(0, maxLength)}...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                flag={modalDetail.flag}
                showCustomBackdrop={modalDetail.flag === "CommentBox" ? true : false}
                show={modalDetail.show}
                backdrop={modalDetail.flag === "CommentBox" ? null : "static"}
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "CommentBox" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "CommentBox" ? "commentBox" : modalDetail.flag === "CompanyLogo" ? "CompanyLogo" : modalDetail.flag === "allPostLikeUser" ? "allPostLikeUser" : modalDetail.flag === "addTagToPost" ? "addTagToPost" : modalDetail.flag === "Endorsements" ? "Endorsements" : ""}
                child={
                    modalDetail.flag === "CommentBox" ? (
                        <CommmentBox
                            id={modalDetail.id}
                            handleVideoPlay={() => handleVideoPlay()}
                            close={() => handleOnCloseModal()} />
                    ) : modalDetail.flag === "CompanyLogo" ?
                        (
                            <CompanyLogo
                                close={() => handleOnCloseModal()} />
                        )
                        :

                        modalDetail.flag == "allPostLikeUser" ?
                            <AllPostLikeUser
                                id={modalDetail.id}
                                close={() => handleOnCloseModal()} />

                            : modalDetail.flag == "addTagToPost" ?
                                <AddTagToPost
                                    id={modalDetail.id}
                                    close={() => handleOnCloseModal()}
                                    updateTagData={(data, ids) => updateTagData(data, ids)}
                                    selectedCompanies={mainTags.companies.length > 0 ? mainTags.companies : []}
                                    selectedUsers={mainTags.users.length > 0 ? mainTags.users : []}
                                /> : modalDetail.flag == "Endorsements" ?
                                    <EndorsementsText
                                        close={() => handleOnCloseModal()} />
                                    : ""
                }
                header={
                    modalDetail.flag === "CommentBox" ?
                        <h2 className="headingSmall_">
                            Comments
                        </h2>
                        :
                        <div className='modalHeader_'>
                            <div className="common_">
                                {modalDetail.flag === "CompanyLogo" ?
                                    <h2 className="headingSmall_">
                                        Company Logo
                                    </h2>
                                    : modalDetail.flag == "allPostLikeUser" ?
                                        <h2 className="headingSmall_">
                                            Post Like List
                                        </h2>
                                        : modalDetail.flag == "addTagToPost" ?
                                            <h2 className="headingSmall_">
                                                {modalDetail.header}
                                            </h2>
                                            :
                                            ""}
                            </div>
                        </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default AccountDashboard
